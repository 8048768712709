import type { IScopeModel, IUIAssetCreationMeta } from "@/models/global.model";
import type { IStatusColOptions } from "@/models/table.model";
import {
  type AssetCreationRequest,
  type AssetMeta,
  type WorkloadRefAndStatus,
  type AssetUsageInfo,
  AssetSyncStatusEnum,
  AssetClusterStatusIssueEnum,
  type AssetClusterStatusInfo,
} from "@/swagger-models/assets-service-client";
import { ScopeType } from "@/swagger-models/authorization-client";
import { tableUtil } from "../table.util";
import type { PolicyMeta } from "@/swagger-models/policy-service-client";

export const assetsUtil = {
  filterById,
  prepareAssetMetaToCreationRequest,
  extractWorkloadNames,
  extractNames,
  setAssetScopeFromQuery,
  getStatusColOptions,
};

interface IAssetMeta {
  meta: AssetMeta | PolicyMeta;
}

function filterById<T extends IAssetMeta>(list: T[], entityToFilter: T | null): T[] {
  if (!entityToFilter) return list;
  return list.filter((asset: T) => asset.meta.id !== entityToFilter.meta.id);
}

function prepareAssetMetaToCreationRequest(uiModelMeta: IUIAssetCreationMeta): AssetCreationRequest | null {
  if (!uiModelMeta.scope) return null;
  return {
    ...uiModelMeta,
    scope: uiModelMeta.scope,
  };
}

function extractWorkloadNames(usageInfo: AssetUsageInfo): string[] {
  const workspaces = extractNames(usageInfo.workspaces || []);
  const trainings = extractNames(usageInfo.trainings || []);
  const inferences = extractNames(usageInfo.inferences || []);
  const distributed = extractNames(usageInfo.distributed || []);

  return [...workspaces, ...trainings, ...inferences, ...distributed];
}

function extractNames(workloadsRefs: Array<WorkloadRefAndStatus>): Array<string> {
  return workloadsRefs.map((workload: WorkloadRefAndStatus) => workload.name);
}

function setAssetScopeFromQuery(assetMeta: IUIAssetCreationMeta, query: IScopeModel) {
  assetMeta.scope = query.scope;
  if (query.scope === ScopeType.Cluster && query.clusterId) {
    assetMeta.clusterId = query.clusterId;
  } else if (query.scope === ScopeType.Department && query.departmentId) {
    assetMeta.departmentId = query.departmentId;
  } else if (query.scope === ScopeType.Project && query.projectId) {
    assetMeta.projectId = +query.projectId;
  }
  return assetMeta;
}

function getStatusColOptions(status: AssetClusterStatusInfo | undefined, createdAt: string): IStatusColOptions {
  if (!status) {
    return {
      status: "-",
      tooltipText: "",
      displayAnimation: false,
      filterKey: "status",
    };
  }

  return getAssetStatusOptions(status, createdAt);
}

function getAssetStatusOptions(status: AssetClusterStatusInfo, createdAt: string): IStatusColOptions {
  let colStatusOption: IStatusColOptions;

  if (status.issues) {
    const oneMinuteAgo = Date.now() - 60000;
    const createdAtTimestamp = Date.parse(createdAt);
    if (createdAtTimestamp > oneMinuteAgo) {
      colStatusOption = {
        status: "Creating...",
        displayAnimation: true,
        filterKey: "status",
      };
      return tableUtil.getStatusColOptions(colStatusOption);
    }
    if (status.issues.filter((i) => i.issue === AssetClusterStatusIssueEnum.NotCreated).length) {
      colStatusOption = {
        status: AssetSyncStatusEnum.IssuesFound,
        color: "negative",
        displayAnimation: false,
        filterKey: "status",
        showIconToolTip: true,
        tooltipText: status.message,
      };
    } else {
      colStatusOption = {
        status: AssetSyncStatusEnum.IssuesFound,
        color: "warning",
        displayAnimation: false,
        filterKey: "status",
        showIconToolTip: true,
        tooltipText: status.message,
      };
    }
  } else {
    if (status.status === AssetSyncStatusEnum.NoIssuesFound) {
      colStatusOption = {
        status: status.status,
        color: "success",
        displayAnimation: false,
        filterKey: "status",
        showIconToolTip: true,
        tooltipText: status.message,
      };
    } else {
      colStatusOption = {
        status: AssetSyncStatusEnum.NoStatus,
        displayAnimation: false,
        filterKey: "status",
        showIconToolTip: true,
        tooltipText: status.message,
      };
    }
  }

  if (status.url) {
    colStatusOption.tooltipText += `. <a href="${status.url}" target="_blank">Contact Run:ai support.</a>`;
  }

  return tableUtil.getStatusColOptions(colStatusOption);
}
