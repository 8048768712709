<template>
  <section class="workspace-template-edit column items-center q-pt-md">
    <workload-default-create-form
      v-if="isPageReady && selectedProject"
      :workload-type="formType"
      :workload="workspace"
      :selected-project="selectedProject"
      submit-btn-text="Create Workspace"
      :submitting="submitting"
      :workload-sections-config="workloadSectionsConfig"
      @workload-changed="onWorkloadChanged"
      @canceled="onCancel"
      @submit="onSubmit"
      @back-clicked="onBack"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

// components
import { WorkloadDefaultCreateForm } from "@/components/workload/workload-create/workload-default-create-form";

// models
import { EWorkloadFormType, EWorkloadType, type IUIWorkloadCreation } from "@/models/workload.model";
import type { IProject, ISelectedNodeAffinity } from "@/models/project.model";
import type { IAssetsFilter } from "@/models/filter.model";
import type { WorkspacePolicyV2 } from "@/swagger-models/policy-service-client";
import {
  type IWorkloadCreateFormConfig,
  EWorkloadSectionNames,
} from "@/components/workload/workload-create/workload-create-form";
import type { IUIWorkloadEnvSectionModel, IWorkloadEnvSectionOptions } from "@/components/section/environment-section";
import type { IWorkloadComputeSectionOptions } from "@/components/section/compute-resource-section";
import {
  Scope,
  type PVCAsset,
  AssetKind,
  type EnvironmentAsset,
  type ComputeAsset,
  type AssetIdAndKind,
  type DatasourceListResponseEntry,
  type WorkspaceCreationRequestV2,
  type WorkloadCreationRequest,
  type WorkspaceV2,
} from "@/swagger-models/assets-service-client";
import type { IUIVolume } from "@/models/data-source.model";

// stores
import { useAppStore } from "@/stores/app.store";
import { useWorkspaceStore } from "@/stores/workspace.store";
import { useDataSourceStore } from "@/stores/data-source.store";
import { useEnvironmentStore } from "@/stores/environment.store";
import { useComputeResourceStore } from "@/stores/compute-resource.store";
import { useProjectStore } from "@/stores/project.store";
import { useClusterStore } from "@/stores/cluster.store";
import { useWorkloadStore } from "@/stores/workload.store";
import { useAuthStore } from "@/stores/auth.store";

// services
import { dataSourceService } from "@/services/control-plane/data-source.service/data-source.service";
import { workspaceService } from "@/services/control-plane/workspace.service/workspace.service";
import { requestToLeave } from "@/services/infra/router.service/router.service";

// utils
import { alertUtil } from "@/utils/alert.util";
import { fallbackDefaultIfNullOrUndefined } from "@/utils/common.util";
import { policyUtil } from "@/utils/policy.util";
import { workloadUtil } from "@/utils/workload.util/workload.util";
import { dataSourceUtil } from "@/utils/data-source.util";
import { environmentSectionUtil } from "@/components/section/environment-section";
import { ErrorAlert } from "@/utils/error-alert.util";
import { workloadCreateFormUtil } from "@/components/workload/workload-create/workload-create-form/workload-create-form.util";

// routes
import { WORKSPACE_ROUTE_NAMES } from "@/router/workspace.routes/workspace.routes.names";
import type { IWorkspaceList } from "@/models/workspace.model";
import { WORKLOAD_ROUTE_NAMES } from "@/router/workloads.routes/workloads.routes.names";
import { MIN_WORKLOAD_CREATION_V2_VERSION } from "@/common/version.constant";
import { useSettingStore } from "@/stores/setting.store";

export default defineComponent({
  components: {
    WorkloadDefaultCreateForm,
  },
  provide() {
    return {
      policy: computed(() => this.workspacePolicy),
    };
  },
  data() {
    return {
      appStore: useAppStore(),
      projectStore: useProjectStore(),
      workspaceStore: useWorkspaceStore(),
      environmentStore: useEnvironmentStore(),
      computeResourceStore: useComputeResourceStore(),
      workloadStore: useWorkloadStore(),
      authStore: useAuthStore(),
      nodeAffinity: [] as Array<ISelectedNodeAffinity>,
      storageClasses: [] as Array<string>,
      dataSourceStore: useDataSourceStore(),
      clusterStore: useClusterStore(),
      settingStore: useSettingStore(),
      formType: EWorkloadFormType.Workspace,
      workspace: workloadUtil.getEmptyUIWorkloadCreation() as IUIWorkloadCreation,
      workspacePolicy: null as WorkspacePolicyV2 | null,
      submitting: false as boolean,
      workloadSectionsConfig: {} as IWorkloadCreateFormConfig,
      envSectionOptions: {
        canAddEnvVariable: true,
      } as IWorkloadEnvSectionOptions,
      computeSectionOptions: {
        applyPolicyDefaults: false,
      } as IWorkloadComputeSectionOptions,
      assetsFilter: {} as IAssetsFilter,
    };
  },
  async created() {
    const { kind, fromCopyId, createdEntityId } = this.$route.query;
    this.workspace = this.workspaceStore.workspace;
    try {
      if (fromCopyId) {
        await this.loadFromExistingWorkspace(String(fromCopyId));
      }
      this.assetsFilter = {
        projectId: this.workspace.projectId,
        complyToProject: this.workspace.projectId,
        complyToWorkloadType: EWorkloadType.Workspace,
      };

      await this.loadProjects();
      await this.loadPolicy();

      const createdEnvironmentId: string | undefined =
        kind === AssetKind.Environment ? createdEntityId?.toString() : undefined;
      const createdComputeId: string | undefined = kind === AssetKind.Compute ? createdEntityId?.toString() : undefined;

      const isDataSource = [
        AssetKind.HostPath,
        AssetKind.Nfs,
        AssetKind.Git,
        AssetKind.S3,
        AssetKind.Pvc,
        AssetKind.ConfigMap,
        AssetKind.SecretVolume,
      ].some((assetKind) => assetKind === kind);

      const createdDataSourceId: string | undefined = isDataSource ? createdEntityId?.toString() : undefined;

      await Promise.all([
        this.loadEnvironments(createdEnvironmentId),
        this.loadComputeResources(createdComputeId),
        this.loadDataSources(createdDataSourceId, kind as AssetKind),
        this.loadStorageClasses(),
      ]);

      this.loadNodeAffinity();

      this.computeSectionOptions.applyPolicyDefaults = !!fromCopyId || !!this.workspaceStore.templateId;
      this.applyPolicyDefaultsAndRules();
      this.setWorkloadSectionsConfig(createdDataSourceId, fromCopyId ? String(fromCopyId) : undefined);
      this.appStore.setPageLoading(false);
    } catch (error: unknown) {
      this.$q.notify(alertUtil.getError("Failed to load assets"));
      console.error(error);
      this.appStore.setFallback(true);
    }
  },
  computed: {
    isPageReady(): boolean {
      return !this.appStore.isPageLoading;
    },
    projects(): Array<IProject> {
      return this.projectStore.projectList;
    },
    selectedProject(): IProject | undefined {
      return this.projects.find((project: IProject) => project.id === this.projectId);
    },
    projectId(): number {
      return this.workspace.projectId;
    },
    environments(): Array<EnvironmentAsset> {
      return this.environmentStore.environmentList;
    },
    computeResources(): Array<ComputeAsset> {
      return this.computeResourceStore.computeResourcesList;
    },
    dataSources(): Array<DatasourceListResponseEntry> {
      return this.dataSourceStore.dataSourceList;
    },
    clusterUid(): string {
      return this.clusterStore.currentClusterId;
    },
  },
  methods: {
    setWorkloadSectionsConfig(createdDataSourceId: string | undefined, fromCopyId: string | undefined): void {
      const defaultOptions: IWorkloadCreateFormConfig = workloadCreateFormUtil.getWorkloadSectionDefaultsConfig([
        EWorkloadSectionNames.Project,
        EWorkloadSectionNames.WorkloadName,
        EWorkloadSectionNames.Environment,
        EWorkloadSectionNames.Compute,
        EWorkloadSectionNames.Volumes,
        EWorkloadSectionNames.DataSource,
        EWorkloadSectionNames.General,
      ]);

      this.workloadSectionsConfig.project = {
        ...defaultOptions.project,
        projects: this.projects,
      };

      this.workloadSectionsConfig.workloadName = {
        ...defaultOptions.workloadName,
        sectionDefaultOpen: !!fromCopyId,
      };

      this.workloadSectionsConfig.multiNode = {
        ...defaultOptions.multiNode,
        sectionDefaultOpen: false,
      };

      this.workloadSectionsConfig.environment = {
        ...defaultOptions.environment,
        environments: this.environments,
        sectionOptions: {
          ...defaultOptions.environment?.sectionOptions,
          ...this.envSectionOptions,
        },
      };

      this.workloadSectionsConfig.compute = {
        ...defaultOptions.compute,
        computeResources: this.computeResources,
        nodeAffinity: this.nodeAffinity,
        sectionOptions: {
          ...defaultOptions.compute?.sectionOptions,
          ...this.computeSectionOptions,
        },
      };

      this.workloadSectionsConfig.dataSource = {
        ...defaultOptions.dataSource,
        dataSources: this.dataSources,
        sectionDefaultOpen: !!createdDataSourceId,
      };

      this.workloadSectionsConfig.volumes = {
        ...defaultOptions.volumes,
        storageClasses: this.storageClasses,
      };

      this.workloadSectionsConfig.general = {
        ...defaultOptions.general,
      };
    },
    async loadFromExistingWorkspace(workspaceId: string): Promise<void> {
      try {
        // TODO: need to raise an issue of what if this workload was not created by the assets API
        const originalWorkspace: WorkspaceV2 = await this.workspaceStore.loadById(workspaceId);
        let uiVolumes: Array<IUIVolume> | undefined;
        if (originalWorkspace.assets.workloadVolumes?.length) {
          const pvcs: Array<PVCAsset> = await dataSourceService.loadPVCAssets(originalWorkspace.assets.workloadVolumes);
          uiVolumes = dataSourceUtil.mapPvcsToUiVolumes(pvcs);
        }
        this.workspace = workloadUtil.convertWorkloadToWorkloadUI(originalWorkspace, uiVolumes);
        this.saveWorkspace(this.workspace);
      } catch (error: unknown) {
        console.error("failed to get workspace with id:", workspaceId, error);
        this.$q.notify(alertUtil.getError("Failed to load workspace"));
        this.appStore.setFallback(true);
      }
    },
    async loadEnvironments(createdEnvironmentId: string | undefined): Promise<void> {
      if (!this.selectedProject) return;
      const environmentsFilter = {
        ...this.assetsFilter,
        isWorkspace: true,
      };
      await this.environmentStore.loadEnvironments(environmentsFilter);
      if (createdEnvironmentId) {
        this.setEnvironment(createdEnvironmentId);
      }

      // unselect non compliance environment (from policy)
      if (this.workspace.assets.environment) {
        const selectedEnvironment: EnvironmentAsset | undefined = this.environments.find(
          (environment: EnvironmentAsset) => environment.meta.id === this.workspace.assets.environment,
        );

        if (!selectedEnvironment?.compliance?.compliance) {
          this.workspace.assets.environment = "";
        }
      }
    },
    async loadComputeResources(createdComputeResourceId: string | undefined): Promise<void> {
      if (!this.selectedProject) return;
      await this.computeResourceStore.loadComputeResources(this.assetsFilter);

      // set the last created data source as selected
      if (createdComputeResourceId) {
        this.setComputeResource(String(createdComputeResourceId));
      }

      // unselect non compliance compute (from policy)
      if (this.workspace.assets.compute) {
        const selectedCompute: ComputeAsset | undefined = this.computeResources.find(
          (compute: ComputeAsset) => compute.meta.id === this.workspace.assets.compute,
        );

        if (!selectedCompute?.compliance?.compliance) {
          this.workspace.assets.compute = "";
        }
      }
    },
    async loadDataSources(createdDataSourceId: string | undefined, kind: AssetKind): Promise<void> {
      if (!this.selectedProject) return;
      await this.dataSourceStore.loadDataSources(this.assetsFilter);

      // TODO: we need to move it to apply policy
      const nonComplianceDataSources: Set<string> = new Set(
        this.dataSources
          .filter((dataSource: DatasourceListResponseEntry) => !dataSource.compliance?.compliance)
          .map((dataSource: DatasourceListResponseEntry) => dataSource.meta.id),
      );

      // set the last created data source as selected
      if (createdDataSourceId && kind) {
        this.setDataSource(createdDataSourceId, kind);
      }

      // force selecting imposed (by policy) data sources
      this.dataSources.forEach((dataSource: DatasourceListResponseEntry) => {
        if (dataSource.compliance?.imposed) {
          this.setDataSource(dataSource.meta.id, dataSource.meta.kind);
        }
      });

      // unselect non compliance data sources (from policy)
      this.workspace.assets.datasources = this.workspace.assets.datasources?.filter(
        (datasource: AssetIdAndKind) => !nonComplianceDataSources.has(datasource.id),
      );
    },
    async loadStorageClasses(): Promise<void> {
      this.storageClasses = await dataSourceService.listStorageClass(this.clusterUid);
    },
    loadNodeAffinity(): void {
      if (!this.selectedProject) this.nodeAffinity = [];
      this.nodeAffinity = this.projectStore.getInteractiveNodeAffinityByProjectId(this.workspace.projectId);
      if (this.workspace.specificEnv && this.nodeAffinity && this.nodeAffinity.length === 1)
        this.workspace.specificEnv.nodeType = this.nodeAffinity[0].name;
    },
    async loadProjects(): Promise<void> {
      await this.projectStore.loadProjects({
        withAccessRules: false,
        withNamespace: true,
        withMetrics: false,
        clusterId: this.clusterStore.currentClusterId,
      });
    },
    async loadPolicy(): Promise<void> {
      if (!this.selectedProject) return;
      this.workspacePolicy = await workspaceService.getPolicy(this.projectId);
    },
    setEnvironment(environmentId: string): void {
      const environment = this.environments?.find((environment) => environment.meta.id === environmentId);
      if (!environment) return;

      const specificEnv: IUIWorkloadEnvSectionModel = environmentSectionUtil.getSpecificEnvFromEnvironment(environment);
      this.workspace.assets.environment = environmentId;
      this.workspace.specificEnv = { ...this.workspace.specificEnv, ...specificEnv };
    },
    setComputeResource(id: string): void {
      this.workspace.assets.compute = id;
    },
    setDataSource(id: string, kind: AssetKind): void {
      if (!this.workspace.assets.datasources) this.workspace.assets.datasources = [];
      const alreadyExist: AssetIdAndKind | undefined = this.workspace.assets.datasources.find(
        (datasource: AssetIdAndKind) => datasource.id === id,
      );
      if (!alreadyExist) {
        this.workspace.assets.datasources.push({ id, kind });
      }
    },
    removeImposedDataSources(): void {
      if (!this.workspace.assets.datasources) return;

      const imposedIds = this.dataSources.reduce(
        (acc: Record<string, string>, dataSource: DatasourceListResponseEntry) => {
          if (dataSource.compliance?.imposed) {
            acc[dataSource.meta.id] = dataSource.meta.id;
          }
          return acc;
        },
        {},
      );

      this.workspace.assets.datasources = this.workspace.assets.datasources.filter((datasource: AssetIdAndKind) => {
        return !imposedIds[datasource.id];
      });
    },
    async onSubmit(): Promise<void> {
      try {
        let workspaceAdded: IWorkspaceList | WorkspaceV2 | null = null;
        this.submitting = true;
        this.workspace.clusterId = this.clusterUid;
        this.workspace.namespace = this.projectStore.getNamespaceByProjectId(this.workspace.projectId);

        // making sure imposed data sources are removed before submitting
        this.removeImposedDataSources();

        this.saveWorkspace(this.workspace);
        let workloadVolumes: Array<string> | undefined;
        if (this.workspace.assets.uiVolumes?.length) {
          workloadVolumes = await dataSourceService.createWorkloadVolumes(
            this.workspace.name,
            this.workspace.assets.uiVolumes,
            {
              scope: Scope.Project,
              projectId: this.projectId,
            },
          );
        }

        const isWorkloadCreationV2 =
          this.clusterStore.isClusterVersionSufficient(this.clusterUid, MIN_WORKLOAD_CREATION_V2_VERSION) &&
          this.settingStore.isWorkloadSubmissionV2Enabled;

        if (isWorkloadCreationV2) {
          const workloadCreationRequestV2: WorkspaceCreationRequestV2 = workloadUtil.getWorkspaceCreationRequestV2(
            this.workspace,
            workloadVolumes,
          );
          workspaceAdded = await this.workspaceStore.createWorkspaceV2(workloadCreationRequestV2);
        } else {
          const workloadCreationRequest: WorkloadCreationRequest = workloadUtil.getWorkloadCreationRequest(
            this.workspace,
            workloadVolumes,
          );

          if (workloadCreationRequest.specificEnv?.backoffLimit && !this.clusterStore.isVersionSupportBackoffLimit) {
            workloadCreationRequest.specificEnv.backoffLimit = null;
          }

          workspaceAdded = await this.workspaceStore.createWorkspace(workloadCreationRequest);
        }

        if (workspaceAdded) {
          this.workloadStore.setWorkloadAdded(workspaceAdded);
          this.$q.notify(alertUtil.getSuccess(`Workspace ${this.workspace.name} created`));
          this.redirectToPrevRoute();
        }
      } catch (error: unknown) {
        const errorAlert = new ErrorAlert({
          generalMessage: ErrorAlert.failedCreateMessage("workspace"),
        });
        this.$q.notify(errorAlert.getNotification(error));
      } finally {
        this.submitting = false;
      }
    },
    applyPolicyDefaultsAndRules(): void {
      this.applyPolicyForEnvironmentSection();
      this.saveWorkspace(this.workspace);
    },
    applyPolicyForEnvironmentSection(): void {
      if (!this.workspacePolicy?.effective) return;

      if (this.workspacePolicy.effective.defaults?.environmentVariables) {
        this.workspace.specificEnv.environmentVariables = policyUtil.mergeEnvironmentVariablesWithPolicyDefaults(
          this.workspace.specificEnv.environmentVariables,
          this.workspacePolicy.effective,
        );
      }

      if (this.workspacePolicy.effective.rules?.environmentVariables?.instances) {
        this.envSectionOptions.canAddEnvVariable = fallbackDefaultIfNullOrUndefined<boolean>(
          this.workspacePolicy.effective.rules?.environmentVariables?.instances.canAdd,
          true,
        );
      }
    },
    saveWorkspace(workspace: IUIWorkloadCreation): void {
      this.workspaceStore.setWorkspace(workspace);
    },
    async onCancel(): Promise<void> {
      const allowToLeave: boolean = await requestToLeave();
      if (allowToLeave) {
        this.redirectToPrevRoute();
      }
    },
    redirectToPrevRoute(): void {
      this.$router.push({ name: WORKLOAD_ROUTE_NAMES.WORKLOAD_INDEX });
    },
    onBack(): void {
      this.$router.push({ name: WORKSPACE_ROUTE_NAMES.WORKSPACE_NEW });
    },
    onWorkloadChanged(workspace: IUIWorkloadCreation): void {
      this.workspace = workspace;
      this.saveWorkspace(workspace);
    },
  },
});
</script>
