<template>
  <section class="backoff-limit-section">
    <div class="row items-center">
      <span>Set the backoff limit before workload failure</span>
      <runai-tooltip class="q-ml-md" :tooltip-text="tooltipText" />
    </div>
    <div class="row">
      <q-input
        class="col-3"
        type="number"
        min="1"
        :model-value="modelValue"
        @update:model-value="updateBackoffLimit"
        label="Attempts"
        stack-label
        no-error-icon
        :rules="[validBackoffLimit]"
      />
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Cmps
import { RunaiTooltip } from "@/components/common/runai-tooltip";

export default defineComponent({
  name: "backoff-limit-section",
  components: {
    RunaiTooltip,
  },
  emits: ["update:model-value"],
  props: {
    modelValue: {
      type: [Number, null, undefined] as PropType<number | null | undefined>,
      required: true,
    },
  },
  data() {
    return {
      tooltipText: `The backoff limit is the maximum number of retry attempts for failed workloads. After reaching the limit, the workload's status will change to "Failed."`,
    };
  },
  computed: {},
  methods: {
    updateBackoffLimit(val: string | number | null): void {
      this.$emit("update:model-value", val ? +val : 0);
    },
    validBackoffLimit(val: string | number | null): string | boolean {
      return (val !== null && !!+val) || "Enter a number between 1 and 100";
    },
  },
});
</script>
