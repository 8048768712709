<template>
  <div class="column items-center q-pt-md">
    <project-edit-form
      v-if="!loading"
      :is-project-submitting="isProjectSubmitting"
      :project="project"
      :is-department-enabled="isDepartmentEnabled"
      @on-save="onSave"
      @on-cancel="onCancel"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PROJECT_ROUTE_NAMES } from "@/router/project.routes/project.routes.names";
// cmps
import { ProjectEditForm } from "@/components/project/project-edit-form";
// services
import { alertUtil } from "@/utils/alert.util";
import { requestToLeave } from "@/services/infra/router.service/router.service";
// stores
import { useAppStore } from "@/stores/app.store";
import { useProjectStore } from "@/stores/project.store";
import { useSettingStore } from "@/stores/setting.store";
import { useAuthStore } from "@/stores/auth.store";
// models
import { EProjectColumnName, type INodePoolResources, type IProject, type IProjectCreate } from "@/models/project.model";
import {
  OVER_QUOTA_ENABLED_VALUE,
  RESOURCE_MAX_ALLOWED_INFINITE_VALUE,
  OVER_QUOTA_DISABLED_VALUE,
  EOverQuotaPriority,
} from "@/models/resource.model";
import { ConflictError, HttpErrorResponse } from "@/models/http-response.model";
import { DEFAULT_RESOURCES_VALUE } from "@/models/resource.model";
import { NODE_AFFINITY_DEFAULT_VALUE } from "@/models/project.model";
import { projectUtil } from "@/utils/project.util";
import type { IDepartment } from "@/models/department.model";
import { DEFAULT_DEPARTMENT_NAME } from "@/models/department.model";
import { useClusterStore } from "@/stores/cluster.store";
import { departmentService } from "@/services/control-plane/department.service/department.service";
import { projectService } from "@/services/control-plane/project.service/project.service";
import { resourceUtil } from "@/utils/resource.util";
import { CLUSTER_COLUMN_FILTER_NAME, type IFilterBy } from "@/models/filter.model";
import { filterService } from "@/services/filter.service/filter.service";
import { ETableFilters } from "@/models/table.model";
import { projectIndexColumns } from "@/table-models/project.table-model";
import type { INodePool } from "@/models/node-pool.model";
import { nodePoolService } from "@/services/control-plane/node-pool.service/node-pool.service";

export default defineComponent({
  components: { ProjectEditForm },
  data() {
    return {
      loading: false as boolean,
      isProjectSubmitting: false as boolean,
      appStore: useAppStore(),
      authStore: useAuthStore(),
      projectStore: useProjectStore(),
      settingStore: useSettingStore(),
      clusterStore: useClusterStore(),
      departments: [] as Array<IDepartment>,
      project: {
        clusterUuid: "",
        name: "",
        namespace: "",
        nodePoolsResources: [],
        defaultNodePools: [],
        resources: { ...DEFAULT_RESOURCES_VALUE },
        nodeAffinity: { ...NODE_AFFINITY_DEFAULT_VALUE },
      } as IProject | IProjectCreate,
    };
  },
  async created() {
    try {
      this.loading = true;
      this.departments = await departmentService.list(this.activeClusterFilterId);
      await this.loadProject();
    } catch (err) {
      console.log(err);
      this.appStore.setFallback(true);
    } finally {
      this.loading = false;
      this.appStore.setPageLoading(false);
    }
  },
  computed: {
    isNewProject(): boolean {
      return this.$route.name === PROJECT_ROUTE_NAMES.PROJECT_NEW;
    },
    projectId(): number | null {
      return +this.$route.params.id;
    },
    departmentId(): number | undefined {
      return this.project.departmentId;
    },
    isDepartmentEnabled(): boolean {
      return this.settingStore.isDepartmentEnabled;
    },
    isCpuEnabled(): boolean {
      return this.settingStore.isCPUResourcesQuotaEnabled;
    },
    isOverQuotaPriorityEnabled(): boolean {
      return this.settingStore.isOverQuotaPriorityEnabled;
    },
    activeClusterFilterId(): string {
      return this.clusterStore.getClusterIdFromFilters(ETableFilters.PROJECT);
    },
  },
  methods: {
    async loadProject(): Promise<void> {
      await this.projectStore.loadNamespaces();

      if (this.isNewProject) {
        await this.loadNewProject();
      } else if (this.projectId) {
        await this.loadExistingProject(this.projectId);
      }
    },
    async loadNewProject(): Promise<void> {
      let defaultDepartmentId;

      if (this.settingStore.isDepartmentEnabled) {
        //select 'default' department if its under user scope, otherwise select the first department.
        const defaultDepartment = this.departments.find(
          (department: IDepartment) => department.name === DEFAULT_DEPARTMENT_NAME,
        );
        defaultDepartmentId = defaultDepartment?.id || this.departments[0]?.id;
      } else if (this.departments.length > 0) {
        defaultDepartmentId = this.departments[0]?.id;
      }

      this.project = await projectService.getEmptyProjectModel(this.isCpuEnabled, this.activeClusterFilterId, {
        departmentId: defaultDepartmentId,
      });
    },
    async loadExistingProject(projectId: number): Promise<void> {
      //@ts-ignore - we use for project type IProject client side type and not the server side type
      const project: IProject = await projectService.getProjectById(this.activeClusterFilterId, projectId);
      project.nodePoolsResources = resourceUtil.sortNodePools(project.nodePoolsResources);

      if (!this.isOverQuotaPriorityEnabled && project) {
        // Backend might mutate overQuotaWeight in some cases, need to assign the correct value based on maxAllowed
        project.nodePoolsResources.forEach((nodePool: INodePoolResources) => {
          nodePool.gpu.overQuotaWeight =
            nodePool.gpu.maxAllowed === RESOURCE_MAX_ALLOWED_INFINITE_VALUE
              ? OVER_QUOTA_ENABLED_VALUE
              : OVER_QUOTA_DISABLED_VALUE;
        });
      } else {
        project.nodePoolsResources.forEach((nodePool: INodePoolResources) => {
          if (nodePool.gpu.overQuotaWeight === EOverQuotaPriority.Lowest) {
            if (nodePool.gpu.maxAllowed === RESOURCE_MAX_ALLOWED_INFINITE_VALUE) {
              nodePool.gpu.overQuotaWeight = EOverQuotaPriority.Lowest;
            } else {
              nodePool.gpu.overQuotaWeight = EOverQuotaPriority.None;
            }
          }
        });
      }

      if (project) {
        this.project = project;
      }
    },
    async getClusterNodePools(clusterId: string): Promise<INodePoolResources[]> {
      const nodePools: Array<INodePool> = await nodePoolService.getNodePools(clusterId);
      return nodePools.map((np: INodePool) => {
        return {
          nodePool: {
            id: np.id,
            name: np.name,
          },
          ...departmentService.getNodeResourcesModel(this.isCpuEnabled),
        };
      });
    },
    async onCancel(): Promise<void> {
      const allowToLeave: boolean = await requestToLeave();
      if (allowToLeave) {
        this.$router.back();
      }
    },
    async onSave(project: IProject | IProjectCreate): Promise<void> {
      //@ts-ignore - we are about to move project to use new api types.
      const { clusterUuid, id, ...projectData } = project;
      projectUtil.removeProjectDeprecatedPostFields(project);
      this.isProjectSubmitting = true;
      projectUtil.updateMaxAllowedOverQuota(project, this.isOverQuotaPriorityEnabled);

      if (!this.isCpuEnabled) {
        projectUtil.resetCpuResources(project);
      }

      let savedProject: IProject;
      try {
        if (this.isNewProject) {
          savedProject = await projectService.createProject(projectData as IProjectCreate, clusterUuid as string);
          await useAuthStore().loadUserOrgUnits();
        } else {
          savedProject = await projectService.updateProject(projectData as IProject, id, clusterUuid as string);
        }
        this.redirect(savedProject);
        this.$q.notify(alertUtil.getSuccess(`Project ${project.name} ${this.isNewProject ? "created" : "saved"}`));
      } catch (error: unknown) {
        this.handleError(project, error);
      } finally {
        this.isProjectSubmitting = false;
      }
    },
    redirect(savedProject: IProject): void {
      const routeQuery = this.$route.query.backToPage;
      const routeName = routeQuery ? routeQuery.toString() : PROJECT_ROUTE_NAMES.PROJECT_INDEX;
      const shouldApplyClusterFilter = this.isNewProject && routeName === PROJECT_ROUTE_NAMES.PROJECT_INDEX;

      if (shouldApplyClusterFilter) {
        this.setClusterColumnFilter(savedProject);
      }

      this.$router.push({
        name: routeName,
        query: { createdEntityId: savedProject.id },
      });
    },
    setClusterColumnFilter(project: IProject): void {
      const filterBy: IFilterBy = this.getProjectsFilterBy();
      filterService.setColumnFilter(
        filterBy,
        project.clusterUuid as string,
        CLUSTER_COLUMN_FILTER_NAME,
        ETableFilters.PROJECT,
      );
    },
    getProjectsFilterBy(): IFilterBy {
      const defaultFilters: IFilterBy = filterService.getDefaultFilters(
        EProjectColumnName.ProjectName,
        projectIndexColumns,
      );
      return filterService.loadFilters(window.location, ETableFilters.PROJECT, defaultFilters);
    },
    handleError(project: IProject | IProjectCreate, error: unknown) {
      if (error instanceof HttpErrorResponse) {
        console.error(error.serialize());
        if (error instanceof ConflictError) {
          this.$q.notify(alertUtil.getError(`Project with name ${project.name} already exists.`));
        } else {
          this.$q.notify(alertUtil.getError(error.message));
        }
      } else {
        this.$q.notify(alertUtil.getError(`Failed to ${this.isNewProject ? "create" : "save"} project`));
      }
    },
  },
});
</script>
