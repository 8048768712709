<template>
  <div class="dashboard-page-filter q-px-md">
    <div class="filters-container flex-1">
      <runai-page-filter
        v-if="!hideFilter"
        :filter-options="filterOptions"
        :initial-filters-model="initialFilters"
        @update-column-filters="onFilterChange"
        use-selection
        :async-options-promise-map="asyncFilterOptionsPromiseMap"
      />
    </div>
    <div class="datepicker-container">
      <runai-datepicker-with-predefined
        @changed="updateDateRange"
        :range-of-dates="rangeOfDatesCallback"
        info-bar-message="Select a period of up to 30 days within the last 90 days"
        :predefined-options="$options.predefinedOptions"
        :default-option-index="5"
        :default-end-date="filterByDates?.dateEnd"
        :default-start-date="filterByDates?.dateStart"
        :default-option-label="filterByDates?.label"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { RunaiPageFilter } from "@/components/common/runai-page-filter";
import type { IFilterModel, IFilterOption } from "@/models/filter.model";
import { RunaiDatepickerWithPredefined } from "@/components/common/runai-datepicker-with-predefined";
import { extendedHoursPredefinedOptions, dateUtil, type IRangeDates, TimeUnit } from "@/utils/date.util";
import type { PredefinedOption } from "@/models/date.model";
import type { ISelectOption } from "@/models/global.model";
export default defineComponent({
  name: "dashboard-page-filter",
  components: { RunaiDatepickerWithPredefined, RunaiPageFilter },
  emits: ["filter-changed", "date-changed"],
  props: {
    filterOptions: {
      type: Array as PropType<IFilterOption[]>,
      default: () => [],
    },
    asyncFilterOptionsPromiseMap: {
      type: Object as PropType<Record<string, (searchQuery: string) => Promise<Array<ISelectOption>>>>,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
    },
    initialFilters: {
      type: Array as PropType<IFilterModel[]>,
      default: () => [],
    },
    hideFilter: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    filterByDates: {
      type: Object as PropType<IRangeDates>,
      required: false,
    },
  },
  predefinedOptions: extendedHoursPredefinedOptions as Array<PredefinedOption>,
  methods: {
    onFilterChange(filters: Array<IFilterModel>) {
      this.$emit("filter-changed", filters);
    },
    updateDateRange(dateRange: Array<Date>, label: string): void {
      this.$emit("date-changed", { startDate: dateRange[0], endDate: dateRange[1], label });
    },
    rangeOfDatesCallback(date: string): boolean {
      const todayDate: number = new Date().getTime();
      const dateToCheck: number = Date.parse(date);
      const back90Days: Date = dateUtil.adjustDateBy(TimeUnit.day, todayDate, -90);
      return dateToCheck >= back90Days.getTime() && dateToCheck <= todayDate;
    },
  },
});
</script>

<style scoped lang="scss">
.dashboard-page-filter {
  background: $white;
  box-shadow: 0 2px 4px $black-15;
  height: 60px;
  display: flex;
  align-items: center;
}
</style>
