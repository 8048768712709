<template>
  <section class="department-index">
    <runai-table-wrapper :filters-object="filterBy" sticky>
      <template v-slot:actions>
        <runai-page-actions
          :filters="filterBy"
          :columns="columns"
          hide-all-cluster-filter-option
          primary-btn-label="New department"
          :primary-btn-disable="disablePrimaryBtn"
          @filters-changed="setFilterBy"
          :selected-rows-amount="selectedRowsAmount"
          @selected-actions-close="resetSelectedRows"
          @create-entity="createNewDepartment"
          @export-csv="exportTableAsCsv"
        >
          <template v-slot:selected-rows-actions>
            <runai-action-button
              v-permission="{ resourceType: ResourceType.AccessRules, action: Action.Read }"
              btn-action="assign"
              @click="openAccessRuleModal"
            />
            <runai-action-button
              v-permission="{ resourceType: ResourceType.Department, action: Action.Update }"
              btn-action="edit"
              @click="editDepartment"
            />
            <runai-tooltip-wrapper
              :display-tooltip="disablePolicyButton"
              tooltip-text="There is no policy applied for the selected department"
            >
              <runai-action-button
                v-if="showPolicyButton"
                v-permission="{ resourceType: ResourceType.Department, action: Action.Read }"
                aid="view-department-policy-btn"
                btn-action="viewPolicy"
                :disable="disablePolicyButton"
              >
                <policy-dropdown
                  @policy-selected="viewPolicy"
                  entity-name="department"
                  :existing-policies="policyMenuOptions"
                />
              </runai-action-button>
            </runai-tooltip-wrapper>
            <runai-action-button
              v-permission="{ resourceType: ResourceType.Department, action: Action.Delete }"
              btn-action="delete"
              @click="openDeleteModal"
            />
            <runai-action-button
              v-permission="{ resourceType: ResourceType.Department, action: Action.Read }"
              btn-action="review"
              @click="viewDepartment"
            />
          </template>
        </runai-page-actions>
      </template>
      <template v-slot:table>
        <runai-table
          :rows="tableData"
          :columns="columns"
          :filter-by="filterBy"
          v-model:selected="selectedRows"
          :loading="loadingTableData"
          @projects-clicked="displayProjects"
          @access-rules-clicked="displayAccessRuleTableModal"
          @workloads-clicked="displayWorkloadsModal"
          @node-pools-clicked="displayDepartmentNodePools"
          @update-filters="setFilterBy"
          :top-row="lastCreatedDepartment"
          :get-row-key="getRowKey"
          sticky-columns
        >
          <template #no-data>
            <runai-table-no-data
              v-if="!loadingTableData && !lastCreatedDepartment"
              :filter-by="filterBy"
              entity-name="department"
              icon-name="department"
              :show-error="loadingError"
              @clear-filters="clearFilters"
              @create-new="createNewDepartment"
            />
          </template>
        </runai-table>
      </template>
    </runai-table-wrapper>

    <project-modal v-if="openProjectModal" @close="closeProjectModal" :modal-options="projectModalOptions" />
    <access-rule-table-modal
      v-if="isAccessRuleTableModalOpen"
      @close="closeAccessRuleTableModal"
      :modal-options="accessRuleTableModalOptions"
    />
    <node-pools-modal :modal-options="nodePoolsModalOptions" v-if="isNodePoolsModalOpen" @close="closeNodePoolsModal" />
    <delete-department-modal
      v-if="isDeleteModalOpen && selectedDepartment"
      :is-department-deleting="deleting"
      :current-department="selectedDepartment"
      @cancel="isDeleteModalOpen = false"
      @delete="deleteDepartment"
    />
    <access-rule-management-modal
      v-if="isAccessRuleManagementModalOpen"
      @close="isAccessRuleManagementModalOpen = false"
      @access-rule-created="onAccessRuleCreated"
      @access-rule-deleted="onAccessRuleDeleted"
      :modal-options="accessRuleManagementModalOptions"
    />
    <workload-list-modal
      v-if="isWorkloadsModalOpen"
      :modal-options="workloadsModalOptions"
      @close="isWorkloadsModalOpen = false"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";

// cmps
import { RunaiPageActions } from "@/components/common/runai-page-actions";
import { ProjectModal } from "@/components/project/project-modal";
import { DeleteDepartmentModal } from "@/components/department/delete-department-modal";
import { RunaiActionButton } from "@/components/common/runai-page-actions/runai-action-button";
import { RunaiTable } from "@/components/common";
import { RunaiTableNoData } from "@/components/common/runai-table/runai-table-no-data";
import { AccessRuleTableModal } from "@/components/rbac/access-rule/access-rule-table-modal";
import { PolicyDropdown } from "@/components/policy/policy-dropdown";

// stores
import { useSettingStore } from "@/stores/setting.store";
import { useClusterStore } from "@/stores/cluster.store";
import { useAppStore } from "@/stores/app.store";
// models
import type { IDepartment } from "@/models/department.model";
import { departmentDependentColumns, departmentIndexColumns } from "@/table-models/department.table-model";
import { ETableFilters, type ITableColumn } from "@/models/table.model";
import { ETableExportCsvFilesNames } from "@/models/table.model";
import { EFilterOperator } from "@/models/filter.model";
import type { IAccessRuleTableModalOptions } from "@/models/access-rule.model";
import { EAccessRuleModalPage, type IAccessRuleManagementModalOptions } from "@/models/access-rule.model";
import { accessRulesMiniTableColumns } from "@/table-models/access-rule.table-model";
// services
import { tableUtil } from "@/utils/table.util";
import { alertUtil } from "@/utils/alert.util";

// route
import { DEPARTMENT_ROUTE_NAMES } from "@/router/department.routes/department.routes.names";
import { RunaiTableWrapper } from "@/components/common/runai-table-wrapper";
import { accessRuleService } from "@/services/control-plane/rbac/access-rule.service/access-rule.service";
import { nodePoolService } from "@/services/control-plane/node-pool.service/node-pool.service";
import NodePoolsModal from "@/components/node-pools/node-pools-modal/node-pools-modal.vue";
import { EProjectModalEntity, type INodePoolModalOptions, type IProjectsModalOptions } from "@/models/project.model";
import { EQuotaEntity } from "@/models/resource.model";
import { AccessRuleManagementModal } from "@/components/rbac/access-rule/access-rule-management-modal/";
import {
  PolicyType,
  type TrainingPolicyV2,
  type TrainingPolicyDefaultsAndRulesV2Defaults,
  type TrainingPolicyDefaultsAndRulesV2Rules,
  type WorkspacePolicyV2,
  type WorkspacePolicyDefaultsAndRulesV2Defaults,
  type WorkspacePolicyDefaultsAndRulesV2Rules,
  type DistributedPolicyRulesV2,
  type DistributedPolicyDefaultsV2,
  type DistributedPolicyV2,
} from "@/swagger-models/policy-service-client";
import { type AccessRule, Action, ResourceType, ScopeType } from "@/swagger-models/authorization-client";
import { usePermissionStore } from "@/stores/permissions.store";
import { useNodePoolStore } from "@/stores/node-pool.store";
import { policyUtil, type IPolicyScopeProperties } from "@/utils/policy.util/policy.util";
import { POLICIES_ROUTE_NAMES } from "@/router/policy.routes/policy.routes.names";
import { RunaiTooltipWrapper } from "@/components/common/runai-tooltip-wrapper";
import { WorkloadListModal } from "@/components/workload/workload-list-modal";
import { EWorkloadModalEntity, type IWorkloadListModalOptions } from "@/models/workload.model";
import { WorkloadSortFilterFields } from "@/swagger-models/workloads-service-client";
import { workloadListModalColumns } from "@/table-models/workload.table-model";
import { useTableData } from "@/composables/use-table-data.composable";
import { EIndexPages } from "@/composables/helpers/use-table-data.mapper";
import { departmentService } from "@/services/control-plane/department.service/department.service";
import { useAuthStore } from "@/stores/auth.store";
import type { IPolicyMap } from "@/models/policy.model";
import { policyService } from "@/services/control-plane/policy.service/policy.service";
import { enrichScopeEntityWithAccessRules } from "@/utils/rbac.util/access-rule.util/access-rule.util";
import { resourcesService } from "@/services/control-plane/resources/resources.service";

const DEFAULT_WORKLOADS_MODAL_OPTIONS: IWorkloadListModalOptions = {
  entityFilter: "",
  entityName: "",
  filterName: WorkloadSortFilterFields.DepartmentName,
  entityType: EWorkloadModalEntity.Department,
  columns: workloadListModalColumns,
};
const DEFAULT_PROJECT_MODAL_OPTIONS: IProjectsModalOptions = {
  entityName: "",
  entityType: EProjectModalEntity.Department,
};
export default defineComponent({
  components: {
    WorkloadListModal,
    RunaiTooltipWrapper,
    AccessRuleManagementModal,
    NodePoolsModal,
    AccessRuleTableModal,
    RunaiPageActions,
    ProjectModal,
    DeleteDepartmentModal,
    RunaiTableWrapper,
    RunaiActionButton,
    RunaiTable,
    RunaiTableNoData,
    PolicyDropdown,
  },
  data() {
    return {
      appStore: useAppStore(),
      selectedRows: [] as Array<IDepartment>,
      openProjectModal: false as boolean,
      projectModalOptions: {
        ...DEFAULT_PROJECT_MODAL_OPTIONS,
      } as IProjectsModalOptions,
      isDeleteModalOpen: false as boolean,
      isAccessRuleTableModalOpen: false as boolean,
      isNodePoolsModalOpen: false as boolean,
      deleting: false as boolean,
      isAccessRuleManagementModalOpen: false as boolean,
      isWorkloadsModalOpen: false as boolean,
      permissionStore: usePermissionStore(),
      accessRuleTableModalOptions: {
        accessRules: [],
        header: "",
        columns: accessRulesMiniTableColumns,
        loading: false,
      } as IAccessRuleTableModalOptions,
      nodePoolsModalOptions: {
        header: "",
        loading: false,
        nodePools: [],
        entity: EQuotaEntity.department,
      } as INodePoolModalOptions,
      accessRuleManagementModalOptions: {
        page: EAccessRuleModalPage.Department,
        scopeType: ScopeType.Department,
        scopeName: "",
        scopeId: "",
      } as IAccessRuleManagementModalOptions,
      loadingPolicies: false as boolean,
      selectedDepartmentPolicies: null as IPolicyMap | null,
      workloadsModalOptions: {
        ...DEFAULT_WORKLOADS_MODAL_OPTIONS,
      } as IWorkloadListModalOptions,
    };
  },
  setup() {
    const clusterStore = useClusterStore();
    const permissionStore = usePermissionStore();
    const settingStore = useSettingStore();
    const nodePoolStore = useNodePoolStore();

    const isOnlyDefaultNodePool = ref(false);

    const getDepartments = async (): Promise<Array<IDepartment>> => {
      const clusterId = clusterStore.getClusterIdFromFilters(ETableFilters.DEPARTMENT);
      isOnlyDefaultNodePool.value = await nodePoolStore.isOnlyDefaultNodePoolByClusterId(clusterId);
      return await departmentService.list(clusterId);
    };

    const getDepartmentsWithAdditionalData = async (): Promise<IDepartment[]> => {
      const clusterId = clusterStore.getClusterIdFromFilters(ETableFilters.DEPARTMENT);
      let departments: IDepartment[] = await getDepartments();
      try {
        if (permissionStore.hasPermission(ResourceType.AccessRules, Action.Read)) {
          const scopeAccessRules = await accessRuleService.getAccessRules({
            scopeType: ScopeType.Department,
          });
          departments = enrichScopeEntityWithAccessRules(departments, scopeAccessRules);
        }

        return await resourcesService.enrichDepartmentsWithResourcesMetrics(departments, clusterId);
      } catch (e) {
        console.error("Failed to enrich departments with additional data", e);
        return departments;
      }
    };

    const initialColumns = departmentIndexColumns.filter((col) => {
      if (departmentDependentColumns.cpu.includes(col.name)) {
        return settingStore.isCPUResourcesQuotaEnabled;
      }
      if (departmentDependentColumns.accessRules.includes(col.name)) {
        return permissionStore.hasPermission(ResourceType.AccessRules, Action.Read);
      }
      return true;
    });

    const {
      setFilterBy,
      clearFilters,
      refreshList,
      tableData,
      filterBy,
      loadingTableData,
      loadingError,
      lastCreatedEntity,
      clearLastCreated,
      initTableFilter,
      updateCellContent,
      removeRow,
      setColumns,
      columns,
    } = useTableData<IDepartment>(
      getDepartments,
      initialColumns,
      EIndexPages.DEPARTMENT,
      getDepartmentsWithAdditionalData,
    );
    initTableFilter();

    watch(isOnlyDefaultNodePool, (newVal: boolean) => {
      if (newVal) {
        setColumns(
          initialColumns.filter((col: ITableColumn) => !departmentDependentColumns.nodePools.includes(col.name)),
        );
      } else {
        setColumns(initialColumns);
      }
    });

    return {
      lastCreatedEntity,
      clearLastCreated,
      setFilterBy,
      clearFilters,
      refreshList,
      tableData,
      filterBy,
      loadingTableData,
      loadingError,
      columns,
      updateCellContent,
      removeRow,
      clusterStore,
      settingStore,
    };
  },
  created() {
    this.appStore.setPageLoading(false);
  },
  computed: {
    Action(): typeof Action {
      return Action;
    },
    ResourceType(): typeof ResourceType {
      return ResourceType;
    },
    disablePrimaryBtn(): boolean {
      if (this.loadingTableData) return true;
      return !this.canCreateDepartment;
    },
    canCreateDepartment(): boolean {
      return this.permissionStore.hasPermission(ResourceType.Department, Action.Create);
    },
    selectedRowsAmount(): number {
      return this.selectedDepartment ? 1 : 0;
    },
    lastCreatedDepartment(): IDepartment | null {
      return this.lastCreatedEntity;
    },
    disablePolicyButton(): boolean {
      return this.loadingPolicies || !this.policyMenuOptions.length;
    },
    policyMenuOptions(): Array<PolicyType> {
      const policyOptions: Array<PolicyType> = [];
      if (!this.selectedDepartmentPolicies) return policyOptions;

      this.hasInteractivePolicy && policyOptions.push(PolicyType.Workspace);
      this.hasTrainingPolicy && policyOptions.push(PolicyType.Training);
      this.hasDistributedPolicy && policyOptions.push(PolicyType.Distributed);

      return policyOptions;
    },
    hasInteractivePolicy(): boolean {
      if (!this.selectedDepartmentPolicies) return false;
      const rules: WorkspacePolicyDefaultsAndRulesV2Rules =
        this.selectedDepartmentPolicies[PolicyType.Workspace]?.effective?.rules || {};
      const defaults: WorkspacePolicyDefaultsAndRulesV2Defaults =
        this.selectedDepartmentPolicies[PolicyType.Training]?.effective?.defaults || {};
      return !!Object.keys(rules).length || !!Object.keys(defaults).length;
    },
    hasTrainingPolicy(): boolean {
      if (!this.selectedDepartmentPolicies) return false;
      const rules: TrainingPolicyDefaultsAndRulesV2Rules =
        this.selectedDepartmentPolicies[PolicyType.Training]?.effective?.rules || {};
      const defaults: TrainingPolicyDefaultsAndRulesV2Defaults =
        this.selectedDepartmentPolicies[PolicyType.Training]?.effective?.defaults || {};
      return !!Object.keys(rules).length || !!Object.keys(defaults).length;
    },
    hasDistributedPolicy(): boolean {
      if (!this.selectedDepartmentPolicies) return false;
      const rules: DistributedPolicyRulesV2 =
        this.selectedDepartmentPolicies[PolicyType.Distributed]?.effective?.rules || {};
      const defaults: DistributedPolicyDefaultsV2 =
        this.selectedDepartmentPolicies[PolicyType.Distributed]?.effective?.defaults || {};
      return !!Object.keys(rules).length || !!Object.keys(defaults).length;
    },
    showPolicyButton(): boolean {
      return this.settingStore.isPolicyManagerEnabled;
    },
    selectedDepartment(): IDepartment | null {
      return this.selectedRows[0] || null;
    },
  },
  methods: {
    getRowKey(department: IDepartment): number {
      return Number(department.id);
    },
    async loadSelectedDepartmentPolicy(): Promise<void> {
      if (!this.selectedDepartment?.id) {
        this.selectedDepartmentPolicies = null;
        return;
      }
      try {
        this.loadingPolicies = true;
        const scopeProperties: IPolicyScopeProperties = {
          departmentId: this.selectedDepartment.id.toString(),
          scope: ScopeType.Department,
        };
        await Promise.all([
          policyService.loadPolicyByMetaAndType(PolicyType.Workspace, scopeProperties),
          policyService.loadPolicyByMetaAndType(PolicyType.Training, scopeProperties),
          policyService.loadPolicyByMetaAndType(PolicyType.Distributed, scopeProperties),
        ]).then((res) => {
          this.selectedDepartmentPolicies = {
            [PolicyType.Workspace]: res[0] as WorkspacePolicyV2,
            [PolicyType.Training]: res[1] as TrainingPolicyV2,
            [PolicyType.Distributed]: res[2] as DistributedPolicyV2,
          };
        });
      } catch (err: unknown) {
        console.error("Failed to load department policies", err);
      } finally {
        this.loadingPolicies = false;
      }
    },
    viewPolicy(workloadType: PolicyType): void {
      if (!this.selectedDepartment?.id) return;
      const policyId = policyUtil.createPolicyId(workloadType, ScopeType.Department, this.selectedDepartment.id);
      this.$router.push({
        name: POLICIES_ROUTE_NAMES.POLICY_VIEW,
        params: { id: policyId },
        query: { previousRoute: DEPARTMENT_ROUTE_NAMES.DEPARTMENT_INDEX },
      });
    },
    displayProjects(department: IDepartment): void {
      this.openProjectModal = Boolean(department);
      this.projectModalOptions.departmentId = department.id;
      this.projectModalOptions.entityName = department.name;
    },
    closeProjectModal(): void {
      this.openProjectModal = false;
      this.resetProjectModalOptions();
    },
    resetProjectModalOptions(): void {
      this.projectModalOptions = { ...DEFAULT_PROJECT_MODAL_OPTIONS };
    },
    resetSelectedRows(): void {
      this.selectedRows = [];
    },
    openDeleteModal(): void {
      this.isDeleteModalOpen = true;
    },
    editDepartment() {
      if (!this.selectedDepartment?.id) return;
      this.$router.push({ name: DEPARTMENT_ROUTE_NAMES.DEPARTMENT_EDIT, params: { id: this.selectedDepartment.id } });
    },
    createNewDepartment(): void {
      this.$router.push({
        name: DEPARTMENT_ROUTE_NAMES.DEPARTMENT_NEW,
      });
    },
    viewDepartment() {
      if (!this.selectedDepartment?.id) return;
      this.$router.push({ name: DEPARTMENT_ROUTE_NAMES.DEPARTMENT_VIEW, params: { id: this.selectedDepartment.id } });
    },
    async deleteDepartment(): Promise<void> {
      const departmentId = this.selectedDepartment?.id;
      const departmentName = this.selectedDepartment?.name;
      const clusterId = this.selectedDepartment?.clusterUuid;
      if (this.selectedDepartment === null || !departmentId || !departmentName || !clusterId) return;
      try {
        this.deleting = true;
        await departmentService.remove(departmentId, clusterId);
        this.removeRow(this.selectedDepartment);
        this.isDeleteModalOpen = false;
        await useAuthStore().loadUserOrgUnits();
        this.$q.notify(alertUtil.getSuccess(`Department ${departmentName} deleted`));
      } catch (err) {
        console.error(err, `Could not remove department with id ${departmentId}`);
        this.$q.notify(alertUtil.getError(`Department ${departmentName} couldn't be deleted `));
      } finally {
        this.resetSelectedRows();
        this.deleting = false;
      }
    },
    async displayAccessRuleTableModal(department: IDepartment): Promise<void> {
      if (!department.id) return;
      this.isAccessRuleTableModalOpen = true;
      this.accessRuleTableModalOptions.loading = true;
      this.accessRuleTableModalOptions.header = `Subjects Authorized for department ${department.name}`;
      const assigmentRecords = await accessRuleService.getAccessRules({
        scopeType: ScopeType.Department,
        scopeId: department.id.toString(),
      });
      this.accessRuleTableModalOptions.accessRules = assigmentRecords.accessRules;
      this.accessRuleTableModalOptions.loading = false;
    },
    closeAccessRuleTableModal(): void {
      this.isAccessRuleTableModalOpen = false;
      this.accessRuleTableModalOptions.accessRules = [];
      this.accessRuleTableModalOptions.header = "";
    },
    async displayDepartmentNodePools(department: IDepartment): Promise<void> {
      const clusterId = this.clusterStore.getClusterIdFromFilters(ETableFilters.DEPARTMENT);
      this.isNodePoolsModalOpen = true;
      this.nodePoolsModalOptions.header = `Node Pools Associated with department ${department.name}`;
      this.nodePoolsModalOptions.loading = true;
      this.nodePoolsModalOptions.nodePools = await nodePoolService.enrichDepartmentNodePoolsWithResourceMetric(
        department.nodePoolsResources,
        clusterId,
      );
      this.nodePoolsModalOptions.loading = false;
    },
    closeNodePoolsModal(): void {
      this.isNodePoolsModalOpen = false;
      this.resetNodePoolsModalOptions();
    },
    resetNodePoolsModalOptions(): void {
      this.nodePoolsModalOptions = { nodePools: [], loading: false, entity: EQuotaEntity.project, header: "" };
    },
    openAccessRuleModal(): void {
      if (!this.selectedDepartment) return;
      this.accessRuleManagementModalOptions.scopeName = this.selectedDepartment?.name;
      this.accessRuleManagementModalOptions.scopeId = this.selectedDepartment?.id?.toString();
      this.isAccessRuleManagementModalOpen = true;
    },
    closeAccessRuleManagementModal(): void {
      this.refreshList();
      this.isAccessRuleManagementModalOpen = false;
    },
    exportTableAsCsv(): void {
      const columns = this.columns.filter((col: ITableColumn) => this.filterBy.displayedColumns?.includes(col.name));

      tableUtil.exportTableAsCsv(ETableExportCsvFilesNames.Department, this.tableData, columns);
    },
    async displayWorkloadsModal(department: IDepartment): Promise<void> {
      const clusterId = this.clusterStore.getClusterIdFromFilters(ETableFilters.DEPARTMENT);
      this.isWorkloadsModalOpen = true;
      this.workloadsModalOptions.clusterId = clusterId;
      this.workloadsModalOptions.entityFilter = `${WorkloadSortFilterFields.DepartmentId}${EFilterOperator.Equals}${department.id}`;
      this.workloadsModalOptions.entityName = department.name;
    },
    resetWorkloadsModalOptions(): void {
      this.workloadsModalOptions = { ...DEFAULT_WORKLOADS_MODAL_OPTIONS };
    },
    onAccessRuleCreated(accessRule: AccessRule): void {
      if (!this.selectedDepartment?.id) return;
      const roles = this.tableData.find(
        (department: IDepartment) => department.id === this.selectedDepartment?.id,
      )?.roles;
      this.updateCellContent(this.selectedDepartment, "roles", [accessRule.subjectType, ...(roles || [])]);
    },
    onAccessRuleDeleted(accessRuleIndex: number): void {
      if (!this.selectedDepartment?.id) return;
      const roles = this.tableData.find(
        (department: IDepartment) => department.id === this.selectedDepartment?.id,
      )?.roles;
      const rolesWithoutDeleted = roles?.filter((role: string, index: number) => index !== accessRuleIndex);
      this.updateCellContent(this.selectedDepartment, "roles", rolesWithoutDeleted);
    },
  },
  watch: {
    selectedDepartment: {
      handler(newVal: IDepartment | null): void {
        if (!newVal) return;
        this.loadSelectedDepartmentPolicy();
      },
    },
    isWorkloadsModalOpen(isOpen: boolean): void {
      if (!isOpen) {
        this.resetWorkloadsModalOptions();
      }
    },
  },
});
</script>
