<template>
  <quota-management-section
    :section-invalid="sectionInvalid"
    :node-pools-resources="department.nodePoolsResources"
    :quota-statuses="quotaStatuses"
    @update:node-pools-resources="$emit('update:node-pools-resources', $event)"
    :entity="EQuotaEntity.department"
    :department-id="department.id"
    :cluster-id="department.clusterUuid"
    :read-only="readOnly"
    :loading="loading"
  >
    <template #over-quota-toggle>
      <q-toggle
        :disable="readOnly"
        left-label
        label="Allow department to go over quota"
        :model-value="isOverQuotaOn"
        @update:model-value="allowGpuOverQuotaChanged"
      />
      <runai-tooltip
        tooltip-position="right"
        width="320px"
        tooltip-text="Departments that have quota overage disabled are limited to
                    using the GPUs assigned to them. Departments that have it
                    enabled can use GPUs beyond their quota, if needed and
                    available."
      />
    </template>
  </quota-management-section>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { RunaiTooltip } from "@/components/common/runai-tooltip";
import { QuotaManagementSection } from "@/components/project/project-edit-form/quota-management-section";
import { DEFAULT_DEPARTMENT_DESERVED_GPUS, type IDepartment } from "@/models/department.model";
import type { INodePoolResources } from "@/models/project.model";
import { EQuotaEntity, RESOURCE_MAX_ALLOWED_INFINITE_VALUE } from "@/models/resource.model";
import { departmentUtil } from "@/utils/department.util";
import { useSettingStore } from "@/stores/setting.store";
import type { NodePoolQuotaStatus } from "@/swagger-models/backend-client";

export default defineComponent({
  name: "department-quota-management-section",
  components: { QuotaManagementSection, RunaiTooltip },
  emits: ["update:node-pools-resources", "is-section-invalid"],
  props: {
    department: {
      type: Object as PropType<IDepartment>,
      required: true,
    },
    nodePoolsResources: {
      type: Array as PropType<Array<INodePoolResources>>,
      required: true,
    },
    readOnly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    quotaStatuses: {
      type: Array as PropType<Array<NodePoolQuotaStatus>>,
      default: () => [],
    },
  },
  data() {
    return {
      isOverQuotaOn: true as boolean,
      settingStore: useSettingStore(),
    };
  },
  created() {
    const isOverQuotaOn = this.department.maxAllowedGpus === RESOURCE_MAX_ALLOWED_INFINITE_VALUE;
    this.allowGpuOverQuotaChanged(isOverQuotaOn);
  },
  computed: {
    sectionInvalid(): boolean {
      return this.department.nodePoolsResources.some(
        (nodePoolResources: INodePoolResources) => nodePoolResources.gpu.deserved === DEFAULT_DEPARTMENT_DESERVED_GPUS,
      );
    },
    EQuotaEntity(): typeof EQuotaEntity {
      return EQuotaEntity;
    },
    isCpuEnabled(): boolean {
      return this.settingStore.isCPUResourcesQuotaEnabled;
    },
  },
  methods: {
    allowGpuOverQuotaChanged(isOverQuotaOn: boolean): void {
      this.isOverQuotaOn = isOverQuotaOn;
      departmentUtil.updateMaxAllowedOverQuota(this.department, this.isCpuEnabled, isOverQuotaOn);
    },
  },
  watch: {
    sectionInvalid: {
      handler(isSectionInvalid: boolean): void {
        this.$emit("is-section-invalid", isSectionInvalid);
      },
      immediate: true,
    },
  },
});
</script>

<style scoped lang="scss"></style>
