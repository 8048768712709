<template>
  <section class="runai-grafana-iframe">
    <iframe v-if="shouldLoginToGrafana" hidden :src="loginPage" @load="onGrafanaLoaded" />
    <iframe
      v-if="isGrafanaLoggedIn"
      id="grafana-dashboard"
      ref="elIframe"
      @load="onIframeLoaded"
      :src="url"
      frameborder="0"
    ></iframe>
  </section>
</template>

<script lang="ts">
import { useGrafanaStore } from "@/stores/grafana.store";
import { defineComponent, type PropType } from "vue";
import queryString from "query-string";
import { isEmpty } from "@/common/form.validators";
import { useAuthStore } from "@/stores/auth.store";
import { useClusterStore } from "@/stores/cluster.store";
import type { Dashboards } from "@/swagger-models/backend-client";
import { EDashboardName } from "@/models/dashboard.model";

export default defineComponent({
  name: "runai-grafana-iframe",
  props: {
    dashboardName: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      authStore: useAuthStore(),
      grafanaStore: useGrafanaStore(),
      clusterStore: useClusterStore(),
      iframeDoc: undefined as Document | undefined,
    };
  },
  computed: {
    url(): string {
      return this.getUrl(this.query);
    },
    query(): { "var-cluster": string } {
      return {
        "var-cluster": this.clusterStore.currentClusterId,
      };
    },
    loginPage(): string | undefined {
      return this.grafanaStore.dashboards.loginPage;
    },
    shouldLoginToGrafana(): boolean {
      return !this.authStore.tenant.smg && !!this.authStore.userEmail;
    },
    isGrafanaLoggedIn(): boolean {
      return this.grafanaStore.grafanaLoggedIn;
    },
  },
  methods: {
    onGrafanaLoaded(): void {
      setTimeout(() => {
        this.grafanaStore.login();
      }, 1500);
    },
    onIframeLoaded(): void {
      let intervalId = setInterval(() => {
        this.iframeDoc = (this.$refs.elIframe as HTMLIFrameElement)?.contentWindow?.document;
        if (this.iframeDoc) {
          clearInterval(intervalId); // Stop the interval once iframeDoc is defined
          this.hideGrafanaElements(this.iframeDoc);
          this.preventEventKeys(this.iframeDoc);
          // Add a call to set up the MutationObserver inside the iframe
          this.setupMainViewObserverInIframe();
        }
      }, 100); // Check every 100 milliseconds
    },
    preventKeys(event: KeyboardEvent): void {
      const KEY_CODES: Record<string, number> = { ESC: 27, F: 70, I: 73, P: 80, S: 83, V: 86 };
      const keys: Array<number> = [KEY_CODES.ESC, KEY_CODES.F, KEY_CODES.I, KEY_CODES.P, KEY_CODES.S, KEY_CODES.V];

      if (keys.includes(event.keyCode)) {
        event.preventDefault();
        event.stopPropagation();
        return;
      }
    },
    // Block user from exiting grafana ui easily (esc and F keys)
    preventEventKeys(iframeDoc: Document): void {
      iframeDoc.addEventListener("keydown", this.preventKeys, true);
    },
    // styles to remove the filters in the table , the down-arrow in each cube , and the header in the analytics page.
    hideGrafanaElements(iframeDoc: Document): void {
      const elStyle: HTMLElement = document.createElement("style");
      elStyle.innerText = `.panel-title > :nth-child(2) {display: none;} .cell-filter-actions svg {display: none;} .page-toolbar nav {display: none;} .page-toolbar > div > div > svg {display: none;} .panel-header {pointer-events: none;} .grafana-app .sidemenu {display: none;}`;
      iframeDoc.head.appendChild(elStyle);
    },
    setupMainViewObserverInIframe(): void {
      const iframe = this.$refs.elIframe as HTMLIFrameElement;

      if (!iframe || !iframe.contentWindow) {
        return;
      }

      const iframeDoc = iframe.contentWindow.document;
      const targetNode = iframeDoc.body;
      const config = { childList: true, subtree: true };

      const observer = new MutationObserver((_, observer) => {
        const mainView = iframeDoc.querySelector(".main-view");
        if (mainView) {
          observer.disconnect();
          // this is a hack to enable kiosk mode without losing our entities filters (projects, nodePools etc.)
          if (this.dashboardName === EDashboardName.Overview || this.dashboardName === EDashboardName.CpuOverview) {
            this.hideGrafanaMainView();
          } else if (
            this.dashboardName === EDashboardName.Analytics ||
            this.dashboardName === EDashboardName.CpuAnalytics ||
            this.dashboardName === EDashboardName.Consumption
          ) {
            this.hideGrafanaTopToolBar();
          }
        }
      });

      observer.observe(targetNode, config);
    },
    hideGrafanaTopToolBar(): void {
      const iframe = this.$refs.elIframe as HTMLIFrameElement;

      if (!iframe || !iframe.contentWindow) {
        console.error("Iframe element or its content window not found.");
        return;
      }

      const iframeDoc = iframe.contentWindow.document;
      const topBar = iframeDoc.querySelector("[data-testid='data-testid Nav toolbar']");
      if (topBar) {
        const firstChild = topBar.firstElementChild;
        const secondChild = topBar.children[1];

        if (firstChild) {
          topBar.removeChild(firstChild);
        }
        if (secondChild) {
          topBar.removeChild(secondChild);
        }
      }
    },
    hideGrafanaMainView(): void {
      try {
        const iframe = this.$refs.elIframe as HTMLIFrameElement;

        if (!iframe || !iframe.contentWindow) {
          return;
        }

        const iframeDoc = iframe.contentWindow.document;
        const mainView = iframeDoc.querySelector(".main-view");
        if (mainView) {
          const firstChild = mainView.firstChild;
          //this is how we locate grafana top bar
          if (firstChild && firstChild.textContent?.includes("Home")) {
            mainView.removeChild(firstChild);
            if (mainView.firstChild) {
              //@ts-ignore
              mainView.firstChild.style.padding = "0";
            }
          }
        }
      } catch (error) {
        //do nothing
      }
    },
    mergeUrls({ hostUrl, pathUrl }: { hostUrl: string; pathUrl: string }): string {
      const hostUrlObj = new URL(hostUrl);
      let pathUrlObj = new URL(pathUrl);
      pathUrlObj.protocol = hostUrlObj.protocol;
      pathUrlObj.hostname = hostUrlObj.hostname;
      pathUrlObj.port = hostUrlObj.port;
      return pathUrlObj.toString();
    },
    getUrl(propsQuery: object): string {
      let pageUrl = undefined;
      if (!this.dashboardName) {
        console.error("missing dashboard to load");
        return "";
      }

      pageUrl = this.grafanaStore.dashboards[this.dashboardName as keyof Dashboards];
      if (!pageUrl) {
        return "";
      }
      const { url, query } = queryString.parseUrl(pageUrl);
      const extendedQuery: Record<string, string | string[] | null> = {
        ...query,
        ...propsQuery,
      };

      const extendedQueryString: string = queryString.stringify(extendedQuery);
      let grafanaUrl = url + (isEmpty(extendedQueryString) ? "" : "?" + extendedQueryString);
      if (this.authStore.tenant.smg) {
        grafanaUrl = this.mergeUrls({ hostUrl: window.location.toString(), pathUrl: grafanaUrl });
      }

      return grafanaUrl;
    },
  },
  unmounted() {
    if (!this.iframeDoc) return;
    this.iframeDoc.removeEventListener("keydown", this.preventKeys, true);
  },
});
</script>

<style lang="scss" scoped>
iframe {
  width: 100%;
  height: 100%;
}
</style>
