<template>
  <section class="department-new column items-center">
    <div class="loader" v-if="loading"></div>
    <department-edit-form
      :department="department"
      v-if="!loading && department"
      @save="save"
      @cancel="onCancel"
      :read-only="isDepartmentViewPage"
      :submitting="submitting"
    ></department-edit-form>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { useClusterStore } from "@/stores/cluster.store";
import { useSettingStore } from "@/stores/setting.store";

import { DepartmentEditForm } from "@/components/department/department-edit-form";

import { nodePoolService } from "@/services/control-plane/node-pool.service/node-pool.service";
import { requestToLeave } from "@/services/infra/router.service/router.service";

import { DEFAULT_DEPARTMENT_NAME, EDepartmentColumnName, type IDepartment } from "@/models/department.model";
import type { INodePool } from "@/models/node-pool.model";

// route
import { DEPARTMENT_ROUTE_NAMES } from "@/router/department.routes/department.routes.names";
import { useAppStore } from "@/stores/app.store";
import { alertUtil } from "@/utils/alert.util";
import { departmentService } from "@/services/control-plane/department.service/department.service";
import { HttpErrorResponse } from "@/models/http-response.model";
import { departmentUtil } from "@/utils/department.util";
import { CLUSTER_COLUMN_FILTER_NAME, type IFilterBy } from "@/models/filter.model";
import { filterService } from "@/services/filter.service/filter.service";
import { ETableFilters } from "@/models/table.model";
import { departmentIndexColumns } from "@/table-models/department.table-model";
import { useAuthStore } from "@/stores/auth.store";
import type { INodePoolResources } from "@/models/project.model";

export default defineComponent({
  components: {
    DepartmentEditForm,
  },
  data() {
    return {
      settingStore: useSettingStore(),
      appStore: useAppStore(),
      clusterStore: useClusterStore(),
      department: {} as IDepartment,
      loading: false as boolean,
      submitting: false as boolean,
    };
  },
  async created() {
    try {
      this.loading = true;

      if (this.$route.params.id) {
        this.department = await this.loadDepartment(this.$route.params.id as string);
      } else {
        this.department = await this.getEmptyDepartment();
      }
    } catch (err) {
      console.log(err);
      this.appStore.setFallback(true);
    } finally {
      this.loading = false;
      this.appStore.setPageLoading(false);
    }
  },
  computed: {
    isNewDepartment(): boolean {
      return this.$route.name === DEPARTMENT_ROUTE_NAMES.DEPARTMENT_NEW;
    },
    isCpuEnabled(): boolean {
      return this.settingStore.isCPUResourcesQuotaEnabled;
    },
    isDepartmentViewPage(): boolean {
      return this.$route.name === DEPARTMENT_ROUTE_NAMES.DEPARTMENT_VIEW;
    },
    activeClusterFilterId(): string {
      return this.clusterStore.getClusterIdFromFilters(ETableFilters.DEPARTMENT);
    },
  },
  methods: {
    async loadDepartment(departmentId: string): Promise<IDepartment> {
      const selectedDepartment = await departmentService.getById(+departmentId, this.activeClusterFilterId);
      this.sortNodePools(selectedDepartment);
      return selectedDepartment;
    },
    async onCancel(): Promise<void> {
      const allowToLeave: boolean = await requestToLeave();
      if (allowToLeave) this.$router.push({ name: DEPARTMENT_ROUTE_NAMES.DEPARTMENT_INDEX });
    },
    async save(department: IDepartment): Promise<void> {
      const { clusterUuid, ...departmentData } = department;
      if (!clusterUuid) return;
      departmentUtil.removeDepartmentDeprecatedPostFields(department);
      this.submitting = true;
      if (!this.isCpuEnabled) {
        departmentUtil.resetCpuResources(departmentData);
      }
      try {
        const savedDepartment = await departmentService.save(departmentData, clusterUuid);
        await useAuthStore().loadUserOrgUnits();
        this.$q.notify(
          alertUtil.getSuccess(`Department ${department.name} ${this.isNewDepartment ? "created" : "saved"}`),
        );
        this.redirectToDepartmentIndex(savedDepartment);
      } catch (error: unknown) {
        this.handleError(error);
      } finally {
        this.submitting = false;
      }
    },
    handleError(error: unknown) {
      if (error instanceof HttpErrorResponse) {
        console.error(error.serialize());
        this.$q.notify(alertUtil.getError(error.message));
      } else {
        this.$q.notify(alertUtil.getError(`Failed to ${this.isNewDepartment ? "create" : "save"} department`));
      }
    },
    async getEmptyDepartment(): Promise<IDepartment> {
      const department: IDepartment = departmentService.getEmptyDepartmentModel(this.isCpuEnabled);
      department.clusterUuid = this.activeClusterFilterId;
      department.nodePoolsResources = await this.getClusterNodePools(department.clusterUuid);
      this.sortNodePools(department);
      return department;
    },
    async getClusterNodePools(clusterId: string): Promise<INodePoolResources[]> {
      const nodePools: Array<INodePool> = await nodePoolService.getNodePools(clusterId);
      return nodePools.map((np: INodePool) => {
        return {
          nodePool: {
            id: np.id,
            name: np.name,
          },
          ...departmentService.getNodeResourcesModel(this.isCpuEnabled),
        };
      });
    },
    sortNodePools(department: IDepartment) {
      department.nodePoolsResources.sort((nodePoolResourceA, nodePoolResourceB) =>
        nodePoolResourceA.nodePool?.name.localeCompare(nodePoolResourceB.nodePool?.name),
      );

      const defaultIdx = department.nodePoolsResources.findIndex((np) => np.nodePool?.name === DEFAULT_DEPARTMENT_NAME);

      if (defaultIdx !== -1) {
        const defaultNodePool = department.nodePoolsResources.splice(defaultIdx, 1)[0];
        department.nodePoolsResources.unshift(defaultNodePool);
      }
    },
    redirectToDepartmentIndex(department: IDepartment): void {
      if (this.isNewDepartment) {
        this.setClusterColumnFilter(department);
      }
      this.$router.push({
        name: DEPARTMENT_ROUTE_NAMES.DEPARTMENT_INDEX,
        query: {
          createdEntityId: department.id,
        },
      });
    },
    setClusterColumnFilter(department: IDepartment): void {
      const filterBy: IFilterBy = this.getDepartmentsFilterBy();
      filterService.setColumnFilter(
        filterBy,
        department.clusterUuid as string,
        CLUSTER_COLUMN_FILTER_NAME,
        ETableFilters.DEPARTMENT,
      );
    },
    getDepartmentsFilterBy(): IFilterBy {
      const defaultFilters: IFilterBy = filterService.getDefaultFilters(
        EDepartmentColumnName.DepartmentName,
        departmentIndexColumns,
      );
      return filterService.loadFilters(window.location, ETableFilters.DEPARTMENT, defaultFilters);
    },
  },
});
</script>
