<template>
  <section class="workload-template-create-form column items-center q-pt-md">
    <runai-form-wrapper :form-state="workloadTemplateModel" ref="workloadTemplateForm">
      <runai-scope-section
        kind-of-asset="template"
        :read-only="false"
        :scope-model="scopeModel"
        @changed-scope="updateScope"
        :allowed-scopes="allowedScopes"
        @is-section-invalid="sectionValidation.scope = !$event"
        :scopes-supported-versions="scopesSupportedVersions"
      />

      <runai-name-section
        aid="name-section"
        label="Template"
        asset-kind="workload-template"
        v-model="workloadTemplateModel.name"
        :scope-model="scopeModel"
        @is-section-invalid="sectionValidation.name = !$event"
        support-description
        :description="workloadTemplateModel.description"
        @update-description="updateDescription"
      />

      <transition-group name="fade">
        <template v-if="showAssetSections">
          <environment-section
            :key="`environment-section-${workloadTemplateModel.projectId}`"
            :entity-type="formType"
            aid="environment-section"
            :loading="loadingAssets"
            :environments="environments"
            :environment-id="workloadTemplateModel.assets.environment || ''"
            :specific-env="environmentModel"
            @environment-changed="updateSelectedEnvironment"
            @create-new="onCreateNewEnvironment"
            :section-options="envSectionOptions"
          />

          <compute-resource-section
            :key="`compute-resource-section-${workloadTemplateModel.projectId}`"
            :entity-type="formType"
            aid="compute-resource-section"
            :loading="loadingAssets"
            :compute-resources="computeResources"
            :compute-resource-data="computeResourceData"
            @compute-resource-data-changed="onComputeResourceDataChanged"
            @create-new="onCreateNewComputeResource"
            :is-required="false"
          />

          <volume-section
            :volumes="volumes"
            :storage-classes="storageClasses"
            :loading="loadingStorageClasses"
            @update-volumes="setVolumes"
          />

          <data-source-section
            :key="`data-source-section-${workloadTemplateModel.projectId}`"
            :entity-type="formType"
            aid="data-source-section"
            :loading="loadingAssets"
            :data-sources="dataSources"
            :selected-data-sources="workloadTemplateModel.assets.datasources || []"
            @datasource-changed="updateSelectedDataSources"
            @create-new="onCreateNewDataSource"
          />

          <general-section
            :workload-form-type="formType"
            :general-model="generalSectionModel"
            @general-model-changed="updateGeneralModel"
            :show-backoff-limit="supportBackoffLimit"
          />
        </template>
      </transition-group>

      <section class="row items-center q-mt-md">
        <q-field class="col-4 form-hint no-padding" :model-value="displayFormHint" :rules="[isFormIncomplete]"></q-field>
        <div class="buttons q-ml-auto">
          <q-btn class="q-mr-md" @click="onCancel" flat color="primary" label="cancel" />
          <q-btn color="primary" @click="save" :loading="submitting" aid="create-template-btn" label="create template" />
        </div>
      </section>
    </runai-form-wrapper>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// Components
import { RunaiFormWrapper } from "@/components/common/runai-form-wrapper";
import { RunaiScopeSection } from "@/components/common/runai-scope-section";
import { RunaiNameSection } from "@/components/common/runai-name-section";
import { EnvironmentSection } from "@/components/section/environment-section";
import { ComputeResourceSection } from "@/components/section/compute-resource-section";
import { DataSourceSection } from "@/components/section/data-source-section";
import { VolumeSection } from "@/components/section/volume-section";
import { GeneralSection } from "@/components/section/general-section";

// Models
import type { IProject, INodePoolResources } from "@/models/project.model";
import type { IUIGeneralSectionModel } from "@/components/section/general-section";
import {
  Scope,
  type AssetIdAndKind,
  type ComputeAsset,
  type DatasourceListResponseEntry,
  type EnvironmentAsset,
  type WorkloadTemplateCreationRequest,
  type EnvironmentVariable,
  type Annotation,
  type Label,
  type AssetsIds,
  type SpecificRunParams,
  type SpecificRunConnectionInfo,
} from "@/swagger-models/assets-service-client";
import { ScopeType, type PermittedScopes } from "@/swagger-models/authorization-client";
import type { IComputeSectionNodePoolData } from "@/components/section/compute-resource-section";
import { ENodePoolsListOrigin } from "@/components/section/compute-resource-section";
import { EWorkloadFormType, type IWorkloadMeta } from "@/models/workload.model";
import type { IItemizedListItem } from "@/components/common/runai-itemized-list";
import type { IUIWorkloadEnvSectionModel, IWorkloadEnvSectionOptions } from "@/components/section/environment-section";
import type { IComputeSectionData } from "@/components/section/compute-resource-section";

// Services
import { dataSourceService } from "@/services/control-plane/data-source.service/data-source.service";
import { orgTreeService } from "@/services/control-plane/rbac/org-tree.service/org-tree.service";

// Stores
import { useProjectStore } from "@/stores/project.store";
import { useEnvironmentStore } from "@/stores/environment.store";
import { useComputeResourceStore } from "@/stores/compute-resource.store";
import { useDataSourceStore } from "@/stores/data-source.store";
import { useClusterStore } from "@/stores/cluster.store";
import { useWorkloadTemplateStore } from "@/stores/workload-template.store";
import { useSettingStore } from "@/stores/setting.store";
import { useAuthStore } from "@/stores/auth.store";

// Constants
import { WORKLOAD_TEMPLATE_ROUTE_NAMES } from "@/router/workload-template.routes/workload-template.routes.names";
import { DATA_SOURCES_TYPES_TO_NAMES, type IUIVolume } from "@/models/data-source.model";

// Utils
import { pick, omit, fallbackDefaultIfNullOrUndefined } from "@/utils/common.util";
import type { IScopeModel } from "@/models/global.model";
import type { IAssetsFilter } from "@/models/filter.model";
import type {
  IUIWorkloadTemplateCreation,
  IUIWorkloadTemplateCreationRequestModel,
} from "@/models/workload-template.model";
import { ErrorAlert } from "@/utils/error-alert.util";
import { useAppStore } from "@/stores/app.store";
import {
  MIN_ClUSTER_SCOPE_ASSETS,
  MIN_DEPARTMENT_SCOPE_CREDENTIALS,
  MIN_TENANT_SCOPE_CREDENTIALS,
  MIN_VERSION_BACKOFF_LIMIT,
} from "@/common/version.constant";

interface IUIWorkloadTemplateMeta extends Omit<IWorkloadMeta, "clusterId" | "projectId" | "departmentId"> {
  scope: Scope | null;
  clusterId?: string | null;
  projectId?: number | null;
  departmentId?: string | null;
}

export default defineComponent({
  components: {
    RunaiFormWrapper,
    RunaiScopeSection,
    RunaiNameSection,
    EnvironmentSection,
    ComputeResourceSection,
    DataSourceSection,
    VolumeSection,
    GeneralSection,
  },
  emits: ["cancel", "submit"],
  props: {
    initialWorkloadTemplate: {
      type: Object as PropType<IUIWorkloadTemplateCreationRequestModel>,
      required: true,
    },
    submitting: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    allowedScopes: {
      type: Object as PropType<PermittedScopes>,
      required: false,
    },
  },
  data() {
    return {
      sectionValidation: {
        name: false,
        scope: false,
      } as Record<string, boolean>,
      workloadTemplateStore: useWorkloadTemplateStore(),
      clusterStore: useClusterStore(),
      dataSourceStore: useDataSourceStore(),
      computeResourceStore: useComputeResourceStore(),
      environmentStore: useEnvironmentStore(),
      projectStore: useProjectStore(),
      settingStore: useSettingStore(),
      appStore: useAppStore(),
      workloadTemplateForm: null as HTMLFormElement | null,
      workloadTemplateModel: this.getWorkloadTemplateModel() as IUIWorkloadTemplateCreation,
      displayFormHint: false as boolean,
      timeOutId: null as ReturnType<typeof setTimeout> | null,
      storageClasses: [] as Array<string>,
      loadingStorageClasses: false as boolean,
      formType: EWorkloadFormType.Template,
      loadingAssets: false as boolean,
    };
  },
  created() {
    this.workloadTemplateModel = this.convertTemplateToWorkloadUI(this.initialWorkloadTemplate);
  },
  mounted() {
    this.workloadTemplateForm = this.$refs.workloadTemplateForm as HTMLFormElement;
  },
  computed: {
    scopesSupportedVersions(): Record<ScopeType, string> {
      return {
        [ScopeType.Tenant]: MIN_TENANT_SCOPE_CREDENTIALS,
        [ScopeType.Department]: MIN_DEPARTMENT_SCOPE_CREDENTIALS,
        [ScopeType.Cluster]: MIN_ClUSTER_SCOPE_ASSETS,
        [ScopeType.Project]: "",
      };
    },
    showAssetSections(): boolean {
      const { scope, departmentId, projectId, clusterId } = this.workloadTemplateModel;
      const isTenantScope: boolean = scope === Scope.Tenant;
      return !!scope && (isTenantScope || !!departmentId || !!projectId || !!clusterId);
    },
    volumes(): Array<IUIVolume> {
      return this.workloadTemplateStore.workloadVolumes;
    },
    projects(): Array<IProject> {
      return this.projectStore.projectList;
    },
    selectedProject(): IProject | undefined {
      if (!this.workloadTemplateModel.projectId) return undefined;
      return this.findProject(this.workloadTemplateModel.projectId);
    },
    environments(): Array<EnvironmentAsset> {
      return this.environmentStore.environmentList;
    },
    selectedEnvironment(): EnvironmentAsset | undefined {
      return this.environments.find(
        (environment: EnvironmentAsset) => environment.meta.id === this.workloadTemplateModel.assets.environment,
      );
    },
    computeResources(): Array<ComputeAsset> {
      return this.computeResourceStore.computeResourcesList;
    },
    dataSources(): Array<DatasourceListResponseEntry> {
      return this.dataSourceStore.dataSourceList;
    },
    allNodePoolsOptions(): Array<string> {
      return (
        this.selectedProject?.nodePoolsResources.map(
          (nodePoolResource: INodePoolResources) => nodePoolResource.nodePool.name,
        ) || []
      );
    },
    nodePoolsData(): IComputeSectionNodePoolData | null {
      if (!this.workloadTemplateModel.specificEnv || !this.workloadTemplateModel.specificEnv.nodePools) {
        return null;
      }

      return {
        defaultNodePools: this.workloadTemplateModel.specificEnv.nodePools,
        allNodePoolsOptions: this.allNodePoolsOptions,
        nodePoolsListOrigin: ENodePoolsListOrigin.Project,
      };
    },
    generalSectionModel(): IUIGeneralSectionModel {
      return {
        allowOverQuota: this.workloadTemplateModel.specificEnv?.allowOverQuota || false,
        autoDeletionTimeAfterCompletionSeconds: Number.isInteger(
          this.workloadTemplateModel.specificEnv?.autoDeletionTimeAfterCompletionSeconds,
        )
          ? this.workloadTemplateModel.specificEnv?.autoDeletionTimeAfterCompletionSeconds
          : null,
        annotations: this.workloadTemplateModel.specificEnv?.annotations,
        labels: this.workloadTemplateModel.specificEnv?.labels,
        backoffLimit: this.workloadTemplateModel.specificEnv?.backoffLimit || null,
      };
    },
    scopeModel(): IScopeModel {
      return {
        scope: this.workloadTemplateModel.scope,
        projectId: this.workloadTemplateModel.projectId,
        departmentId: this.workloadTemplateModel.departmentId,
        clusterId: this.workloadTemplateModel.clusterId,
      };
    },
    environmentModel(): IUIWorkloadEnvSectionModel {
      const specificEnv = this.workloadTemplateModel.specificEnv;
      return {
        command: specificEnv?.command || "",
        args: specificEnv?.args || "",
        environmentVariables: specificEnv?.environmentVariables || [],
        connections: specificEnv?.connections || [],
        runAsGid: specificEnv?.runAsGid || null,
        runAsUid: specificEnv?.runAsUid || null,
        supplementalGroups: specificEnv?.supplementalGroups || null,
      };
    },
    envSectionOptions(): IWorkloadEnvSectionOptions {
      return {
        isToolBoxRequired: false,
      };
    },
    computeResourceData(): IComputeSectionData {
      return {
        computeResourceId: this.workloadTemplateModel.assets.compute || null,
        nodeType: this.workloadTemplateModel.specificEnv?.nodeType || null,
        nodePools: this.nodePoolsData,
      };
    },
    scopeId(): string {
      const { clusterId, departmentId, projectId } = this.workloadTemplateModel;
      switch (this.workloadTemplateModel.scope) {
        case Scope.Cluster:
          return clusterId || "";
        case Scope.Department:
          return departmentId || "";
        case Scope.Project:
          return projectId?.toString() || "";
        default:
          return "";
      }
    },
    parentClusterId(): string | null {
      if (!this.workloadTemplateModel.scope || this.workloadTemplateModel.scope === Scope.Tenant) return null;
      return orgTreeService.getParentClusterUuid(
        useAuthStore().orgUnitList,
        this.scopeId,
        this.workloadTemplateModel.scope,
      );
    },
    supportBackoffLimit(): boolean {
      if (this.workloadTemplateModel.scope === Scope.Tenant) return false;
      return this.clusterStore.isClusterVersionSufficient(this.parentClusterId, MIN_VERSION_BACKOFF_LIMIT);
    },
  },
  methods: {
    updateDescription(description: string): void {
      this.workloadTemplateModel.description = description;
    },
    async loadAssets() {
      if (!this.workloadTemplateModel.scope) return;
      const assetsFilter: IAssetsFilter = {
        projectId: this.workloadTemplateModel.projectId,
        departmentId: this.workloadTemplateModel.departmentId,
        clusterId: this.workloadTemplateModel.clusterId || undefined,
      };

      if (this.workloadTemplateModel.scope === Scope.Tenant) {
        assetsFilter.scope = Scope.Tenant;
      }

      try {
        this.loadingAssets = true;
        await Promise.all([
          this.environmentStore.loadEnvironments(assetsFilter),
          this.computeResourceStore.loadComputeResources(assetsFilter),
          this.dataSourceStore.loadDataSources(assetsFilter),
        ]);
      } catch (error: unknown) {
        const errorAlert = new ErrorAlert({
          generalMessage: ErrorAlert.failedLoadingMessage("assets"),
          404: ErrorAlert.failedLoadingMessage("assets"),
        });
        this.$q.notify(errorAlert.getNotification(error));
        this.appStore.setFallback(true);
      } finally {
        this.loadingAssets = false;
      }
    },
    setVolumes(volumes: Array<IUIVolume>): void {
      this.workloadTemplateStore.setWorkloadVolumes(volumes);
    },
    async loadStorageClasses(clusterUuid: string): Promise<void> {
      try {
        this.loadingStorageClasses = true;
        const clusterId = clusterUuid || this.clusterStore.currentClusterId;
        this.storageClasses = await dataSourceService.listStorageClass(clusterId);
      } catch (err) {
        console.error(err);
      } finally {
        this.loadingStorageClasses = false;
      }
    },
    onCreateNewEnvironment(): void {
      this.saveTemplate(this.workloadTemplateModel);
      this.$router.push({
        name: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_ENVIRONMENT_NEW,
        query: {
          fromPage: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_NEW,
          projectId: this.workloadTemplateModel.projectId,
          departmentId: this.workloadTemplateModel.departmentId,
          clusterId: this.workloadTemplateModel.clusterId,
          scope: this.workloadTemplateModel.scope,
        },
      });
    },
    onCreateNewComputeResource(): void {
      this.saveTemplate(this.workloadTemplateModel);
      this.$router.push({
        name: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_COMPUTE_RESOURCE_NEW,
        query: {
          fromPage: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_NEW,
          projectId: this.workloadTemplateModel.projectId,
          departmentId: this.workloadTemplateModel.departmentId,
          clusterId: this.workloadTemplateModel.clusterId,
          scope: this.workloadTemplateModel.scope,
        },
      });
    },
    onCreateNewDataSource(selectedDataSourceType: string): void {
      const pageRouteMap: Record<string, string> = {
        [DATA_SOURCES_TYPES_TO_NAMES.NFS]: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_NFS_NEW,
        [DATA_SOURCES_TYPES_TO_NAMES.PVC]: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_PVC_NEW,
        [DATA_SOURCES_TYPES_TO_NAMES.S3]: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_S3_NEW,
        [DATA_SOURCES_TYPES_TO_NAMES.GIT]: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_GIT_NEW,
        [DATA_SOURCES_TYPES_TO_NAMES.HOST_PATH]: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_HOST_PATH_NEW,
        [DATA_SOURCES_TYPES_TO_NAMES.CONFIG_MAP]: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_CONFIG_MAP_NEW,
      };

      this.saveTemplate(this.workloadTemplateModel);
      this.$router.push({
        name: pageRouteMap[selectedDataSourceType],
        query: {
          fromPage: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_NEW,
          projectId: this.workloadTemplateModel.projectId,
          departmentId: this.workloadTemplateModel.departmentId,
          clusterId: this.workloadTemplateModel.clusterId,
          scope: this.workloadTemplateModel.scope,
        },
      });
    },
    updateSelectedDataSources(dataSources: Array<AssetIdAndKind>): void {
      this.workloadTemplateModel.assets.datasources = dataSources;
    },
    onComputeResourceDataChanged(computeResourceData: IComputeSectionData): void {
      this.workloadTemplateModel.assets.compute = computeResourceData.computeResourceId;

      if (this.workloadTemplateModel.specificEnv) {
        this.workloadTemplateModel.specificEnv.nodePools = computeResourceData.nodePools?.defaultNodePools;
        this.workloadTemplateModel.specificEnv.nodeType = computeResourceData.nodeType || null;
      }
    },
    updateSelectedEnvironment(envData: { environmentId: string; specificEnv: IUIWorkloadEnvSectionModel }): void {
      this.workloadTemplateModel.assets.environment = envData.environmentId;
      const environmentVariables = envData.specificEnv.environmentVariables.map((item: IItemizedListItem) =>
        pick(item, "name", "value", "deleted"),
      );
      this.workloadTemplateModel.specificEnv = {
        ...this.workloadTemplateModel.specificEnv,
        ...envData.specificEnv,
        environmentVariables,
      };
    },
    updateScope(updatedScope: IScopeModel): void {
      this.workloadTemplateModel = {
        ...this.getWorkloadTemplateModel(),
        name: this.workloadTemplateModel.name,
        scope: updatedScope.scope,
        projectId: updatedScope.projectId || null,
        departmentId: updatedScope.departmentId || null,
        clusterId: updatedScope.clusterId || null,
      };
      this.loadAssets();
      this.loadStorageClasses(updatedScope.parentClusterUuid || "");

      if (this.workloadTemplateModel.projectId) {
        this.workloadTemplateModel.specificEnv = {
          ...this.workloadTemplateModel.specificEnv,
          nodePools: this.getNodePoolsFromProject(this.workloadTemplateModel.projectId),
        };
      }
    },
    validate(): Promise<boolean> {
      return (this.workloadTemplateForm as HTMLFormElement).validate();
    },
    onCancel(): void {
      this.$emit("cancel");
    },
    async save(): Promise<void> {
      this.displayFormHint = false;
      const success: boolean = await this.validate();
      if (!success || !this.workloadTemplateModel.assets.environment) {
        this.showHint();
        return;
      }
      if (this.workloadTemplateModel.scope === Scope.Project) {
        this.workloadTemplateModel.clusterId = this.parentClusterId;
      }

      if (this.workloadTemplateModel.specificEnv?.backoffLimit && !this.clusterStore.isVersionSupportBackoffLimit) {
        this.workloadTemplateModel.specificEnv.backoffLimit = null;
      }

      this.saveTemplate(this.workloadTemplateModel);
      this.$emit("submit", this.workloadTemplateStore.workloadTemplateCreation);
    },
    showHint(): void {
      this.displayFormHint = true;
      this.timeOutId && clearTimeout(this.timeOutId);
      this.timeOutId = setTimeout(() => (this.displayFormHint = false), 15000);
    },
    isFormIncomplete(val: boolean): boolean | string {
      return !val ? true : "Please review and fix the issues in the form";
    },
    findProject(projectId: number): IProject | undefined {
      return this.projectStore.projects.find((project: IProject) => project.id === projectId);
    },
    getNodePoolsFromProject(projectId: number): Array<string> | null {
      const project: IProject | undefined = this.findProject(projectId);
      if (!project) return null;

      return project.defaultNodePools || null;
    },
    updateGeneralModel(generalModel: IUIGeneralSectionModel): void {
      this.workloadTemplateModel.specificEnv = {
        ...this.workloadTemplateModel.specificEnv,
        ...generalModel,
      };
    },
    emptyConnectionsCustomInfo(connections: Array<SpecificRunConnectionInfo>): Array<SpecificRunConnectionInfo> {
      return connections.map((connection: SpecificRunConnectionInfo) => {
        if (connection.nodePort) {
          return {
            ...connection,
            nodePort: null,
          };
        } else if (connection.externalUrl) {
          return {
            ...connection,
            externalUrl: null,
          };
        }
        return connection;
      });
    },
    getWorkloadUI(
      meta: IUIWorkloadTemplateMeta,
      assets: AssetsIds,
      specificEnv?: SpecificRunParams,
    ): IUIWorkloadTemplateCreation {
      const workloadUI: IUIWorkloadTemplateCreation = {
        name: meta.name,
        namespace: meta.namespace,
        clusterId: meta.clusterId,
        projectId: meta.projectId,
        departmentId: meta.departmentId,
        scope: meta.scope,
        assets: {
          environment: assets.environment,
          compute: assets.compute || null,
          datasources: assets.datasources,
        },
        specificEnv: {
          args: specificEnv?.args || "",
          command: specificEnv?.command,
          runAsUid: specificEnv?.runAsUid || null,
          runAsGid: specificEnv?.runAsGid || null,
          supplementalGroups: specificEnv?.supplementalGroups,
          nodeType: specificEnv?.nodeType || null,
          allowOverQuota: specificEnv?.allowOverQuota || false,
          nodePools: specificEnv?.nodePools ? specificEnv?.nodePools : null,
          autoDeletionTimeAfterCompletionSeconds: fallbackDefaultIfNullOrUndefined(
            specificEnv?.autoDeletionTimeAfterCompletionSeconds,
            null,
          ),
          backoffLimit: specificEnv?.backoffLimit || 6,
        },
      };

      if (specificEnv) {
        if (specificEnv.connections) {
          workloadUI.specificEnv.connections = this.emptyConnectionsCustomInfo(specificEnv.connections);
        }

        if (specificEnv.environmentVariables) {
          workloadUI.specificEnv.environmentVariables = specificEnv.environmentVariables.map(
            (env: EnvironmentVariable) => ({ ...env, locked: false }),
          );
        }

        if (specificEnv.annotations) {
          workloadUI.specificEnv.annotations = specificEnv.annotations.map((ann: Annotation) => ({
            ...ann,
            locked: false,
          }));
        }

        if (specificEnv.labels) {
          workloadUI.specificEnv.labels = specificEnv.labels.map((lbl: Label) => ({ ...lbl, locked: false }));
        }
      }

      return workloadUI;
    },
    convertTemplateToWorkloadUI(workloadTemplate: IUIWorkloadTemplateCreationRequestModel): IUIWorkloadTemplateCreation {
      const workloadMeta: IUIWorkloadTemplateMeta = {
        name: workloadTemplate.meta.name,
        projectId: workloadTemplate.meta.projectId || null,
        departmentId: workloadTemplate.meta.departmentId || null,
        namespace: "",
        scope: workloadTemplate.meta.scope,
        clusterId: workloadTemplate.meta.clusterId || null,
      };
      return this.getWorkloadUI(
        workloadMeta,
        workloadTemplate.spec.assets,
        workloadTemplate.spec.specificEnv || undefined,
      );
    },
    saveTemplate(workloadTemplate: IUIWorkloadTemplateCreation): void {
      if (!workloadTemplate.scope) return;
      const workloadCreation: WorkloadTemplateCreationRequest = {
        meta: {
          name: workloadTemplate.name,
          description: workloadTemplate.description,
          projectId: workloadTemplate.projectId,
          departmentId: workloadTemplate.departmentId,
          scope: workloadTemplate.scope,
          clusterId: workloadTemplate.clusterId,
        },
        spec: {
          assets: {
            environment: workloadTemplate.assets.environment || "",
            compute: workloadTemplate.assets.compute,
          },
          specificEnv: {
            ...omit(workloadTemplate.specificEnv, ["environmentVariables", "annotations", "labels"]),
          },
        },
      };

      if (workloadCreation.spec.specificEnv) {
        workloadCreation.spec.specificEnv.args ||= null;
        workloadCreation.spec.specificEnv.command ||= null;
        if (workloadCreation.spec.specificEnv.supplementalGroups) {
          if (Array.isArray(workloadCreation.spec.specificEnv.supplementalGroups)) {
            workloadCreation.spec.specificEnv.supplementalGroups =
              workloadCreation.spec.specificEnv.supplementalGroups.join(",");
          }
        }
      }

      if (workloadTemplate.assets.datasources) {
        workloadCreation.spec.assets.datasources = workloadTemplate.assets.datasources;
      }

      if (workloadCreation.spec.specificEnv) {
        if (workloadTemplate.specificEnv.environmentVariables) {
          workloadCreation.spec.specificEnv.environmentVariables = workloadTemplate.specificEnv.environmentVariables.map(
            (env: IItemizedListItem) => pick(env, "name", "value", "deleted"),
          );
        }

        if (workloadTemplate.specificEnv.annotations) {
          workloadCreation.spec.specificEnv.annotations = workloadTemplate.specificEnv.annotations.map(
            (env: IItemizedListItem) => pick(env, "name", "value", "deleted"),
          );
        }

        if (workloadTemplate.specificEnv.labels) {
          workloadCreation.spec.specificEnv.labels = workloadTemplate.specificEnv.labels.map((env: IItemizedListItem) =>
            pick(env, "name", "value", "deleted"),
          );
        }
      }

      this.workloadTemplateStore.updateWorkloadTemplateCreation(workloadCreation);
    },
    getWorkloadTemplateModel(): IUIWorkloadTemplateCreation {
      return {
        name: "",
        description: null,
        projectId: null,
        departmentId: null,
        namespace: "",
        scope: Scope.Project,
        assets: {
          environment: "",
          compute: null,
        },
        specificEnv: {
          backoffLimit: 6,
        },
      };
    },
  },
  unmounted() {
    this.timeOutId && clearTimeout(this.timeOutId);
  },
});
</script>
<style lang="scss" scoped></style>
