// This file is a hack to fix the bug in vite.
// The bug:
// When you import the WORKSPACE_ROUTE_NAMES from the workspace.route.ts file into
// component, during the work you do some changes to the component (or to his parents),
// HMR (used by vite) show an exception about "initialization" and "syntax errors or importing non-existent modules".
// So I moved the names out of the route file, which fix this error.
export const TRAINING_ROUTE_NAMES = {
  TRAINING_NEW: "training-new",
  TRAINING_ASSETS_EDIT: "training-assets-edit",
  TRAINING_ENVIRONMENT_NEW: "training-environment-new",
  TRAINING_COMPUTE_RESOURCE_NEW: "training-compute-resource-new",
  TRAINING_HOST_PATH_NEW: "training-host-path-new",
  TRAINING_NFS_NEW: "training-nfs-new",
  TRAINING_S3_NEW: "training-s3-new",
  TRAINING_GIT_NEW: "training-git-new",
  TRAINING_PVC_NEW: "training-pvc-new",
  TRAINING_CONFIG_MAP_NEW: "training-config-map-new",
  TRAINING_SECRET_VOLUME_NEW: "training-secret-volume-new",
};
