export enum INFERENCE_ROUTE_NAMES {
  INFERENCE_NEW = "inference-new",
  INFERENCE_HISTORY = "inference-history",
  INFERENCE_ASSETS_EDIT = "inference-assets-edit",
  INFERENCE_MODEL_EDIT = "inference-model-edit",
  INFERENCE_ENVIRONMENT_NEW = "inference-environment-new",
  INFERENCE_COMPUTE_RESOURCE_NEW = "inference-compute-resource-new",
  INFERENCE_HOST_PATH_NEW = "inference-host-path-new",
  INFERENCE_NFS_NEW = "inference-nfs-new",
  INFERENCE_S3_NEW = "inference-s3-new",
  INFERENCE_GIT_NEW = "inference-git-new",
  INFERENCE_PVC_NEW = "inference-pvc-new",
  INFERENCE_CONFIG_MAP_NEW = "inference-config-map-new",
  INFERENCE_SECRET_VOLUME_NEW = "inference-secret-volume-new",
}
