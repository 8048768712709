import { type INodePool, MIN_OVER_PROVISIONING_RATIO } from "@/models/node-pool.model";
import type { Node } from "@/swagger-models/cluster-service-client";
import { binPackLabel, PlacementStrategy, spreadLabel } from "@/models/node-pool.model";

export const nodePoolUtil = {
  enrichNodePoolsWithInfoFromCluster,
  placementStrategyText,
  getNodePoolOprDisplayValue,
};

type TotalGpusMap = Record<string, number>;

function enrichNodePoolsWithInfoFromCluster(nodePools: INodePool[], clusterServiceNodes: Node[]): INodePool[] {
  const totalGpusMap: TotalGpusMap = {};

  clusterServiceNodes.forEach((node: Node) => {
    const { name, gpuInfo } = node;
    if (gpuInfo) totalGpusMap[name] = gpuInfo.gpuCount;
  });

  const nodePoolsHaveNodes = nodePools.some((nodePool: INodePool) => nodePool.nodes);
  if (!nodePoolsHaveNodes) {
    //prometheus is not available, so extract node names from clusterServiceNodes.
    return _assignNodesToNodePools(nodePools, clusterServiceNodes);
  }

  const enrichedNodePools = nodePools.map((nodePool: INodePool) => {
    const nodeNames = nodePool.nodes.split(", ");
    const totalGpus = nodeNames.reduce((total, nodeName) => total + (totalGpusMap[nodeName] || 0), 0);

    return { ...nodePool, totalGpus };
  });

  return enrichedNodePools;
}

function _assignNodesToNodePools(nodePools: INodePool[], nodes: Node[]): INodePool[] {
  const nodeMap: Record<string, Node[]> = {};

  nodes.forEach((node: Node) => {
    if (!nodeMap[node.nodePool]) {
      nodeMap[node.nodePool] = [];
    }
    nodeMap[node.nodePool].push(node);
  });

  return nodePools.map((nodePool: INodePool) => {
    const nodeNames: string = nodeMap[nodePool.name]?.map((node) => node.name).join(", ") || "";
    return {
      ...nodePool,
      nodes: nodeNames,
    };
  });
}

function placementStrategyText(strategy: PlacementStrategy): string {
  return strategy === PlacementStrategy.Binpack ? binPackLabel : spreadLabel;
}

function getNodePoolOprDisplayValue(overProvisioningRatio: number): string {
  if (overProvisioningRatio === MIN_OVER_PROVISIONING_RATIO) {
    return "Flexible";
  }
  return `Fixed ${overProvisioningRatio}`;
}
