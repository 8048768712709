<template>
  <span>
    <q-btn-dropdown
      class="profile-dropdown"
      :class="{ dark: darkMode }"
      content-class="profile-dropdown-menu-container"
      flat
      :label="shortName"
      dropdown-icon="none"
      no-icon-animation
      fab-mini
      aid="profile-btn"
    >
      <div class="profile-dropdown-menu-content">
        <q-list>
          <q-item>
            <q-item-section>
              <q-item-label class="user-details">
                <div class="user-avatar q-mb-sm">{{ shortName }}</div>
                <div class="user-email">{{ userEmail }}</div>
              </q-item-label>
            </q-item-section>
          </q-item>

          <q-separator />
          <q-item clickable v-close-popup @click="openUserSettings">
            <q-item-section>
              <q-item-label class="settings-row">
                <div>Settings</div>
              </q-item-label>
            </q-item-section>
          </q-item>

          <template v-if="!isSSOUser">
            <q-item clickable v-close-popup @click="onChangePassword">
              <q-item-section>
                <q-item-label class="change-password-row">
                  <div>Change password</div>
                </q-item-label>
              </q-item-section>
            </q-item>
          </template>

          <q-item clickable v-close-popup @click="onSignOut">
            <q-item-section>
              <q-item-label class="logout-row" aid="sign-out-btn">
                <div>Sign out</div>
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </q-btn-dropdown>
    <q-tooltip class="q-pa-sm tooltip-dark">
      <div>{{ userEmail }}</div>
    </q-tooltip>

    <runai-change-password-modal v-if="showChangePassModal" @close="showChangePassModal = false" />
  </span>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

// cmps
import { RunaiChangePasswordModal } from "@/components/common/runai-change-password-modal";

// stores
import { useAuthStore } from "@/stores/auth.store";

// models
import { EUserType } from "@/models/user.model";

export default defineComponent({
  components: {
    RunaiChangePasswordModal,
  },
  props: {
    darkMode: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      authStore: useAuthStore(),
      showChangePassModal: false as boolean,
    };
  },
  computed: {
    userEmail(): string {
      return this.authStore.currentUser.email;
    },
    shortName(): string {
      return this.userEmail?.substring(0, 1).toUpperCase() || "";
    },
    isSSOUser(): boolean {
      return this.authStore.currentUser.entityType === EUserType.SSO_USER;
    },
  },
  methods: {
    onSignOut(): void {
      this.authStore.logout();
    },
    onChangePassword(): void {
      this.showChangePassModal = true;
    },
    openUserSettings(): void {
      this.$router.push("/user-settings");
    },
  },
});
</script>

<style lang="scss">
.profile-dropdown {
  .q-icon {
    display: none;
  }

  &.q-btn {
    background: #f29f43;
    color: $white;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
  }

  &.dark {
    background: $navy;
  }
}

.profile-dropdown-menu-container {
  background: $dark-gray !important;
  color: $white;

  .profile-dropdown-menu-content {
    width: 300px;

    .user-details {
      padding: 15px 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }

    .user-avatar {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      background: $white-30;
      font-size: 36px;
    }
  }
}
</style>
