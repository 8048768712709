import type {
  EnvironmentAsset,
  ComputeAsset,
  DatasourceListResponseEntry,
} from "@/swagger-models/assets-service-client";
import type { ISelectedNodeAffinity } from "@/models/project.model";
import type { IProject } from "@/models/project.model";
import type { IWorkloadEnvSectionOptions } from "@/components/section/environment-section";
import type { IWorkloadComputeSectionOptions } from "@/components/section/compute-resource-section";
import type { IWorkloadDataSourceSectionOptions } from "@/components/section/data-source-section";
import type { IWorkloadToggleEditingFormSectionOptions } from "@/components/section/toggle-editing-form";

export interface IWorkloadFormBaseConfig {
  viewOnly?: boolean;
  sectionDefaultOpen?: boolean; // Move to section options?
  isRequired?: boolean; // Move to section options?
}

interface IWorkloadAssetsProjectSectionConfig extends IWorkloadFormBaseConfig {
  projects: Array<IProject>;
}

interface IWorkloadEnvironmentSectionConfig extends IWorkloadFormBaseConfig {
  environments: Array<EnvironmentAsset>;
  sectionOptions?: IWorkloadEnvSectionOptions;
}

interface IWorkloadComputeSectionConfig extends IWorkloadFormBaseConfig {
  computeResources: Array<ComputeAsset>;
  nodeAffinity?: Array<ISelectedNodeAffinity>;
  sectionOptions?: IWorkloadComputeSectionOptions;
}

type IWorkloadGeneralSectionConfig = IWorkloadFormBaseConfig;

interface IWorkloadDataSourceSectionConfig extends IWorkloadFormBaseConfig {
  dataSources: Array<DatasourceListResponseEntry>;
  sectionOptions?: IWorkloadDataSourceSectionOptions;
}

interface IWorkloadVolumesSectionConfig extends IWorkloadFormBaseConfig {
  storageClasses: Array<string>;
}

interface IToggleEditingForm extends IWorkloadFormBaseConfig {
  sectionOptions: IWorkloadToggleEditingFormSectionOptions;
}

interface IModelSummaryConfig extends IWorkloadFormBaseConfig {
  summary: string;
}

export enum EWorkloadSectionNames {
  ToggleEditingForm = "toggleEditingForm",
  Project = "project",
  ModelSummary = "modelSummary",
  WorkloadName = "workloadName",
  MultiNode = "multiNode",
  Credentials = "credentials",
  ModelName = "modelName",
  Environment = "environment",
  Compute = "compute",
  DataSource = "dataSource",
  Volumes = "volumes",
  General = "general",
}
export interface IWorkloadCreateFormConfig {
  [EWorkloadSectionNames.ToggleEditingForm]?: IToggleEditingForm;
  [EWorkloadSectionNames.Project]?: IWorkloadAssetsProjectSectionConfig;
  [EWorkloadSectionNames.ModelSummary]?: IModelSummaryConfig;
  [EWorkloadSectionNames.WorkloadName]?: IWorkloadFormBaseConfig;
  [EWorkloadSectionNames.MultiNode]?: IWorkloadFormBaseConfig;
  [EWorkloadSectionNames.Credentials]?: IWorkloadFormBaseConfig;
  [EWorkloadSectionNames.ModelName]?: IWorkloadFormBaseConfig;
  [EWorkloadSectionNames.Environment]?: IWorkloadEnvironmentSectionConfig;
  [EWorkloadSectionNames.Compute]?: IWorkloadComputeSectionConfig;
  [EWorkloadSectionNames.DataSource]?: IWorkloadDataSourceSectionConfig;
  [EWorkloadSectionNames.Volumes]?: IWorkloadVolumesSectionConfig;
  [EWorkloadSectionNames.General]?: IWorkloadGeneralSectionConfig;
}
