import type { INodePoolResources } from "@/models/project.model";
import { EMPTY_CPU_RESOURCE_VALUE, RESOURCE_MAX_ALLOWED_INFINITE_VALUE } from "@/models/resource.model";
import type { IDepartment } from "@/models/department.model";

export const departmentUtil = {
  updateMaxAllowedOverQuota,
  resetCpuResources,
  removeDepartmentDeprecatedPostFields,
};

function updateMaxAllowedOverQuota(
  department: IDepartment,
  isCPUResourcesQuotaEnabled: boolean,
  isOverQuotaOn: boolean,
): void {
  if (isOverQuotaOn) {
    department.resources.gpu.maxAllowed = RESOURCE_MAX_ALLOWED_INFINITE_VALUE;
    department.maxAllowedGpus = RESOURCE_MAX_ALLOWED_INFINITE_VALUE;
    if (isCPUResourcesQuotaEnabled) {
      department.resources.cpu.maxAllowed = RESOURCE_MAX_ALLOWED_INFINITE_VALUE;
      department.resources.memory.maxAllowed = RESOURCE_MAX_ALLOWED_INFINITE_VALUE;
    }
    department.nodePoolsResources.forEach((nodePool: INodePoolResources) => {
      nodePool.gpu.maxAllowed = RESOURCE_MAX_ALLOWED_INFINITE_VALUE;
      if (isCPUResourcesQuotaEnabled) {
        nodePool.cpu.maxAllowed = RESOURCE_MAX_ALLOWED_INFINITE_VALUE;
        nodePool.memory.maxAllowed = RESOURCE_MAX_ALLOWED_INFINITE_VALUE;
      }
    });
  } else {
    department.resources.gpu.maxAllowed = department.resources.gpu.deserved;
    department.maxAllowedGpus = department.resources.gpu.maxAllowed;
    if (isCPUResourcesQuotaEnabled) {
      department.resources.cpu.maxAllowed = department.resources.cpu.deserved;
      department.resources.memory.maxAllowed = department.resources.memory.deserved;
    }

    department.nodePoolsResources.forEach((nodePool: INodePoolResources) => {
      nodePool.gpu.maxAllowed = nodePool.gpu.deserved;
      if (isCPUResourcesQuotaEnabled) {
        nodePool.cpu.maxAllowed = nodePool.cpu.deserved;
        nodePool.memory.maxAllowed = nodePool.memory.deserved;
      }
    });
  }
}

function resetCpuResources(department: IDepartment): void {
  department.nodePoolsResources.forEach((nodePool: INodePoolResources) => {
    nodePool.cpu = { ...EMPTY_CPU_RESOURCE_VALUE };
    nodePool.memory = { ...EMPTY_CPU_RESOURCE_VALUE };
  });
}

function removeDepartmentDeprecatedPostFields(department: IDepartment): void {
  const deprecatedPostFields = [
    "tenantId",
    "createdAt",
    "projectsDeservedGpus",
    "quotaStatuses",
    "projectAggregatedNodePoolsResources",
    "resources",
  ];
  deprecatedPostFields.forEach((field) => {
    //@ts-ignore
    delete department[field];
  });
}
