import { defineStore } from "pinia";
import { clusterService } from "@/services/control-plane/cluster.service/cluster.service";
import { clusterApiService } from "@/services/cluster/cluster-api.service/cluster-api.service";
import { allClusterColumns } from "@/table-models/cluster.table-model";
import { filterService } from "@/services/filter.service/filter.service";
import { TRAINING_DISTRIBUTED_MIN_VERSION, type ICluster } from "@/models/cluster.model";
import { USE_EXTERNAL_TOKEN } from "@/common/storage.constant";
import { CLUSTER_COLUMN_FILTER_NAME, type IFilterBy, type IFilterModel } from "@/models/filter.model";
import { useAuthStore } from "@/stores/auth.store";
import { useGrafanaStore } from "./grafana.store";
import { isNewerVersion } from "@/utils/version.util";
import { storageUtil } from "@/utils/storage.util";
import { usePermissionStore } from "@/stores/permissions.store";
import { ResourceType, Action } from "@/swagger-models/authorization-client";
import { ClusterDisplayedStatusStateEnum } from "@/swagger-models/cluster-service-client";

import type { IReadinessStatus } from "@/models/researcher.model";

import {
  FEATURE_BRANCH_VERSION,
  MIN_CLI_VERSION,
  MIN_CLUSTER_SERVICE_NODES_VERSION,
  MIN_DEPARTMENT_QUEUE_METRIC_VERSION,
  MIN_DEPENDENCIES_VERSION,
  MIN_NEW_DEPARTMENT_METRIC_VERSION,
  MIN_VERSION_BACKOFF_LIMIT,
  TEST_ENV_VERSION,
} from "@/common/version.constant";
import { clusterUtil } from "@/utils/cluster.util";
import { whoamiService } from "@/services/cluster/whoami.service/whoami.service";
import type { DisplayedCluster } from "@/swagger-models/cluster-service-client";
import type { ETableFilters } from "@/models/table.model";

export const useClusterStore = defineStore("Cluster", {
  state: () => ({
    clusters: [] as Array<ICluster>,
    currentCluster: {} as DisplayedCluster,
    lastCreatedClusterUuid: null as null | string,
    firstConnectedCluster: undefined as ICluster | undefined,
  }),
  getters: {
    isKnativeInstalledInActiveCluster(): boolean {
      // if the cluster version is lower than the minimum dependencies version, return true
      // and let the cluster validation handle the knative availability
      if (!isNewerVersion(this.currentClusterVersion, MIN_DEPENDENCIES_VERSION)) return true;

      return this.currentCluster?.status?.dependencies?.optional?.knative.available || false;
    },
    isClusterDisconnected(): boolean {
      return this.currentCluster?.status?.state === ClusterDisplayedStatusStateEnum.Disconnected;
    },
    clusterList(): Array<ICluster> {
      return this.clusters;
    },
    clusterListLastCreatedExcluded(): Array<ICluster> {
      if (!this.lastCreatedClusterUuid) return this.clusters;
      return this.clusters.filter((cluster: ICluster) => cluster.uuid !== this.lastCreatedClusterUuid);
    },
    lastCreatedCluster(): ICluster | null {
      if (!this.lastCreatedClusterUuid) return null;
      return this.clusters.find((cluster: ICluster) => cluster.uuid === this.lastCreatedClusterUuid) || null;
    },
    currentClusterId(): string {
      return this.currentCluster?.uuid || "";
    },
    currentClusterVersion(): string {
      return this.currentCluster?.version || "";
    },
    currentClusterName(): string {
      return this.currentCluster?.name || "";
    },
    currentClusterDomain(): string {
      return this.currentCluster?.domain || "";
    },
    isClusterAmdGpuType(): boolean {
      return this.currentCluster?.status?.dependencies?.required?.["gpu-stack"].components?.amd?.available || false;
    },
    availableDependencies(): Array<string> {
      const requiredDependenciesAvailable: Array<string> = Object.keys(
        this.currentCluster?.status?.dependencies?.required || {},
      ).filter((key: string) => this.currentCluster.status?.dependencies?.required[key]?.available);

      const optionalDependenciesAvailable: Array<string> = Object.keys(
        this.currentCluster?.status?.dependencies?.optional || {},
      ).filter((key: string) => this.currentCluster.status?.dependencies?.optional[key]?.available);

      return [...requiredDependenciesAvailable, ...optionalDependenciesAvailable];
    },
    availableDependenciesByClusterUuid:
      (state) =>
      (clusterUuid: string): Array<string> => {
        const relevantCluster = state.clusters.find((c) => c.uuid === clusterUuid);
        if (!relevantCluster) return [];
        const requiredDependenciesAvailable: Array<string> = Object.keys(
          relevantCluster.status?.dependencies?.required || {},
        ).filter((key: string) => state.currentCluster.status?.dependencies?.required[key]?.available);

        const optionalDependenciesAvailable: Array<string> = Object.keys(
          relevantCluster.status?.dependencies?.optional || {},
        ).filter((key: string) => state.currentCluster.status?.dependencies?.optional[key]?.available);

        return [...requiredDependenciesAvailable, ...optionalDependenciesAvailable];
      },
    isVersionSupportDistributedTraining(): boolean {
      return isNewerVersion(this.currentClusterVersion, TRAINING_DISTRIBUTED_MIN_VERSION);
    },
    isVersionSupportClusterServiceNodes(): boolean {
      return isNewerVersion(this.currentClusterVersion, MIN_CLUSTER_SERVICE_NODES_VERSION);
    },
    isVersionSupportClusterDepartmentMetric(): boolean {
      return isNewerVersion(this.currentClusterVersion, MIN_NEW_DEPARTMENT_METRIC_VERSION);
    },
    isVersionSupportClusterDepartmentQueuesMetric(): boolean {
      return isNewerVersion(this.currentClusterVersion, MIN_DEPARTMENT_QUEUE_METRIC_VERSION);
    },
    isVersionSupportBackoffLimit(): boolean {
      return isNewerVersion(this.currentClusterVersion, MIN_VERSION_BACKOFF_LIMIT);
    },
    isCLIAvailable(): boolean {
      const version = this.currentCluster.version;
      if (!version) return false;
      return (
        isNewerVersion(version, MIN_CLI_VERSION) ||
        version.includes(TEST_ENV_VERSION) ||
        version.toLowerCase().includes(FEATURE_BRANCH_VERSION)
      );
    },
    canCreateCluster(): boolean {
      const permissionStore = usePermissionStore();
      const authStore = useAuthStore();
      return permissionStore.hasPermission(ResourceType.Cluster, Action.Read) || authStore.isAdmin;
    },
    clusterByUuid:
      (state) =>
      (clusterUuid: string): ICluster | undefined => {
        return state.clusters.find((cluster: ICluster) => clusterUuid === cluster.uuid);
      },
  },
  actions: {
    async loadClusters(filterBy: IFilterBy = {}): Promise<Array<ICluster>> {
      const clusterList = await clusterService.list();
      this.clusters = filterService.filterListByTableFilters(clusterList, filterBy, allClusterColumns);
      return this.clusters;
    },
    async setCurrentCluster(currentCluster: ICluster): Promise<DisplayedCluster> {
      this.currentCluster = currentCluster;
      clusterApiService.setBaseURL(this.currentCluster.domain || "");
      const isClusterShouldUseExternalTokenResponse: IReadinessStatus = await whoamiService.shouldUseExternalToken(
        this.currentCluster.domain as string,
      );
      storageUtil.save<boolean>(USE_EXTERNAL_TOKEN, isClusterShouldUseExternalTokenResponse.connected);
      if (usePermissionStore().hasPermission(ResourceType.DashboardsOverview, Action.Read)) {
        await useGrafanaStore().getGrafanaDashboards(this.currentCluster.uuid);
      }
      clusterUtil.saveLastSelectedClusterUuid(this.currentCluster.uuid);
      return this.currentCluster;
    },
    async create(name: string): Promise<ICluster> {
      const newCluster: ICluster = await clusterService.create(name);
      await useAuthStore().loadUserOrgUnits();
      this.lastCreatedClusterUuid = newCluster?.uuid || null;
      return newCluster;
    },
    async deleteCluster(uuid: string): Promise<void> {
      await clusterService.remove(uuid);
      this.clusters = this.clusters.filter((cluster: ICluster) => cluster.uuid !== uuid);
      await useAuthStore().loadUserOrgUnits();
    },
    async updateCluster(uuid: string, updatedCluster: ICluster): Promise<void> {
      await clusterService.update(uuid, updatedCluster);
    },
    removeLastCreatedCluster(): void {
      this.lastCreatedClusterUuid = null;
    },
    isClusterNameExists(name: string): boolean {
      return !!this.clusters.find((cluster) => cluster.name === name);
    },
    getClusterById(clusterId: string): ICluster | null {
      return this.clusters.find((cluster: ICluster) => cluster.uuid === clusterId) || null;
    },
    async setActiveClusterAfterLogin(): Promise<void> {
      const clusterFromStorage: ICluster | undefined = this.getActiveClusterFromStorage();
      if (clusterFromStorage) {
        await this.setCurrentCluster(clusterFromStorage);
        return;
      }
      if (this.clusterList.length === 1) {
        await this.setCurrentCluster(this.clusterList[0]);
        return;
      }
      if (this.clusterList.length > 1) {
        this.firstConnectedCluster = await this.getFirstActiveCluster();
        if (this.firstConnectedCluster) {
          await this.setCurrentCluster(this.firstConnectedCluster);
          return;
        } else {
          await this.setCurrentCluster(this.clusterList[0]);
          return;
        }
      }
    },
    getActiveClusterFromStorage(): ICluster | undefined {
      const selectedClusterUuid: string = clusterUtil.getLastSelectedClusterUuid();
      if (!selectedClusterUuid) return;

      return this.clusterList.find((cluster: ICluster) => cluster.uuid === selectedClusterUuid);
    },
    async getFirstActiveCluster(): Promise<ICluster | undefined> {
      const authStore = useAuthStore();
      const connectedClusters: ICluster[] = this.clusterList.filter(
        (c) => c.status?.state == ClusterDisplayedStatusStateEnum.Connected,
      );
      let connectedCluster: ICluster | undefined;
      // call /whoami only for connected clusters (cluster connected doesnt mean /whoami returns 200)
      for (const cluster of connectedClusters) {
        const responseStatus = await clusterService.checkClusterConnection(
          cluster.domain,
          authStore.accessToken,
          authStore.externalToken,
        );
        if (responseStatus === 200) {
          connectedCluster = cluster;
          break;
        }
      }
      if (connectedCluster) return connectedCluster;

      const lastSelectedCluster: ICluster | undefined = this.getActiveClusterFromStorage();
      if (lastSelectedCluster) {
        return lastSelectedCluster;
      }
      return this.clusterList[0];
    },
    isClusterVersionSufficient(clusterUuid: string | null, checkForVersion: string): boolean {
      if (!clusterUuid) return false;
      const clusterVersion = this.clusterList.find((c) => c.uuid === clusterUuid)?.version;
      if (!clusterVersion) return false;

      return isNewerVersion(clusterVersion, checkForVersion);
    },
    isAtleastOneClusterSufficientForVersion(checkForVersion: string): boolean {
      return this.clusterList.some((c) => isNewerVersion(c.version, checkForVersion));
    },
    isAllClustersSupportSpecificVersion(version: string): boolean {
      if (!version) return false;
      return this.clusterList.every((c: ICluster) => this.isClusterVersionSufficient(c.uuid, version));
    },
    /**
     * Retrieves the ID of the selected cluster based on the specified table filter.
     *
     * @param {ETableFilters} tableFilterName - The name of the table filter to apply.
     * @param isAdvancedFilter  isAdvancedFilter - A flag indicating whether the filter is an advanced filter and the cluster id should extract from the clusterId field.
     * @returns {string} The ID of the filtered cluster if found; otherwise, the current cluster ID.
     */
    getClusterIdFromFilters(tableFilterName: ETableFilters, isAdvancedFilter = false): string {
      const filterBy: IFilterBy = filterService.loadFilters(window.location, tableFilterName, {});
      const defaultClusterUuid = this.currentClusterId ? this.currentClusterId : this.firstConnectedCluster?.uuid;

      let filteredClusterUuid: string | undefined;
      if (isAdvancedFilter) {
        filteredClusterUuid = filterBy.clusterId;
      } else {
        filteredClusterUuid = filterBy.columnFilters?.find(
          (filter: IFilterModel) => filter.name === CLUSTER_COLUMN_FILTER_NAME,
        )?.term;
      }

      if (filteredClusterUuid && this.clusterList.some((cluster: ICluster) => cluster.uuid === filteredClusterUuid)) {
        return filteredClusterUuid;
      }

      if (defaultClusterUuid) {
        return defaultClusterUuid;
      }

      throw new Error("No cluster found for the specified filter criteria.");
    },
  },
});
