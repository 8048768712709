<template>
  <chart-widget-wrapper
    :options="wrapperOptions"
    @link-clicked="redirectToWorkloads"
    @export-csv="exportCsv"
    :loading="displayLoading"
    :error="error"
    :empty="isChartEmpty"
  >
    <highcharts v-if="chartOptions" :options="chartOptions" ref="chart" key="workload-status-chart" />
  </chart-widget-wrapper>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
//routes
import { WORKLOAD_ROUTE_NAMES } from "@/router/workloads.routes/workloads.routes.names";
//store
import { useClusterStore } from "@/stores/cluster.store";
import { useSettingStore } from "@/stores/setting.store";
//models
import { EWidgetEntity, type IWidgetWrapperOptions, UNKNOWN_COLOR } from "@/models/chart.model";
//cmps
import { Chart } from "highcharts-vue";
import { ChartWidgetWrapper } from "@/components/dashboard-v2/widgets/common/widget-wrapper/chart-widget-wrapper";
//Highcharts
import type {
  BreadcrumbOptions,
  BreadcrumbsFormatterCallbackFunction,
  Options as HighchartsOptions,
  SeriesOptionsType,
} from "highcharts";
import Highcharts from "highcharts";
import dataModule from "highcharts/modules/data";
import drilldown from "highcharts/modules/drilldown";
//service
import { workloadService } from "@/services/cluster/workload.service/workload.service";
import { filterService } from "@/services/filter.service/filter.service";
import {
  Phase,
  type TelemetryResponseValuesInner,
  type TelemetryResponseValuesInnerGroupsInner,
  WorkloadSortFilterFields,
  WorkloadTelemetryType,
} from "@/swagger-models/workloads-service-client";
//model
import {
  type DrilldownEventWorkloadTelemetryObject,
  type DrillupEventWorkloadTelemetryObject,
  EWorkloadTelemetryGroupBy,
} from "@/models/workload.model";
import { workloadIndexColumns } from "@/table-models/workload.table-model";
import { HttpErrorResponse } from "@/models/http-response.model";
import {
  EColumnFilterType,
  EFilterOperator,
  type IFilterBy,
  type IFilterModel,
  type IFreeTextFilterModel,
} from "@/models/filter.model";
import { ETableFilters } from "@/models/table.model";
//util
import { widgetUtil } from "@/utils/widget.util";
import { chartUtil } from "@/utils/chart.util";
import { SettingKeys } from "@/models/setting.model";
import { dashboardUtil } from "@/utils/dashboard.util";

drilldown(Highcharts);
dataModule(Highcharts);

const DISPLAYED_PHASES: string[] = [Phase.Failed, Phase.Pending, Phase.Running];
const colorsMap = chartUtil.assignColorsToKeys<string>(DISPLAYED_PHASES);

export default defineComponent({
  name: "workloads-status-widget",
  components: { ChartWidgetWrapper, Highcharts: Chart },
  props: {
    filterBy: {
      type: Array as PropType<IFilterModel[]>,
      required: false,
    },
  },
  data() {
    return {
      clusterStore: useClusterStore(),
      isDataLoading: false as boolean,
      displayLoading: true as boolean,
      chartOptions: null as null | HighchartsOptions,
      error: false as boolean,
      isChartEmpty: false as boolean,
      wrapperOptions: {
        title: "Workloads by status",
        timeFrame: "Now",
        linkText: "All workloads",
        tooltipText:
          "Workload statuses represent the <br/> workload's life cycle and indicate the <br/>  health of the department or project",
        entityType: EWidgetEntity.Workload,
      } as IWidgetWrapperOptions,
      departmentId: undefined as string | undefined,
      groupBy: undefined as EWorkloadTelemetryGroupBy | undefined,
    };
  },
  created() {
    this.loadChartOptions();
    this.loadInitialChartData();
  },
  mounted() {
    if (this.chartOptions !== null) {
      //@ts-ignore
      this.chartOptions.chart.events.drilldown = this.drilldown.bind(this);
      //@ts-ignore
      this.chartOptions.chart.events.drillup = this.drillup.bind(this);
    }
  },
  computed: {
    isDepartmentEnabled(): boolean {
      return useSettingStore().isFeatureEnabled(SettingKeys.DepartmentsUse);
    },
    clusterName(): string {
      return this.clusterStore.currentClusterName;
    },
    clusterId(): string {
      return this.clusterStore.currentClusterId;
    },
    chart(): Highcharts.Chart {
      const chartRef = this.$refs?.chart as Chart;
      return chartRef?.chart as Highcharts.Chart;
    },
    nodePoolFilter(): string | undefined {
      const nodePoolFilter = this.filterBy?.find((filter: IFilterModel) => filter.field === "nodepool");
      if (nodePoolFilter) {
        return nodePoolFilter.term;
      }
      return undefined;
    },
  },
  methods: {
    loadChartOptions(): void {
      this.chartOptions = chartUtil.getBasicWidgetChartOptions({
        yAxisTitle: "Workloads",
        type: "column",
        height: 240,
        sharedTooltip: true,
        drilldownFormatter: this.formatBreadcrumbs as unknown as BreadcrumbsFormatterCallbackFunction,
      });
    },
    async loadInitialChartData(): Promise<void> {
      if (this.chartOptions === null) return;
      try {
        const res: TelemetryResponseValuesInner[] = await this.getWorkloadsTelemetry(
          EWorkloadTelemetryGroupBy.ClusterId,
        );
        const enrichedRes: TelemetryResponseValuesInner[] = widgetUtil.enrichTelemetryResponseByKeyValue(
          res,
          EWorkloadTelemetryGroupBy.ClusterId,
          this.clusterName,
          "name",
        );
        if (enrichedRes.length === 0) {
          this.isChartEmpty = true;
        } else {
          this.isChartEmpty = false;
          this.chartOptions.series = this.aggregateTelemetryGroupedDataForHighcharts(
            enrichedRes,
            EWorkloadTelemetryGroupBy.ClusterId,
            true,
          );
        }
      } catch (e: unknown) {
        this.handleError(e);
      } finally {
        this.displayLoading = false;
      }
    },
    async exportCsv(): Promise<void> {
      try {
        await workloadService.getWorkloadsTelemetryCsv(
          WorkloadTelemetryType.WorkloadsCount,
          this.clusterId,
          this.nodePoolFilter,
          this.departmentId,
          this.groupBy ? [this.groupBy, EWorkloadTelemetryGroupBy.Phase] : [EWorkloadTelemetryGroupBy.Phase],
        );
      } catch (e: unknown) {
        this.$q.notify(dashboardUtil.getCsvErrorMessage());
        this.handleError(e);
      }
    },
    async drillup(e: DrillupEventWorkloadTelemetryObject): Promise<void> {
      if (this.isDataLoading || !this.chartOptions) return;
      const { groupByKey } = e.seriesOptions.data[0].meta;
      if (groupByKey === EWorkloadTelemetryGroupBy.ClusterId) {
        await this.loadInitialChartData();
        return;
      }
      try {
        this.isDataLoading = true;
        const res = await this.getWorkloadsTelemetry(groupByKey);
        this.chartOptions.series = this.aggregateTelemetryGroupedDataForHighcharts(res, groupByKey, true);

        this.error = false;
      } catch (error: unknown) {
        this.handleError(error);
      } finally {
        this.isDataLoading = false;
      }
    },
    async drilldown(e: DrilldownEventWorkloadTelemetryObject): Promise<void> {
      if (this.isDataLoading || !(!e.seriesOptions && this.chartOptions !== null)) return;

      const { departmentIdFilter, includeDrilldown, groupByKey } = widgetUtil.getWorkloadTelemetryDrilldownOptions(
        e.point.meta,
        this.isDepartmentEnabled,
      );

      try {
        this.isDataLoading = true;
        const res = await this.getWorkloadsTelemetry(groupByKey, departmentIdFilter);
        const series = this.aggregateTelemetryGroupedDataForHighcharts(res, groupByKey, includeDrilldown);

        // @ts-ignore
        series.forEach((type: SeriesOptionsType) => this.chart.addSingleSeriesAsDrilldown(e.point, type));
        // @ts-ignore
        this.chart.applyDrilldown();
        this.error = false;
      } catch (error: unknown) {
        this.handleError(error);
      } finally {
        this.isDataLoading = false;
      }
    },
    formatBreadcrumbs(breadcrumbOptions: BreadcrumbOptions): string {
      if (breadcrumbOptions.level === 0) return this.clusterName;
      if (breadcrumbOptions.level === 1 && this.isDepartmentEnabled) {
        return "Departments";
      }
      return "Projects";
    },
    async getWorkloadsTelemetry(
      groupByKey: EWorkloadTelemetryGroupBy,
      departmentId?: string,
    ): Promise<TelemetryResponseValuesInner[]> {
      try {
        this.error = false;
        this.isDataLoading = true;
        const res = await workloadService.getWorkloadsTelemetry(
          WorkloadTelemetryType.WorkloadsCount,
          this.clusterId,
          this.nodePoolFilter,
          departmentId,
          [groupByKey, EWorkloadTelemetryGroupBy.Phase],
        );
        let telemetryValues: TelemetryResponseValuesInner[] = res.values;
        this.departmentId = departmentId;
        this.groupBy = groupByKey;
        if (groupByKey === EWorkloadTelemetryGroupBy.ProjectId) {
          telemetryValues = widgetUtil.limitProjectsTelemetryResponse(res.values);
        }
        return this.filterDisplayedPhases(telemetryValues);
      } catch (error: unknown) {
        this.handleError(error);
      } finally {
        this.isDataLoading = false;
      }
      return [];
    },
    filterDisplayedPhases(values: TelemetryResponseValuesInner[]): TelemetryResponseValuesInner[] {
      return values.filter((telemetryResponse: TelemetryResponseValuesInner) => {
        const phaseGroup = telemetryResponse?.groups?.find(
          (group: TelemetryResponseValuesInnerGroupsInner) => group.key === EWorkloadTelemetryGroupBy.Phase,
        );
        const phaseValue: string | undefined = phaseGroup?.value;

        return phaseValue && DISPLAYED_PHASES.includes(phaseValue);
      });
    },
    aggregateTelemetryGroupedDataForHighcharts(
      values: TelemetryResponseValuesInner[],
      groupByKey: EWorkloadTelemetryGroupBy,
      includeDrilldown = false,
    ): SeriesOptionsType[] {
      const series: SeriesOptionsType[] = widgetUtil.aggregateTelemetryGroupedDataForHighcharts(
        values,
        [groupByKey, EWorkloadTelemetryGroupBy.Phase],
        includeDrilldown,
      );
      return this.addColorsByPhase(series);
    },
    addColorsByPhase(series: SeriesOptionsType[]): SeriesOptionsType[] {
      return series.map((series: SeriesOptionsType) => {
        const phase: string | undefined = series.name;
        if (!phase) return series;
        if (colorsMap.has(phase)) {
          series.color = colorsMap.get(phase);
        } else {
          series.color = UNKNOWN_COLOR;
        }
        return series;
      });
    },
    handleError(error: unknown): void {
      this.error = true;
      if (error instanceof HttpErrorResponse) {
        console.error(error.message);
      } else {
        console.error("Error fetching data", error);
      }
    },
    redirectToWorkloads(): void {
      this.setColumnFilter();
      this.$router.push({
        name: WORKLOAD_ROUTE_NAMES.WORKLOAD_INDEX,
      });
    },
    getWorkloadsFilterBy(): IFilterBy {
      const defaultFilters: IFilterBy = filterService.getDefaultFilters(
        WorkloadSortFilterFields.Phase,
        workloadIndexColumns,
      );
      return filterService.loadFilters(window.location, ETableFilters.WORKLOAD, defaultFilters);
    },
    setColumnFilter(): void {
      const workloadsFilterBy: IFilterBy = this.getWorkloadsFilterBy();
      if (this.nodePoolFilter) {
        this.setNodePoolColumnFilter(workloadsFilterBy);
      } else {
        this.removeNodePoolFilter(workloadsFilterBy);
      }
    },
    setNodePoolColumnFilter(workloadsFilterBy: IFilterBy): void {
      if (this.nodePoolFilter) {
        const nodePoolColumnFilter: IFreeTextFilterModel = {
          name: WorkloadSortFilterFields.Nodepool,
          term: this.nodePoolFilter,
          value: EFilterOperator.Equals,
          label: "Node pool",
          type: EColumnFilterType.FreeText,
        };
        filterService.setColumnAdvancedFilter(workloadsFilterBy, nodePoolColumnFilter, ETableFilters.WORKLOAD);
      }
    },
    removeNodePoolFilter(workloadsFilterBy: IFilterBy): void {
      filterService.removeAdvancedFilter(workloadsFilterBy, WorkloadSortFilterFields.Nodepool, ETableFilters.WORKLOAD);
    },
  },
  watch: {
    filterBy: {
      handler(): void {
        this.displayLoading = true;
        this.loadInitialChartData();
      },
      deep: true,
    },
  },
});
</script>

<style scoped lang="scss"></style>
