<template>
  <q-menu class="policy-dropdown" anchor="bottom middle" self="top middle">
    <q-list class="column">
      <q-item
        clickable
        v-close-popup
        v-for="opt in optionsForDisplay"
        :key="opt.value"
        @click="$emit('policy-selected', opt.value)"
        :aid="opt.value + '-btn'"
        :disable="opt.disable"
        class="row justify-between items-center"
      >
        <span class="q-mr-sm"
          >{{ opt.label }}
          <q-tooltip v-if="opt.disable">There is no policy applied for the selected {{ entityName }}</q-tooltip></span
        >
        <div>
          <runai-tooltip :tooltip-text="opt.toolTip" tooltip-position="right" />
        </div>
      </q-item>
    </q-list>
  </q-menu>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { RunaiTooltip } from "@/components/common/runai-tooltip";
import { EWorkloadType } from "@/models/workload.model";
import type { PropType } from "vue";
import type { ISelectOption } from "@/models/global.model";
import { PolicyType } from "@/swagger-models/policy-service-client";
interface IPolicySelectOptions extends Omit<ISelectOption, "value" | "disable" | "toolTip"> {
  label: EWorkloadType;
  value: PolicyType;
  toolTip: string;
  disable?: boolean;
}

export default defineComponent({
  name: "policy-dropdown",
  components: {
    RunaiTooltip,
  },
  emits: ["policy-selected"],
  props: {
    existingPolicies: {
      type: Array as PropType<Array<PolicyType>>,
      default: () => [PolicyType.Workspace, PolicyType.Training],
    },
    entityName: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      options: [
        {
          label: EWorkloadType.Workspace,
          value: PolicyType.Workspace,
          toolTip:
            "This policy defines workspace submission. Its rules derive from both the project-level policy and higher-level policies.",
        },
        {
          label: EWorkloadType.Training,
          value: PolicyType.Training,
          toolTip:
            "This policy defines training submission. Its rules derive from both the project-level policy and higher-level policies.",
        },
        // hiding it for now until product decides what to do with it
        // {
        //   label: EWorkloadType.Distributed,
        //   value: PolicyType.Distributed,
        //   toolTip:
        //     "This policy defines distributed submission. Its rules derive from both the project-level policy and higher-level policies.",
        // },
      ] as IPolicySelectOptions[],
    };
  },
  computed: {
    optionsForDisplay() {
      return this.options.map((opt: IPolicySelectOptions) => {
        opt.disable = !this.existingPolicies.includes(opt.value as PolicyType);
        return opt;
      });
    },
  },
});
</script>
