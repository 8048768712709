<template>
  <runai-expansion-item label="Department name" default-opened :section-invalid="sectionInvalid">
    <template #subheader>
      <span>
        {{ departmentName || "&lt;Department name&gt;" }}
      </span>
    </template>
    <runai-name-validation
      :disable="readOnly"
      :model-value="departmentName"
      @update:model-value="$emit('update:departmentName', $event)"
      :rules="[isValidEntityName, isNameUnique]"
    />
  </runai-expansion-item>
</template>
<script lang="ts">
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import { RunaiNameValidation } from "@/components/common/runai-name-validation";
import { defineComponent, type PropType } from "vue";
import { isValidEntityName } from "@/common/form.validators";
import { errorMessages } from "@/common/error-message.constant";
import { departmentService } from "@/services/control-plane/department.service/department.service";
import { useClusterStore } from "@/stores/cluster.store";

export default defineComponent({
  name: "department-name-section",
  components: {
    RunaiNameValidation,
    RunaiExpansionItem,
  },
  emits: ["update:departmentName", "is-section-invalid"],
  props: {
    departmentName: {
      type: String as PropType<string>,
      required: true,
    },
    departmentId: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },
    readOnly: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    clusterId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      clusterStore: useClusterStore(),
    };
  },
  computed: {
    sectionInvalid(): boolean {
      return !isValidEntityName(this.departmentName);
    },
  },
  methods: {
    isValidEntityName(name: string): boolean | string {
      return isValidEntityName(name) || errorMessages.VALID_FORMAT;
    },
    async isNameUnique(name: string): Promise<boolean | string> {
      const res = await departmentService.isNameUnique(this.clusterId, name, this.departmentId);
      return res || errorMessages.UNIQUE_NAME;
    },
  },
  watch: {
    sectionInvalid: {
      handler(isSectionInvalid: boolean): void {
        this.$emit("is-section-invalid", isSectionInvalid);
      },
      immediate: true,
    },
  },
});
</script>

<style scoped lang="scss"></style>
