<template>
  <q-linear-progress animation-speed="100" indeterminate v-if="loading" />
  <div class="runai-org-tree">
    <q-tree
      ref="tree"
      class="col-12 col-sm-6"
      :nodes="nodes"
      node-key="path"
      :tick-strategy="tickStrategy"
      :ticked="ticked"
      @update:ticked="updateTicked"
      :filter="searchTerm"
      :no-results-label="noResultsLabel"
      :duration="100"
    >
      <template v-slot:default-header="prop">
        <div class="row items-center" :aid="prop.node.path">
          <q-icon class="tree-icon" :name="prop.node.icon" size="16px" /> {{ prop.node.label }}
          <runai-tooltip
            v-if="prop.node.tooltip"
            class="tooltip-icon q-ml-xs"
            :tooltip-text="prop.node.tooltip"
            width="450px"
            size="xs"
            tooltip-position="right"
            icon="far fa-circle-info"
            :ripple="false"
          />
        </div>
      </template>
    </q-tree>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
// stores
import { useClusterStore } from "@/stores/cluster.store";
import { useAuthStore } from "@/stores/auth.store";

// services
import { orgTreeService } from "@/services/control-plane/rbac/org-tree.service/org-tree.service";

// models
import type { IOrgTreeNode, IOrgTreeNodeId } from "@/models/org-tree.model";
import type { QTree } from "quasar";
import { ScopeType, type PermittedScopes } from "@/swagger-models/authorization-client";
import { RunaiTooltip } from "@/components/common/runai-tooltip";

const enum EDisabledScopesTooltips {
  DEFAULT = "This scope can't be selected. Try selecting its subordinates.",
  CLUSTER_VERSION = "This scope can't be selected for the current version of the cluster. Try selecting its subordinates.",
  BRANCH_CLUSTER_VERSION = "This scope and its subordinates can't be selected for the current version of the cluster.",
  PERMISSION = "You don't have permissions to select this scope. Try selecting its subordinates.",
  CLUSTER_VERSION_NO_SUBORDINATES = "This scope can't be selected for the current version of the cluster.",
}

export default defineComponent({
  components: { RunaiTooltip },
  emits: ["update:selected"],
  props: {
    selected: {
      type: Object as PropType<IOrgTreeNodeId>,
      default: {} as IOrgTreeNodeId,
    },
    readonly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    searchTerm: {
      type: String as PropType<string>,
      default: "",
    },
    noResultsLabel: {
      type: String as PropType<string>,
      default: "No cluster, department, or project match your filters",
    },
    hideOtherClusters: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    allowedScopes: {
      type: Object as PropType<PermittedScopes>,
    },
    scopesSupportedVersions: {
      type: Object as PropType<Record<ScopeType, string>>,
      default: () => ({}),
    },
    disableTenant: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    disableCluster: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    forcedDisabledScopes: {
      type: Object as PropType<Record<ScopeType, boolean>>,
      default: () => ({}),
    },
    hiddenScopeTypes: {
      type: [Object, null] as PropType<Record<ScopeType, boolean> | null>,
      default: null,
    },
    tickStrategy: {
      type: String as PropType<"leaf" | "leaf-filtered" | "none" | "strict" | undefined>,
      default: "strict",
    },
  },
  data() {
    return {
      selectedNode: {} as IOrgTreeNodeId,
      ticked: [] as string[],
      lastTicked: [] as string[],
      nodes: [] as IOrgTreeNode[],
      loading: false as boolean,
      clusterStore: useClusterStore(),
      authStore: useAuthStore(),
      expandedAllowedScopes: {} as PermittedScopes,
    };
  },
  async created() {
    this.initTreeNodes();
    if (!this.allowedScopes) return;
    this.expandedAllowedScopes = orgTreeService.getExpandedPermittedScopes(
      this.allowedScopes,
      this.authStore.orgUnitList,
    );
  },
  mounted() {
    this.markSelectedNode();
    this.disableNodes(this.nodes);
  },
  methods: {
    async initTreeNodes(): Promise<void> {
      const orgTree: IOrgTreeNode = orgTreeService.getOrgTree(this.authStore.orgUnitList);
      if (this.hideOtherClusters) {
        orgTree.children = this.removeOtherClusters(orgTree);
      }
      if (this.hiddenScopeTypes !== null) {
        this.nodes = orgTreeService.filterHiddenScopes([orgTree], this.hiddenScopeTypes);
      } else {
        this.nodes = [orgTree];
      }
    },
    removeOtherClusters(orgTree: IOrgTreeNode) {
      return orgTree.children.filter((node: IOrgTreeNode) => {
        return node.id === this.clusterStore.currentCluster.uuid;
      });
    },
    markSelectedNode(): void {
      if (!this.selected) return;
      const path: string | null = orgTreeService.getNodePathById(this.nodes[0], this.selected);
      if (!path) return;
      this.updateTicked([path]);
      if (this.readonly) {
        const rootNode: IOrgTreeNode = !!this.$refs.tree && (this.$refs.tree as QTree).getNodeByKey(this.nodes[0].path);
        this.changeTickable(rootNode, false);
        rootNode.tickable = false;
      }
      this.setPathExpanded(path);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateTicked(ticked: readonly any[]): void {
      const tree: QTree = this.$refs.tree as QTree;
      if (this.ticked[0] && this.ticked[0] !== ticked[0]) {
        // as part of support single selection, so check if the last ticked node is the same as the current one
        this.unTickAndEnableChildren(tree.getNodeByKey(this.ticked[0]));
        this.ticked = [];
      } else {
        if (this.ticked[0]) {
          // as part of support single unselect the current node, since it is the same node
          this.unTickAndEnableChildren(tree.getNodeByKey(this.ticked[0]));
        }
        this.ticked = ticked[ticked.length - 1] ? [ticked[ticked.length - 1]] : [];
        const lastTicked: string = ticked[ticked.length - 1];
        const lastTickedNode: IOrgTreeNode = !!this.$refs.tree && tree.getNodeByKey(lastTicked);
        if (lastTickedNode?.children) this.disableNodeChildren(lastTickedNode, true);
      }
      const selectedOrgTreeNode: IOrgTreeNode = !!this.$refs.tree && tree.getNodeByKey(this.ticked[0]);
      if (selectedOrgTreeNode) {
        this.selectedNode = {
          id: selectedOrgTreeNode.id,
          type: selectedOrgTreeNode.type,
          path: selectedOrgTreeNode.path,
        };
      } else {
        this.selectedNode = {} as IOrgTreeNodeId;
      }
      this.$emit("update:selected", this.selectedNode);
    },
    disableNodeChildren(node: IOrgTreeNode, shouldTick = false): void {
      node.children?.forEach((child: IOrgTreeNode) => {
        shouldTick && this.ticked.push(child.path);
        child.tickable = false;
        this.disableNodeChildren(child, shouldTick);
      });
    },
    changeTickable(node: IOrgTreeNode, tickable: boolean): void {
      node.children?.forEach((child: IOrgTreeNode) => {
        child.tickable = tickable;
        this.changeTickable(child, tickable);
      });
    },
    unTickAndEnableChildren(node: IOrgTreeNode): void {
      node.children?.forEach((child: IOrgTreeNode) => {
        this.ticked = this.ticked.filter((id) => id !== child.path);
        child.tickable = true;
        this.unTickAndEnableChildren(child);
      });
      this.disableNodes(this.nodes);
    },
    setPathExpanded(path: string): void {
      const subPaths = path.split("/");
      let currentSubPath = "";
      for (const subPath of subPaths) {
        if (subPath) {
          currentSubPath += currentSubPath !== "" ? `/${subPath}` : subPath;
          !!this.$refs.tree && (this.$refs.tree as QTree).setExpanded(currentSubPath, true);
        }
      }
    },
    disableNodes(nodes: Array<IOrgTreeNode>): void {
      if (!nodes || !nodes.length || !this.allowedScopes) return;
      nodes.forEach((node: IOrgTreeNode) => {
        const parentClusterUuid = orgTreeService.getParentClusterUuid(this.authStore.orgUnitList, node.id, node.type);

        // Disabling scopes due to special reasons (not related to version or permission)
        if (this.forcedDisabledScopes[node.type]) {
          this.disableNodeWithTooltip(node, EDisabledScopesTooltips.DEFAULT);
          if (node.children?.length) this.disableNodes(node.children);
          return;
        }

        // Disabling due permissions
        if (!this.isPermittedNode(node)) {
          this.disableNodeWithTooltip(node, EDisabledScopesTooltips.PERMISSION);
          if (node.children?.length) this.disableNodes(node.children);
          return;
        }

        // Disabling all non-allowed node scopes by prop or version
        if (node.type === ScopeType.Tenant) {
          if (this.disableTenant) {
            this.disableNodeWithTooltip(node, EDisabledScopesTooltips.DEFAULT);
          } else if (
            this.scopesSupportedVersions.tenant &&
            !this.clusterStore.isAtleastOneClusterSufficientForVersion(this.scopesSupportedVersions.tenant)
          ) {
            this.disableNodeWithTooltip(node, EDisabledScopesTooltips.CLUSTER_VERSION);
          }
        }
        if (node.type === ScopeType.Cluster) {
          if (this.scopesSupportedVersions.cluster || this.disableCluster) {
            const shouldDisableNode =
              (this.scopesSupportedVersions.cluster &&
                !this.clusterStore.isClusterVersionSufficient(
                  parentClusterUuid,
                  this.scopesSupportedVersions.cluster,
                )) ||
              this.disableCluster;
            if (shouldDisableNode) {
              if (this.versionSupportsChildNodes(parentClusterUuid, node)) {
                this.disableNodeWithTooltip(node, EDisabledScopesTooltips.CLUSTER_VERSION);
              } else {
                // Disabling all the branch below
                this.disableNodeWithTooltip(node, EDisabledScopesTooltips.BRANCH_CLUSTER_VERSION);
                this.disableNodeChildren(node);
                return;
              }
            }
          }
        }
        if (node.type === ScopeType.Department && this.scopesSupportedVersions.department) {
          const shouldDisableNode = !this.clusterStore.isClusterVersionSufficient(
            parentClusterUuid,
            this.scopesSupportedVersions.department,
          );
          if (shouldDisableNode) {
            if (this.versionSupportsChildNodes(parentClusterUuid, node)) {
              this.disableNodeWithTooltip(node, EDisabledScopesTooltips.CLUSTER_VERSION);
            } else {
              // Disabling all the branch below
              this.disableNodeWithTooltip(node, EDisabledScopesTooltips.BRANCH_CLUSTER_VERSION);
              this.disableNodeChildren(node);
              return;
            }
          }
        }
        if (node.type === ScopeType.Project && this.scopesSupportedVersions.project) {
          const shouldDisableNode = !this.clusterStore.isClusterVersionSufficient(
            parentClusterUuid,
            this.scopesSupportedVersions.project,
          );
          shouldDisableNode &&
            this.disableNodeWithTooltip(node, EDisabledScopesTooltips.CLUSTER_VERSION_NO_SUBORDINATES);
        }

        if (node.children?.length) this.disableNodes(node.children);
      });
    },
    disableNodeWithTooltip(node: IOrgTreeNode, reason: EDisabledScopesTooltips) {
      node.tickable = false;
      node.tooltip = reason;
    },
    versionSupportsChildNodes(parentClusterId: string | null, node: IOrgTreeNode): boolean {
      if (!this.scopesSupportedVersions.department && !this.scopesSupportedVersions.project) return true;
      const supportDepartmentScope: boolean =
        !this.scopesSupportedVersions.department ||
        this.clusterStore.isClusterVersionSufficient(parentClusterId, this.scopesSupportedVersions.department);
      const supportProjectScope: boolean =
        !this.scopesSupportedVersions.project ||
        this.clusterStore.isClusterVersionSufficient(parentClusterId, this.scopesSupportedVersions.project);

      if (node.type === ScopeType.Cluster) return supportDepartmentScope || supportProjectScope;
      if (node.type === ScopeType.Department) return supportProjectScope;
      return true;
    },
    isPermittedNode(node: IOrgTreeNode): boolean {
      switch (node.type) {
        case ScopeType.Tenant:
          return this.expandedAllowedScopes?.tenant?.includes(node.id) || false;
        case ScopeType.Cluster:
          return this.expandedAllowedScopes?.clusters?.includes(node.id) || false;
        case ScopeType.Department:
          return this.expandedAllowedScopes?.departments?.includes(node.id) || false;
        case ScopeType.Project:
          return this.expandedAllowedScopes?.projects?.includes(node.id) || false;
      }
    },
  },
});
</script>
<style lang="scss">
.runai-org-tree {
  .fal {
    font-weight: 900;
    font-size: 11px !important;
  }
  .q-tree__icon {
    color: $tree-icon;
    font-size: 16px !important;
    margin-left: 2px;
    margin-right: 6px;
  }
  .q-tree__arrow {
    padding-right: 2px !important;
  }
  .q-tree {
    padding: 10px;
    text-align: center;
    align-items: center;
    display: grid;
  }
  .tree-icon {
    color: $tree-icon;
    margin-left: 2px;
    margin-right: 6px;
  }
  .tooltip-icon .q-icon {
    font-size: 16px;
    color: $info;
  }
}
</style>
<style lang="scss" scoped></style>
