<template>
  <chart-widget-wrapper
    :options="wrapperOptions"
    body-no-padding
    :loading="loading"
    :error="error"
    @export-csv="exportCsv"
  >
    <highcharts
      class="resource-allocation-time-range-highchart"
      v-if="chartOptions"
      :options="chartOptions"
      ref="chart"
      key=""
    />
  </chart-widget-wrapper>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

//models
import type { IWidgetWrapperOptions, ITooltipPoint, TMeasurementsTimeAndValue } from "@/models/chart.model";
import {
  MetricsType,
  type MetricsResponse,
  type MeasurementResponseValuesInner,
} from "@/swagger-models/cluster-service-client";

//cmps
import { Chart } from "highcharts-vue";
import { ChartWidgetWrapper } from "@/components/dashboard-v2/widgets/common/widget-wrapper/chart-widget-wrapper";

//Highcharts
import type { Options as HighchartsOptions, XAxisOptions } from "highcharts";
import dataModule from "highcharts/modules/data";
import Highcharts from "highcharts";

// services
import { clusterService } from "@/services/control-plane/cluster.service/cluster.service";

//utils
import { chartUtil } from "@/utils/chart.util";
import { metricUtil } from "@/utils/metric.util";
import { dateUtil, IRangeDates } from "@/utils/date.util";
import { roundToDecimal } from "@/utils/format.util";

// stores
import { useSettingStore } from "@/stores/setting.store";

// models
import { SettingKeys } from "@/models/setting.model";
import { useClusterStore } from "@/stores/cluster.store";
import type { IFilterModel } from "@/models/filter.model";
import { dashboardUtil } from "@/utils/dashboard.util";

export default defineComponent({
  name: "resource-allocation-time-range-widget",
  components: { ChartWidgetWrapper, Highcharts: Chart },
  props: {
    clusterId: {
      type: String as PropType<string>,
      required: true,
    },
    filterByDates: {
      type: Object as PropType<IRangeDates>,
      required: true,
    },
    filterBy: {
      type: Array as PropType<IFilterModel[]>,
      required: false,
    },
  },
  data() {
    return {
      settingStore: useSettingStore(),
      clusterStore: useClusterStore(),
      wrapperOptions: {
        title: "Resources allocation",
        timeFrame: "",
        tooltipText:
          "Quota/Total shows whether the quota is suitable for the physical resources available. <br/>Allocated/Total shows how many resources are being used by the departments or projects.<br/>Click and drag to view a shorter timeframe.",
      } as IWidgetWrapperOptions,
      chartOptions: null as null | HighchartsOptions,
      measurementMap: {} as Record<string, Array<MeasurementResponseValuesInner>>,
      error: false as boolean,
      loading: false as boolean,
      gpuQuotaTitle: "" as string,
      timeframes: [] as Array<string>,
    };
  },
  async created() {
    dataModule(Highcharts);
    this.loadChartOptions();
    this.loadChartData();
    if (
      this.settingStore.isFeatureEnabled(SettingKeys.DepartmentsUse) &&
      this.clusterStore.isVersionSupportClusterDepartmentQueuesMetric
    ) {
      this.gpuQuotaTitle = `GPU quota (All departments)`;
    } else {
      this.gpuQuotaTitle = `GPU quota (All projects)`;
    }
  },
  computed: {
    nodePoolFilter(): string | undefined {
      const nodePoolFilter = this.filterBy?.find((filter: IFilterModel) => filter.field === "nodepool");
      if (nodePoolFilter) {
        return nodePoolFilter.term;
      }
      return undefined;
    },
  },
  methods: {
    loadChartOptions(): void {
      this.chartOptions = chartUtil.getBasicWidgetChartOptions({
        yAxisTitle: "GPU devices",
        type: "area",
        height: 250,
        showSharedCrosshair: true,
        sharedTooltip: true,
      });

      this.chartOptions.chart = {
        ...this.chartOptions.chart,
        zoomType: "x",
      };

      this.chartOptions.xAxis = chartUtil.getDatetimeOptions(this.chartOptions.xAxis as XAxisOptions);

      this.chartOptions.tooltip = {
        ...this.chartOptions.tooltip,
        useHTML: true,
        formatter: function () {
          if (!this.points) return "";
          const points: Array<ITooltipPoint> = this.points.map((point: Highcharts.TooltipFormatterContextObject) => {
            return {
              name: point.series.name,
              y: point.y ? roundToDecimal(point.y) : point.y,
              color: point.color?.toString(),
            };
          });
          return chartUtil.formatSharedTooltip(points, this.x);
        },
      };

      this.chartOptions.plotOptions = {
        ...this.chartOptions.plotOptions,
        area: {
          ...this.chartOptions.plotOptions?.area,
          fillOpacity: 0.2,
          stacking: "normal",
          lineWidth: 1,
          marker: {
            lineWidth: 1,
          },
        },
      };
    },
    calculateUnallocatedValues(
      totalGPU: Array<TMeasurementsTimeAndValue>,
      allocatedGPU: Array<TMeasurementsTimeAndValue>,
    ): Array<TMeasurementsTimeAndValue> {
      if (totalGPU.length !== allocatedGPU.length) {
        throw new Error("Arrays must have the same length.");
      }

      const differenceArray: Array<TMeasurementsTimeAndValue> = [];

      for (let i = 0; i < totalGPU.length; i++) {
        const totalValue: number | null = totalGPU[i][1];
        const allocatedValue: number | null = allocatedGPU[i][1];
        if (totalValue === null || allocatedValue === null) {
          differenceArray.push([totalGPU[i][0], null]);
        } else {
          const difference: number = totalValue - allocatedValue;
          differenceArray.push([totalGPU[i][0], difference]);
        }
      }

      return differenceArray;
    },
    async loadChartData(): Promise<void> {
      if (this.chartOptions === null) return;

      this.wrapperOptions.timeFrame = dateUtil.getTimeframePreview(this.filterByDates);

      try {
        this.loading = true;
        this.timeframes = chartUtil.getTimeFrames(this.filterByDates.dateStart, this.filterByDates.dateEnd);
        const metricsResponse: MetricsResponse = await this.getMetric();

        this.measurementMap = metricUtil.mapMeasurementsToMap(metricsResponse);

        this.setSeries();
      } catch (error: unknown) {
        this.error = true;
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async getMetric(): Promise<MetricsResponse> {
      const metricType: Array<MetricsType> = [MetricsType.TotalGpu, MetricsType.AllocatedGpu, MetricsType.GpuQuota];
      const samples = this.timeframes.length;

      if (this.nodePoolFilter) {
        return await clusterService.getNodepoolMetrics(
          this.clusterId,
          this.nodePoolFilter,
          dateUtil.convertDateToISO(this.filterByDates.dateStart),
          dateUtil.convertDateToISO(this.filterByDates.dateEnd),
          metricType,
          samples,
        );
      } else {
        return await clusterService.getClusterMetrics(
          this.clusterId,
          dateUtil.convertDateToISO(this.filterByDates.dateStart),
          dateUtil.convertDateToISO(this.filterByDates.dateEnd),
          metricType,
          samples,
        );
      }
    },
    async exportCsv(): Promise<void> {
      const metricType: Array<MetricsType> = [MetricsType.TotalGpu, MetricsType.AllocatedGpu, MetricsType.GpuQuota];

      try {
        const samples = this.timeframes.length;
        await clusterService.getClusterMetricsCsv(
          this.clusterId,
          dateUtil.convertDateToISO(this.filterByDates.dateStart),
          dateUtil.convertDateToISO(this.filterByDates.dateEnd),
          metricType,
          samples,
        );
      } catch (error: unknown) {
        this.$q.notify(dashboardUtil.getCsvErrorMessage());
        console.error(error);
      }
    },
    setSeries(): void {
      if (this.chartOptions === null) return;

      const totalGpus: Array<TMeasurementsTimeAndValue> = this.getMeasurements(
        this.timeframes,
        this.measurementMap[MetricsType.TotalGpu],
      );

      const allocatedGpus: Array<TMeasurementsTimeAndValue> = this.getMeasurements(
        this.timeframes,
        this.measurementMap[MetricsType.AllocatedGpu],
      );

      const gpuQuota: Array<TMeasurementsTimeAndValue> = this.getMeasurements(
        this.timeframes,
        this.measurementMap[MetricsType.GpuQuota],
      );

      this.chartOptions.series = [
        {
          name: "GPU devices (total)",
          type: "line",
          dashStyle: "ShortDot",
          data: totalGpus,
          marker: {
            enabled: false,
            symbol: "circle",
            fillColor: "white",
          },
        },
        {
          name: "GPU devices (unallocated)",
          type: "area",
          data: this.calculateUnallocatedValues(totalGpus, allocatedGpus),
          marker: {
            enabled: false,
            symbol: "circle",
            fillColor: "white",
          },
        },
        {
          name: "GPU devices (allocated)",
          type: "area",
          data: allocatedGpus,
          marker: {
            enabled: false,
            symbol: "circle",
            fillColor: "white",
          },
        },
        {
          name: this.gpuQuotaTitle,
          type: "line",
          dashStyle: "ShortDot",
          data: gpuQuota,
          marker: {
            enabled: false,
            symbol: "circle",
            fillColor: "white",
          },
        },
      ];
    },
    getMeasurements(
      timeframes: string[],
      measurements: Array<MeasurementResponseValuesInner>,
    ): Array<TMeasurementsTimeAndValue> {
      return chartUtil.arrangeMeasurementsByTime(timeframes, measurements);
    },
  },
  watch: {
    filterByDates(): void {
      this.loadChartData();
    },
    filterBy: {
      async handler(): Promise<void> {
        await this.loadChartData();
      },
      deep: true,
    },
  },
});
</script>

<style scoped lang="scss">
.resource-allocation-time-range-highchart {
  width: 100%;
}
</style>
