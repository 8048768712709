<template>
  <section class="training-assets-edit column items-center q-pt-md">
    <template v-if="isPageReady && selectedProject">
      <workload-distributed-create-form
        v-if="training.distributed"
        :workload-type="formType"
        :workload="training"
        :selected-project="selectedProject"
        submit-btn-text="Create Training"
        :submitting="submitting"
        :workload-workers-config="distributedWorkloadWorkersSectionsConfig"
        :workload-master-config="distributedWorkloadMasterSectionsConfig"
        :display-step="$route.query.workloadFormName?.toString() || undefined"
        @workload-changed="onWorkloadChanged"
        @canceled="onCancel"
        @submit="onSubmit"
        @back-clicked="onBack"
        @init-master-model="initMasterModel"
      />
      <workload-default-create-form
        v-else
        :workload-type="formType"
        :workload="training"
        :selected-project="selectedProject"
        submit-btn-text="Create Training"
        :submitting="submitting"
        :workload-sections-config="trainingWorkloadSectionsConfig"
        @workload-changed="onWorkloadChanged"
        @canceled="onCancel"
        @submit="onSubmit"
        @back-clicked="onBack"
      />
    </template>
  </section>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

// components
import { WorkloadDistributedCreateForm } from "@/components/workload/workload-create/workload-distributed-create-form";
import { WorkloadDefaultCreateForm } from "@/components/workload/workload-create/workload-default-create-form";

// models
import {
  EDistributedReplicaType,
  EWorkloadFormType,
  EWorkloadType,
  type IUIDistributedMaster,
  type IUIWorkloadCreation,
} from "@/models/workload.model";
import {
  type IWorkloadCreateFormConfig,
  EWorkloadSectionNames,
} from "@/components/workload/workload-create/workload-create-form";
import {
  Scope,
  type EnvironmentAsset,
  type WorkloadCreationRequest,
  type ComputeAsset,
  type AssetIdAndKind,
  AssetKind,
  type PVCAsset,
  type DatasourceListResponseEntry,
  type TrainingCreationRequestV2,
  type TrainingV2,
} from "@/swagger-models/assets-service-client";
import {
  PolicyType,
  type DistributedPolicyDefaultsAndRulesV2,
  type DistributedPolicyV2,
  type TrainingPolicyDefaultsAndRulesV2,
  type TrainingPolicyDefaultsAndRulesV2Defaults,
  type TrainingPolicyDefaultsAndRulesV2Rules,
  type TrainingPolicyV2,
} from "@/swagger-models/policy-service-client";
import type { IUIWorkloadEnvSectionModel, IWorkloadEnvSectionOptions } from "@/components/section/environment-section";
import type { IProject, ISelectedNodeAffinity } from "@/models/project.model";
import type { IUIVolume } from "@/models/data-source.model";
import type { IWorkloadComputeSectionOptions } from "@/components/section/compute-resource-section";

// stores
import { useAppStore } from "@/stores/app.store";
import { useTrainingStore } from "@/stores/training.store";
import { useProjectStore } from "@/stores/project.store";
import { useEnvironmentStore } from "@/stores/environment.store";
import { useComputeResourceStore } from "@/stores/compute-resource.store";
import { useClusterStore } from "@/stores/cluster.store";
import { useDataSourceStore } from "@/stores/data-source.store";
import { useWorkloadStore } from "@/stores/workload.store";
import { useAuthStore } from "@/stores/auth.store";

// services
import { trainingService } from "@/services/control-plane/training.service/training.service";
import { dataSourceService } from "@/services/control-plane/data-source.service/data-source.service";
import { requestToLeave } from "@/services/infra/router.service/router.service";

// utils
import { fallbackDefaultIfNullOrUndefined } from "@/utils/common.util";
import { alertUtil } from "@/utils/alert.util";
import { policyUtil } from "@/utils/policy.util";
import { workloadUtil } from "@/utils/workload.util/workload.util";
import { dataSourceUtil } from "@/utils/data-source.util";
import { environmentSectionUtil } from "@/components/section/environment-section";
import { workloadCreateFormUtil } from "@/components/workload/workload-create/workload-create-form/workload-create-form.util";

// routes
import { TRAINING_ROUTE_NAMES } from "@/router/training.routes/training.routes.names";

// common
import type { IAssetsFilter } from "@/models/filter.model";
import { ErrorAlert } from "@/utils/error-alert.util";
import { WORKLOAD_ROUTE_NAMES } from "@/router/workloads.routes/workloads.routes.names";
import type { ITrainingList } from "@/models/training.model";
import { MIN_WORKLOAD_CREATION_V2_VERSION } from "@/common/version.constant";
import { useSettingStore } from "@/stores/setting.store";

interface ITrainingFormAssetsLists {
  environments: EnvironmentAsset[];
  computes: ComputeAsset[];
  datasources: DatasourceListResponseEntry[];
}

export default defineComponent({
  components: {
    WorkloadDistributedCreateForm,
    WorkloadDefaultCreateForm,
  },
  provide() {
    return {
      policy: computed(() => this.trainingPolicy),
    };
  },
  data() {
    return {
      appStore: useAppStore(),
      projectStore: useProjectStore(),
      trainingStore: useTrainingStore(),
      environmentStore: useEnvironmentStore(),
      computeResourceStore: useComputeResourceStore(),
      workloadStore: useWorkloadStore(),
      authStore: useAuthStore(),
      nodeAffinity: [] as Array<ISelectedNodeAffinity>,
      storageClasses: [] as Array<string>,
      dataSourceStore: useDataSourceStore(),
      clusterStore: useClusterStore(),
      settingStore: useSettingStore(),
      formType: EWorkloadFormType.Training,
      training: workloadUtil.getEmptyUIWorkloadCreation() as IUIWorkloadCreation,
      trainingPolicy: null as TrainingPolicyV2 | DistributedPolicyV2 | null,
      submitting: false as boolean,
      distributedWorkloadWorkersSectionsConfig: {} as IWorkloadCreateFormConfig,
      distributedWorkloadMasterSectionsConfig: {} as IWorkloadCreateFormConfig,
      trainingWorkloadSectionsConfig: {} as IWorkloadCreateFormConfig,
      envSectionOptions: {
        canAddEnvVariable: true,
      } as IWorkloadEnvSectionOptions,
      computeSectionOptions: {
        applyPolicyDefaults: false,
      } as IWorkloadComputeSectionOptions,
      assetsFilter: {} as IAssetsFilter,
      workloadFormName: this.$route.query.workloadFormName
        ? this.$route.query.workloadFormName
        : (null as string | null),
      assetsLists: {
        computes: [],
        datasources: [],
        environments: [],
      } as ITrainingFormAssetsLists,
      masterAssetsLists: {
        computes: [],
        datasources: [],
        environments: [],
      } as ITrainingFormAssetsLists,
    };
  },
  async created() {
    const { kind, fromCopyId, createdEntityId } = this.$route.query;
    this.training = this.trainingStore.training;
    try {
      if (fromCopyId) {
        await this.loadFromExistingTraining(String(fromCopyId));
      }

      this.initFilterProperties();

      await this.loadProjects();
      await this.loadPolicy();

      const createdEnvironmentId: string | undefined =
        kind === AssetKind.Environment ? createdEntityId?.toString() : undefined;
      const createdComputeId: string | undefined = kind === AssetKind.Compute ? createdEntityId?.toString() : undefined;

      const isDataSource = [
        AssetKind.HostPath,
        AssetKind.Nfs,
        AssetKind.Git,
        AssetKind.S3,
        AssetKind.Pvc,
        AssetKind.ConfigMap,
        AssetKind.SecretVolume,
      ].some((assetKind) => assetKind === kind);

      const createdDataSourceId: string | undefined = isDataSource ? createdEntityId?.toString() : undefined;

      await Promise.all([
        this.loadEnvironments(),
        this.loadComputeResources(),
        this.loadDataSources(),
        this.loadStorageClasses(),
      ]);

      this.setCreatedAssets(createdEnvironmentId, createdComputeId, createdDataSourceId, kind as AssetKind);

      const isDataSourceSectionOpen = !!createdDataSourceId;

      this.loadNodeAffinity();
      this.applyPolicyDefaultsAndRules(); //TODO: requires checks in relation to distributed (master)
      this.computeSectionOptions.applyPolicyDefaults = !!fromCopyId || !!this.trainingStore.templateId;
      this.setTrainingWorkloadSectionsConfig(isDataSourceSectionOpen, fromCopyId ? String(fromCopyId) : undefined);
      this.setDistributedWorkloadWorkersSectionsConfig(
        isDataSourceSectionOpen,
        fromCopyId ? String(fromCopyId) : undefined,
      );
      this.setDistributedWorkloadMasterSectionsConfig(isDataSourceSectionOpen);
      this.cleanupNoneComplianceAssets();
    } catch (error: unknown) {
      this.$q.notify(alertUtil.getError("Failed to load assets"));
      console.error(error);
      this.appStore.setFallback(true);
    } finally {
      this.appStore.setPageLoading(false);
    }
  },
  computed: {
    isPageReady(): boolean {
      return !this.appStore.isPageLoading;
    },
    projectId(): number {
      return this.training.projectId;
    },
    selectedProject(): IProject | undefined {
      return this.projects.find((project: IProject) => project.id === this.projectId);
    },
    projects(): Array<IProject> {
      return this.projectStore.projects;
    },
    clusterUid(): string {
      return this.clusterStore.currentClusterId;
    },
  },
  methods: {
    setTrainingWorkloadSectionsConfig(isDataSourceSectionOpen: boolean, fromCopyId: string | undefined): void {
      const defaultOptions: IWorkloadCreateFormConfig = workloadCreateFormUtil.getWorkloadSectionDefaultsConfig([
        EWorkloadSectionNames.Project,
        EWorkloadSectionNames.MultiNode,
        EWorkloadSectionNames.WorkloadName,
        EWorkloadSectionNames.Environment,
        EWorkloadSectionNames.Compute,
        EWorkloadSectionNames.Volumes,
        EWorkloadSectionNames.DataSource,
        EWorkloadSectionNames.General,
      ]);

      this.trainingWorkloadSectionsConfig.project = {
        ...defaultOptions.project,
        projects: this.projects,
      };

      this.trainingWorkloadSectionsConfig.workloadName = {
        ...defaultOptions.workloadName,
        sectionDefaultOpen: !!fromCopyId,
      };

      this.trainingWorkloadSectionsConfig.multiNode = {
        ...defaultOptions.multiNode,
        sectionDefaultOpen: false,
      };

      this.trainingWorkloadSectionsConfig.environment = {
        ...defaultOptions.environment,
        environments: this.assetsLists.environments,
        sectionOptions: {
          ...defaultOptions.environment?.sectionOptions,
          ...this.envSectionOptions,
        },
      };

      this.trainingWorkloadSectionsConfig.compute = {
        ...defaultOptions.compute,
        computeResources: this.assetsLists.computes,
        nodeAffinity: this.nodeAffinity,
        sectionOptions: {
          ...this.computeSectionOptions,
        },
      };

      this.trainingWorkloadSectionsConfig.dataSource = {
        ...defaultOptions.dataSource,
        dataSources: this.assetsLists.datasources,
        sectionDefaultOpen: !!isDataSourceSectionOpen,
      };

      this.trainingWorkloadSectionsConfig.volumes = {
        ...defaultOptions.volumes,
        storageClasses: this.storageClasses,
      };

      this.trainingWorkloadSectionsConfig.general = {
        ...defaultOptions.general,
      };
    },
    setDistributedWorkloadWorkersSectionsConfig(isDataSourceSectionOpen: boolean, fromCopyId: string | undefined): void {
      const defaultOptions: IWorkloadCreateFormConfig = workloadCreateFormUtil.getWorkloadSectionDefaultsConfig([
        EWorkloadSectionNames.Project,
        EWorkloadSectionNames.MultiNode,
        EWorkloadSectionNames.WorkloadName,
        EWorkloadSectionNames.Environment,
        EWorkloadSectionNames.Compute,
        EWorkloadSectionNames.Volumes,
        EWorkloadSectionNames.DataSource,
        EWorkloadSectionNames.General,
      ]);

      this.distributedWorkloadWorkersSectionsConfig.project = {
        ...defaultOptions.project,
        projects: this.projects,
      };

      this.distributedWorkloadWorkersSectionsConfig.workloadName = {
        ...defaultOptions.workloadName,
        sectionDefaultOpen: !!fromCopyId,
      };

      this.distributedWorkloadWorkersSectionsConfig.multiNode = {
        ...defaultOptions.multiNode,
        sectionDefaultOpen: false,
      };

      this.distributedWorkloadWorkersSectionsConfig.environment = {
        ...defaultOptions.environment,
        environments: this.assetsLists.environments,
        sectionOptions: {
          ...defaultOptions.environment?.sectionOptions,
          ...this.envSectionOptions,
          cardsTitle: "Select the environment for the workers",
        },
      };

      this.distributedWorkloadWorkersSectionsConfig.compute = {
        ...defaultOptions.compute,
        computeResources: this.assetsLists.computes,
        nodeAffinity: this.nodeAffinity,
        isRequired: true,
        sectionOptions: {
          ...defaultOptions.compute?.sectionOptions,
          ...this.computeSectionOptions,
          applyPolicyDefaults: false,
          cardsTitle: "Select the node resources needed to run each worker",
        },
      };

      this.distributedWorkloadWorkersSectionsConfig.dataSource = {
        ...defaultOptions.dataSource,
        dataSources: this.assetsLists.datasources,
        sectionDefaultOpen: isDataSourceSectionOpen,
        sectionOptions: {
          ...defaultOptions.dataSource?.sectionOptions,
          cardsTitle: "Select the data sources your workers need to access",
        },
      };

      this.distributedWorkloadWorkersSectionsConfig.volumes = {
        ...defaultOptions.volumes,
        storageClasses: this.storageClasses,
        sectionDefaultOpen: false,
      };

      this.distributedWorkloadWorkersSectionsConfig.general = {
        ...defaultOptions.general,
        sectionDefaultOpen: false,
      };
    },
    setDistributedWorkloadMasterSectionsConfig(isDataSourceSectionOpen: boolean): void {
      const defaultOptions: IWorkloadCreateFormConfig = workloadCreateFormUtil.getWorkloadSectionDefaultsConfig([
        EWorkloadSectionNames.ToggleEditingForm,
        EWorkloadSectionNames.MultiNode,
        EWorkloadSectionNames.Environment,
        EWorkloadSectionNames.Compute,
        EWorkloadSectionNames.Volumes,
        EWorkloadSectionNames.DataSource,
        EWorkloadSectionNames.General,
      ]);

      this.distributedWorkloadMasterSectionsConfig.toggleEditingForm = {
        ...defaultOptions.toggleEditingForm,
        sectionOptions: {
          ...defaultOptions.toggleEditingForm?.sectionOptions,
          mainTitle: "Allow different setup for the master",
          tooltip: `
        When enabled, you can change the master's setup to be different from the workers'.
        When disabled, the master will inherit the workers' setup automatically.
        `,
        },
      };

      this.distributedWorkloadMasterSectionsConfig.multiNode = {
        ...defaultOptions.multiNode,
      };

      this.distributedWorkloadMasterSectionsConfig.environment = {
        ...defaultOptions.environment,
        environments: this.masterAssetsLists.environments,
        sectionDefaultOpen: true,
        sectionOptions: {
          ...defaultOptions.environment?.sectionOptions,
          ...this.envSectionOptions,
          cardsTitle: "Select the environment for the master",
        },
      };

      this.distributedWorkloadMasterSectionsConfig.compute = {
        ...defaultOptions.compute,
        computeResources: this.masterAssetsLists.computes,
        nodeAffinity: this.nodeAffinity,
        sectionDefaultOpen: true,
        isRequired: true,
        sectionOptions: {
          ...defaultOptions.compute?.sectionOptions,
          ...this.computeSectionOptions,
          applyPolicyDefaults: false,
          cardsTitle: "Select the node resources needed to run the master",
          hideNodePools: true,
        },
      };

      this.distributedWorkloadMasterSectionsConfig.dataSource = {
        ...defaultOptions.dataSource,
        dataSources: this.masterAssetsLists.datasources,
        sectionDefaultOpen: isDataSourceSectionOpen,
        sectionOptions: {
          ...defaultOptions.dataSource?.sectionOptions,
          cardsTitle: "Select the data sources your master need to access",
        },
      };

      this.distributedWorkloadMasterSectionsConfig.volumes = {
        ...defaultOptions.volumes,
        storageClasses: this.storageClasses,
      };

      this.distributedWorkloadMasterSectionsConfig.general = {
        ...defaultOptions.general,
      };
    },
    initFilterProperties(): void {
      this.assetsFilter = {
        projectId: this.training.projectId,
        usageInfo: true,
        complyToProject: this.training.projectId,
        complyToWorkloadType: this.training.distributed ? EWorkloadType.Distributed : EWorkloadType.Training,
        isTraining: true,
        isDistributed: !!this.training.distributed,
      };
    },
    async loadEnvironments(): Promise<void> {
      if (!this.selectedProject) return;

      if (!this.training.distributed) {
        this.assetsLists.environments = await this.environmentStore.loadEnvironments(this.assetsFilter);
      } else {
        this.assetsLists.environments = await this.environmentStore.loadEnvironments({
          ...this.assetsFilter,
          complyToReplicaType: EDistributedReplicaType.Worker,
          distributedFramework: this.training.distributed.distFramework,
        });
        this.masterAssetsLists.environments = await this.environmentStore.loadEnvironments({
          ...this.assetsFilter,
          complyToReplicaType: EDistributedReplicaType.Master,
          distributedFramework: this.training.distributed.distFramework,
        });
      }
    },
    async loadComputeResources(): Promise<void> {
      if (!this.selectedProject) return;
      if (!this.training.distributed) {
        this.assetsLists.computes = await this.computeResourceStore.loadComputeResources(this.assetsFilter);
      } else {
        this.assetsLists.computes = await this.computeResourceStore.loadComputeResources({
          ...this.assetsFilter,
          complyToReplicaType: EDistributedReplicaType.Worker,
        });
        this.masterAssetsLists.computes = await this.computeResourceStore.loadComputeResources({
          ...this.assetsFilter,
          complyToReplicaType: EDistributedReplicaType.Master,
        });
      }
    },
    async loadDataSources(): Promise<void> {
      if (!this.selectedProject) return;
      if (!this.training.distributed) {
        this.assetsLists.datasources = await this.dataSourceStore.loadDataSources(this.assetsFilter);
      } else {
        this.assetsLists.datasources = await this.dataSourceStore.loadDataSources({
          ...this.assetsFilter,
          complyToReplicaType: EDistributedReplicaType.Worker,
        });
        this.masterAssetsLists.datasources = await this.dataSourceStore.loadDataSources({
          ...this.assetsFilter,
          complyToReplicaType: EDistributedReplicaType.Master,
        });
      }
    },
    cleanupNoneComplianceAssets(masterOnly = false): void {
      if (!masterOnly) {
        if (this.training.assets.environment) {
          const selectedEnvironment: EnvironmentAsset | undefined = this.assetsLists.environments.find(
            (environment: EnvironmentAsset) => environment.meta.id === this.training.assets.environment,
          );
          if (!selectedEnvironment?.compliance?.compliance) {
            this.training.assets.environment = "";
          }
        }
        if (this.training.assets.compute) {
          const selectedCompute: ComputeAsset | undefined = this.assetsLists.computes.find(
            (compute: ComputeAsset) => compute.meta.id === this.training.assets.compute,
          );

          if (!selectedCompute?.compliance?.compliance) {
            this.training.assets.compute = "";
          }
        }
        if (this.training.assets.datasources?.length) {
          const nonComplianceDataSources: Set<string> = new Set(
            this.assetsLists.datasources
              .filter((dataSource: DatasourceListResponseEntry) => !dataSource.compliance?.compliance)
              .map((dataSource: DatasourceListResponseEntry) => dataSource.meta.id),
          );
          this.training.assets.datasources = this.training.assets.datasources.filter(
            (datasource: AssetIdAndKind) => !nonComplianceDataSources.has(datasource.id),
          );
        }
      }
      if (this.training.distributed?.master) {
        const masterEnv = this.training.distributed.master.assets.environment;
        if (masterEnv) {
          const selectedEnvironment: EnvironmentAsset | undefined = this.masterAssetsLists.environments.find(
            (environment: EnvironmentAsset) => environment.meta.id === masterEnv,
          );
          if (!selectedEnvironment?.compliance?.compliance) {
            this.training.distributed.master.assets.environment = "";
            // Setting master editing to be ON as its no longer a perfect copy of worker
            this.setMasterEditingTrueAndDisableToggle();
          }
        }
        const masterCompute = this.training.distributed.master.assets.compute;
        if (masterCompute) {
          const selectedCompute: ComputeAsset | undefined = this.masterAssetsLists.computes.find(
            (compute: ComputeAsset) => compute.meta.id === masterCompute,
          );

          if (!selectedCompute?.compliance?.compliance) {
            this.training.distributed.master.assets.compute = "";
            // Setting master editing to be ON as its no longer a perfect copy of worker
            this.setMasterEditingTrueAndDisableToggle();
          }
        }
        const masterDataSources = this.training.distributed.master.assets.datasources;
        if (masterDataSources?.length) {
          const nonComplianceDataSources: Set<string> = new Set(
            this.masterAssetsLists.datasources
              .filter((dataSource: DatasourceListResponseEntry) => !dataSource.compliance?.compliance)
              .map((dataSource: DatasourceListResponseEntry) => dataSource.meta.id),
          );
          this.training.distributed.master.assets.datasources = masterDataSources.filter(
            (datasource: AssetIdAndKind) => {
              const isComplied = !nonComplianceDataSources.has(datasource.id);
              if (!isComplied) {
                // Setting master editing to be ON as its no longer a perfect copy of worker
                this.setMasterEditingTrueAndDisableToggle();

                this.distributedWorkloadMasterSectionsConfig.dataSource &&
                  (this.distributedWorkloadMasterSectionsConfig.dataSource.sectionDefaultOpen = true);
              }
              return isComplied;
            },
          );
        }
      }
      this.saveTraining(this.training);
    },
    setMasterEditingTrueAndDisableToggle(): void {
      this.training.enableEditingMaster = true;
      this.distributedWorkloadMasterSectionsConfig.toggleEditingForm &&
        (this.distributedWorkloadMasterSectionsConfig.toggleEditingForm.viewOnly = true);
    },
    setCreatedAssets(
      createdEnvironmentId?: string,
      createdComputeResourceId?: string,
      createdDataSourceId?: string,
      kind?: AssetKind,
    ) {
      if (createdEnvironmentId) {
        this.updateEnvironment(createdEnvironmentId);
      }
      if (createdComputeResourceId) {
        this.updateComputeResource(createdComputeResourceId);
      }
      if (createdDataSourceId && kind) {
        this.updateDataSource(createdDataSourceId, kind);
      }
    },
    initMasterModel(masterModel: IUIDistributedMaster): void {
      this.training.distributed && (this.training.distributed.master = masterModel);
      this.setDistributedWorkloadMasterSectionsConfig(false);
      // We set the master model (copy of worker by default)
      // but we have to make sure to cleanup any non complied assets, due to a possible difference between worker & master policies
      // if we cleanup any we trigger the form to be editable on master page
      this.applyMasterPolicyForEnvironmentSection();
      this.cleanupNoneComplianceAssets(true);
      this.saveTraining(this.training);
    },
    async loadStorageClasses(): Promise<void> {
      this.storageClasses = await dataSourceService.listStorageClass(this.clusterUid);
    },
    async loadProjects(): Promise<void> {
      await this.projectStore.loadProjects({
        withAccessRules: false,
        withNamespace: true,
        withMetrics: false,
        clusterId: this.clusterStore.currentClusterId,
      });
    },
    async loadPolicy(): Promise<void> {
      if (!this.selectedProject) return;
      const type = this.training.distributed ? PolicyType.Distributed : PolicyType.Training;
      this.trainingPolicy = await trainingService.getPolicy(type, this.projectId);
    },
    loadNodeAffinity(): void {
      if (!this.selectedProject) this.nodeAffinity = [];
      this.nodeAffinity = this.projectStore.getTrainingNodeAffinityByProjectId(this.training.projectId);
      if (this.training.specificEnv && this.nodeAffinity && this.nodeAffinity.length === 1)
        this.training.specificEnv.nodeType = this.nodeAffinity[0].name;
    },
    setEnvironment(environment: EnvironmentAsset): void {
      const specificEnv: IUIWorkloadEnvSectionModel = environmentSectionUtil.getSpecificEnvFromEnvironment(environment);
      this.training.assets.environment = environment.meta.id;
      this.training.specificEnv = {
        ...this.training.specificEnv,
        ...specificEnv,
      };
    },
    setEnvironmentDistributed(environment: EnvironmentAsset): void {
      if (!this.training.distributed?.master) return;

      const specificEnv: IUIWorkloadEnvSectionModel = environmentSectionUtil.getSpecificEnvFromEnvironment(environment);
      this.training.distributed.master.assets.environment = environment.meta.id;
      this.training.distributed.master.specificEnv = {
        ...this.training.distributed.master.specificEnv,
        ...specificEnv,
      };
    },
    updateEnvironment(createdEnvironmentId: string): void {
      if (this.workloadFormName === EDistributedReplicaType.Master) {
        const environment: EnvironmentAsset | undefined = this.masterAssetsLists.environments.find(
          (environment: EnvironmentAsset) => environment.meta.id === createdEnvironmentId,
        );
        if (!environment) return;
        this.setEnvironmentDistributed(environment);
      } else {
        const environment: EnvironmentAsset | undefined = this.assetsLists.environments.find(
          (environment: EnvironmentAsset) => environment.meta.id === createdEnvironmentId,
        );
        if (!environment) return;
        this.setEnvironment(environment);
      }
    },
    updateComputeResource(createdId: string): void {
      if (this.workloadFormName === EDistributedReplicaType.Master && this.training.distributed?.master) {
        this.training.distributed.master.assets.compute = createdId;
      } else {
        this.training.assets.compute = createdId;
      }
    },
    updateDataSource(createdId: string, kind: AssetKind): void {
      if (this.workloadFormName === EDistributedReplicaType.Master && this.training.distributed?.master) {
        this.training.distributed.master.assets.datasources = this.dataSourceNoDuplicates(
          this.training.distributed.master.assets.datasources,
          createdId,
          kind,
        );
      } else {
        this.training.assets.datasources = this.dataSourceNoDuplicates(
          this.training.assets.datasources,
          createdId,
          kind,
        );
      }
    },
    dataSourceNoDuplicates(
      datasources: Array<AssetIdAndKind> | undefined,
      id: string,
      kind: AssetKind,
    ): Array<AssetIdAndKind> {
      if (!datasources) datasources = [];
      const alreadyExist: AssetIdAndKind | undefined = datasources.find(
        (datasource: AssetIdAndKind) => datasource.id === id,
      );
      if (!alreadyExist) {
        datasources.push({ id, kind });
      }

      return datasources;
    },
    async loadFromExistingTraining(trainingId: string): Promise<void> {
      try {
        // TODO: need to raise an issue of what if this workload was not created by the assets API
        const originalTraining: TrainingV2 = await this.trainingStore.loadById(trainingId);

        let uiVolumes: Array<IUIVolume> | undefined;
        if (originalTraining.assets.workloadVolumes?.length) {
          const pvcs: Array<PVCAsset> = await dataSourceService.loadPVCAssets(originalTraining.assets.workloadVolumes);
          uiVolumes = dataSourceUtil.mapPvcsToUiVolumes(pvcs);
        }
        this.training = workloadUtil.convertWorkloadToWorkloadUI(originalTraining, uiVolumes);
        this.saveTraining(this.training);
      } catch (error: unknown) {
        console.error("failed to get training with id:", trainingId, error);
        this.$q.notify(alertUtil.getError("Failed to load training"));
        this.appStore.setFallback(true);
      }
    },
    async onCancel(): Promise<void> {
      const allowToLeave: boolean = await requestToLeave();
      if (allowToLeave) {
        this.redirectToPrevRoute();
      }
    },
    redirectToPrevRoute(): void {
      this.$router.push({ name: WORKLOAD_ROUTE_NAMES.WORKLOAD_INDEX });
    },
    removeImposedDataSources(): void {
      if (this.training.assets.datasources) {
        const imposedIds = this.assetsLists.datasources.reduce(
          (acc: Record<string, string>, dataSource: DatasourceListResponseEntry) => {
            if (dataSource.compliance?.imposed) {
              acc[dataSource.meta.id] = dataSource.meta.id;
            }
            return acc;
          },
          {},
        );

        this.training.assets.datasources = this.training.assets.datasources.filter(
          (datasource: AssetIdAndKind) => !imposedIds[datasource.id],
        );
      }
      if (this.training.distributed?.master?.assets?.datasources) {
        const imposedIds = this.masterAssetsLists.datasources.reduce(
          (acc: Record<string, string>, dataSource: DatasourceListResponseEntry) => {
            if (dataSource.compliance?.imposed) {
              acc[dataSource.meta.id] = dataSource.meta.id;
            }
            return acc;
          },
          {},
        );

        this.training.distributed.master.assets.datasources = this.training.distributed.master.assets.datasources.filter(
          (datasource: AssetIdAndKind) => !imposedIds[datasource.id],
        );
      }
    },
    async onSubmit(): Promise<void> {
      try {
        let trainingAdded: ITrainingList | TrainingV2 | null;
        this.submitting = true;
        this.training.clusterId = this.clusterUid;
        this.training.namespace = this.projectStore.getNamespaceByProjectId(this.training.projectId);

        // making sure imposed data sources are removed before submitting
        this.removeImposedDataSources();

        this.saveTraining(this.training);
        let workloadVolumes: Array<string> | undefined;
        if (this.training.assets.uiVolumes?.length) {
          workloadVolumes = await dataSourceService.createWorkloadVolumes(
            this.training.name,
            this.training.assets.uiVolumes,
            {
              scope: Scope.Project,
              projectId: this.projectId,
            },
          );
        }

        let masterWorkloadVolumes: Array<string> | undefined;
        if (this.training.distributed?.master?.assets.uiVolumes?.length) {
          masterWorkloadVolumes = await dataSourceService.createWorkloadVolumes(
            this.training.name,
            this.training.distributed.master.assets.uiVolumes,
            {
              scope: Scope.Project,
              projectId: this.projectId,
            },
          );
        }

        const isWorkloadCreationV2 =
          this.clusterStore.isClusterVersionSufficient(this.clusterUid, MIN_WORKLOAD_CREATION_V2_VERSION) &&
          this.settingStore.isWorkloadSubmissionV2Enabled;

        if (isWorkloadCreationV2) {
          const workloadCreationRequestV2: TrainingCreationRequestV2 = workloadUtil.getTrainingCreationRequestV2(
            this.training,
            workloadVolumes,
            masterWorkloadVolumes,
          );
          trainingAdded = await this.trainingStore.createTrainingV2(workloadCreationRequestV2);
        } else {
          const workloadCreationRequest: WorkloadCreationRequest = workloadUtil.getWorkloadCreationRequest(
            this.training,
            workloadVolumes,
            masterWorkloadVolumes,
          );

          if (workloadCreationRequest.specificEnv?.backoffLimit && !this.clusterStore.isVersionSupportBackoffLimit) {
            workloadCreationRequest.specificEnv.backoffLimit = null;
          }

          trainingAdded = await this.trainingStore.createTraining(workloadCreationRequest);
        }

        if (trainingAdded) {
          this.workloadStore.setWorkloadAdded(trainingAdded);
          this.$q.notify(alertUtil.getSuccess(`Training ${this.training.name} created`));
          this.redirectToPrevRoute();
        }
      } catch (error: unknown) {
        // This is a quick fix when creating workload fails we need to create a pvc
        // with a different name
        this.training.assets.uiVolumes?.forEach(
          (uiVolume: IUIVolume) => (uiVolume.claimName = dataSourceUtil.getRandomClaimName(`${this.training.name}-pvc`)),
        );

        if (this.training.distributed?.master?.assets.uiVolumes) {
          this.training.distributed.master.assets.uiVolumes.forEach(
            (uiVolume: IUIVolume) =>
              (uiVolume.claimName = dataSourceUtil.getRandomClaimName(`${this.training.name}-pvc`)),
          );
        }

        const errorAlert = new ErrorAlert({
          generalMessage: ErrorAlert.failedCreateMessage("training"),
        });
        this.$q.notify(errorAlert.getNotification(error));
      } finally {
        this.submitting = false;
      }
    },
    applyPolicyDefaultsAndRules(): void {
      this.applyPolicyForEnvironmentSection();
      this.saveTraining(this.training);
    },
    applyPolicyForEnvironmentSection(): void {
      if (!this.trainingPolicy?.effective) return;
      if (!this.training.distributed) {
        if ((this.trainingPolicy.effective.defaults as TrainingPolicyDefaultsAndRulesV2Defaults)?.environmentVariables) {
          this.training.specificEnv.environmentVariables = policyUtil.mergeEnvironmentVariablesWithPolicyDefaults(
            this.training.specificEnv.environmentVariables,
            this.trainingPolicy.effective as TrainingPolicyDefaultsAndRulesV2,
          );
        }

        if (
          (this.trainingPolicy.effective.rules as TrainingPolicyDefaultsAndRulesV2Rules)?.environmentVariables?.instances
        ) {
          this.envSectionOptions.canAddEnvVariable = fallbackDefaultIfNullOrUndefined<boolean>(
            (this.trainingPolicy.effective.rules as TrainingPolicyDefaultsAndRulesV2Rules).environmentVariables
              ?.instances?.canAdd,
            true,
          );
        }
      } else {
        this.applyWorkerPolicyForEnvironmentSection();
        this.applyMasterPolicyForEnvironmentSection();
      }
    },
    applyWorkerPolicyForEnvironmentSection(): void {
      if (!this.trainingPolicy?.effective || !this.training.distributed) return;
      const workerPolicy = {
        defaults: {
          ...(this.trainingPolicy.effective as DistributedPolicyDefaultsAndRulesV2).defaults?.worker,
        },
        rules: {
          ...(this.trainingPolicy.effective as DistributedPolicyDefaultsAndRulesV2).rules?.worker,
        },
      };

      if (workerPolicy.defaults?.environmentVariables) {
        this.training.specificEnv.environmentVariables = policyUtil.mergeEnvironmentVariablesWithPolicyDefaults(
          this.training.specificEnv.environmentVariables,
          workerPolicy,
        );
      }

      if (workerPolicy.rules?.environmentVariables?.instances) {
        this.envSectionOptions.canAddEnvVariable = fallbackDefaultIfNullOrUndefined<boolean>(
          workerPolicy.rules.environmentVariables.instances?.canAdd,
          true,
        );
      }
    },
    applyMasterPolicyForEnvironmentSection(): void {
      // this wont have any effect before we init master model
      if (!this.trainingPolicy?.effective || !this.training.distributed) return;
      const masterPolicy = {
        defaults: {
          ...(this.trainingPolicy.effective as DistributedPolicyDefaultsAndRulesV2).defaults?.master,
        },
        rules: {
          ...(this.trainingPolicy.effective as DistributedPolicyDefaultsAndRulesV2).rules?.master,
        },
      };

      if (masterPolicy.defaults?.environmentVariables && this.training?.distributed?.master) {
        this.training.distributed.master.specificEnv.environmentVariables =
          policyUtil.mergeEnvironmentVariablesWithPolicyDefaults(
            this.training.distributed.master.specificEnv.environmentVariables,
            masterPolicy,
          );
      }
      if (masterPolicy.rules?.environmentVariables?.instances) {
        this.envSectionOptions.canAddEnvVariable = fallbackDefaultIfNullOrUndefined<boolean>(
          masterPolicy.rules.environmentVariables.instances?.canAdd,
          true,
        );
      }
    },
    saveTraining(training: IUIWorkloadCreation): void {
      this.trainingStore.setTraining(training);
    },
    onBack(): void {
      this.$router.push({ name: TRAINING_ROUTE_NAMES.TRAINING_NEW });
    },
    onWorkloadChanged(training: IUIWorkloadCreation): void {
      this.training = training;
      this.saveTraining(training);
    },
  },
});
</script>

<style lang="scss" scoped>
.training-assets-edit {
  .training-assets-edit-actions {
    width: 100%;
    display: flex;
    align-items: center;
  }
}
</style>
