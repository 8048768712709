import type { DepartmentsMetricData, MetricsData } from "@/models/metrics.model";
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import { dateUtil } from "@/utils/date.util";
import type { IDepartment } from "@/models/department.model";
import type { IMetricResourceMap, MetricsResourceData } from "@/models/resource.model";
import type { IProject } from "@/models/project.model";

const DEFAULT_NUMBER_OF_SAMPLES = 30;

export const resourcesService = {
  getClusterMetrics,
  getDepartmentsMetrics,
  getProjectsMetrics,
  enrichDepartmentsWithResourcesMetrics,
  enrichProjectsWithResourcesMetrics,
};

async function getMetrics(
  endPointResourcePath: string,
  clusterUUID: string,
  start: string,
  end: string,
  nodePoolFilter?: string,
  resourceId?: string,
  numberOfSamples = DEFAULT_NUMBER_OF_SAMPLES,
): Promise<DepartmentsMetricData | MetricsData> {
  const { startDate, endDate } = dateUtil.getEpochDates(start, end);
  let endPoint = `v1/k8s/clusters/${clusterUUID}`;

  if (endPointResourcePath) {
    endPoint += `/${endPointResourcePath}`;
  }
  if (resourceId) {
    endPoint += `/${resourceId}`;
  }
  endPoint += `/metrics?start=${startDate}&end=${endDate}&numberOfSamples=${numberOfSamples}`;

  if (nodePoolFilter) {
    endPoint += `&nodepoolName=${nodePoolFilter}`;
  }

  return await controlPlaneService.get(endPoint);
}

async function getClusterMetrics(
  clusterUUID: string,
  start: string,
  end: string,
  nodePoolFilter?: string,
  numberOfSamples = DEFAULT_NUMBER_OF_SAMPLES,
): Promise<MetricsData> {
  return (await getMetrics("", clusterUUID, start, end, nodePoolFilter, undefined, numberOfSamples)) as MetricsData;
}

async function getDepartmentsMetrics(
  clusterUUID: string,
  start: string,
  end: string,
  nodePoolFilter?: string,
  departmentId?: string,
  numberOfSamples = DEFAULT_NUMBER_OF_SAMPLES,
): Promise<DepartmentsMetricData | MetricsData> {
  return await getMetrics("departments", clusterUUID, start, end, nodePoolFilter, departmentId, numberOfSamples);
}

async function getProjectsMetrics(
  clusterUUID: string,
  start: string,
  end: string,
  nodePoolFilter?: string,
  projectId?: string,
  numberOfSamples = DEFAULT_NUMBER_OF_SAMPLES,
): Promise<MetricsData> {
  return (await getMetrics(
    "projects",
    clusterUUID,
    start,
    end,
    nodePoolFilter,
    projectId,
    numberOfSamples,
  )) as MetricsData;
}

async function enrichDepartmentsWithResourcesMetrics(
  departments: IDepartment[],
  clusterUuid: string,
): Promise<IDepartment[]> {
  try {
    const metric = await controlPlaneService.get(`v1/k8s/clusters/${clusterUuid}/departments/metrics`);
    const resourceMap: IMetricResourceMap = _buildResourceMap(metric.data, "departmentId");

    return _updateEntitiesWithResources(departments, resourceMap);
  } catch (e) {
    console.log("failed to get departments metrics", e);
    return departments;
  }
}

async function enrichProjectsWithResourcesMetrics(projects: IProject[], clusterUuid: string): Promise<IProject[]> {
  try {
    const metric = await controlPlaneService.get(`v1/k8s/clusters/${clusterUuid}/projects/metrics`);
    const resourceMap: IMetricResourceMap = _buildResourceMap(metric.data, "projectId");

    return _updateEntitiesWithResources(projects, resourceMap);
  } catch (e) {
    console.error("failed to get projects metrics: ", (e as Error)?.message);
    return projects;
  }
}

function _buildResourceMap(data: MetricsData[], entityKey: "projectId" | "departmentId"): IMetricResourceMap {
  const resourceMap: IMetricResourceMap = {};

  for (const item of data) {
    for (const resource of item.current.resources) {
      const entityId = item.metadata?.[entityKey];
      const allocatedResources: MetricsResourceData = {
        cpu: resource.cpu.allocated,
        memory: resource.memory.allocated,
        gpu: resource.gpu.allocated,
      };

      if (entityId) {
        if (!resourceMap[entityId]) {
          resourceMap[entityId] = allocatedResources;
        } else {
          resourceMap[entityId].cpu += allocatedResources.cpu;
          resourceMap[entityId].memory += allocatedResources.memory;
          resourceMap[entityId].gpu += allocatedResources.gpu;
        }
      }
    }
  }

  return resourceMap;
}

function _updateEntitiesWithResources<T extends IProject | IDepartment>(
  entities: T[],
  resourceMap: IMetricResourceMap,
): T[] {
  for (const entity of entities) {
    if (entity.id) {
      entity.allocatedCpu = resourceMap[entity.id]?.cpu || 0;
      entity.allocatedMemory = resourceMap[entity.id]?.memory || 0;
      entity.allocatedGpus = resourceMap[entity.id]?.gpu || 0;
    }
  }
  return entities;
}
