<template>
  <section class="workload-distributed-create-form">
    <runai-stepper :steps="steps" :current-step="currentStep" @step-changed="onStepClicked">
      <template #step>
        <div v-if="currentStep === steps[0].name" class="step-workers">
          <workload-create-form
            ref="workloadCreateForm"
            :workload-type="workloadType"
            :workload="workload"
            :selected-project="selectedProject"
            :workload-config="workloadWorkersConfig"
            @workload-changed="onWorkloadChanged"
            @back-clicked="onBack"
            @leave-page="onLeavePage"
            :workload-form-name="steps[0].name"
          >
            <template #error-message>
              <q-field
                class="col-4 form-hint flex-1"
                :model-value="displayFormHint"
                :rules="[isFormIncomplete]"
              ></q-field>
            </template>

            <template #nav-btns>
              <q-btn flat color="primary" label="Cancel" class="q-mr-sm" @click="$emit('canceled')" />
              <q-btn
                v-if="hideMasterStep"
                color="primary"
                :label="submitBtnText"
                aid="submit-btn"
                :loading="submitting"
                @click="onSubmit"
              />
              <q-btn v-else color="primary" label="Continue" aid="continue-btn" @click="onContinue" />
            </template>
          </workload-create-form>
        </div>
        <div v-if="masterWorkloadModel && steps.length > 1 && currentStep === steps[1].name" class="step-master">
          <workload-create-form
            ref="workloadCreateForm"
            :workload-type="workloadType"
            :workload="masterWorkloadModel"
            :selected-project="selectedProject"
            :workload-config="workloadMasterConfig"
            @workload-changed="onMasterWorkloadChanged"
            :key="`${workload.enableEditingMaster}`"
            :workload-form-name="steps[1].name"
          >
            <template #error-message>
              <q-field
                class="col-4 form-hint flex-1"
                :model-value="displayFormHint"
                :rules="[isFormIncomplete]"
              ></q-field>
            </template>

            <template #nav-btns>
              <q-btn flat color="primary" label="Back" class="q-mr-sm" @click="onBackStep" />
              <q-btn color="primary" :label="submitBtnText" aid="submit-btn" :loading="submitting" @click="onSubmit" />
            </template>
          </workload-create-form>
        </div>
      </template>
    </runai-stepper>
  </section>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";

// cmps
import { RunaiStepper } from "@/components/common/runai-stepper";
import { EWorkloadSectionNames, WorkloadCreateForm } from "@/components/workload/workload-create/workload-create-form";

// models
import type {
  IWorkloadCreateFormConfig,
  IWorkloadFormBaseConfig,
} from "src/components/workload/workload-create/workload-create-form";
import {
  type EWorkloadFormType,
  type IUIWorkloadCreation,
  type IUIDistributedMaster,
  EDistributedReplicaType,
} from "@/models/workload.model";
import type { IProject } from "@/models/project.model";
import type { IStepsConfig } from "@/components/common/runai-stepper";

// constants
import { errorMessages } from "@/common/error-message.constant";
import { LEAVE_REASON } from "@/components/common/runai-form-wrapper";

// utils
import { deepCopy } from "@/utils/common.util";
import { workloadUtil } from "@/utils/workload.util/workload.util";

const allSteps: Array<IStepsConfig> = [
  { name: EDistributedReplicaType.Worker, title: "Workers", icon: "fa-solid fa-check", done: false },
  { name: EDistributedReplicaType.Master, title: "Master", icon: "fa-solid fa-graduation-cap", done: false },
];

export default defineComponent({
  components: {
    RunaiStepper,
    WorkloadCreateForm,
  },
  emits: ["workload-changed", "volume-changed", "canceled", "submit", "back-clicked", "init-master-model"],
  props: {
    workloadType: {
      type: String as PropType<EWorkloadFormType>,
      required: true,
    },
    workload: {
      type: Object as PropType<IUIWorkloadCreation>,
      required: true,
    },
    selectedProject: {
      type: Object as PropType<IProject>,
      required: true,
    },
    submitBtnText: {
      type: String as PropType<string>,
      required: true,
    },
    submitting: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    workloadWorkersConfig: {
      type: Object as PropType<IWorkloadCreateFormConfig>,
      required: true,
    },
    workloadMasterConfig: {
      type: Object as PropType<IWorkloadCreateFormConfig>,
      required: true,
    },
    displayStep: {
      type: String as PropType<string>,
      required: false,
    },
  },
  provide() {
    return {
      distributedReplicaType: computed(() => this.currentStep),
    };
  },
  data() {
    return {
      currentStep: EDistributedReplicaType.Worker as string,
      steps: allSteps as Array<IStepsConfig>,
      displayFormHint: false as boolean,
      timeOutId: null as ReturnType<typeof setTimeout> | null,
      initialWorkload: workloadUtil.getEmptyUIWorkloadCreation() as IUIWorkloadCreation,
    };
  },
  created() {
    if (this.displayStep) {
      this.currentStep = this.displayStep;
    }

    if (this.hideMasterStep) {
      this.steps = allSteps.slice(0, 1);
      return;
    }

    this.updateViewState(!this.workload.enableEditingMaster);
    this.initialWorkload = deepCopy(this.workload);
  },
  computed: {
    hideMasterStep(): boolean {
      return !!this.workload.distributed?.noMaster;
    },
    masterWorkloadModel(): IUIWorkloadCreation {
      return {
        ...this.workload,
        assets: this.workload.distributed?.master?.assets || this.workload.assets,
        specificEnv: this.workload.distributed?.master?.specificEnv || this.workload.specificEnv,
        distributed: {
          distFramework: this.workload.distributed?.distFramework,
        },
      };
    },
  },
  methods: {
    isFormIncomplete(val: boolean): boolean | string {
      return !val ? true : errorMessages.FORM_INCOMPLETE;
    },
    async validate(): Promise<boolean> {
      const workloadCreateForm: HTMLFormElement | unknown = this.$refs["workloadCreateForm"];
      if (!workloadCreateForm) return false;

      return await (workloadCreateForm as HTMLFormElement).validate();
    },
    showHint(): void {
      this.displayFormHint = true;
      this.timeOutId && clearTimeout(this.timeOutId);
      this.timeOutId = setTimeout(() => (this.displayFormHint = false), 15000);
    },
    updateViewState(viewOnly: boolean): void {
      Object.keys(this.workloadMasterConfig).forEach((key: string): void => {
        if (key === EWorkloadSectionNames.ToggleEditingForm) return;
        const config: IWorkloadFormBaseConfig | undefined =
          this.workloadMasterConfig[key as keyof IWorkloadCreateFormConfig];
        if (!config) return;
        config.viewOnly = viewOnly;
      });
    },
    onMasterWorkloadChanged(masterWorkload: IUIWorkloadCreation): void {
      const viewOnly = !masterWorkload.enableEditingMaster;

      // return the origin distributed values
      const distributed = {
        ...this.workload.distributed,
      };

      // take the master details fro master step
      if (masterWorkload.distributed) {
        if (masterWorkload.enableEditingMaster) {
          distributed.master = {
            assets: masterWorkload.assets,
            specificEnv: masterWorkload.specificEnv,
          };
        } else {
          distributed.master = {
            assets: this.workload.assets,
            specificEnv: this.workload.specificEnv,
          };
        }
      }

      this.updateViewState(viewOnly);
      this.onWorkloadChanged({
        ...this.workload,
        distributed,
        enableEditingMaster: masterWorkload.enableEditingMaster,
      });
    },
    onWorkloadChanged(workload: IUIWorkloadCreation): void {
      this.$emit("workload-changed", workload);
    },
    onBackStep(): void {
      this.steps[0].done = false;
      this.currentStep = EDistributedReplicaType.Worker;

      // reset the master values + the toggle
      const workload: IUIWorkloadCreation = {
        ...this.workload,
        distributed: {
          ...this.workload.distributed,
          master: this.initialWorkload.distributed?.master,
        },
        enableEditingMaster: this.initialWorkload.enableEditingMaster,
      };
      this.$emit("workload-changed", workload);

      this.updateViewState(!this.initialWorkload.enableEditingMaster);
    },
    onBack(): void {
      (this.$refs.workloadCreateForm as HTMLFormElement).beforeLeave();
    },
    onLeavePage(leaveReason: LEAVE_REASON): void {
      if (leaveReason === LEAVE_REASON.LEAVE_PAGE) {
        this.$emit("back-clicked");
      }
    },
    async onContinue(): Promise<void> {
      const success = await this.validate();
      if (!success) {
        this.showHint();
        return;
      }

      // Update the master before moving to the next step:
      this.$emit("init-master-model", this.getMasterData());

      this.steps[0].done = true;
      this.currentStep = EDistributedReplicaType.Master;
      this.updateViewState(!this.workload.enableEditingMaster);
    },
    getMasterData(): IUIDistributedMaster {
      // if master should inherit the workers (keep the toggle enable editing master off),
      // we need to force the master to be aligned with workers.
      // Otherwise, we keep the initial master data
      let master: IUIDistributedMaster;
      if (!this.initialWorkload.enableEditingMaster || !this.initialWorkload.distributed?.master) {
        master = {
          assets: deepCopy(this.workload.assets),
          specificEnv: this.workload.specificEnv,
        };
      } else {
        master = {
          assets: this.initialWorkload.distributed.master.assets,
          specificEnv: this.initialWorkload.distributed.master.specificEnv,
        };
      }
      return master;
    },
    async onSubmit(): Promise<void> {
      const success = await this.validate();
      if (!success) {
        this.showHint();
        return;
      }

      this.$emit("submit");
    },
    onStepClicked(step: string): void {
      let isDone = true;
      this.steps = this.steps.map((currStep: IStepsConfig) => {
        if (step === currStep.name) {
          isDone = false;
        }

        return {
          ...currStep,
          done: isDone,
        };
      });

      this.currentStep = step;
    },
  },
  unmounted() {
    this.timeOutId && clearTimeout(this.timeOutId);
  },
});
</script>
