import { ResourceType, Action } from "@/swagger-models/authorization-client";
import type { RunaiRouteRecord } from "vue-router";
import { CREDENTIAL_ROUTE_NAMES } from "@/router/credential.routes/credential.routes.names";

export const credentialRoutes: RunaiRouteRecord = {
  path: "/credentials",
  // route level code-splitting
  // this generates a separate chunk (department.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import("@/views/credential/credential-app.vue"),
  children: [
    {
      path: "",
      name: CREDENTIAL_ROUTE_NAMES.CREDENTIAL_INDEX,
      component: () => import("@/views/credential/credential-index.vue"),
      meta: {
        pageTitle: "Credentials",
        resourceType: ResourceType.Credentials,
        minPermittedActions: [Action.Read],
        requiresAuth: true,
        hideClusterSelection: true,
      },
    },
    {
      path: "userpass/new",
      name: CREDENTIAL_ROUTE_NAMES.CREDENTIAL_USERPASS_NEW,
      component: () => import("@/views/credential/userpass/userpass-new.vue"),
      meta: {
        pageTitle: "New credential",
        fullWidth: true,
        requestToLeave: true,
        backPageName: CREDENTIAL_ROUTE_NAMES.CREDENTIAL_INDEX,
        resourceType: ResourceType.Credentials,
        minPermittedActions: [Action.Create],
        requiresAuth: true,
        hideClusterSelection: true,
      },
    },
    {
      path: "access-key/new",
      name: CREDENTIAL_ROUTE_NAMES.CREDENTIAL_ACCESS_KEY_NEW,
      component: () => import("@/views/credential/access-key/access-key-new.vue"),
      meta: {
        pageTitle: "New credential",
        fullWidth: true,
        requestToLeave: true,
        backPageName: CREDENTIAL_ROUTE_NAMES.CREDENTIAL_INDEX,
        resourceType: ResourceType.Credentials,
        minPermittedActions: [Action.Create],
        requiresAuth: true,
        hideClusterSelection: true,
      },
    },
    {
      path: "docker-registry/new",
      name: CREDENTIAL_ROUTE_NAMES.CREDENTIAL_DOCKER_REGISTRY_NEW,
      component: () => import("@/views/credential/docker-registry/docker-registry-new.vue"),
      meta: {
        pageTitle: "New credential",
        fullWidth: true,
        requestToLeave: true,
        backPageName: CREDENTIAL_ROUTE_NAMES.CREDENTIAL_INDEX,
        resourceType: ResourceType.Credentials,
        minPermittedActions: [Action.Create],
        requiresAuth: true,
        hideClusterSelection: true,
      },
    },
    {
      path: "key-value-pair/new",
      name: CREDENTIAL_ROUTE_NAMES.CREDENTIAL_GENERIC_SECRET_NEW,
      component: () => import("@/views/credential/generic-secret/generic-secret-new.vue"),
      meta: {
        pageTitle: "New credential",
        fullWidth: true,
        requestToLeave: true,
        backPageName: CREDENTIAL_ROUTE_NAMES.CREDENTIAL_INDEX,
        resourceType: ResourceType.Credentials,
        minPermittedActions: [Action.Create],
        requiresAuth: true,
        hideClusterSelection: true,
      },
    },
  ],
};
