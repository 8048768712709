<template>
  <section class="page column justify-between bg-white">
    <header class="row justify-between items-center q-mx-xl q-py-md q-px-sm">
      <runai-svg-icon name="logo-blue" size="50px" />
      <span v-if="errorNum" class="error text-h3">{{ errorNum }}</span>
    </header>
    <router-view class="flex-1 q-mx-xl" />
    <footer class="row justify-center items-center q-mx-xl q-py-lg">
      <q-icon name="fa-regular fa-copyright" color="black-54"></q-icon>
      <span class="q-ml-xs">{{ currentYear }} Shakti-Cloud Labs all rights reserved</span>
    </footer>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";
import { useAppStore } from "@/stores/app.store";

export default defineComponent({
  components: {
    RunaiSvgIcon,
  },
  data() {
    return {
      errorNum: this.$route.meta?.pageTitle,
      currentYear: new Date().getFullYear(),
      appStore: useAppStore(),
    };
  },
  created() {
    this.appStore.setAppLoaded(true);
    this.appStore.setPageLoading(false);
  },
});
</script>

<style lang="scss" scoped>
.page {
  min-height: 100vh;
}
header {
  border-bottom: 1px solid #e0e0e0;
}

.error {
  color: $black-12;
  font-weight: 700;
}

footer {
  border-top: 1px solid #e0e0e0;
  color: $black-54;
}
</style>
