<template>
  <section class="runai-page-filter">
    <runai-advanced-filter-summary :filters="filtersModel" @remove="removeFilter" @select="selectFilter" />
    <q-btn class="add-filter-btn" ref="add-filter-btn" flat no-caps label="Add Filter">
      <q-menu fit transition-show="scale" transition-hide="scale" transition-duration="500">
        <q-list>
          <q-item
            v-for="option in availableFilterOptions"
            :key="option.label"
            clickable
            v-close-popup
            class="cursor-pointer add-filter-item"
            @click="selectFilterToAdd(option)"
          >
            <q-item-section>{{ option.label }} </q-item-section>
          </q-item>
        </q-list>
      </q-menu>
      <component
        v-if="selectedFilter"
        @close="selectFilter(null)"
        @save="saveFilter"
        :filter="selectedFilter"
        :is="filterComponent(selectedFilter.name)"
      />
    </q-btn>
  </section>
</template>

<script lang="ts">
//vue
import { defineComponent, type PropType } from "@vue/runtime-core";

// models
import {
  EColumnFilterType,
  type IAdvancedFilterModel,
  type IAdvancedFilterOption,
  type IFilterOption,
} from "@/models/filter.model";

// services
import { filterService } from "@/services/filter.service/filter.service";

// utils
import { deepCopy } from "@/utils/common.util";

// cmps
import { RunaiPageFilterWrapper } from "./runai-page-filter-wrapper";
import RunaiPageFilterPopupWithSelect from "@/components/common/runai-page-filter/runai-page-filter-popup-with-select.vue";
import { RunaiFreeTextFilter } from "@/components/common/runai-advanced-filters/runai-free-text-filter";
import { RunaiEnumBasedFilter } from "@/components/common/runai-advanced-filters/runai-enum-based-filter";
import RunaiPageFilterPopup from "@/components/common/runai-page-filter/runai-page-filter-popup.vue";
import { RunaiAdvancedFilterSummary } from "@/components/common/runai-advanced-filters/runai-advanced-filter-summary";
import { filterUtil } from "@/utils/filter.util/filter.util";
import { RunaiDateFilter } from "@/components/common/runai-advanced-filters/runai-date-filter";
import { RunaiNumericFilter } from "@/components/common/runai-advanced-filters/runai-numeric-filter";

type FilterComponentType =
  | typeof RunaiFreeTextFilter
  | typeof RunaiEnumBasedFilter
  | typeof RunaiNumericFilter
  | typeof RunaiDateFilter;

export default defineComponent({
  components: {
    RunaiAdvancedFilterSummary,
    RunaiPageFilterWrapper,
    RunaiPageFilterPopup,
    RunaiPageFilterPopupWithSelect,
    RunaiFreeTextFilter,
    RunaiEnumBasedFilter,
  },
  emits: ["update-column-filters"],
  props: {
    initialFiltersModel: {
      type: Array as PropType<Array<IAdvancedFilterModel>>,
      required: true,
    },
    filterOptions: {
      type: Array as PropType<Array<IAdvancedFilterOption>>,
      required: true,
    },
  },
  data() {
    return {
      selectedFilter: null as IAdvancedFilterModel | null,
      optionsObj: new Map<string, IFilterOption>(),
      filtersModel: [] as Array<IAdvancedFilterModel>,
    };
  },
  created() {
    this.initFilterOptions();
    this.initFilterModel();
  },
  computed: {
    availableFilterOptions(): Array<IAdvancedFilterOption> {
      return filterService.getAvailableFilterOptions(
        this.filtersModel,
        this.filterOptions.filter((f) => f.type),
      );
    },
  },
  methods: {
    removeFilter(filterName: string): void {
      this.filtersModel = this.filtersModel.filter(
        (filter: IAdvancedFilterModel): boolean => filter.name !== filterName,
      );
      this.saveFilters();
    },
    selectFilterToAdd(filterOption: IAdvancedFilterOption): void {
      const defaultFilter = filterUtil.getAdvancedFilterModelByType(filterOption.type);
      this.selectFilter({ ...defaultFilter, ...filterOption } as IAdvancedFilterModel);
    },
    saveFilter(filter: IAdvancedFilterModel): void {
      const filterIndex = this.filtersModel.findIndex((f: IAdvancedFilterModel) => f.name === filter.name);
      if (filterIndex !== -1) {
        this.filtersModel.splice(filterIndex, 1, filter);
      } else {
        this.filtersModel.push(filter);
      }
      this.saveFilters();
    },
    saveFilters(): void {
      this.$emit("update-column-filters", this.filtersModel);
      this.selectFilter(null);
    },
    initFilterModel(): void {
      this.filtersModel = deepCopy(this.initialFiltersModel);
    },
    initFilterOptions(): void {
      this.filterOptions.reduce((acc: Map<string, IFilterOption>, item: IFilterOption) => {
        acc.set(item.name, item);
        return acc;
      }, this.optionsObj);
    },
    filterComponent(filterName: string): FilterComponentType {
      const currentFilter = this.filterOptions.find((option: IFilterOption) => option.name === filterName);

      switch (currentFilter?.type) {
        case EColumnFilterType.FreeText:
          return RunaiFreeTextFilter;
        case EColumnFilterType.EnumBased:
          return RunaiEnumBasedFilter;
        case EColumnFilterType.Numeric:
          return RunaiNumericFilter;
        case EColumnFilterType.Date:
          return RunaiDateFilter;
        default:
          throw new Error(`Unknown filter type: ${filterName}`);
      }
    },
    selectFilter(filter: IAdvancedFilterModel | null): void {
      this.selectedFilter = filter;
    },
  },
  watch: {
    filterOptions: {
      handler() {
        this.initFilterOptions();
      },
      deep: true,
    },
    initialFiltersModel: {
      handler(newVal) {
        this.filtersModel = newVal;
      },
    },
  },
});
</script>

<style lang="scss">
.runai-page-filter {
  .filter-value {
    font-weight: 500;
  }

  .add-filter-btn {
    color: $black-70;
  }
}
</style>
