import type { IAssignedResources } from "@/models/resource.model";
import type { INodePoolResources } from "./project.model";
import type { NodePoolQuotaStatus } from "@/swagger-models/backend-client";

export const DEFAULT_DEPARTMENT_NAME = "default";
export const DEFAULT_DEPARTMENT_DESERVED_GPUS = -1;
export interface IDepartmentProject {
  name: string;
  id: number;
  deserved_gpus: number;
}

export interface IDepartment {
  id?: number;
  name: string;
  tenantId?: number;
  deservedGpus?: number;
  maxAllowedGpus?: number | null;
  clusterUuid?: string;
  projectsDeservedGpus?: null | number;
  projects?: Array<IDepartmentProject>;
  resources: IAssignedResources;
  nodePoolsResources: INodePoolResources[];
  assignedResourcesId: number;
  createdAt: string;
  roles?: string[];
  allocatedCpu?: number;
  allocatedGpus?: number;
  allocatedMemory?: number;
  quotaStatuses?: Array<NodePoolQuotaStatus>;
  projectAggregatedNodePoolsResources: {
    nodePool: { id: string; name: string };
    gpu: { deserved: number };
    cpu: { deserved: number };
    memory: { deserved: number };
  }[];
}

export enum EDepartmentColumnName {
  DepartmentName = "department-name",
  NodePools = "node-pools",
  AssignedGpus = "assigned-gpus",
  ProjectsGpus = "projects-gpus",
  AccessRules = "accessRules",
  Projects = "projects",
  AssignedCpu = "assigned-cpu",
  AssignedMemory = "assigned-memory",
  AllocatedCpu = "allocated-cpu",
  AllocatedMemory = "allocated-memory",
  AllocatedGpus = "allocated-gpus",
  GpuUtilization = "gpu-utilization",
  CpuUtilization = "cpu-utilization",
  MemoryUtilization = "memory-utilization",
  CreatedAt = "created-at",
  Workloads = "workloads",
}
