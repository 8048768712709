<template>
  <runai-form-wrapper :form-state="formData" ref="elProjectEditCreateForm">
    <runai-expansion-wrapper>
      <project-scope-section
        @update-scope="updateProjectScope"
        :cluster-id="formData.clusterUuid || ''"
        :department-id="formData.departmentId"
      />
      <project-name-section
        v-model:project-name="formData.name"
        v-model:namespace="formData.namespace"
        @is-section-invalid="invalidSection.projectName = !$event"
      />
      <quota-management-section
        v-model:node-pools-resources="formData.nodePoolsResources"
        v-model:node-pools-priorities="formData.defaultNodePools"
        :loading="loading"
        :entity="EQuotaEntity.project"
        :department-id="departmentId"
        :cluster-id="formData.clusterUuid"
        :quota-statuses="quotaStatuses"
      />
      <scheduling-rules-section
        v-model:training-duration="formData.trainingJobMaxIdleDurationSecs"
        v-model:interactive-duration="formData.interactiveJobMaxIdleDurationSecs"
        v-model:interactive-preemptible-duration="formData.interactivePreemptibleJobMaxIdleDurationSecs"
        v-model:interactive-time-limit="formData.interactiveJobTimeLimitSecs"
        v-model:training-time-limit="formData.trainingJobTimeLimitSecs"
        v-model:node-affinity="formData.nodeAffinity"
        :training-time-limit-supported="isNewClusterVersion"
        @is-section-invalid="invalidSection.schedulingRules = !$event"
      />
      <project-form-footer-section
        :loading="isProjectSubmitting"
        :is-form-valid="isFormValid"
        @on-cancel="$emit('on-cancel')"
        @on-save="save"
      />
    </runai-expansion-wrapper>
  </runai-form-wrapper>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
// cmps
import { RunaiExpansionWrapper } from "@/components/common/runai-expansion-wrapper";
import { RunaiFormWrapper } from "@/components/common/runai-form-wrapper";
import { SchedulingRulesSection } from "@/components/project/project-edit-form/scheduling-rules-section/";
import { ProjectNameSection } from "@/components/project/project-edit-form/project-name-section";
import { QuotaManagementSection } from "@/components/project/project-edit-form/quota-management-section";
import { ProjectFormFooterSection } from "@/components/project/project-edit-form/project-form-footer-section";
// services
import { deepCopy } from "@/utils/common.util";
import { isNewerVersion } from "@/utils/version.util";
// stores
import { useSettingStore } from "@/stores/setting.store";
// models
import type { INodePoolResources, IProject, IProjectCreate } from "@/models/project.model";
import { useClusterStore } from "@/stores/cluster.store";
import { MIN_TRAIN_TIME_LIMIT_VERSION, TEST_ENV_VERSION } from "@/common/version.constant";
import { EQuotaEntity } from "@/models/resource.model";
import { departmentService } from "@/services/control-plane/department.service/department.service";
import type { INodePool } from "@/models/node-pool.model";
import { nodePoolService } from "@/services/control-plane/node-pool.service/node-pool.service";
import ProjectScopeSection from "@/components/project/project-edit-form/project-scope-section/project-scope-section.vue";
import type { NodePoolQuotaStatus } from "@/swagger-models/backend-client";
import { projectUtil } from "@/utils/project.util";
import { ErrorAlert } from "@/utils/error-alert.util";

interface ISectionValidation {
  departmentSelect: boolean;
  projectName: boolean;
  schedulingRules: boolean;
}
export default defineComponent({
  components: {
    ProjectScopeSection,
    SchedulingRulesSection,
    ProjectFormFooterSection,
    ProjectNameSection,
    QuotaManagementSection,
    RunaiFormWrapper,
    RunaiExpansionWrapper,
  },
  emits: ["on-save", "on-cancel"],
  props: {
    project: {
      type: Object as PropType<IProject | IProjectCreate>,
      required: true,
    },
    isProjectSubmitting: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  data() {
    return {
      formData: deepCopy(this.project) as IProject,
      settingStore: useSettingStore(),
      clusterStore: useClusterStore(),
      isFormValid: true as boolean,
      loading: false as boolean,
      invalidSection: {
        departmentSelect: false as boolean,
        projectName: false as boolean,
        schedulingRules: false as boolean,
      } as ISectionValidation,
    };
  },
  created() {
    if (!this.isDepartmentEnabled) {
      this.invalidSection.departmentSelect = true;
    }
  },
  computed: {
    quotaStatuses(): Array<NodePoolQuotaStatus> {
      return (this.project as IProject)?.status?.quotaStatuses || [];
    },
    EQuotaEntity(): typeof EQuotaEntity {
      return EQuotaEntity;
    },
    isDepartmentEnabled(): boolean {
      return this.settingStore.isDepartmentEnabled;
    },
    isCpuEnabled(): boolean {
      return this.settingStore.isCPUResourcesQuotaEnabled;
    },
    departmentId(): number | undefined {
      return this.formData.departmentId;
    },
    isNewClusterVersion(): boolean {
      const version = this.clusterStore.currentCluster.version;
      if (!version) return false;
      return isNewerVersion(version, MIN_TRAIN_TIME_LIMIT_VERSION) || version.includes(TEST_ENV_VERSION);
    },
  },
  methods: {
    async updateProjectScope({
      clusterId,
      departmentId,
    }: {
      clusterId: string;
      departmentId: number | null;
    }): Promise<void> {
      this.loading = true;
      try {
        if (this.formData.clusterUuid !== clusterId || this.formData.departmentId !== departmentId) {
          await this.updateProjectNodePools(clusterId);
        }
        this.formData.clusterUuid = clusterId;
        await this.updateDepartmentId(departmentId, clusterId);
      } catch (error: unknown) {
        this.handleUpdateScopeError(error, clusterId, departmentId);
      } finally {
        this.loading = false;
      }
    },
    handleUpdateScopeError(error: unknown, clusterId: string, departmentId: number | null): void {
      const errorAlert = new ErrorAlert({
        generalMessage: `Failed to update project scope - cluster: ${clusterId}, department: ${departmentId}`,
      });
      this.$q.notify(errorAlert.getNotification(error));
    },
    async updateDepartmentId(departmentId: number | null, clusterId: string): Promise<void> {
      if (departmentId === null) {
        //department ff is off so no department id in the scope, get the department default id by the cluster id
        this.formData.departmentId = await this.getDefaultDepartmentIdByClusterId(clusterId);
      } else {
        this.formData.departmentId = departmentId;
      }
    },
    async updateProjectNodePools(clusterId: string): Promise<void> {
      this.formData.resources = departmentService.getNodeResourcesModel(this.isCpuEnabled);
      this.formData.nodePoolsResources = await this.getClusterNodePools(clusterId);
    },
    async getDefaultDepartmentIdByClusterId(clusterId: string): Promise<number> {
      return await departmentService.getDefaultDepartmentIdByClusterId(clusterId);
    },
    async validate(): Promise<boolean> {
      return await (this.$refs.elProjectEditCreateForm as HTMLFormElement).validate();
    },
    async save(): Promise<void> {
      this.isFormValid = await this.validate();
      if (this.isFormValid) {
        this.$emit("on-save", this.formData);
      }
    },
    async getClusterNodePools(clusterId: string): Promise<INodePoolResources[]> {
      const nodePools: Array<INodePool> = await nodePoolService.getNodePools(clusterId);
      return projectUtil.mapNodePoolToNodePoolResources(nodePools, this.isCpuEnabled);
    },
  },
  watch: {
    formData: {
      handler() {
        this.isFormValid = true;
      },
      deep: true,
    },
  },
});
</script>
