<template>
  <button :disabled="readOnly" v-bind="$attrs" class="editable-button" :class="buttonClass" type="button">
    {{ editableText }}
    <q-menu anchor="bottom middle" self="top middle" @hide="resetValue" v-model="isOpen">
      <q-card :class="isDepartmentEnabled ? 'with-chart' : ''">
        <q-card-section class="row justify-between items-center q-py-sm card-wrapper">
          {{ $options.RESOURCE_LABEL.GPU }}
          <q-btn flat size="12px" icon="fa-regular fa-xmark" class="q-pa-xs" round v-close-popup></q-btn>
        </q-card-section>
        <q-card-section class="q-py-sm">
          <div class="row" :class="{ 'input-row': isDepartmentEnabled }">
            <div :class="{ 'col-9': isDepartmentEnabled }">
              <runai-numeric-input
                class="q-mr-md"
                :class="{ 'quota-input': isDepartmentEnabled }"
                aid="gpu-quota-input"
                ref="numeric-input"
                :autofocus="true"
                v-model.number="inputValue"
                :placeholder="0"
                input-class="wide-input"
                no-error-icon
                stack-label
                :read-only="readOnly"
              >
                <template v-if="isGpuExceedsQuota || isGpuUnderAllocatedNonPreemptible" #error>
                  <div v-text="alertText" class="quota-exceeds-alert" :class="textAlertClass"></div>
                </template>
              </runai-numeric-input>
            </div>
          </div>
          <template v-if="!hideSlider">
            <div class="row q-my-xl">
              <div class="col q-pr-xl">
                <quota-slider
                  v-model="inputValue"
                  :max="departmentDeservedGpu"
                  :inner-max="sliderInnerMax"
                  :inner-min="sliderInnerMin"
                  :is-warning-on="isGpuExceedsQuota || isGpuUnderAllocatedNonPreemptible"
                  :is-limited="
                    isLimitQuotaOverSubscriptionEnabled || inputValue === $options.DEFAULT_DEPARTMENT_DESERVED_GPUS
                  "
                  inner-max-marker-text="Department available quota"
                  :inner-min-marker-text="innerMinMarkerText"
                  :hide-max="isDepartmentEntity"
                />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="italic text-caption">
                  For more information, see the
                  <a :href="documentationLink" target="_blank">{{ documentationLinkText }}</a>
                </div>
              </div>
            </div>
          </template>
        </q-card-section>
        <q-card-section class="row justify-end q-py-sm">
          <q-btn
            aid="save-quota-btn"
            flat
            color="primary"
            label="Apply"
            v-close-popup="!isInputInvalid"
            @click="updateModelValue"
          />
        </q-card-section>
      </q-card>
    </q-menu>
  </button>
  <q-tooltip
    class="gpu-quota-tooltip"
    v-if="isAlertOn"
    max-width="350px"
    :offset="[180, 0]"
    :class="tooltipAlertClass"
    anchor="top right"
  >
    {{ alertText }}
  </q-tooltip>
</template>

<script lang="ts">
import { computed, defineComponent, inject, type PropType, ref, toRef } from "vue";

// cmps
import { RunaiNumericInput } from "@/components/common/runai-numeric-input";

// models
import { EQuotaEntity, EResourceLabel, EResourceType } from "@/models/resource.model";
//utils
import { resourceUtil } from "@/utils/resource.util";
//store
import { QuotaSlider } from "@/components/quota-management/quota-slider";
import {
  useQuotaInput,
  type useQuotaInputProperties,
} from "@/components/quota-management/quota-inputs/quota-input-composable/use-quota-input.composeable";
import { DEFAULT_DEPARTMENT_DESERVED_GPUS } from "@/models/department.model";

export default defineComponent({
  components: {
    QuotaSlider,
    RunaiNumericInput,
  },
  emits: ["update:model-value", "close"],
  inject: ["entity"],
  props: {
    modelValue: {
      type: Number as PropType<number>,
      required: true,
    },
    readOnly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    projectsDeservedGpu: {
      type: Number as PropType<number>,
      default: 0,
    },
    departmentDeservedGpu: {
      type: Number as PropType<number>,
      default: 0,
    },
    allocatedNonPreemptibleGpu: {
      type: Number as PropType<number>,
      default: 0,
    },
    isAlertOn: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    hideSlider: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  RESOURCE_LABEL: EResourceLabel,
  DEFAULT_DEPARTMENT_DESERVED_GPUS: DEFAULT_DEPARTMENT_DESERVED_GPUS,
  data() {
    return {
      inputValue: this.modelValue as number | null,
    };
  },
  setup(props) {
    const entity = ref(inject<EQuotaEntity>("entity"));
    if (!entity.value) {
      throw new Error("'entity' is not provided");
    }

    const {
      isProjectEntity,
      isOpen,
      isLimitQuotaOverSubscriptionEnabled,
      isDepartmentEnabled,
      documentationLink,
      documentationLinkText,
      innerMinMarkerText,
    }: useQuotaInputProperties = useQuotaInput(entity.value, toRef(props, "isAlertOn"));

    //initial value of department gpus is -1, we need to force the user to enter a value greater than -1
    const isErrorOn = computed(
      () => isLimitQuotaOverSubscriptionEnabled.value || props.modelValue === DEFAULT_DEPARTMENT_DESERVED_GPUS,
    );
    const buttonClass = computed(() => {
      if (isOpen.value) return "primary-border";
      if (!props.isAlertOn) return "";
      return isErrorOn.value ? "negative-border" : "amber-14-border";
    });
    const textAlertClass = computed(() => (isErrorOn.value ? "text-negative" : "text-amber-14"));
    const tooltipAlertClass = computed(() => (isErrorOn.value ? "bg-red-2" : "bg-orange-1"));
    return {
      isProjectEntity,
      isOpen,
      isLimitQuotaOverSubscriptionEnabled,
      isDepartmentEnabled,
      documentationLink,
      documentationLinkText,
      innerMinMarkerText,
      textAlertClass,
      tooltipAlertClass,
      buttonClass,
    };
  },
  computed: {
    sliderInnerMin(): number {
      return this.isDepartmentEntity ? this.projectsDeservedGpu : this.allocatedNonPreemptibleGpu;
    },
    sliderInnerMax(): number | undefined {
      return this.isDepartmentEntity ? undefined : this.departmentAvailableQuota;
    },
    isDepartmentEntity(): boolean {
      return this.entity === EQuotaEntity.department;
    },
    EQuotaEntity(): typeof EQuotaEntity {
      return EQuotaEntity;
    },
    innerProjectsDeservedGpu(): number {
      return this.projectsDeservedGpu - this.modelValue;
    },
    departmentAvailableQuota(): number {
      return this.departmentDeservedGpu - this.projectsDeservedGpu + this.modelValue;
    },
    projectsDeservedGpuWithCurrentInput(): number {
      if (this.inputValue !== null) {
        return this.innerProjectsDeservedGpu + this.inputValue;
      }
      return this.innerProjectsDeservedGpu;
    },
    isInputInvalid(): boolean {
      return (
        this.isLimitQuotaOverSubscriptionEnabled && (this.isGpuExceedsQuota || this.isGpuUnderAllocatedNonPreemptible)
      );
    },
    isGpuExceedsQuota(): boolean {
      if (!this.isDepartmentEnabled) return false;
      if (this.isDepartmentEntity && this.inputValue !== null) {
        return this.inputValue < this.projectsDeservedGpu;
      }

      return resourceUtil.isResourceExceedsQuota(this.projectsDeservedGpuWithCurrentInput, this.departmentDeservedGpu);
    },
    isGpuUnderAllocatedNonPreemptible(): boolean {
      if (!this.isDepartmentEnabled) return false;
      if (this.inputValue === null) return false;
      return resourceUtil.isResourceUnderAllocatedNonPreemptible(this.inputValue, this.allocatedNonPreemptibleGpu);
    },
    editableText() {
      return resourceUtil.getResourceDisplayValue(this.modelValue, EResourceType.GPU);
    },
    alertText(): string {
      if (this.isDepartmentEntity) {
        return `The department's quota is less than the quota assigned to its subordinate projects. To remain within the limit, enter a number above ${this.projectsDeservedGpu}.`;
      }
      if (this.isGpuExceedsQuota) {
        const remainQuota = this.departmentDeservedGpu - (this.projectsDeservedGpu - this.modelValue);
        return remainQuota < 0
          ? "The project's quota exceeds the available department quota."
          : `The project's quota exceeds the available department quota. To remain within the quota, enter a number up to ${remainQuota}.`;
      }
      if (this.isGpuUnderAllocatedNonPreemptible) {
        return `The project's quota is less than the usage by non-preemptive workloads. To remain above the usage, enter a number above ${this.allocatedNonPreemptibleGpu}`;
      }
      return "";
    },
  },
  methods: {
    resetValue(): void {
      this.inputValue = this.modelValue;
    },
    updateModelValue(): void {
      if (this.isInputInvalid) return;
      if (this.inputValue === null) {
        this.$emit("update:model-value", 0);
      } else {
        this.$emit("update:model-value", this.inputValue);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.card-wrapper {
  font-weight: 500 !important;
  font-size: 14px;
}
.quota-input {
  max-width: 231px;
  margin-bottom: 30px;
}
.with-chart {
  min-height: 400px;
  width: 400px;
}
.input-row {
  height: 110px;
}
.editable-button {
  cursor: pointer;
  width: 100px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid $black-54;
  text-align: center;
  box-shadow: 0 1px 6px $black-15;
  color: $black-70;
  background-color: white;
  font-weight: 500;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 1px 6px 2px $black-25;
  }
}
.primary-border {
  border: 2px solid $primary;
}
.amber-14-border {
  border: 2px solid $amber-14;
}
.negative-border {
  border: 2px solid $negative;
}
.quota-exceeds-alert {
  margin: 5px 0;
  font-size: 12px;
  max-width: 231px;
  top: 65px;
  position: absolute;
  line-height: 12px;
}
</style>
