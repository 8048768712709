<template>
  <q-card class="model-spec-card">
    <div class="background-cover">
      <img v-if="thumbnail" class="image-cover" :src="thumbnail" />
      <runai-svg-icon v-else name="model-asset" size="87px" />
    </div>

    <q-card-section class="model-spec-details">
      <div class="model-spec-title">{{ modelSpec.spec.title }}</div>
      <div class="model-spec-description">
        {{ description }}
        <span v-if="shouldShowDescriptionInTooltip" class="text-bold q-ml-xs">
          Read more
          <q-tooltip max-width="350px">{{ fullDescription }}</q-tooltip>
        </span>
      </div>
    </q-card-section>

    <q-card-actions align="between" class="model-spec-actions">
      <span>
        <q-btn
          round
          flat
          size="10px"
          icon="fa-regular fa-arrow-up-right-from-square"
          :href="huggingFaceHref"
          target="_blank"
          class="no-underline"
        ></q-btn>
        <q-tooltip>View on Hugging Face</q-tooltip>
      </span>
      <span>
        <q-btn color="primary" flat :disable="disableDeploy" :aid="aidDeployBtnName" @click="onDeploy">Deploy</q-btn>
        <q-tooltip v-if="disableDeploy && disableDeployReason">
          {{ disableDeployReason }}
        </q-tooltip>
      </span>
    </q-card-actions>
  </q-card>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// utils
import { stringUtil } from "@/utils/string.util/string.util";

// types
import type { ModelAsset } from "@/swagger-models/assets-service-client";

import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";

import { INFERENCE_ROUTE_NAMES } from "@/router/inference.routes/inference.routes.names";

const MAX_CHARACTERS_IN_DESCRIPTION = 215;

export default defineComponent({
  components: {
    RunaiSvgIcon,
  },
  props: {
    modelSpec: {
      type: Object as PropType<ModelAsset>,
      required: true,
    },
    disableDeploy: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    disableDeployReason: {
      type: String as PropType<string>,
      required: false,
    },
  },
  computed: {
    aidDeployBtnName(): string {
      return `${stringUtil.slug(this.modelSpec.meta.name)}-deploy-btn`;
    },
    thumbnail(): string {
      return this.modelSpec.spec.thumbnail || ""; // TODO: product should decide it this prop is option or no
    },
    huggingFaceHref(): string {
      return `https://huggingface.co/${this.modelSpec.spec.huggingfaceId}`;
    },
    fullDescription(): string {
      return this.modelSpec.spec.description || "";
    },
    description(): string {
      if (!this.shouldShowDescriptionInTooltip) return this.fullDescription;

      return this.fullDescription.substring(0, MAX_CHARACTERS_IN_DESCRIPTION) + "...";
    },
    shouldShowDescriptionInTooltip(): boolean {
      return this.fullDescription.length > MAX_CHARACTERS_IN_DESCRIPTION;
    },
  },
  methods: {
    onDeploy(): void {
      this.$router.push({ name: INFERENCE_ROUTE_NAMES.INFERENCE_NEW, query: { modelId: this.modelSpec.meta.id } });
    },
  },
});
</script>
<style lang="scss" scoped>
.model-spec-card {
  width: 300px;
  border-radius: 0;
  height: 340px;
  display: flex;
  flex-direction: column;

  .background-cover {
    height: 141px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $body-background-color;
  }
  .image-cover {
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  .model-spec-details {
    flex: 1;
    padding-bottom: 0;

    .model-spec-title {
      font-size: 17px;
      font-weight: 700;
    }

    .model-spec-description {
      margin-top: 11px;
      font-size: 12px;
    }
  }

  .model-spec-actions {
    padding-top: 0px;
  }
}
</style>
