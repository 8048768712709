<template>
  <section class="model-spec-index">
    <div class="q-pa-md row items-start q-gutter-md">
      <template v-if="loading">
        <model-spec-card-skeleton v-for="index in 3" :key="index" />
      </template>
      <template v-else>
        <model-spec-card
          v-for="model in modelAssets"
          :key="model.meta.id"
          :model-spec="model"
          :disable-deploy="shouldDisableDeployBtn"
          :disable-deploy-reason="disableDeployBtnReason"
        />
      </template>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// Stores
import { useAppStore } from "@/stores/app.store";
import { useProjectStore } from "@/stores/project.store";
import { useModelSpecStore } from "@/stores/model-spec.store";
import { usePermissionStore } from "@/stores/permissions.store";

// cmps
import { ModelSpecCard, ModelSpecCardSkeleton } from "@/components/model-spec/model-spec-card";

// models
import type { ModelAsset } from "@/swagger-models/assets-service-client";
import { Action, ResourceType } from "@/swagger-models/authorization-client";

// utils
import { getEmptyArray } from "@/utils/common.util";
import { alertUtil } from "@/utils/alert.util";
import { useClusterStore } from "@/stores/cluster.store";
import { useSettingStore } from "@/stores/setting.store";

export default defineComponent({
  components: {
    ModelSpecCardSkeleton,
    ModelSpecCard,
  },
  data() {
    return {
      clusterStore: useClusterStore(),
      appStore: useAppStore(),
      settingStore: useSettingStore(),
      modelSpecStore: useModelSpecStore(),
      projectStore: useProjectStore(),
      permissionStore: usePermissionStore(),
      loading: true as boolean,
      skelatonCards: getEmptyArray(3),
    };
  },
  created() {
    this.appStore.setPageLoading(false);
    this.loadModelsData();
  },
  computed: {
    modelAssets(): Array<ModelAsset> {
      if (!this.allowDeployingGPT2ModelEnabled) {
        return this.modelSpecStore.modelAssets.filter((model) => {
          return model.spec.license == "";
        });
      }
      return this.modelSpecStore.modelAssets;
    },
    allowDeployingGPT2ModelEnabled(): boolean {
      return this.settingStore.isAllowDeployingGPT2ModelEnabled;
    },
    isKnativeInstalled(): boolean {
      return this.clusterStore.isKnativeInstalledInActiveCluster;
    },
    shouldDisableDeployBtn(): boolean {
      return !this.projectStore.hasProjects || !this.canCreateInference || !this.isKnativeInstalled;
    },
    canCreateInference(): boolean {
      return this.permissionStore.hasPermission(ResourceType.Inferences, Action.Create);
    },
    canCreateProject(): boolean {
      return this.permissionStore.hasPermission(ResourceType.Project, Action.Create);
    },
    disableDeployBtnReason(): string {
      if (!this.isKnativeInstalled) {
        return "This model can't be deployed because Knative is not installed on the selected cluster. Contact your administrator.";
      } else if (!this.projectStore.hasProjects) {
        if (this.canCreateProject) {
          return "To deploy a model, you need to be assigned to a project. First create at least one project.";
        } else {
          return "To deploy a model, you need to be assigned to a project. Contact your administrator to be assigned.";
        }
      } else if (!this.canCreateInference) {
        return "You don't have permissions to deploy. Contact your admin";
      }
      return "";
    },
  },
  methods: {
    async loadModelsData(): Promise<void> {
      try {
        this.loading = true;
        await Promise.all([
          this.modelSpecStore.loadModels(),
          this.projectStore.loadProjects({
            withAccessRules: false,
            withNamespace: true,
            withMetrics: false,
            clusterId: this.clusterStore.currentClusterId,
          }),
        ]);
      } catch (error: unknown) {
        this.$q.notify(alertUtil.getError("Failed to load data"));
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
