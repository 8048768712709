import {
  EColumnFilterType,
  EDateFilterOperator,
  EFilterOperator,
  type IAdvancedFilterModel,
} from "@/models/filter.model";

export const filterUtil = { getAdvancedFilterModelByType };

function getAdvancedFilterModelByType(filterType: EColumnFilterType): Partial<IAdvancedFilterModel> {
  switch (filterType) {
    case EColumnFilterType.FreeText:
      return {
        term: "",
        value: EFilterOperator.Contains,
      };

    case EColumnFilterType.EnumBased:
      return {
        selectOptions: [],
        selectedValues: [],
      };

    case EColumnFilterType.Date:
      return {
        value: EDateFilterOperator.Before,
        date: "",
      };

    case EColumnFilterType.Numeric:
      return {
        minValue: 0,
        maxValue: 0,
        operator: EFilterOperator.GreaterThanOrEqual,
      };

    default:
      throw new Error(`Unknown filter type: ${filterType}`);
  }
}
