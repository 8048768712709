import { ResourceType, Action } from "@/swagger-models/authorization-client";

import JobIndex from "@/views/job/job-index.vue";
import JobNew from "@/views/job/job-new.vue";
import JobHistory from "@/views/job/job-history.vue";
import { JOB_ROUTE_NAMES } from "./job.routes.names";
import type { RunaiRouteRecord } from "vue-router";
import { EBadgeLabel } from "@/models/setting.model";

export const jobRoutes: RunaiRouteRecord = {
  path: "/jobs",
  component: () => import("@/views/job/job-app.vue"),
  children: [
    {
      path: "",
      name: JOB_ROUTE_NAMES.JOB_INDEX,
      component: JobIndex,
      meta: {
        pageTitle: "Jobs",
        resourceType: ResourceType.Jobs,
        minPermittedActions: [Action.Read],
        requiresAuth: true,
        hideVersion: true,
        stageBadge: EBadgeLabel.Legacy,
      },
    },
    {
      path: "submit",
      name: JOB_ROUTE_NAMES.JOB_NEW,
      component: JobNew,
      meta: {
        pageTitle: "Jobs (legacy)",
        resourceType: ResourceType.Jobs,
        minPermittedActions: [Action.Create],
        requiresAuth: true,
        hideVersion: true,
      },
    },
    {
      path: "history",
      name: JOB_ROUTE_NAMES.JOB_HISTORY,
      component: JobHistory,
      meta: {
        pageTitle: "Jobs (legacy)",
        resourceType: ResourceType.Jobs,
        minPermittedActions: [Action.Read],
        requiresAuth: true,
        hideVersion: true,
      },
    },
  ],
};
