import { defineStore } from "pinia";

import type { IFilterBy } from "@/models/filter.model";
import type { INode } from "@/models/node.model";
import type { Nodes, Node } from "@/swagger-models/cluster-service-client";
import type { ITableColumn } from "@/models/table.model";
import { nodeService } from "@/services/control-plane/node.service/node.service";
import { useClusterStore } from "./cluster.store";
import { filterService } from "@/services/filter.service/filter.service";
import { allNodesColumns, nodeAdvancedMetricsColumns } from "@/table-models/node.table-model";
import { isNewerVersion } from "@/utils/version.util";
import { MIN_NODE_WORKLOAD_METRICS_VERSION } from "@/common/version.constant";
import { useSettingStore } from "./setting.store";
import { HttpErrorResponse } from "@/models/http-response.model";
import { nodeUtil } from "@/utils/node.util";
import { usePermissionStore } from "./permissions.store";
import { Action, ResourceType } from "@/swagger-models/authorization-client";

export const useNodeStore = defineStore("Node", {
  state: () => ({
    nodes: [] as Array<INode>,
    selectedNode: null as INode | null,
    nodesWithoutMetric: [] as Node[],
    clusterStore: useClusterStore(),
  }),
  getters: {
    nodeList(): Array<INode> {
      return this.nodes;
    },
    totalClusterGpu(): number {
      return this.nodes.reduce((sum: number, node: INode) => sum + parseFloat(node.totalGpus || "0"), 0);
    },
    totalClusterCpuCores(): number {
      return this.nodes.reduce((sum: number, node: INode) => sum + parseFloat(node.totalCpus), 0);
    },
    totalClusterCpuMemory(): number {
      return this.nodes.reduce((sum: number, node: INode) => sum + parseFloat(node.totalCpuMemory), 0);
    },
  },
  actions: {
    async loadNodes(filterBy: IFilterBy): Promise<void> {
      if (this.clusterStore.isClusterDisconnected) {
        this.nodes = [];
        return;
      }

      if (this.nodes.length === 0 && this.clusterStore.isVersionSupportClusterServiceNodes) {
        //first load, get backend api data
        await this.getNodesFromClusterService();
        this.enrichNodesFromCluster([], filterBy);
      }

      await this.getNodesWithMetric(filterBy);
    },
    enrichNodesFromCluster(nodes: INode[], filterBy: IFilterBy): void {
      const enrichedNodes = nodeUtil.enrichNodesWithInfoFromCluster(nodes, this.nodesWithoutMetric);
      this.nodes = filterService.filterListByTableFilters(enrichedNodes, filterBy, allNodesColumns);
    },
    async getNodesFromClusterService(): Promise<void> {
      try {
        const response: Nodes = await nodeService.getNodesFromClusterService(this.clusterStore.currentClusterId);
        this.nodesWithoutMetric = response.nodes;
      } catch (error: unknown) {
        if (error instanceof HttpErrorResponse) {
          console.error(error.message);
        }
        console.log("Failed to load nodes from cluster service", error);
      }
    },
    async getNodesWithMetric(filterBy: IFilterBy): Promise<void> {
      const clusterVersion = this.clusterStore.currentClusterVersion;
      const clusterId = this.clusterStore.currentClusterId;
      const useNewWorkloadMetrics = isNewerVersion(clusterVersion, MIN_NODE_WORKLOAD_METRICS_VERSION);
      const isAdvancedSettingsEnabled: boolean = useSettingStore().isAdvancedGpuMetricsEnabled;
      if (this.clusterStore.isVersionSupportClusterServiceNodes) {
        await this.getNodesFromClusterService();
      }

      let nodes = await nodeService.getNodes(
        clusterVersion,
        clusterId,
        useNewWorkloadMetrics,
        filterBy,
        isAdvancedSettingsEnabled,
      );

      nodes = filterService.filterListByTableFilters(nodes, filterBy, allNodesColumns);

      if (this.clusterStore.isVersionSupportClusterServiceNodes) {
        this.enrichNodesFromCluster(nodes, filterBy);
      } else {
        this.nodes = nodes;
      }
    },
    getFilteredColumns(isOnlyDefaultNodePools: boolean, advancedMetricsEnabled = false): Array<ITableColumn> {
      const advancedColumns = new Set(nodeAdvancedMetricsColumns.map((col) => col.name));
      return allNodesColumns.filter((column) => {
        if (!advancedMetricsEnabled && advancedColumns.has(column.name)) return false;
        if (isOnlyDefaultNodePools && column.name === "node-pool") return false;
        return !(!usePermissionStore().hasPermission(ResourceType.Jobs, Action.Read) && column.name === "workloads");
      });
    },
  },
});
