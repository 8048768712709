<template>
  <runai-expansion-item label="Cluster" default-opened>
    <template #subheader>
      {{ selectedScope?.path || "none" }}
    </template>
    <scope-input
      :readonly="!isNewDepartment || readonly"
      :selected-scope="selectedScope"
      tick-strategy="leaf"
      @update-selected="selectedScope = $event"
      :hidden-scope-types="hiddenScopeTypes"
      :clearable="false"
      title="Clusters"
      placeholder-search="Search cluster"
    />
  </runai-expansion-item>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import { ScopeInput } from "@/components/rbac/common/scope-input";
import type { IOrgTreeNodeId } from "@/models/org-tree.model";
import { ScopeType } from "@/swagger-models/authorization-client";
import { orgTreeService } from "@/services/control-plane/rbac/org-tree.service/org-tree.service";
import { useAuthStore } from "@/stores/auth.store";
import { useClusterStore } from "@/stores/cluster.store";
import { DEPARTMENT_ROUTE_NAMES } from "@/router/department.routes/department.routes.names";

export default defineComponent({
  name: "department-scope-section",
  components: { ScopeInput, RunaiExpansionItem },
  emits: ["update:cluster-id"],
  props: {
    clusterId: {
      type: String as PropType<string>,
      required: true,
    },
    readonly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      selectedScope: { id: "", type: ScopeType.Cluster } as IOrgTreeNodeId,
      clusterStore: useClusterStore(),
      hiddenScopeTypes: {
        [ScopeType.System]: false,
        [ScopeType.Tenant]: false,
        [ScopeType.Cluster]: false,
        [ScopeType.Department]: true,
        [ScopeType.Project]: true,
      } as Record<ScopeType, boolean>,
    };
  },
  created() {
    this.initSelectedScope();
  },
  computed: {
    isNewDepartment(): boolean {
      return this.$route.name === DEPARTMENT_ROUTE_NAMES.DEPARTMENT_NEW;
    },
  },
  methods: {
    initSelectedScope(): void {
      const selectedScope = orgTreeService.getOrgTreeNodeId(
        useAuthStore().orgUnitList,
        this.clusterId,
        ScopeType.Cluster,
      );
      if (selectedScope !== null) {
        this.selectedScope = selectedScope;
      }
    },
  },
  watch: {
    selectedScope: {
      handler(): void {
        this.$emit("update:cluster-id", this.selectedScope?.id);
      },
    },
  },
});
</script>

<style scoped lang="scss"></style>
