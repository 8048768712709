<template>
  <runai-form-wrapper class="department-edit-form" :form-state="editDepartment" ref="departmentForm">
    <runai-expansion-wrapper>
      <department-scope-section :cluster-id="editDepartment.clusterUuid || ''" @update:cluster-id="updateClusterId" />
      <department-name-section
        :read-only="readOnly"
        v-model:department-name="editDepartment.name"
        :cluster-id="editDepartment.clusterUuid || ''"
        :department-id="editDepartment.id"
        @is-section-invalid="invalidSection.departmentName = !$event"
      />
      <department-quota-management-section
        v-model:node-pools-resources="editDepartment.nodePoolsResources"
        :quota-statuses="quotaStatuses"
        :department="editDepartment"
        :read-only="readOnly"
        :loading="loading"
        @is-section-invalid="invalidSection.quotaManagement = !$event"
      />
      <department-form-footer-section
        :read-only="readOnly"
        :loading="submitting"
        :is-form-valid="isFormValid"
        @cancel="$emit('cancel')"
        @save="save"
      />
    </runai-expansion-wrapper>
  </runai-form-wrapper>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// model
import type { IDepartment } from "@/models/department.model";

// cmps
import { RunaiExpansionWrapper } from "@/components/common/runai-expansion-wrapper";
import { RunaiFormWrapper } from "@/components/common/runai-form-wrapper";
import { DepartmentNameSection } from "@/components/department/department-edit-form/department-name-section/";
// util
import { deepCopy } from "@/utils/common.util";
import DepartmentQuotaManagementSection from "@/components/department/department-edit-form/department-quota-management-section/department-quota-management-section.vue";
import DepartmentFormFooterSection from "@/components/department/department-edit-form/department-form-footer-section/department-form-footer-section.vue";
import type { INodePoolResources } from "@/models/project.model";
import type { INodePool } from "@/models/node-pool.model";
import { nodePoolService } from "@/services/control-plane/node-pool.service/node-pool.service";
import { departmentService } from "@/services/control-plane/department.service/department.service";
import { useSettingStore } from "@/stores/setting.store";
import DepartmentScopeSection from "@/components/department/department-edit-form/department-scope-section/department-scope-section.vue";
import type { NodePoolQuotaStatus } from "@/swagger-models/backend-client";

interface ISectionValidation {
  departmentName: boolean;
  quotaManagement: boolean;
}
export default defineComponent({
  components: {
    DepartmentScopeSection,
    DepartmentFormFooterSection,
    DepartmentQuotaManagementSection,
    DepartmentNameSection,
    RunaiExpansionWrapper,
    RunaiFormWrapper,
  },
  emits: ["save", "cancel"],
  props: {
    department: {
      type: Object as PropType<IDepartment>,
      required: true,
    },
    readOnly: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    submitting: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  data() {
    return {
      editDepartment: deepCopy(this.department) as IDepartment,
      departmentForm: null as HTMLFormElement | null,
      invalidSection: {
        departmentName: false as boolean,
        quotaManagement: false as boolean,
      } as ISectionValidation,
      isFormValid: true as boolean,
      loading: false as boolean,
    };
  },
  computed: {
    isCpuEnabled(): boolean {
      return useSettingStore().isCPUResourcesQuotaEnabled;
    },
    quotaStatuses(): Array<NodePoolQuotaStatus> {
      return this.department?.quotaStatuses || [];
    },
  },
  methods: {
    async updateClusterId(clusterId: string): Promise<void> {
      if (this.editDepartment.clusterUuid === clusterId) {
        return;
      }
      try {
        this.loading = true;
        this.editDepartment.resources = departmentService.getNodeResourcesModel(this.isCpuEnabled);
        this.editDepartment.nodePoolsResources = await this.getClusterNodePools(clusterId);
      } catch (e) {
        console.error("failed to get cluster node pools", e);
      } finally {
        this.loading = false;
      }

      this.editDepartment.clusterUuid = clusterId;
    },
    async save(): Promise<void> {
      const isValidationSuccess: boolean = await this.validate();
      this.isFormValid = isValidationSuccess;
      if (isValidationSuccess) {
        this.$emit("save", this.editDepartment);
      }
    },
    async validate(): Promise<boolean> {
      const isAllSectionsValid: boolean = Object.values(this.invalidSection).every(
        (isSectionValid: boolean) => isSectionValid,
      );
      const isFormValid: boolean = await (this.$refs.departmentForm as HTMLFormElement).validate();
      return isAllSectionsValid && isFormValid;
    },
    async getClusterNodePools(clusterId: string): Promise<INodePoolResources[]> {
      const nodePools: Array<INodePool> = await nodePoolService.getNodePools(clusterId);
      return nodePools.map((np: INodePool) => {
        return {
          nodePool: {
            id: np.id,
            name: np.name,
          },
          ...departmentService.getNodeResourcesModel(this.isCpuEnabled),
        };
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.department-edit-form {
  .create-department-form {
    .number-input {
      width: 220px;
    }
  }
}
</style>
