<template>
  <runai-expansion-item class="user-settings-container" label="User details" :subheader="userEmail" default-opened>
    <div class="row items-center q-mb-md">
      <div class="col-2">
        <div>
          <q-icon class="avatar" size="80px">
            <span class="icon-letter">{{ shortName }}</span>
          </q-icon>
        </div>
      </div>
      <div class="col-10">
        <div class="row text-subtitle1 q-pb-md">Email</div>
        <div class="row">{{ userEmail }}</div>
      </div>
    </div>
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import { useAuthStore } from "@/stores/auth.store";

export default defineComponent({
  name: "user-profile-section",
  components: {
    RunaiExpansionItem,
  },
  data() {
    return {
      authStore: useAuthStore(),
    };
  },
  computed: {
    userEmail(): string {
      return this.authStore.currentUser.email || "";
    },
    shortName(): string {
      return this.userEmail?.substring(0, 1).toUpperCase() || "";
    },
  },
});
</script>
<style lang="scss" scoped>
.user-settings-container {
  margin: auto;
  width: 800px;
  .icon-letter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    color: $white;
  }
  .avatar {
    background-color: $black-30;
    border-radius: 50%;
    font-style: normal;
  }
}
</style>
