import type { IStatusColOptions } from "@/models/table.model";
import type { IAssignedResources } from "@/models/resource.model";
import { DEFAULT_RESOURCES_VALUE, EQuotaEntity } from "@/models/resource.model";
import type { NodePoolQuotaStatus } from "@/swagger-models/backend-client";
import type { Scope } from "@/swagger-models/assets-service-client";

import type { ISelectOption } from "@/models/global.model";

export enum EWorkloadNodeAffinity {
  Train = "train",
  Interactive = "interactive",
}

export enum EAffinityType {
  NoLimit = "no_limit",
  OnlySelected = "only_selected",
}

export enum IConditionStatus {
  True = "True",
  False = "False",
}

export enum EProjectModalEntity {
  Department = "Department",
}

//project status
export enum EProjectPhase {
  Creating = "Creating",
  Updating = "Updating",
  Deleting = "Deleting",
  Ready = "Ready",
  NotReady = "NotReady",
}

export const NODE_AFFINITY_DEFAULT_VALUE: INodeAffinity = {
  train: {
    affinityType: EAffinityType.NoLimit,
    selectedTypes: [],
  },
  interactive: {
    affinityType: EAffinityType.NoLimit,
    selectedTypes: [],
  },
};

export const NEW_PROJECT_DEFAULT_FIELDS = {
  name: "",
  namespace: "",
  defaultNodePools: [],
  departmentId: 1,
  resources: { ...DEFAULT_RESOURCES_VALUE },
  nodeAffinity: { ...NODE_AFFINITY_DEFAULT_VALUE },
  permissions: {
    users: [],
    groups: [],
    applications: [],
  } as IProjectPermissions,
};

export const ProjectPhaseMap: Record<EProjectPhase, IStatusColOptions> = {
  [EProjectPhase.Creating]: {
    status: "Creating...",
    displayAnimation: true,
    filterKey: "status",
  },
  [EProjectPhase.Updating]: {
    status: "Updating...",
    displayAnimation: true,
    filterKey: "status",
  },
  [EProjectPhase.Deleting]: {
    status: "Deleting...",
    displayAnimation: true,
    filterKey: "status",
  },
  [EProjectPhase.Ready]: {
    status: "Ready",
    color: "success",
    displayAnimation: false,
    filterKey: "status",
  },
  [EProjectPhase.NotReady]: {
    status: "Not Ready",
    color: "negative",
    displayAnimation: false,
    filterKey: "status",
  },
};

export const ProjectStatusMessageMap: Record<string, string> = {
  NamespaceGetFailed:
    "A namespace couldn't be gotten for the project (NamespaceGetFailed). Try creating the project again or contact Run:ai support.",
  NamespaceNotFound:
    "A namespace couldn't be found for the project (NamespaceNotFound). Make sure the namespace exists or contact Run:ai support.",
  NamespaceLabelMissing:
    "The 'runai/queue' label is missing from the namespace (NamespaceLabelMissing). Add the label or contact Run:ai support.",
  NamespaceUpdateFailed:
    "The namespace couldn't be updated for the project (NamespaceUpdateFailed). Try creating the project again or contact Run:ai support.",
  NamespaceCreateFailed:
    "The namespace couldn't be created for the project (NamespaceCreateFailed). Try creating the project again or contact Run:ai support.",
  NamespaceHandlerFailed: "The project couldn't be created (NamespaceHandlerFailed). Contact Run:ai support.",
  LimitRangeHandlerFailed: "The project couldn't be created (LimitRangeHandlerFailed). Contact Run:ai support.",
  RoleBindingsHandlerFailed: "The project couldn't be created (RoleBindingsHandlerFailed). Contact Run:ai support.",
  ServiceMonitorHandlerFailed: "The project couldn't be created (ServiceMonitorHandlerFailed). Contact Run:ai support.",
  SecretsHandlerFailed: "The project couldn't be created (SecretsHandlerFailed). Contact Run:ai support.",
  PvcsHandlerFailed: "The project couldn't be created (PvcsHandlerFailed). Contact Run:ai support.",
};
export interface ISelectedNodeAffinity {
  id: number;
  name: string;
}
export interface INodeAffinityOption {
  affinityType: string;
  selectedTypes: Array<ISelectedNodeAffinity>;
}
export interface ILoadProjectsOptions {
  clusterId: string;
  withMetrics?: boolean;
  withAccessRules?: boolean;
  withNamespace?: boolean;
}
export interface INodeAffinitySelectOption {
  label: string;
  value: string;
  loading: boolean;
  selectedTypes: ISelectOption[];
}

export interface INodeAffinity {
  train: INodeAffinityOption;
  interactive: INodeAffinityOption;
}
export interface IProjectPermissions {
  users: Array<string>;
  groups: Array<string>;
  applications: Array<string>;
}

export interface IProjectStatusCondition {
  type: IConditionStatus;
  status: string;
  reason?: string;
  message?: string;
}
export interface IProjectStatus {
  phase?: string;
  message?: string;
  namespace?: string;
  conditions?: IProjectStatusCondition[];
  quotaStatuses?: Array<NodePoolQuotaStatus>;
}

export interface IProjectsModalOptions {
  departmentId?: number;
  entityName: string;
  entityType: EProjectModalEntity;
}
export interface IProject {
  id: number;
  clusterUuid?: string;
  createdAt: string;
  deservedGpus?: number;
  maxAllowedGpus?: number;
  name: string;
  tenantId?: number;
  status?: IProjectStatus;
  departmentId: number;
  interactiveJobTimeLimitSecs?: null | number;
  interactiveJobMaxIdleDurationSecs?: null | number;
  interactivePreemptibleJobMaxIdleDurationSecs?: null | number;
  trainingJobTimeLimitSecs?: null | number;
  trainingJobMaxIdleDurationSecs?: null | number;
  swapEnabled: boolean;
  gpuOverQuotaWeight: number;
  nodeAffinity: INodeAffinity;
  departmentName: string;
  permissions: IProjectPermissions;
  resources: IAssignedResources;
  nodePoolsResources: INodePoolResources[];
  defaultNodePools?: string[];
  namespace?: string;
  roles?: string[];
  allocatedCpu?: number;
  allocatedGpus?: number;
  allocatedMemory?: number;
  phase: EProjectPhase | null;
}

export interface IProjectCreate {
  clusterUuid: string;
  name: string;
  departmentId?: number;
  deservedGpus?: number;
  maxAllowedGpus?: number;
  gpuOverQuotaWeight?: number;
  swapEnabled?: boolean;
  defaultNodePools?: Array<string>;

  interactiveJobTimeLimitSecs?: number;

  interactiveJobMaxIdleDurationSecs?: number;

  interactivePreemptibleJobMaxIdleDurationSecs?: number;

  trainingJobMaxIdleDurationSecs?: number;

  nodeAffinity: INodeAffinity;

  nodePoolsResources: INodePoolResources[];

  namespace?: string;

  resources: IAssignedResources;
}

export interface IProjectResources {
  id: number;
  tenantId: number;
  clusterUuid: string;
  name: string;
  deservedGpus: number;
  allocatedGpus: number;
  createdAt?: string;
}

export interface INodePoolResourceDetails {
  id: number;
  name: string;
}

export interface INodePoolResources {
  nodePool: INodePoolResourceDetails;
  gpu: IResourceQuota;
  cpu: IResourceQuota;
  memory: IResourceQuota;
}
export interface INodePoolsResourcesRow extends INodePoolResources {
  priority?: number | string;
  allocatedGpus?: string;
  allocatedCpu?: string;
  allocatedMemory?: string;
}
export interface IResourceQuota {
  deserved?: number | null;
  maxAllowed?: number | null;
  overQuotaWeight?: number | null;
}

export interface IProjectSectionModel {
  scope: Scope;
  projectId: number | null;
}

export interface IAllocatedResourceMetric {
  [projectName: string]: { [allocatedResource: string]: number };
}

export interface INodePoolModalOptions {
  header: string;
  entity: EQuotaEntity;
  nodePools: INodePoolsResourcesRow[];
  loading: boolean;
}

export enum EProjectColumnName {
  ProjectName = "project-name",
  DepartmentName = "department-name",
  Status = "status",
  NodePools = "node-pools",
  AccessRules = "accessRules",
  AllocatedCpu = "allocated-cpu",
  AllocatedMemory = "allocated-memory",
  AllocatedGpus = "allocated-gpus",
  GpuUtilization = "gpu-utilization",
  CpuUtilization = "cpu-utilization",
  MemoryUtilization = "memory-utilization",
  GpuQuota = "gpu-quota",
  CpuQuota = "cpu-quota",
  CpuMemoryQuota = "cpu-memory-quota",
  NodeAffinityTrain = "node-affinity-train",
  NodeAffinityInteractive = "node-affinity-interactive",
  TrainingJobMaxIdleDurationSecs = "training-job-max-idle-duration-secs",
  InteractivePreemptibleJobMaxIdleDurationSecs = "interactive-preemptible-job-max-idle-duration-secs",
  InteractiveJobMaxIdleDurationSecs = "interactive-job-max-idle-duration-secs",
  InteractiveJobTimeLimitSecs = "interactive-job-time-limit-secs",
  TrainingJobTimeLimitSecs = "training-job-time-limit-secs",
  CreatedAt = "created-at",
  Workloads = "workloads",
}
