// services
import { prometheusService } from "@/services/control-plane/prometheus.service/prometheus.service";
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import { isNewerVersion } from "@/utils/version.util";
import { dateUtil, TimeUnit } from "@/utils/date.util";

import type { INode } from "@/models/node.model";
// models
import type { IFilterBy } from "@/models/filter.model";
import type { IPrometheusMetric, IPrometheusResponse, PrometheusMetricResponse } from "@/models/prometheus.model";
import { DAY_IN_HOURS, MIN_PROMETHEUS_STEP, PROMETHEUS_STEP_FACTOR, SECOND_IN_MS } from "@/models/prometheus.model";
import type { INodeGpu } from "@/models/node-gpu.model";
import type { Nodes } from "@/swagger-models/cluster-service-client";
import { MIN_AMD_GPU_BASED_NODES_VERSION, TEST_ENV_VERSION } from "@/common/version.constant";
import { nodeUtil } from "@/utils/node.util";
import { deepCopy } from "@/utils/common.util";
export const nodeService = {
  getNodes,
  createNodePromQueries,
  getNodeGPUAndCPUMetrics,
  getPrometheusQueriesForGPUAndCPU,
  timeRangeSeriesValues,
  getNodesLimitedSet,
  getAdvancedNodeGPUAndCPUMetrics,
  getNodesFromClusterService,
};

export enum ChartDataKeys {
  gpuUtilization = "gpuUtilization",
  gpuMemoryUsed = "gpuMemoryUsed",
  gpuMemoryUtilization = "gpuMemoryUtilization",
  cpuUtilization = "cpuUtilization",
  cpuMemoryUsed = "cpuMemoryUsed",
  cpuMemoryUtilization = "cpuMemoryUtilization",
}

async function getNodesFromClusterService(clusterId: string): Promise<Nodes> {
  const endpoint = `api/v1/clusters/${clusterId}/nodes`;
  return await controlPlaneService.get(endpoint);
}

async function getNodes(
  clusterVersion: string,
  clusterUuid: string,
  useNewMetrics: boolean,
  filterBy?: IFilterBy,
  includeAdvancedMetrics = false,
): Promise<Array<INode>> {
  const { promQueries, extractor, defaults } = createNodePromQueries(
    clusterVersion,
    clusterUuid,
    useNewMetrics,
    includeAdvancedMetrics,
  );
  let nodes: Array<INode> = await prometheusService
    .multipleQueries(promQueries)
    .then((res) => _prepareDataByNodes(res, extractor, defaults));

  nodes = await getGpuDevicesFromMetrics(clusterUuid, nodes, includeAdvancedMetrics);

  return nodes;
}

async function getNodesLimitedSet(
  clusterVersion: string,
  clusterUuid: string,
  useNewMetrics: boolean,
): Promise<Array<INode>> {
  const isVersionSupportAmdGpu =
    isNewerVersion(clusterVersion, MIN_AMD_GPU_BASED_NODES_VERSION) || clusterVersion.includes(TEST_ENV_VERSION);
  const { promQueries, extractor, defaults } = createNodePromQueries(
    clusterVersion,
    clusterUuid,
    useNewMetrics,
    !isVersionSupportAmdGpu,
  );

  const nodes = await prometheusService
    .multipleQueries(promQueries)
    .then((res) => _prepareDataByNodes(res, extractor, defaults));

  if (isVersionSupportAmdGpu) {
    try {
      const response: Nodes = await nodeService.getNodesFromClusterService(clusterUuid);
      return nodeUtil.enrichNodesWithInfoFromCluster(nodes, response.nodes);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  return nodes;
}

function createNodePromQueries(
  clusterVersion: string,
  clusterId: string,
  useNewMetrics: boolean,
  includeAdvancedMetrics = false,
  includeTotalGpusAndGpuType = true,
): { promQueries: Record<string, string>; extractor: Record<string, string>; defaults: Record<string, string> } {
  const clusterFilter = `clusterId="${clusterId}"`;
  let promQueries: Record<string, string> = {
    ...(includeTotalGpusAndGpuType && {
      totalGpus: `(sum(runai_gpu_count_per_node{${clusterFilter}}) by (node)) or (count(runai_gpus_is_running_with_pod2{${clusterFilter}}) by (node))`,
    }),
    nodeStatus: `kube_node_status_condition{status="true", ${clusterFilter}}==1`,
    allocatedGpus: `(sum(runai_allocated_gpu_count_per_node{${clusterFilter}}) by (node)) or ((sum(runai_gpus_is_running_with_pod2{${clusterFilter}}) by (node))) + (sum(runai_used_shared_gpu_per_node{${clusterFilter}}) by (node))`,
    usedGpuMemory: `(sum(runai_gpu_memory_used_mebibytes_per_pod_per_gpu{${clusterFilter}} * 1024 * 1024) by (node)) or (sum(runai_node_gpu_used_memory{${clusterFilter}} * 1024 * 1024) by (node))`,
    totalGpuMemory: `(sum(runai_gpu_memory_total_mebibytes_per_gpu{${clusterFilter}} * 1024 * 1024) by (node)) or (sum(runai_node_gpu_total_memory{${clusterFilter}} * 1024 * 1024) by (node))`,
    totalCpuMemory: `(sum (kube_node_status_capacity{resource="memory",${clusterFilter}}) by (node))`,
    usedCpuMemory: `runai_node_memory_used_bytes{${clusterFilter}}`,
    swapCpuMemory: `(sum (runai_pod_group_swap_memory_used_bytes{${clusterFilter}}) by (node))`,
    totalCpus: `(sum (kube_node_status_capacity{resource="cpu",${clusterFilter}}) by (node))`,
    usedCpus: `avg(runai_node_cpu_utilization{${clusterFilter}}) by(node) * 100`,
    allocatedCpus: `runai_node_cpu_requested{${clusterFilter}}`,
    allocatedMemory: `runai_node_requested_memory_bytes{${clusterFilter}}`,
    utilization: `(sum(runai_gpu_utilization_per_node{${clusterFilter}}) by (node)) or ((sum(runai_node_gpu_utilization{${clusterFilter}}) by (node)) / on (node) (count(runai_node_gpu_utilization{${clusterFilter}}) by (node)))`,
    freeGpus: `(count(runai_allocated_gpu_count_per_gpu{${clusterFilter}} == 0) by (node)) or (count(sum(runai_node_gpu_utilization{${clusterFilter}}) by (gpu, node) ==0) by (node))`,
    general: `sum(kube_node_status_condition{${clusterFilter}}) by (node, namespace)`,
    ...(includeTotalGpusAndGpuType && {
      gpuType: `(group(runai_gpu_count_per_node{${clusterFilter}}) by(modelName, node)) or (sum by (modelName, node) ((label_replace(dcgm_gpu_utilization{${clusterFilter}},"pod_ip", "$1", "instance", "(.*):(.*)")) * on(pod_ip) group_left(node) kube_pod_info{created_by_name=~".*dcgm-exporter",${clusterFilter}}))`,
    }),
    ready: `sum(kube_node_status_condition{condition="Ready",status="true",${clusterFilter}}) by (node)`,
    nodePool: `runai_node_nodepool{${clusterFilter}}==1`,
  };
  if (includeAdvancedMetrics)
    promQueries = {
      ...promQueries,
      graphicsEngineActivity: `sum(runai_gpu_gr_engine_active_per_node{${clusterFilter}}) by (node)`,
      smActivity: `sum(runai_gpu_sm_active_per_node{${clusterFilter}}) by (node)`,
      smOccupancy: `sum(runai_gpu_sm_occupancy_per_node{${clusterFilter}}) by (node)`,
      tensorActivity: `sum(runai_gpu_pipe_tensor_active_per_node{${clusterFilter}}) by (node)`,
      fp64EngineActivity: `sum(runai_gpu_pipe_fp64_active_per_node{${clusterFilter}}) by (node)`,
      fp32EngineActivity: `sum(runai_gpu_pipe_fp32_active_per_node{${clusterFilter}}) by (node)`,
      fp16EngineActivity: `sum(runai_gpu_pipe_fp16_active_per_node{${clusterFilter}}) by (node)`,
      memoryBWUtilization: `sum(runai_gpu_dram_active_per_node{${clusterFilter}}) by (node)`,
      nvLinkBandwidthRead: `sum(runai_gpu_nvlink_rx_bytes_per_node{${clusterFilter}}) by (node)`,
      nvLinkBandwidthTransmit: `sum(runai_gpu_nvlink_tx_bytes_per_node{${clusterFilter}}) by (node)`,
      pcieBandwidthRead: `sum(runai_gpu_pcie_rx_bytes_per_node{${clusterFilter}}) by (node)`,
      pcieBandwidthTransmit: `sum(runai_gpu_pcie_tx_bytes_per_node{${clusterFilter}}) by (node)`,
    };
  // Specific fields that we get in different structure, we specify the key to get from the query
  const extractor: Record<string, string> = {
    gpuType: "modelName",
    nodeStatus: "condition",
    nodePool: "nodepool",
  };
  const defaults: Record<string, string> = {};
  if (!useNewMetrics) {
    promQueries["nodePool"] = `kube_node_labels{${clusterFilter}}`;
    extractor["nodePool"] = "label_runai_node_pool";
    defaults["nodePool"] = "default";
  }
  return { promQueries, extractor, defaults };
}

function _prepareDataByNodes(
  queryResults: Array<IPrometheusResponse>,
  extractor: Record<string, string>,
  defaults: Record<string, string>,
): Array<INode> {
  const nodesMap: Record<string, INode> = queryResults.reduce(
    (acc: Record<string, INode>, query: IPrometheusResponse) => {
      query.data.forEach((data: IPrometheusMetric) => {
        const nodeName: string | undefined = data.metric.node;
        if (!nodeName || nodeName === "-") return acc;
        if (!acc[nodeName]) {
          acc[nodeName] = {
            node: nodeName,
          } as INode;
        }
        // if the key exists in the extractor it should be handled different
        if (extractor[query.key]) {
          const extractField: string = extractor[query.key];
          let metricField = data.metric[extractField];
          if (!metricField && defaults[query.key]) {
            metricField = defaults[query.key];
          }
          (acc[nodeName][query.key as keyof INode] as string) = metricField;
        } else {
          (acc[nodeName][query.key as keyof INode] as string) = data.value[1];
        }
      });
      return acc;
    },
    {} as Record<string, INode>,
  );
  return Object.values(nodesMap);
}

function getGpusPromQueries(clusterUuid: string, includeAdvancedMetrics = false): Record<string, string> {
  const clusterFilter = `clusterId="${clusterUuid}"`;
  let promQueries: Record<string, string> = {
    utilization: `(sum(runai_gpu_utilization_per_gpu{${clusterFilter}}) by (node, gpu)) or (sum(runai_node_gpu_utilization{${clusterFilter}}) by (node, gpu))`,
    usedMemory: `(sum(runai_gpu_memory_used_mebibytes_per_gpu{${clusterFilter}} * 1024 * 1024) by (node, gpu)) or (sum(runai_node_gpu_used_memory{${clusterFilter}} * 1024 * 1024) by (node, gpu))`,
    idleTime: `(sum(runai_gpu_idle_seconds_per_gpu{${clusterFilter}}) by(node, gpu)) or (sum(time()-runai_node_gpu_last_not_idle_time{${clusterFilter}}) by (node, gpu))`,
    totalMemory: `(sum(runai_gpu_memory_total_mebibytes_per_gpu{${clusterFilter}} * 1024 * 1024) by (node, gpu)) or (sum(runai_node_gpu_total_memory{${clusterFilter}} * 1024 * 1024) by (node, gpu))`,
  };
  if (includeAdvancedMetrics) {
    promQueries = {
      ...promQueries,
      graphicsEngineActivity: `sum(runai_gpu_gr_engine_active_per_gpu{${clusterFilter}}) by (node, gpu)`,
      smActivity: `sum(runai_gpu_sm_active_per_gpu{${clusterFilter}}) by (node, gpu)`,
      smOccupancy: `sum(runai_gpu_sm_occupancy_per_gpu{${clusterFilter}}) by (node, gpu)`,
      tensorActivity: `sum(runai_gpu_pipe_tensor_active_per_gpu{${clusterFilter}}) by (node, gpu)`,
      fp64EngineActivity: `sum(runai_gpu_pipe_fp64_active_per_gpu{${clusterFilter}}) by (node, gpu)`,
      fp32EngineActivity: `sum(runai_gpu_pipe_fp32_active_per_gpu{${clusterFilter}}) by (node, gpu)`,
      fp16EngineActivity: `sum(runai_gpu_pipe_fp16_active_per_gpu{${clusterFilter}}) by (node, gpu)`,
      memoryBWUtilization: `sum(runai_gpu_dram_active_per_gpu{${clusterFilter}}) by (node, gpu)`,
      nvLinkBandwidthRead: `sum(runai_gpu_nvlink_rx_bytes_per_gpu{${clusterFilter}}) by (node, gpu)`,
      nvLinkBandwidthTransmit: `sum(runai_gpu_nvlink_tx_bytes_per_gpu{${clusterFilter}}) by (node, gpu)`,
      pcieBandwidthRead: `sum(runai_gpu_pcie_rx_bytes_per_gpu{${clusterFilter}}) by (node, gpu)`,
      pcieBandwidthTransmit: `sum(runai_gpu_pcie_tx_bytes_per_gpu{${clusterFilter}}) by (node, gpu)`,
    };
  }
  return promQueries;
}

async function getGpuDevicesFromMetrics(
  clusterUuid: string,
  nodesList: Array<INode>,
  includeAdvancedMetrics = false,
): Promise<Array<INode>> {
  const nodes = deepCopy(nodesList);
  const nodesMap = nodes.reduce(function (map: Record<string, INode>, node) {
    node.gpuDevices = [];
    map[node.node] = node;
    return map;
  }, {});
  const promQueries = getGpusPromQueries(clusterUuid, includeAdvancedMetrics);
  await prometheusService.multipleQueries(promQueries).then((res) => {
    res.forEach((metric) => {
      metric.data.forEach((data) => {
        const gpuIndex = data.metric.gpu;
        const node = nodesMap[data.metric.node];

        if (gpuIndex && node) {
          let gpuDevice = node.gpuDevices?.filter((gpu: INodeGpu) => gpu.index === +gpuIndex)
            ? node.gpuDevices?.filter((gpu: INodeGpu) => gpu.index === +gpuIndex)[0]
            : null;
          if (!gpuDevice) {
            gpuDevice = { index: +gpuIndex } as INodeGpu;
            node.gpuDevices?.push(gpuDevice);
          }
          gpuDevice[metric.key as keyof INodeGpu] = +data.value[1];
        }
      });
    });
  });

  return nodes.map((node) => ({
    ...node,
    //filter out empty gpuDevices
    gpuDevices:
      node.gpuDevices?.filter((device: INodeGpu) => Object.prototype.hasOwnProperty.call(device, "utilization")) || [],
  }));
}

async function getNodeGPUAndCPUMetrics(
  clusterUuid: string,
  nodeName: string,
  start: number,
  end: number,
  maxGpuMetrics = 8 as number,
): Promise<Record<string, PrometheusMetricResponse>> {
  const promQueries: Record<string, string> = getPrometheusQueriesForGPUAndCPU(clusterUuid, nodeName);
  const hoursInRange: number = dateUtil.differenceBy(TimeUnit.hour, end, start);
  return await prometheusService
    .multipleQueriesWithTimeRange(
      promQueries,
      start / SECOND_IN_MS,
      end / SECOND_IN_MS,
      (PROMETHEUS_STEP_FACTOR * (hoursInRange / DAY_IN_HOURS)) | MIN_PROMETHEUS_STEP,
    )
    .then((res) => {
      const successfulResponses = res.filter((response) => response.data && response.data.length > 0);
      return timeRangeSeriesValues(successfulResponses, maxGpuMetrics);
    });
}
async function getAdvancedNodeGPUAndCPUMetrics(
  clusterUuid: string,
  nodeName: string,
  start: number,
  end: number,
  maxGpuMetrics = 8 as number,
): Promise<Record<string, PrometheusMetricResponse>> {
  const promQueries: Record<string, string> = getPrometheusAdvancedQueriesForGPU(clusterUuid, nodeName);
  const hoursInRange: number = dateUtil.differenceBy(TimeUnit.hour, end, start);
  return await prometheusService
    .multipleQueriesWithTimeRange(
      promQueries,
      start / SECOND_IN_MS,
      end / SECOND_IN_MS,
      (PROMETHEUS_STEP_FACTOR * (hoursInRange / DAY_IN_HOURS)) | MIN_PROMETHEUS_STEP,
    )
    .then((res) => {
      const successfulResponses = res.filter((response) => response.data && response.data.length > 0);
      return timeRangeSeriesValues(successfulResponses, maxGpuMetrics, true);
    });
}

export function getPrometheusQueriesForGPUAndCPU(clusterId: string, nodeName: string): Record<string, string> {
  const clusterFilter = `clusterId="${clusterId}"`;
  return {
    // The order here is very important, it should be the same as the order in the valuesRangePerGPU function
    // Average should be the second for each metric
    gpuUtilization: `(sum(runai_gpu_utilization_per_gpu{${clusterFilter},node="${nodeName}"}) by(node, gpu)) or (sum(runai_node_gpu_utilization{${clusterFilter},node="${nodeName}"}) by (node, gpu))`,
    gpuUtilizationAverage: `(avg(sum(runai_gpu_utilization_per_gpu{${clusterFilter},node="${nodeName}"}) by(node, gpu))) or (avg(sum(runai_node_gpu_utilization{${clusterFilter},node="${nodeName}"}) by (node, gpu)))`,
    gpuMemoryUsed: `(sum(runai_gpu_memory_used_mebibytes_per_gpu{${clusterFilter},node="${nodeName}"} * 1024 * 1024) by (node, gpu)) or (sum(runai_node_gpu_used_memory_excluding_mig{${clusterFilter},node="${nodeName}"} * 1024 * 1024 ) by (node, gpu))`, //  1024 * 1024 so result is in bytes
    gpuMemoryUsedAverage: `(avg(sum(runai_gpu_memory_used_mebibytes_per_gpu{${clusterFilter},node="${nodeName}"} * 1024 * 1024) by (node, gpu))) or (avg(sum(runai_node_gpu_used_memory_excluding_mig{${clusterFilter},node="${nodeName}"} * 1024 * 1024) by (node, gpu)))`, //  1024 * 1024 so result is in bytes
    cpuUtilization: `runai_node_cpu_utilization{${clusterFilter},node="${nodeName}"} * 100`,
    cpuMemoryUsed: `runai_node_memory_used_bytes{${clusterFilter},node="${nodeName}"}`,
    cpuMemoryUtilization: `runai_node_memory_used_bytes{${clusterFilter},node="${nodeName}"} / on (node) (sum (kube_node_status_capacity{resource="memory",${clusterFilter},node="${nodeName}"}) by (node)) * 100`,
  };
}
export function getPrometheusAdvancedQueriesForGPU(clusterId: string, nodeName: string): Record<string, string> {
  const clusterFilter = `clusterId="${clusterId}"`;
  return {
    // The order here is very important, it should be the same as the order in the valuesRangePerGPU function
    // Average should be the second for each metric
    graphicsEngineActivity: `(sum(runai_gpu_gr_engine_active_per_gpu{${clusterFilter},node="${nodeName}"}) by(node, gpu))`,
    graphicsEngineActivityAverage: `runai_gpu_gr_engine_active_per_node{${clusterFilter},node="${nodeName}"}`,

    smActivity: `(sum(runai_gpu_sm_active_per_gpu{${clusterFilter},node="${nodeName}"}) by(node, gpu))`,
    smActivityAverage: `runai_gpu_sm_active_per_node{${clusterFilter},node="${nodeName}"}`,

    smOccupancy: `(sum(runai_gpu_sm_occupancy_per_gpu{${clusterFilter},node="${nodeName}"}) by(node, gpu))`,
    smOccupancyAverage: `runai_gpu_sm_occupancy_per_node{${clusterFilter},node="${nodeName}"}`,

    tensorActivity: `(sum(runai_gpu_pipe_tensor_active_per_gpu{${clusterFilter},node="${nodeName}"}) by(node, gpu))`,
    tensorActivityAverage: `runai_gpu_pipe_tensor_active_per_node{${clusterFilter},node="${nodeName}"}`,

    fp64EngineActivity: `(sum(runai_gpu_pipe_fp64_active_per_gpu{${clusterFilter},node="${nodeName}"}) by(node, gpu))`,
    fp64EngineActivityAverage: `runai_gpu_pipe_fp64_active_per_node{${clusterFilter},node="${nodeName}"}`,

    fp32EngineActivity: `(sum(runai_gpu_pipe_fp32_active_per_gpu{${clusterFilter},node="${nodeName}"}) by(node, gpu))`,
    fp32EngineActivityAverage: `runai_gpu_pipe_fp32_active_per_node{${clusterFilter},node="${nodeName}"}`,

    fp16EngineActivity: `(sum(runai_gpu_pipe_fp16_active_per_gpu{${clusterFilter},node="${nodeName}"}) by(node, gpu))`,
    fp16EngineActivityAverage: `runai_gpu_pipe_fp16_active_per_node{${clusterFilter},node="${nodeName}"}`,

    memoryBWUtilization: `(sum(runai_gpu_dram_active_per_gpu{${clusterFilter},node="${nodeName}"}) by(node, gpu))`,
    memoryBWUtilizationAverage: `runai_gpu_dram_active_per_node{${clusterFilter},node="${nodeName}"}`,

    nvLinkBandwidthRead: `(sum(runai_gpu_nvlink_rx_bytes_per_gpu{${clusterFilter},node="${nodeName}"}) by (node,gpu))`,
    nvLinkBandwidthReadAverage: `(avg(sum(runai_gpu_nvlink_rx_bytes_per_gpu{${clusterFilter},node="${nodeName}"}) by (node, gpu)))`,

    nvLinkBandwidthTransmit: `(sum(runai_gpu_nvlink_tx_bytes_per_gpu{${clusterFilter},node="${nodeName}"}) by (node,gpu))`,
    nvLinkBandwidthTransmitAverage: `(avg(sum(runai_gpu_nvlink_tx_bytes_per_gpu{${clusterFilter},node="${nodeName}"}) by (node, gpu)))`,

    pcieBandwidthRead: `(sum(runai_gpu_pcie_rx_bytes_per_gpu{${clusterFilter},node="${nodeName}"}) by (node,gpu))`,
    pcieBandwidthReadAverage: `(avg(sum(runai_gpu_pcie_rx_bytes_per_gpu{${clusterFilter},node="${nodeName}"}) by (node, gpu)))`,

    pcieBandwidthTransmit: `(sum(runai_gpu_pcie_tx_bytes_per_gpu{${clusterFilter},node="${nodeName}"}) by (node,gpu))`,
    pcieBandwidthTransmitAverage: `(avg(sum(runai_gpu_pcie_tx_bytes_per_gpu{${clusterFilter},node="${nodeName}"}) by (node, gpu)))`,
  };
}

function _convertDateTimeSeries(values: number[][]): number[][] {
  return values?.map((dateTimeAndValue: number[]) => {
    const dateTime = +dateTimeAndValue[0] * SECOND_IN_MS;
    const value = +dateTimeAndValue[1];
    return [dateTime, value];
  });
}

export function timeRangeSeriesValues(
  queriesResponse: IPrometheusResponse[],
  maxGpuMetrics: number,
  isAdvanced = false,
): Record<string, PrometheusMetricResponse> {
  // Initialize an empty array to store the values per GPU / CPU
  const metrics: Record<string, Array<Array<number>[]>> = {};
  queriesResponse.forEach((response: IPrometheusResponse) => {
    if (!response.key.endsWith("Average")) {
      // In case of gpu series we need to save extra place for the average
      const gpusSlots: number = response.data.length < maxGpuMetrics ? response.data.length + 1 : maxGpuMetrics + 1;
      const series: PrometheusMetricResponse = Array<Array<number>[]>(
        response.key.startsWith("gpu") || isAdvanced ? gpusSlots : response.data.length,
      );
      response.data.forEach((data: IPrometheusMetric) => {
        const gpu: number = +data.metric.gpu;
        const values: Array<Array<number>> = data.values || [];
        // index 0 is saved for the average
        if (Number.isInteger(gpu)) {
          if (maxGpuMetrics > gpu) {
            if (series[gpu + 1]) {
              series[gpu + 1] = series[gpu + 1].concat(_convertDateTimeSeries(values));
            } else {
              series[gpu + 1] = _convertDateTimeSeries(values);
            }
          }
        } else {
          series[0] = _convertDateTimeSeries(values);
        }
      });
      metrics[response.key] = series;
    } else if (response.key.startsWith("gpu") || isAdvanced) {
      const metricKey: string = response.key.replace("Average", "");
      const values: Array<Array<number>> = response.data[0]?.values || [];
      if (metrics[metricKey]) {
        metrics[metricKey][0] = _convertDateTimeSeries(values);
      }
    }
  });
  return metrics;
}
