import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import type { IProject, IProjectCreate, IProjectResources } from "@/models/project.model";
import type { IFilterBy } from "@/models/filter.model";
import { K8S_API } from "@/common/api.constant";
import { pick } from "@/utils/common.util";
import type { Project1 } from "@/swagger-models/backend-client";
import { nodePoolService } from "@/services/control-plane/node-pool.service/node-pool.service";
import { NEW_PROJECT_DEFAULT_FIELDS } from "@/models/project.model";
export const projectService = {
  list,
  listQuota,
  createProject,
  updateProject,
  deleteProject,
  getProjectById,
  getEmptyProjectModel,
};

const getEndpoint = (clusterUuid: string): string => `${K8S_API.v1}/clusters/${clusterUuid}/projects`;

// api calls
async function list(clusterUuid: string, filterBy: IFilterBy = {}): Promise<Array<IProject>> {
  const filters: IFilterBy = pick(filterBy, "sortBy", "page", "rowsPerPage");
  return controlPlaneService.get(getEndpoint(clusterUuid), {
    ...filters,
    excludePermissions: true,
  });
}

async function listQuota(clusterUuid: string): Promise<Array<IProjectResources>> {
  const projectsResources: Array<IProjectResources> = await controlPlaneService.get(
    getEndpoint(clusterUuid) + "/quotas?excludePermissions=true",
  );
  return projectsResources;
}

async function createProject(project: IProjectCreate, clusterUuid: string): Promise<IProject> {
  const endpoint = `${getEndpoint(clusterUuid)}?excludePermissions=true`;
  return controlPlaneService.post(endpoint, project);
}
async function updateProject(project: IProject, projectId: string, clusterUuid: string): Promise<IProject> {
  const endpoint = `${getEndpoint(clusterUuid)}/${projectId}?excludePermissions=true`;
  return controlPlaneService.put(endpoint, project);
}
async function deleteProject(projectId: number, clusterUuid: string): Promise<IProject> {
  const endpoint = `${getEndpoint(clusterUuid)}/${projectId}?excludePermissions=true`;
  return controlPlaneService.delete(endpoint);
}

async function getProjectById(clusterUuid: string, projectId: number): Promise<Project1> {
  return controlPlaneService.get(`${getEndpoint(clusterUuid)}/${projectId}`);
}

async function getEmptyProjectModel(
  isCpuEnabled: boolean,
  clusterUuid: string,
  options?: Partial<IProjectCreate>,
): Promise<IProjectCreate> {
  const nodePoolsResources = await nodePoolService.getEmptyNodePoolsModel(clusterUuid, isCpuEnabled);
  const defaults = {
    ...NEW_PROJECT_DEFAULT_FIELDS,
    nodePoolsResources: nodePoolsResources,
  };
  return {
    ...defaults,
    ...options,
    clusterUuid,
  };
}
