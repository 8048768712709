<template>
  <runai-expansion-item label="Scheduling rules" :default-opened="isSectionOpen" :section-invalid="!sectionValid">
    <template #subheader>{{ expansionSubHeader }} </template>
    <div class="q-my-md">
      <span class="block q-my-md">Set rules to control utilization of the project's compute resources</span>
      <span class="text-italic"
        >For more information, see the
        <a target="_blank" href="https://docs.run.ai/latest/admin/admin-ui-setup/project-setup/">Projects</a>
        guide</span
      >
    </div>
    <gpu-timeout-section
      v-if="showGpuSection"
      :workloads="workloadsDuration"
      @add-workload="addWorkloadDuration"
      @remove-workload="removeWorkloadDuration"
      @close="hideSection($options.ERulesSection.IdleGpuTimeout)"
      @workload-changed="onWorkloadDurationChanged"
    />
    <workspace-section
      v-if="showWorkspaceSection"
      :workspace-duration="interactiveTimeLimit"
      @update:workspace-duration="updateWorkspaceDuration"
      @close="hideSection($options.ERulesSection.WorkspaceDuration)"
    />
    <training-section
      v-if="showTrainingSection && trainingTimeLimitSupported"
      :training-duration="trainingTimeLimit"
      @update:training-duration="updateTrainingDuration"
      @close="hideSection($options.ERulesSection.TrainingDuration)"
    />
    <node-affinity-section
      v-if="showNodeAffinitySection"
      :workloads="nodeAffinityWorkloads"
      @add-workload="addNodeAffinity"
      @remove-workload="removeNodeAffinity"
      @workload-changed="onNodeAffinityChanged"
      @add-new-node-affinity-type="addNewNodeAffinityType"
      @update-workload-loading="updateNodeAffinityLoading"
      @close="hideSection($options.ERulesSection.NodeAffinity)"
    />
    <runai-button-with-menu
      v-if="showRulesButton"
      button-label="+ RULE"
      aid="add-scheduling-rule-button"
      :options="sectionOptions"
      @item-clicked="showSection"
    />
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
// cmps
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import { RunaiButtonWithMenu } from "@/components/common/runai-button-with-menu";
import { WorkspaceSection } from "@/components/project/project-edit-form/scheduling-rules-section/workspace-section";
import { NodeAffinitySection } from "@/components/project/project-edit-form/scheduling-rules-section/node-affinity-section";
import { GpuTimeoutSection } from "@/components/project/project-edit-form/scheduling-rules-section/gpu-timeout-section";
// models
import type { ISelectOption } from "@/models/global.model";
import type { IWorkloadDurationOption } from "@/models/workload.model";
import { EIdleWorkloadMaxDuration } from "@/models/workload.model";
import type { INodeAffinityOption, INodeAffinitySelectOption, ISelectedNodeAffinity } from "@/models/project.model";
import type { INodeAffinity } from "@/models/project.model";
import { EAffinityType, EWorkloadNodeAffinity } from "@/models/project.model";
//utils
import { deepCopy } from "@/utils/common.util";
import TrainingSection from "@/components/project/project-edit-form/scheduling-rules-section/training-section/training-section.vue";

enum ERulesSection {
  IdleGpuTimeout = "idleGpuTimeout",
  WorkspaceDuration = "workspaceDuration",
  TrainingDuration = "trainingDuration",
  NodeAffinity = "nodeAffinity",
}

enum ESectionLabel {
  IdleGpuTimeout = "Idle GPU timeout",
  WorkspaceDuration = "Workspace duration",
  TrainingDuration = "Training duration",
  NodeAffinity = "Node type (Affinity)",
}

export default defineComponent({
  components: {
    TrainingSection,
    GpuTimeoutSection,
    NodeAffinitySection,
    WorkspaceSection,
    RunaiButtonWithMenu,
    RunaiExpansionItem,
  },
  emits: [
    "update:training-duration",
    "update:interactive-duration",
    "update:interactive-preemptible-duration",
    "update:interactive-time-limit",
    "update:training-time-limit",
    "update:node-affinity",
    "is-section-invalid",
  ],
  props: {
    interactiveTimeLimit: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },
    trainingTimeLimit: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },
    interactiveDuration: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },
    interactivePreemptibleDuration: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },
    trainingDuration: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },
    nodeAffinity: {
      type: Object as PropType<INodeAffinity>,
      required: true,
    },
    trainingTimeLimitSupported: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  ERulesSection: ERulesSection,
  data() {
    const sectionOptions = [
      {
        value: ERulesSection.IdleGpuTimeout,
        label: `+ ${ESectionLabel.IdleGpuTimeout}`,
        disable: false,
      },
      {
        value: ERulesSection.WorkspaceDuration,
        label: `+ ${ESectionLabel.WorkspaceDuration}`,
        disable: false,
      },
      ...(this.trainingTimeLimitSupported
        ? [
            {
              value: ERulesSection.TrainingDuration,
              label: `+ ${ESectionLabel.TrainingDuration}`,
              disable: false,
            },
          ]
        : []),
      {
        value: ERulesSection.NodeAffinity,
        label: `+ ${ESectionLabel.NodeAffinity}`,
        disable: false,
      },
    ] as ISelectOption[];
    return {
      showGpuSection: false as boolean,
      showWorkspaceSection: false as boolean,
      showTrainingSection: false as boolean,
      showNodeAffinitySection: false as boolean,
      workloadsDuration: [] as IWorkloadDurationOption[],
      nodeAffinityWorkloads: [] as INodeAffinitySelectOption[],
      sectionOptions: sectionOptions,
    };
  },
  created() {
    this.initGpuWorkloadsSection();
    this.initWorkspaceSection();
    this.initTrainingSection();
    this.initNodeAffinitySection();
  },
  computed: {
    sectionValid(): boolean {
      if (!this.isSectionOpen) {
        return true;
      }
      let isValid = true;
      if (
        this.showGpuSection &&
        this.workloadsDuration.some((workload: IWorkloadDurationOption) => !workload.value || !workload.duration)
      ) {
        isValid = false;
      }
      if (this.showWorkspaceSection && !this.interactiveTimeLimit) {
        isValid = false;
      }
      if (
        this.showNodeAffinitySection &&
        this.nodeAffinityWorkloads.some(
          (workload: INodeAffinitySelectOption) => !workload.value || workload.selectedTypes.length === 0,
        )
      ) {
        isValid = false;
      }
      return isValid;
    },
    isSectionOpen(): boolean {
      return this.showGpuSection || this.showWorkspaceSection || this.showNodeAffinitySection;
    },
    showRulesButton(): boolean {
      return this.sectionOptions.some((item: ISelectOption) => !item.disable);
    },
    isAllSectionsEmpty(): boolean {
      return (
        !this.trainingTimeLimit &&
        !this.trainingDuration &&
        !this.interactiveDuration &&
        !this.interactivePreemptibleDuration &&
        !this.interactiveTimeLimit &&
        !this.nodeAffinity.interactive?.selectedTypes?.length &&
        !this.nodeAffinity.train?.selectedTypes?.length
      );
    },
    expansionSubHeader(): string {
      if (this.isAllSectionsEmpty) {
        return "None";
      }
      const sections = [];
      if (this.trainingDuration || this.interactiveDuration || this.interactivePreemptibleDuration) {
        sections.push(ESectionLabel.IdleGpuTimeout);
      }
      if (this.interactiveTimeLimit) {
        sections.push(ESectionLabel.WorkspaceDuration);
      }
      if (this.trainingTimeLimit) {
        sections.push(ESectionLabel.TrainingDuration);
      }
      if (this.nodeAffinity.interactive.selectedTypes.length || this.nodeAffinity.train.selectedTypes.length) {
        sections.push(ESectionLabel.NodeAffinity);
      }
      return sections.join(" / ");
    },
  },
  methods: {
    initGpuWorkloadsSection() {
      if (this.trainingDuration !== null) {
        this.workloadsDuration.push({
          label: "Training",
          value: EIdleWorkloadMaxDuration.training,
          duration: this.trainingDuration,
        });
        this.showSection(ERulesSection.IdleGpuTimeout);
      }

      if (this.interactivePreemptibleDuration !== null) {
        this.workloadsDuration.push({
          label: "Preemptive workspaces",
          value: EIdleWorkloadMaxDuration.interactivePreemptible,
          duration: this.interactivePreemptibleDuration,
        });
        this.showSection(ERulesSection.IdleGpuTimeout);
      }

      if (this.interactiveDuration !== null) {
        this.workloadsDuration.push({
          label: "Non Preemptive workspaces",
          value: EIdleWorkloadMaxDuration.interactive,
          duration: this.interactiveDuration,
        });
        this.showSection(ERulesSection.IdleGpuTimeout);
      }
    },
    initWorkspaceSection(): void {
      if (this.interactiveTimeLimit !== null) {
        this.showSection(ERulesSection.WorkspaceDuration);
      }
    },
    initTrainingSection(): void {
      if (this.trainingTimeLimitSupported && this.trainingTimeLimit !== null) {
        this.showSection(ERulesSection.TrainingDuration);
      }
    },
    initNodeAffinitySection(): void {
      const pushNodeAffinityWorkload = (affinity: INodeAffinityOption, label: string, value: EWorkloadNodeAffinity) => {
        if (affinity.affinityType === EAffinityType.OnlySelected) {
          const selectedTypes = affinity.selectedTypes.map((type) => ({
            label: type.name,
            value: type.id,
          }));
          this.nodeAffinityWorkloads.push({
            label: label,
            value: value,
            loading: false,
            selectedTypes,
          });
          this.showSection(ERulesSection.NodeAffinity);
        }
      };
      pushNodeAffinityWorkload(this.nodeAffinity.train, "Training", EWorkloadNodeAffinity.Train);
      pushNodeAffinityWorkload(this.nodeAffinity.interactive, "Workspace", EWorkloadNodeAffinity.Interactive);
    },
    addWorkloadDuration(): void {
      this.workloadsDuration.push({
        label: "",
        value: "",
        duration: 0,
      });
    },
    removeWorkloadDuration(workloadIndex: number): void {
      this.workloadsDuration.splice(workloadIndex, 1);
    },
    onWorkloadDurationChanged(workload: IWorkloadDurationOption, workloadIndex: number): void {
      this.workloadsDuration.splice(workloadIndex, 1, workload);
    },
    addNodeAffinity(): void {
      this.nodeAffinityWorkloads.push({
        label: "",
        value: "",
        loading: false,
        selectedTypes: [],
      });
    },
    addNewNodeAffinityType(workloadIndex: number, newType: ISelectOption): void {
      this.nodeAffinityWorkloads[workloadIndex].selectedTypes.push(newType);
    },
    updateNodeAffinityLoading(workloadIndex: number, isLoading: boolean): void {
      this.nodeAffinityWorkloads[workloadIndex].loading = isLoading;
    },
    onNodeAffinityChanged(workload: INodeAffinitySelectOption, workloadIndex: number): void {
      this.nodeAffinityWorkloads.splice(workloadIndex, 1, workload);
    },
    removeNodeAffinity(workloadIndex: number): void {
      this.nodeAffinityWorkloads.splice(workloadIndex, 1);
    },
    getSectionIndexByName(optionValue: string): number {
      return this.sectionOptions.findIndex((option: ISelectOption) => option.value === optionValue);
    },
    resetSection(section: ERulesSection): void {
      switch (section) {
        case ERulesSection.IdleGpuTimeout:
          this.workloadsDuration = [];
          break;
        case ERulesSection.WorkspaceDuration:
          this.updateWorkspaceDuration(null);
          break;
        case ERulesSection.TrainingDuration:
          this.updateTrainingDuration(null);
          break;
        case ERulesSection.NodeAffinity:
          this.nodeAffinityWorkloads = [];
      }
    },
    showSection(section: ERulesSection): void {
      const optionIndex = this.getSectionIndexByName(section);
      this.sectionOptions[optionIndex].disable = true;
      this.toggleSectionVisibility(section, true);
    },
    hideSection(section: ERulesSection): void {
      const optionIndex = this.getSectionIndexByName(section);
      this.sectionOptions[optionIndex].disable = false;
      this.toggleSectionVisibility(section, false);
    },
    toggleSectionVisibility(section: ERulesSection, visible: boolean): void {
      switch (section) {
        case ERulesSection.IdleGpuTimeout:
          this.showGpuSection = visible;
          break;
        case ERulesSection.WorkspaceDuration:
          this.showWorkspaceSection = visible;
          break;
        case ERulesSection.TrainingDuration:
          this.showTrainingSection = visible;
          break;
        case ERulesSection.NodeAffinity:
          this.showNodeAffinitySection = visible;
      }
    },
    getWorkloadDuration(workloads: IWorkloadDurationOption[], value: EIdleWorkloadMaxDuration): number | null {
      const idleWorkload = workloads.find((workload: IWorkloadDurationOption) => workload.value === value);

      if (idleWorkload && idleWorkload.duration) {
        return idleWorkload.duration;
      } else {
        return null;
      }
    },
    updateWorkspaceDuration(duration: number | null): void {
      this.$emit("update:interactive-time-limit", duration);
    },
    updateTrainingDuration(duration: number | null): void {
      this.$emit("update:training-time-limit", duration);
    },
  },
  watch: {
    showGpuSection(isSectionOpened: boolean): void {
      if (!isSectionOpened) {
        this.resetSection(ERulesSection.IdleGpuTimeout);
      } else if (this.workloadsDuration.length === 0) {
        //in case of no values open one section as default.
        this.addWorkloadDuration();
      }
    },
    showWorkspaceSection(isSectionOpened: boolean): void {
      if (!isSectionOpened) {
        this.resetSection(ERulesSection.WorkspaceDuration);
      }
    },
    showTrainingSection(isSectionOpened: boolean): void {
      if (!isSectionOpened) {
        this.resetSection(ERulesSection.TrainingDuration);
      }
    },
    showNodeAffinitySection(isSectionOpened: boolean): void {
      if (!isSectionOpened) {
        this.resetSection(ERulesSection.NodeAffinity);
      } else if (this.nodeAffinityWorkloads.length === 0) {
        //in case of no values open one section as default.
        this.addNodeAffinity();
      }
    },
    workloadsDuration: {
      handler(workloads: IWorkloadDurationOption[]) {
        this.$emit("update:training-duration", this.getWorkloadDuration(workloads, EIdleWorkloadMaxDuration.training));
        this.$emit(
          "update:interactive-preemptible-duration",
          this.getWorkloadDuration(workloads, EIdleWorkloadMaxDuration.interactivePreemptible),
        );
        this.$emit(
          "update:interactive-duration",
          this.getWorkloadDuration(workloads, EIdleWorkloadMaxDuration.interactive),
        );
      },
      deep: true,
    },
    nodeAffinityWorkloads: {
      handler(workloads: INodeAffinitySelectOption[]) {
        const nodeAffinity = deepCopy(this.nodeAffinity) as INodeAffinity;
        const interactive = workloads.find(
          (workload: INodeAffinitySelectOption) => workload.value === EWorkloadNodeAffinity.Interactive,
        );
        const train = workloads.find(
          (workload: INodeAffinitySelectOption) => workload.value === EWorkloadNodeAffinity.Train,
        );

        const setAffinity = (
          nodeAffinity: INodeAffinity,
          affinityWorkload: INodeAffinitySelectOption | undefined,
          affinityType: EWorkloadNodeAffinity,
        ) => {
          if (!affinityWorkload || affinityWorkload.selectedTypes.length === 0) {
            nodeAffinity[affinityType].affinityType = EAffinityType.NoLimit;
            nodeAffinity[affinityType].selectedTypes = [];
          } else {
            nodeAffinity[affinityType].affinityType = EAffinityType.OnlySelected;
            nodeAffinity[affinityType].selectedTypes = affinityWorkload.selectedTypes.map((option: ISelectOption) => {
              return { id: option.value, name: option.label } as ISelectedNodeAffinity;
            });
          }
        };

        setAffinity(nodeAffinity, train, EWorkloadNodeAffinity.Train);
        setAffinity(nodeAffinity, interactive, EWorkloadNodeAffinity.Interactive);

        this.$emit("update:node-affinity", nodeAffinity);
      },
      deep: true,
    },
  },
});
</script>
