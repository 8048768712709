import type { IStatusColOptions } from "@/models/table.model";
import type { IProject, IProjectCreate, IProjectStatus, IResourceQuota } from "@/models/project.model";
import { EProjectPhase, IConditionStatus, ProjectPhaseMap, ProjectStatusMessageMap } from "@/models/project.model";
import type { INodePoolResources } from "@/models/project.model";
import {
  EMPTY_CPU_RESOURCE_VALUE,
  OVER_QUOTA_ENABLED_VALUE,
  EOverQuotaPriority,
  RESOURCE_MAX_ALLOWED_INFINITE_VALUE,
  type IAssignedResources,
  type IResource,
} from "@/models/resource.model";
import { tableUtil } from "@/utils/table.util";
import type { INodePool } from "@/models/node-pool.model";
export const projectUtil = {
  getStatusColOptions,
  resetCpuResources,
  updateMaxAllowedOverQuota,
  sortProjectCol,
  removeProjectDeprecatedPostFields,
  mapNodePoolToNodePoolResources,
};

function getStatusColOptions(phase: EProjectPhase, status?: IProjectStatus): IStatusColOptions {
  const statusOptions: IStatusColOptions = ProjectPhaseMap[phase];
  return tableUtil.getStatusColOptions(statusOptions, _getStatusMessage(phase, status));
}

function _getStatusMessage(phase: string, status?: IProjectStatus): string {
  if (phase === EProjectPhase.Ready) {
    return "";
  }
  if (status && status.conditions) {
    for (const condition of status.conditions) {
      if (condition.status === IConditionStatus.False && condition.reason) {
        const message = ProjectStatusMessageMap[condition.reason];

        if (message) {
          return message;
        }
      }
    }
  }

  return "";
}

//in case of cpu feature flag is off need to reset cpu values
function resetCpuResources(project: IProject | IProjectCreate): void {
  project.nodePoolsResources.forEach((nodePool: INodePoolResources) => {
    nodePool.cpu = { ...EMPTY_CPU_RESOURCE_VALUE };
    nodePool.memory = { ...EMPTY_CPU_RESOURCE_VALUE };
  });
}

// The UI inputs do not include maxAllowed, but its value depends on overQuotaWeight. Therefore, update it with the relevant value.
function updateMaxAllowedOverQuota(project: IProject | IProjectCreate, isOverQuotaPriorityEnabled: boolean): void {
  project.nodePoolsResources.forEach((nodePool: INodePoolResources) => {
    const isOverQuotaOn = isOverQuotaPriorityEnabled
      ? nodePool.gpu.overQuotaWeight !== EOverQuotaPriority.None
      : nodePool.gpu.overQuotaWeight === OVER_QUOTA_ENABLED_VALUE;

    [nodePool.gpu, nodePool.cpu, nodePool.memory].forEach((resource: IResourceQuota) => {
      resource.maxAllowed = isOverQuotaOn ? RESOURCE_MAX_ALLOWED_INFINITE_VALUE : resource.deserved;
    });

    if (isOverQuotaPriorityEnabled) {
      if (nodePool.gpu.overQuotaWeight === EOverQuotaPriority.None) {
        nodePool.gpu.maxAllowed = nodePool.gpu.deserved;
        nodePool.gpu.overQuotaWeight = EOverQuotaPriority.Lowest;
      } else if (nodePool.gpu.overQuotaWeight === EOverQuotaPriority.Lowest) {
        nodePool.gpu.maxAllowed = RESOURCE_MAX_ALLOWED_INFINITE_VALUE;
      }
    }
  });
}

function sortProjectCol(projectA: IProject, projectB: IProject): number {
  if (projectA.phase === null && projectB.phase === null) {
    return 0;
  }
  if (projectA.phase === null) {
    return -1;
  }
  if (projectB.phase === null) {
    return 1;
  }
  return projectA.phase.localeCompare(projectB.phase);
}

function removeProjectDeprecatedPostFields(project: IProject | IProjectCreate): void {
  const deprecatedPostFields = [
    "tenantId",
    "createdAt",
    "projectsDeservedGpus",
    "quotaStatuses",
    "projectAggregatedNodePoolsResources",
    "resources",
  ];
  deprecatedPostFields.forEach((field) => {
    //@ts-ignore
    delete project[field];
  });
}

function mapNodePoolToNodePoolResources(nodePools: INodePool[], enableCpuResourcesQuota: boolean): INodePoolResources[] {
  return nodePools.map((np: INodePool) => {
    return {
      nodePool: {
        id: np.id,
        name: np.name,
      },
      ...getNodeResourcesModel(enableCpuResourcesQuota),
    };
  });
}

function getNodeResourcesModel(enableCpuResourcesQuota: boolean): IAssignedResources {
  return {
    gpu: {
      deserved: 0,
      maxAllowed: -1,
      overQuotaWeight: 2,
    } as IResource,
    cpu: {
      deserved: null,
      maxAllowed: enableCpuResourcesQuota ? -1 : null,
      overQuotaWeight: enableCpuResourcesQuota ? 2 : null,
    } as IResource,
    memory: {
      deserved: null,
      maxAllowed: enableCpuResourcesQuota ? -1 : null,
      overQuotaWeight: enableCpuResourcesQuota ? 2 : null,
    } as IResource,
  };
}
