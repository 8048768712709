<template>
  <div class="email-verify">
    <setting-readonly-input>
      <template v-slot:default="{ readonly }">
        <q-btn
          aid="smtp-verify-button"
          class="verify-button"
          :class="{ verified: verified, failed: verifyingFailed, pending: noneVerify || verifying }"
          :loading="verifying"
          color="grey-4"
          text-color="black-70"
          @click="verify"
          icon="fas fa-shield-check fa-xs"
          :label="verifyButtonLabel"
          :disable="readonly"
        >
          <template v-slot:loading>
            <q-spinner class="on-left" />
            VERIFYING
          </template>
        </q-btn>
      </template>
    </setting-readonly-input>
    <div class="validation q-mt-md" :class="{ 'verified-message': verified, 'non-verified-message': !verified }">
      {{ validationMessage }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Models
import { EmailVerificationStatus } from "@/models/setting.model";
import SettingReadonlyInput from "@/components/settings/setting-readonly-input/setting-readonly-input.vue";

export default defineComponent({
  name: "verify-button",
  components: { SettingReadonlyInput },
  emits: ["verify"],
  props: {
    verifyStatus: {
      type: String as PropType<EmailVerificationStatus>,
      required: true,
    },
    validationMessage: {
      type: String as PropType<string>,
      required: false,
    },
  },
  computed: {
    verified(): boolean {
      return this.verifyStatus === EmailVerificationStatus.Verified;
    },
    verifying(): boolean {
      return this.verifyStatus === EmailVerificationStatus.Verifying;
    },
    noneVerify(): boolean {
      return this.verifyStatus === EmailVerificationStatus.None;
    },
    verifyingFailed(): boolean {
      return this.verifyStatus === EmailVerificationStatus.Failed;
    },
    verifyButtonLabel(): string {
      switch (this.verifyStatus) {
        case EmailVerificationStatus.Verified:
          return "Verified";
        case EmailVerificationStatus.Verifying:
          return "Verifying";
        case EmailVerificationStatus.Failed:
          return "Failed";
        default:
          return "Verify";
      }
    },
  },
  methods: {
    verify(): void {
      this.$emit("verify");
    },
  },
});
</script>

<style scoped lang="scss">
.email-verify {
  .verify-button {
    font-size: 14px;
    width: 130px;
    height: 36px;
    display: flex;
  }
}
</style>
<style lang="scss">
.email-verify {
  .verified .q-icon {
    color: $success;
  }
  .failed .q-icon {
    color: $negative;
  }
  .noneVerify .q-icon {
    color: $black-70;
  }
  .validation {
    font-size: 12px;
  }
  .verified-message {
    color: $success;
  }
  .non-verified-message {
    color: $negative;
  }
}
</style>
