<template>
  <div class="row items-center">
    <div class="col-1">
      <div v-if="!isOnlyDefaultNodePool" class="node-pool-icon" aid="node-pool-icon">
        <runai-svg-icon name="node-pool" size="22"></runai-svg-icon>
        <div class="rotated-text">NODE POOLS</div>
      </div>
      <div v-else class="project-icon-wrapper">
        <runai-svg-icon :name="iconName" size="22"></runai-svg-icon>
      </div>
    </div>
    <div class="col-11">
      <node-pool-row
        v-for="resource in nodePoolsResources"
        :key="resource.nodePool.name"
        :department-name="department?.name"
        :resource="resource"
        :is-over-quota-priority-enabled="isOverQuotaPriorityEnabled"
        :is-cpu-enabled="isCpuEnabled"
        :is-department-enabled="isDepartmentEnabled"
        :read-only="readOnly"
        :is-only-default-node-pool="isOnlyDefaultNodePool"
        :priorities="priorities"
        :priorities-options="prioritiesOptions"
        :is-node-pool-over-quota="isNodePoolOverQuota[resource.nodePool.name]"
        :projects-deserved-resources="projectsDeservedResources[resource.nodePool.name]"
        :department-deserved-resources="departmentDeservedResources[resource.nodePool.name]"
        :allocated-non-preemptible-resources="allocatedNonPreemptibleResources[resource.nodePool.name]"
        @update-node-pool-over-quota="updateNodePoolResourceOverQuota"
        @update-node-pool-resource="updateNodePoolResource"
        @update-node-pool-priority="setNodePoolPriority"
      />
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
//cmps
import { NodePoolRow } from "@/components/quota-management/node-pools-table/node-pool-row/";
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";
import type {
  INodePoolIsOverQuotaRecord,
  INodePoolResourcesSum,
  NodePoolResourcesSumRecord,
} from "@/models/resource.model";
//models
import {
  CPU_VALUE_FACTOR,
  defaultQuotaOption,
  defaultQuotaPriorityOption,
  EMPTY_PRIORITY_VALUE,
  EQuotaEntity,
  EResourceType,
  type NodePoolAllocatedNonPreemptibleSumRecord,
} from "@/models/resource.model";
import type { ISelectOption } from "@/models/global.model";
import type { INodePoolResources } from "@/models/project.model";
import type { IDepartment } from "@/models/department.model";
//stores
import { useSettingStore } from "@/stores/setting.store";
import { useProjectStore } from "@/stores/project.store";
//utils
import { resourceUtil } from "@/utils/resource.util";
import { deepCopy } from "@/utils/common.util";
import { useNodePoolStore } from "@/stores/node-pool.store";
import { departmentService } from "@/services/control-plane/department.service/department.service";
import type { NodePoolQuotaStatus } from "@/swagger-models/backend-client";

export default defineComponent({
  components: {
    NodePoolRow,
    RunaiSvgIcon,
  },
  emits: ["update:node-pools-resources", "update:node-pools-priorities", "update:node-pools-resource"],
  inject: ["entity"],
  props: {
    departmentId: {
      type: Number as PropType<number>,
      required: false,
    },
    clusterId: {
      type: String as PropType<string>,
      default: "",
    },
    nodePoolsResources: {
      type: Array as PropType<Array<INodePoolResources>>,
      required: true,
    },
    nodePoolsPriorities: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
    readOnly: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    isCpuEnabled: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    isOverQuotaPriorityEnabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    quotaStatuses: {
      type: Array as PropType<Array<NodePoolQuotaStatus>>,
      default: () => [],
    },
  },
  data() {
    return {
      settingStore: useSettingStore(),
      projectStore: useProjectStore(),
      nodePoolStore: useNodePoolStore(),
      selectedOverQuotaOption: defaultQuotaOption as ISelectOption,
      selectedOverQuotaPriorityOption: defaultQuotaPriorityOption as ISelectOption,
      selectedOverQuotaPriorityOptions: [] as Array<ISelectOption>,
      selectedOverQuotaOptions: [] as Array<ISelectOption>,
      prioritiesOptions: [] as Array<string | number>,
      priorities: [...this.nodePoolsPriorities] as Array<string>,
      department: null as IDepartment | null,
    };
  },
  async created() {
    await this.loadDepartment();
    this.getPrioritiesOptions();
    if (this.isOverQuotaPriorityEnabled) {
      this.initOverQuotaPriorityOptions();
    } else {
      this.initOverQuotaOptions();
    }
  },
  computed: {
    projectId(): number | null {
      return +this.$route.params.id || null;
    },
    isDepartmentEnabled(): boolean {
      return this.settingStore.isDepartmentEnabled;
    },
    sortedDepartmentNodePools(): INodePoolResources[] {
      return resourceUtil.sortNodePools(this.department?.nodePoolsResources || []);
    },
    departmentDeservedResources(): NodePoolResourcesSumRecord {
      return this.sortedDepartmentNodePools.reduce((acc: NodePoolResourcesSumRecord, nodePool: INodePoolResources) => {
        const resourcesSum = {} as INodePoolResourcesSum;
        resourcesSum.gpu = resourceUtil.sumOfResourcesByType([nodePool], EResourceType.GPU);
        resourcesSum.cpu = resourceUtil.getNodePoolResourceSumByType([nodePool], EResourceType.CPU);
        resourcesSum.memory = resourceUtil.getNodePoolResourceSumByType([nodePool], EResourceType.MEMORY);
        acc[nodePool.nodePool.name] = resourcesSum;
        return acc;
      }, {} as NodePoolResourcesSumRecord);
    },
    projectsDeservedResources(): NodePoolResourcesSumRecord {
      if (this.entity === EQuotaEntity.project) {
        return resourceUtil.projectsDeservedResources(
          this.nodePoolsResources,
          this.projectId,
          this.department?.projects || [],
          this.projectStore.projectById,
          this.isOnlyDefaultNodePool,
        );
      }
      //for department, we can use the aggregated resources.
      const nodePoolAggregatedResources: Record<string, INodePoolResourcesSum> = {};
      this.department?.projectAggregatedNodePoolsResources.forEach((nodeAggregatedPoolsResources) => {
        nodePoolAggregatedResources[nodeAggregatedPoolsResources.nodePool.name] = {
          gpu: nodeAggregatedPoolsResources.gpu.deserved,
          cpu: nodeAggregatedPoolsResources.cpu.deserved,
          memory: nodeAggregatedPoolsResources.memory.deserved,
        };
      });
      return nodePoolAggregatedResources;
    },
    isOnlyDefaultNodePool(): boolean {
      return this.nodePoolsResources.length === 1;
    },
    allocatedNonPreemptibleResources(): NodePoolAllocatedNonPreemptibleSumRecord {
      return resourceUtil.allocatedNonPreemptibleResources(this.quotaStatuses);
    },
    isNodePoolOverQuota(): INodePoolIsOverQuotaRecord {
      return this.sortedDepartmentNodePools.reduce(
        (nodePoolOverQuotaMap: INodePoolIsOverQuotaRecord, nodePool: INodePoolResources) => {
          nodePoolOverQuotaMap[nodePool.nodePool.name] = resourceUtil.computeNodePoolResourceExceedsQuota(
            this.projectsDeservedResources[nodePool.nodePool.name],
            this.departmentDeservedResources[nodePool.nodePool.name],
          );
          return nodePoolOverQuotaMap;
        },
        {} as INodePoolIsOverQuotaRecord,
      );
    },
    iconName(): string {
      return this.entity === EQuotaEntity.project ? "project-icon" : "departments-icon";
    },
  },
  methods: {
    async loadDepartment(): Promise<void> {
      if (!this.departmentId || !this.clusterId) return;
      try {
        this.department = await departmentService.getById(this.departmentId, this.clusterId);
      } catch (e) {
        console.error("failed to get department", e);
      }
    },
    getPrioritiesOptions(): void {
      const priorities = Array.from({ length: this.nodePoolsResources.length }, (_, i) => i + 1) as [number | string];
      priorities.unshift(EMPTY_PRIORITY_VALUE);
      this.prioritiesOptions = priorities;
    },
    setNodePoolPriority({ value, nodePoolName }: { value: string | number; nodePoolName: string }): void {
      if (value === EMPTY_PRIORITY_VALUE) {
        this.priorities.splice(this.priorities.indexOf(nodePoolName), 1);
      } else {
        this.priorities[Number(value) - 1] = nodePoolName;
      }
      this.$emit("update:node-pools-priorities", this.priorities);
    },
    updateNodePoolResource({
      name,
      resourceType,
      resourceValue,
    }: {
      name: string;
      resourceType: EResourceType;
      resourceValue: number | null;
    }): void {
      const nodePoolsResources = deepCopy(this.nodePoolsResources);
      const nodePoolIndex = nodePoolsResources.findIndex(
        (nodePool: INodePoolResources) => nodePool.nodePool.name === name,
      );

      if (resourceType === EResourceType.CPU) {
        const updatedVal = resourceValue === null ? resourceValue : resourceValue * CPU_VALUE_FACTOR;
        nodePoolsResources[nodePoolIndex][resourceType].deserved = updatedVal;
      } else {
        nodePoolsResources[nodePoolIndex][resourceType].deserved = resourceValue;
      }

      this.$emit("update:node-pools-resources", nodePoolsResources);
    },
    updateNodePoolResourceOverQuota({ name, overQuota }: { name: string; overQuota: number }): void {
      const nodePoolsResources = deepCopy(this.nodePoolsResources);
      const nodePoolIndex = nodePoolsResources.findIndex(
        (nodePool: INodePoolResources) => nodePool.nodePool.name === name,
      );
      nodePoolsResources[nodePoolIndex].gpu.overQuotaWeight = overQuota;
      nodePoolsResources[nodePoolIndex].cpu.overQuotaWeight = overQuota;
      nodePoolsResources[nodePoolIndex].memory.overQuotaWeight = overQuota;
      this.$emit("update:node-pools-resources", nodePoolsResources);
    },

    initOverQuotaPriorityOptions(): void {
      this.selectedOverQuotaPriorityOptions = this.nodePoolsResources.map((nodePool) =>
        this.getSelectedOverQuotaOption(nodePool.gpu.overQuotaWeight),
      );
    },
    getSelectedOverQuotaPriorityOption(overQuotaPriority: number | null | undefined): ISelectOption {
      if (overQuotaPriority === null || overQuotaPriority === undefined) {
        return defaultQuotaPriorityOption;
      }
      return {
        label: resourceUtil.getOverQuotaPriorityKeyByValue(overQuotaPriority),
        value: overQuotaPriority,
      };
    },
    initOverQuotaOptions(): void {
      this.selectedOverQuotaOptions = this.nodePoolsResources.map((nodePool) =>
        this.getSelectedOverQuotaPriorityOption(nodePool.gpu.overQuotaWeight),
      );
    },
    getSelectedOverQuotaOption(overQuota: number | null | undefined): ISelectOption {
      if (overQuota === null || overQuota === undefined) {
        return defaultQuotaOption;
      }
      return {
        label: resourceUtil.getOverQuotaKeyByValue(overQuota),
        value: overQuota,
      };
    },
  },
  watch: {
    async departmentId() {
      await this.loadDepartment();
    },
  },
});
</script>
<style scoped lang="scss">
.node-pool-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: $body-background-color;
  border-radius: 5px;
  width: 46px;
  min-width: 46px;
  padding: 10px 0;
  font-size: 12px;
  margin-block: 4px;
  min-height: 130px;
  height: 100%;
  .rotated-text {
    white-space: nowrap;
    transform: rotate(-90deg);
    position: relative;
    top: 40px;
  }
}
.project-icon-wrapper {
  position: relative;
  background-color: $body-background-color;
  border-radius: 5px;
  width: 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  min-height: 46px;
}
</style>
