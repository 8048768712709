<template>
  <runai-base-dialog
    aid="compliance-info-modal"
    class="compliance-info-modal"
    :model-value="true"
    @close="$emit('close')"
    size="width-lg"
    no-padding
  >
    <template #header>{{ title }}</template>
    <template #body>
      <div class="compliance-info-modal-content">
        <div class="subtitle" aid="compliance-info-modal-subtitle">
          {{ subtitle }}
        </div>
        <q-separator />
        <runai-table
          :rows="rows"
          :columns="columns"
          :loading="false"
          hide-pagination
          :filter-by="{ rowsPerPage: 0 }"
          no-shadow
          sticky-header
          :style="{ maxHeight: '500px' }"
          disable-selection
          :bordered="false"
        />
      </div>
    </template>
    <template #footer>
      <q-btn label="Close" color="primary" @click="$emit('close')" aid="compute-policy-info-modal-close-button" />
    </template>
  </runai-base-dialog>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "@vue/runtime-core";

// cmps
import { RunaiBaseDialog } from "@/components/common/runai-base-dialog";
import { RunaiTable } from "@/components/common/runai-table";

// models
import { type ComplianceInfo, type ComplianceInfoReason, AssetKind } from "@/swagger-models/assets-service-client";
import { allPreviewPolicyColumns, policyTypeColumn } from "@/table-models/policy.table-model";
import type { ITableColumn } from "@/models/table.model";

// utils
import { stringUtil } from "@/utils/string.util";
import {
  getDistributedPolicyViewDetails,
  getPolicyViewDetails,
  type IPolicyViewDetails,
  type IPolicyViewProperty,
} from "@/utils/policy.util/policy-view.util";
import type { ReplicaType } from "@/swagger-models/workloads-service-client";

export default defineComponent({
  components: {
    RunaiBaseDialog,
    RunaiTable,
  },
  emits: ["close"],
  props: {
    complianceInfo: {
      type: Object as PropType<ComplianceInfo>,
      required: true,
    },
    policy: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Object as PropType<any>,
      required: true,
    },
    field: {
      type: String as PropType<AssetKind>,
      required: true,
    },
    title: {
      type: String as PropType<string>,
      required: true,
    },
    subtitle: {
      type: String as PropType<string>,
      required: true,
    },
    distributedReplicaType: {
      type: String as PropType<ReplicaType>,
      required: false,
    },
  },
  data() {
    return {
      complianceKeys: new Set<string>(),
    };
  },
  created() {
    if (!this.complianceInfo.reason) return;
    this.complianceKeys = new Set<string>(
      this.complianceInfo.reason
        .map((reason: ComplianceInfoReason) => {
          let field: string = this.removeAttributesFromField(reason.field || "");
          return field ? stringUtil.getStringAfterFirstDot(field) : "";
        })
        .filter((field: string) => field),
    );
  },
  computed: {
    rows(): Array<IPolicyViewProperty> {
      const isFieldExistsInPolicy = this.isDataSourceField
        ? !!Object.keys(this.currentPolicy.storage || {}).length
        : !!Object.keys(this.currentPolicy[this.field as keyof IPolicyViewDetails] || {}).length;

      if (!isFieldExistsInPolicy) return [];

      const allPolicyFields: Array<IPolicyViewProperty> = this.isDataSourceField
        ? Object.values(this.currentPolicy.storage || {})
        : Object.values(this.currentPolicy[this.field as keyof IPolicyViewDetails] || {});

      let filteredRules: Array<IPolicyViewProperty> = allPolicyFields.filter((row: IPolicyViewProperty) => {
        const isRelevantField = this.isDataSourceField
          ? this.complianceKeys.has(`${this.field}.${row.name}`)
          : this.complianceKeys.has(row.name);
        if (this.distributedReplicaType) {
          return row.type?.includes(this.distributedReplicaType.toLocaleLowerCase()) && isRelevantField;
        }
        return isRelevantField;
      });

      return filteredRules;
    },
    currentPolicy(): IPolicyViewDetails {
      if (this.distributedReplicaType) return getDistributedPolicyViewDetails(this.policy.effective);
      return getPolicyViewDetails(this.policy.effective);
    },
    columns(): Array<ITableColumn> {
      if (!this.isDataSourceField && !this.distributedReplicaType) return allPreviewPolicyColumns;

      const dataSourceColumns = [...allPreviewPolicyColumns];
      dataSourceColumns.splice(1, 0, policyTypeColumn);
      return dataSourceColumns;
    },
    isDataSourceField(): boolean {
      return (
        this.field === AssetKind.ConfigMap ||
        this.field === AssetKind.HostPath ||
        this.field === AssetKind.Git ||
        this.field === AssetKind.Nfs ||
        this.field === AssetKind.S3 ||
        this.field === AssetKind.Pvc ||
        this.field === AssetKind.SecretVolume
      );
    },
  },
  methods: {
    removeAttributesFromField(field: string): string {
      const attributesString = "attributes";
      const hasAttributesInField: boolean = field?.includes(attributesString);

      if (!hasAttributesInField) return field;

      return field
        .split(".")
        .filter((s: string) => s !== attributesString)
        .join(".");
    },
  },
});
</script>

<style lang="scss" scoped>
.compliance-info-modal-content {
  padding: 15px;
  .subtitle {
    margin-bottom: 15px;
    font-size: 12px;
  }
}
</style>
