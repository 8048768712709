<template>
  <runai-filter-chip
    title="Cluster"
    :value="clusterName"
    @save="$emit('update-filter', selected)"
    @close="selected = filterValue"
  >
    <template v-slot:input-component>
      <runai-radio-options
        class="cluster-radio-buttons"
        v-model="selected"
        :options="options"
        option-max-width="200px"
      ></runai-radio-options>
    </template>
  </runai-filter-chip>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "@vue/runtime-core";
// stores
import { useClusterStore } from "@/stores/cluster.store";
// cmps
import { RunaiFilterChip } from "@/components/common/runai-filter-chip";
// type
import type { ISelectOption } from "@/models/global.model";
import type { ICluster } from "@/models/cluster.model";

import { RunaiRadioOptions } from "@/components/common/runai-radio-options";

const allOption: ISelectClusterOption = { label: "All", value: "" };

interface ISelectClusterOption extends ISelectOption {
  value: string;
}

export default defineComponent({
  components: {
    RunaiFilterChip,
    RunaiRadioOptions,
  },
  emits: ["update-filter"],
  props: {
    filterValue: {
      type: String as PropType<string>,
      required: true,
    },
    hideAllOption: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      clusterStore: useClusterStore(),
      selected: null as ISelectClusterOption["value"] | null,
    };
  },
  created() {
    this.initSelectedCluster();
  },
  computed: {
    options(): ISelectClusterOption[] {
      const options: ISelectClusterOption[] = this.clusterStore.clusterList.map((c: ICluster) => {
        return { label: c.name, value: c.uuid };
      });
      if (!this.hideAllOption) {
        options.unshift(allOption);
      }
      return options;
    },
    clusterName(): string {
      const defaultLabel = this.hideAllOption ? this.currentClusterName : "All";
      const selectedClusterLabel = this.options.find(
        (opt: ISelectClusterOption) => opt.value === this.filterValue,
      )?.label;
      return selectedClusterLabel || defaultLabel;
    },
    currentClusterName(): string {
      return this.clusterStore.currentClusterName;
    },
    currentClusterId(): string {
      return this.clusterStore.currentClusterId;
    },
  },
  methods: {
    initSelectedCluster(): void {
      if (this.filterValue && this.clusterStore.clusterList.some((c: ICluster) => c.uuid === this.filterValue)) {
        this.selected = this.filterValue;
      } else {
        this.selected = this.hideAllOption ? this.currentClusterId : allOption.value;
        this.$emit("update-filter", this.selected);
      }
    },
  },
  watch: {
    filterValue() {
      this.initSelectedCluster();
    },
  },
});
</script>
<style lang="scss" scoped>
.cluster-radio-buttons {
  max-height: 30vh;
  overflow: scroll;
}
</style>
