import { ETableFilters } from "@/models/table.model";
import type { IPolicy } from "@/stores/policy.store";
import type {
  ComputeAsset,
  CredentialsListResponseEntry,
  DatasourceListResponseEntry,
  EnvironmentAsset,
  WorkloadTemplate,
} from "@/swagger-models/assets-service-client";
import type { IDepartment } from "@/models/department.model";
import { EProjectColumnName, type IProject } from "@/models/project.model";
import { EDepartmentColumnName } from "@/models/department.model";
import { EIntervalLabels } from "@/models/interval.model";

interface IIndexTableInfo {
  storageFilterKey: ETableFilters;
  sortByDefault: string;
  entityText: string;
  intervalLabel?: EIntervalLabels;
  intervalLabelPostCreation?: EIntervalLabels;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getEntityId: (e: any) => string;
}

export enum EIndexPages {
  COMPUTE_RESOURCE = "computeResource",
  DATA_SOURCE = "dataSource",
  ENVIRONMENT = "environment",
  CREDENTIAL = "credential",
  WORKLOAD_TEMPLATE = "workloadTemplate",
  POLICY = "policy",
  DEPARTMENT = "department",
  PROJECT = "project",
}

export const useTableDataIndexMapper: Record<EIndexPages, IIndexTableInfo> = {
  [EIndexPages.COMPUTE_RESOURCE]: {
    storageFilterKey: ETableFilters.COMPUTE_RESOURCE,
    sortByDefault: "compute-resource",
    entityText: "compute resources",
    getEntityId: (e: ComputeAsset) => e.meta?.id || "",
  },
  [EIndexPages.DATA_SOURCE]: {
    storageFilterKey: ETableFilters.DATA_SOURCE,
    sortByDefault: "data-source",
    entityText: "data sources",
    getEntityId: (e: DatasourceListResponseEntry) => e.meta?.id || "",
    intervalLabel: EIntervalLabels.DataSourcesIndex,
    intervalLabelPostCreation: EIntervalLabels.IndexPostCreationRefreshRate,
  },
  [EIndexPages.ENVIRONMENT]: {
    storageFilterKey: ETableFilters.ENVIRONMENT,
    sortByDefault: "environment",
    entityText: "environments",
    getEntityId: (e: EnvironmentAsset) => e.meta?.id || "",
  },
  [EIndexPages.CREDENTIAL]: {
    storageFilterKey: ETableFilters.CREDENTIAL,
    sortByDefault: "credentials",
    entityText: "credentials",
    getEntityId: (e: CredentialsListResponseEntry) => e.meta?.id || "",
    intervalLabel: EIntervalLabels.CredentialsIndex,
    intervalLabelPostCreation: EIntervalLabels.IndexPostCreationRefreshRate,
  },
  [EIndexPages.WORKLOAD_TEMPLATE]: {
    storageFilterKey: ETableFilters.TEMPLATE,
    sortByDefault: "template",
    entityText: "templates",
    getEntityId: (e: WorkloadTemplate) => e.meta?.id || "",
  },
  [EIndexPages.POLICY]: {
    storageFilterKey: ETableFilters.POLICY,
    sortByDefault: "parameter",
    entityText: "policies",
    getEntityId: (e: IPolicy) => e.policyId || "",
    intervalLabel: EIntervalLabels.PolicyIndex,
    intervalLabelPostCreation: EIntervalLabels.IndexPostCreationRefreshRate,
  },
  [EIndexPages.DEPARTMENT]: {
    storageFilterKey: ETableFilters.DEPARTMENT,
    sortByDefault: EDepartmentColumnName.DepartmentName,
    entityText: "departments",
    getEntityId: (e: IDepartment) => e.id?.toString() || "",
  },
  [EIndexPages.PROJECT]: {
    storageFilterKey: ETableFilters.PROJECT,
    sortByDefault: EProjectColumnName.ProjectName,
    entityText: "projects",
    getEntityId: (e: IProject) => e.id.toString() || "",
    intervalLabel: EIntervalLabels.ProjectsIndex,
    intervalLabelPostCreation: EIntervalLabels.IndexPostCreationRefreshRate,
  },
};
