<template>
  <div class="tool-access row">
    <q-input
      :model-value="placeholderLabel"
      no-error-icon
      label="Access"
      stack-label
      :disable="true"
      class="flex-1 tool-access-label"
    >
      <template v-slot:prepend>
        <runai-svg-icon class="q-mt-md" name="user-lock" />
      </template>
    </q-input>
    <span>
      <q-btn
        class="edit-btn"
        round
        flat
        size="sm"
        icon="fa-solid fa-pen"
        @click="showModal = true"
        aid="edit-access-button"
      ></q-btn>
      <q-tooltip max-width="270px">Click to change who can access this tool</q-tooltip>
    </span>

    <tool-access-modal
      v-if="showModal"
      :account-name="accountName"
      :default-user="userEmail"
      :tool-name="toolName"
      :access="access"
      :multi-users="isMultiUsers"
      @close="onClose"
      @save="saveAccess"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// cmps
import RunaiSvgIcon from "@/components/common/runai-svg-icon/runai-svg-icon.vue";
import { ToolAccessModal } from "./tool-access-modal";
import type { IToolAccess } from "./tool-access-modal/tool-access-modal.model";

// stores
import { useAppStore } from "@/stores/app.store";
import { useAuthStore } from "@/stores/auth.store";
import { useClusterStore } from "@/stores/cluster.store";

// constants
import { MIN_CLUSTER_VERSION_FOR_WORKLOAD_ACCESS_MULTI_USERS } from "@/common/version.constant";

// utils
import { addOrMoveItemToBeFirst } from "./tool-access.util";

export default defineComponent({
  components: {
    RunaiSvgIcon,
    ToolAccessModal,
  },
  emits: ["save"],
  props: {
    access: {
      type: Object as PropType<IToolAccess>,
      required: true,
    },
    toolName: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      appStore: useAppStore(),
      authStore: useAuthStore(),
      clusterStore: useClusterStore(),
      placeholderLabel: "" as string,
      showModal: false as boolean,
      accessRoles: {} as IToolAccess,
    };
  },
  created() {
    this.accessRoles.authorizedGroups = this.access.authorizedGroups;
    this.accessRoles.authorizedUsers = this.authorizedUsers();
    this.updateLabel();
  },
  computed: {
    accountName(): string {
      return this.appStore.tenantName;
    },
    userEmail(): string {
      return this.authStore.userEmail;
    },
    isMultiUsers(): boolean {
      return this.clusterStore.isClusterVersionSufficient(
        this.clusterStore.currentClusterId,
        MIN_CLUSTER_VERSION_FOR_WORKLOAD_ACCESS_MULTI_USERS,
      );
    },
  },
  methods: {
    onClose(): void {
      this.showModal = false;
    },
    saveAccess(access: IToolAccess): void {
      this.showModal = false;
      this.accessRoles = access;

      this.$emit("save", access);
      this.$nextTick(() => {
        this.updateLabel();
      });
    },
    authorizedUsers(): string[] | undefined {
      if (!this.access.authorizedUsers) return undefined;

      return addOrMoveItemToBeFirst(this.access.authorizedUsers, this.userEmail);
    },
    updateLabel(): void {
      if (this.accessRoles.authorizedGroups) {
        this.placeholderLabel = `Groups (${this.accessRoles.authorizedGroups.length})`;
        return;
      }

      const authorizedUsers: string[] | undefined | null = this.accessRoles.authorizedUsers;
      if (authorizedUsers) {
        this.placeholderLabel = this.isMultiUsers ? `Users (${authorizedUsers.length})` : `Private`;
      } else {
        this.placeholderLabel = `Everyone at ${this.accountName}`;
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.tool-access {
  width: 100%;

  .tool-access-label {
    margin-bottom: 20px;
  }

  .edit-btn {
    color: $black-54;
    margin-top: 23px;
  }
}
</style>
