import { defineStore } from "pinia";

// models
import type {
  WorkloadCreationRequest,
  Workspace,
  WorkspaceCreationRequestV2,
  WorkspaceV2,
} from "@/swagger-models/assets-service-client";
import { ResourceType, type Action, type PermittedScopes } from "@/swagger-models/authorization-client";
import type { IWorkspaceList } from "@/models/workspace.model";
import type { IUIWorkloadCreation } from "@/models/workload.model";

// services
import { workspaceService } from "@/services/control-plane/workspace.service/workspace.service";
import { permissionService } from "@/services/authorization/permission.service/permission.service";

// stores
import { useClusterStore } from "@/stores/cluster.store";

// utils
import { workloadUtil } from "@/utils/workload.util/workload.util";

export const useWorkspaceStore = defineStore("Workspace", {
  state: () => ({
    workspace: workloadUtil.getEmptyUIWorkloadCreation() as IUIWorkloadCreation,
    templateId: null as string | null,
    selectedWorkspace: null as WorkspaceV2 | null,
    actionPermissionsByScopes: {} as Record<Action, PermittedScopes>,
  }),
  getters: {
    workspaceName(): string {
      return this.workspace.name || "";
    },
    selectedTemplate(): string | null {
      return this.templateId;
    },
  },
  actions: {
    async loadActionPermissions(): Promise<void> {
      if (Object.keys(this.actionPermissionsByScopes).length > 0) return;
      this.actionPermissionsByScopes = await permissionService.getPermittedScopesForAction(ResourceType.Workspaces);
    },
    async loadById(id: string): Promise<WorkspaceV2> {
      this.selectedWorkspace = await workspaceService.getById(id);
      return this.selectedWorkspace;
    },
    setWorkspace(workspace: IUIWorkloadCreation): void {
      this.workspace = workspace;
    },
    setTemplate(templateId: string | null): void {
      this.templateId = templateId;
    },
    resetCreateWorkspaceData(): void {
      this.templateId = null;
      this.workspace = workloadUtil.getEmptyUIWorkloadCreation();
    },
    async createWorkspaceV2(workspace: WorkspaceCreationRequestV2): Promise<WorkspaceV2> {
      return workspaceService.createWorkspaceV2(workspace);
    },
    async createWorkspace(workspace: WorkloadCreationRequest): Promise<IWorkspaceList | null> {
      const createdWorkspace: Workspace = await workspaceService.createFromAssets(workspace);
      const clusterStore = useClusterStore();
      const newWorkspace = await workspaceService.getByIdFromCluster(
        clusterStore.currentClusterId,
        createdWorkspace.meta.id,
      );
      return newWorkspace;
    },
  },
});
