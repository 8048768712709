import { ResourceType, Action } from "@/swagger-models/authorization-client";

import type { RunaiRouteRecord } from "vue-router";
import { NODE_ROUTE_NAMES } from "@/router/node.routes/node.routes.names";

export const nodeRoutes: RunaiRouteRecord = {
  path: "/nodes",
  // route level code-splitting
  // this generates a separate chunk (node.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import("@/views/node/node-app.vue"),
  children: [
    {
      path: "",
      name: NODE_ROUTE_NAMES.NODE_INDEX,
      component: () => import("@/views/node/node-index.vue"),
      meta: {
        pageTitle: "Nodes",
        resourceType: ResourceType.Nodes,
        minPermittedActions: [Action.Read],
        requiresAuth: true,
      },
    },
    {
      path: "node-pool",
      name: NODE_ROUTE_NAMES.NODE_POOL_INDEX,
      component: () => import("@/views/node/node-pool-index.vue"),
      meta: {
        pageTitle: "Node pools",
        resourceType: ResourceType.Nodepools,
        minPermittedActions: [Action.Read],
        requiresAuth: true,
      },
    },
    {
      path: "new-node-pool",
      name: NODE_ROUTE_NAMES.NODE_POOL_NEW,
      component: () => import("@/views/node-pools/node-pool-edit/node-pool-edit.vue"),
      meta: {
        location: "nodes/node-pool/new",
        backPageName: NODE_ROUTE_NAMES.NODE_POOL_INDEX,
        pageTitle: "New node pool",
        resourceType: ResourceType.Nodepools,
        minPermittedActions: [Action.Create],
        requiresAuth: true,
        fullWidth: true,
        requestToLeave: true,
      },
    },
    {
      path: "edit-node-pool/:id",
      name: NODE_ROUTE_NAMES.NODE_POOL_EDIT,
      component: () => import("@/views/node-pools/node-pool-edit/node-pool-edit.vue"),
      meta: {
        location: "nodes/node-pool/edit/:id",
        backPageName: NODE_ROUTE_NAMES.NODE_POOL_INDEX,
        pageTitle: "Edit node pool",
        resourceType: ResourceType.Nodepools,
        minPermittedActions: [Action.Update],
        requiresAuth: true,
        fullWidth: true,
        requestToLeave: true,
      },
    },
  ],
};
