<template>
  <button :disabled="readOnly" class="editable-button" v-bind="$attrs" :class="buttonClass" type="button">
    {{ editableText }}
    <q-menu anchor="bottom middle" self="top middle" @hide="resetValue" v-model="isOpen">
      <q-card :class="isDepartmentEnabled ? 'with-chart' : ''">
        <q-card-section class="row justify-between items-center q-py-sm card-wrapper">
          {{ $options.RESOURCE_LABEL.CPU }}
          <q-btn flat size="12px" icon="fa-regular fa-xmark" class="q-pa-xs" round v-close-popup></q-btn>
        </q-card-section>
        <q-card-section class="q-py-sm">
          <div class="row" :class="{ 'input-row': isDepartmentEnabled }">
            <div :class="{ 'col-9': isDepartmentEnabled }">
              <runai-numeric-input
                class="q-mr-md"
                :class="{ 'quota-input': isDepartmentEnabled }"
                aid="cpu-quota-input"
                ref="numeric-input"
                :autofocus="true"
                v-model.number="inputValue"
                :placeholder="$options.EResourceState.Unlimited"
                input-class="wide-input"
                no-error-icon
                stack-label
                :read-only="readOnly"
              >
                <template v-if="isCpuExceedsQuota || isCpuUnderAllocatedNonPreemptible" #error>
                  <div v-text="alertText" class="quota-exceeds-alert" :class="textAlertClass"></div>
                </template>
              </runai-numeric-input>
            </div>
          </div>
          <template v-if="!hideSlider">
            <div class="row q-my-xl">
              <div class="col q-pr-xl">
                <quota-slider
                  v-model="inputValue"
                  :max="departmentDeservedCpu / $options.CPU_VALUE_FACTOR"
                  :inner-max="sliderInnerMax"
                  :inner-min="sliderInnerMin"
                  :is-warning-on="isCpuExceedsQuota || isCpuUnderAllocatedNonPreemptible"
                  :is-limited="isLimitQuotaOverSubscriptionEnabled"
                  inner-max-marker-text="Department available quota"
                  :inner-min-marker-text="innerMinMarkerText"
                  :hide-max="isDepartmentEntity"
                />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="italic text-caption">
                  For more information, see the
                  <a :href="documentationLink" target="_blank">{{ documentationLinkText }}</a>
                </div>
              </div>
            </div>
          </template>
        </q-card-section>
        <q-card-section class="row justify-end q-py-sm">
          <q-btn
            aid="save-quota-btn"
            flat
            color="primary"
            label="Apply"
            v-close-popup="!isInputInvalid"
            @click="updateModelValue"
          />
        </q-card-section>
      </q-card>
    </q-menu>
  </button>
  <q-tooltip v-if="isAlertOn" max-width="350px" :offset="[180, 0]" :class="tooltipAlertClass" anchor="top right"
    >{{ alertText }}
  </q-tooltip>
</template>

<script lang="ts">
import { inject, type PropType, ref, toRef } from "vue";
import { defineComponent } from "vue";

// cmps
import { RunaiNumericInput } from "@/components/common/runai-numeric-input";

// models
import { CPU_VALUE_FACTOR, EQuotaEntity, EResourceLabel, EResourceState } from "@/models/resource.model";
//utils
import { resourceUtil } from "@/utils/resource.util";
import { QuotaSlider } from "@/components/quota-management/quota-slider";
import {
  useQuotaInput,
  type useQuotaInputProperties,
} from "@/components/quota-management/quota-inputs/quota-input-composable/use-quota-input.composeable";

export default defineComponent({
  components: {
    QuotaSlider,
    RunaiNumericInput,
  },
  emits: ["update:model-value", "close"],
  inject: ["entity"],
  EResourceState: EResourceState,
  RESOURCE_LABEL: EResourceLabel,
  CPU_VALUE_FACTOR: CPU_VALUE_FACTOR,
  props: {
    modelValue: {
      type: [Number, null] as PropType<number | null>,
      required: true,
    },
    readOnly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    projectsDeservedCpu: {
      type: Number as PropType<number>,
      default: 0,
    },
    departmentDeservedCpu: {
      type: Number as PropType<number>,
      default: 0,
    },
    allocatedNonPreemptibleCpu: {
      type: Number as PropType<number>,
      default: 0,
    },
    isAlertOn: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    projectsDeservedCpuIsUnlimited: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    departmentDeservedCpuIsUnlimited: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    hideSlider: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputValue: this.modelValue as number | null,
    };
  },
  setup(props) {
    const entity = ref(inject<EQuotaEntity>("entity"));
    if (!entity.value) {
      throw new Error("'entity' is not provided");
    }
    const {
      isProjectEntity,
      isOpen,
      isLimitQuotaOverSubscriptionEnabled,
      isDepartmentEnabled,
      documentationLink,
      documentationLinkText,
      innerMinMarkerText,
      buttonClass,
      textAlertClass,
      tooltipAlertClass,
    }: useQuotaInputProperties = useQuotaInput(entity.value, toRef(props, "isAlertOn"));
    return {
      isProjectEntity,
      isOpen,
      isLimitQuotaOverSubscriptionEnabled,
      isDepartmentEnabled,
      documentationLink,
      documentationLinkText,
      innerMinMarkerText,
      buttonClass,
      textAlertClass,
      tooltipAlertClass,
    };
  },
  computed: {
    sliderInnerMin(): number {
      return this.isDepartmentEntity
        ? this.projectsDeservedCpu / CPU_VALUE_FACTOR
        : this.allocatedNonPreemptibleCpu / CPU_VALUE_FACTOR;
    },
    sliderInnerMax(): number | undefined {
      return this.isDepartmentEntity ? undefined : this.departmentAvailableQuota / CPU_VALUE_FACTOR;
    },
    isDepartmentEntity(): boolean {
      return this.entity === EQuotaEntity.department;
    },
    EQuotaEntity(): typeof EQuotaEntity {
      return EQuotaEntity;
    },
    innerProjectsDeservedCpu(): number {
      if (this.modelValue === null) {
        return this.projectsDeservedCpu;
      }
      return this.projectsDeservedCpu - this.modelValue * CPU_VALUE_FACTOR;
    },
    departmentAvailableQuota(): number {
      if (this.modelValue === null) {
        return this.departmentDeservedCpu - this.projectsDeservedCpu;
      }
      return this.modelValue * CPU_VALUE_FACTOR + this.departmentDeservedCpu - this.projectsDeservedCpu;
    },
    projectsDeservedCpuWithCurrentInput(): number {
      if (this.inputValue !== null) {
        return this.innerProjectsDeservedCpu + this.inputValue * CPU_VALUE_FACTOR;
      }
      return this.innerProjectsDeservedCpu;
    },
    isInputInvalid(): boolean {
      return (
        this.isLimitQuotaOverSubscriptionEnabled && (this.isCpuExceedsQuota || this.isCpuUnderAllocatedNonPreemptible)
      );
    },
    isCpuUnderAllocatedNonPreemptible(): boolean {
      if (!this.isDepartmentEnabled) return false;
      if (this.inputValue === null) return false;
      return resourceUtil.isResourceUnderAllocatedNonPreemptible(
        this.inputValue || 0,
        this.allocatedNonPreemptibleCpu / CPU_VALUE_FACTOR,
      );
    },
    isCpuExceedsQuota(): boolean {
      if (!this.isDepartmentEnabled) return false;
      if (this.isDepartmentEntity) {
        if (this.inputValue === null) return false;
        return this.inputValue < this.projectsDeservedCpu / CPU_VALUE_FACTOR;
      }

      if (this.inputValue === null && !this.departmentDeservedCpuIsUnlimited) return true;
      return resourceUtil.isResourceExceedsQuota(
        this.projectsDeservedCpuWithCurrentInput,
        this.departmentDeservedCpu,
        this.departmentDeservedCpuIsUnlimited,
      );
    },
    editableText() {
      if (this.modelValue === null) return EResourceState.Unlimited;
      return this.modelValue.toFixed(2);
    },
    alertText(): string {
      if (this.isDepartmentEntity) {
        return `The department's quota is less than the quota assigned to its subordinate projects. To remain within the limit, enter a number above ${
          (this.projectsDeservedCpu || 0) / CPU_VALUE_FACTOR
        }.`;
      }
      if (this.isCpuExceedsQuota) {
        const remainQuota =
          this.departmentDeservedCpu - (this.projectsDeservedCpu - (this.modelValue || 0) * CPU_VALUE_FACTOR);
        return remainQuota < 0
          ? "The project's quota exceeds the available department quota"
          : `The project's quota exceeds the available department quota. To remain within the quota, enter a number up to ${
              remainQuota / CPU_VALUE_FACTOR
            }.`;
      }
      if (this.isCpuUnderAllocatedNonPreemptible) {
        return `The project's quota is less than the usage by non-preemptive workloads. To remain above the usage, enter a number above ${
          this.allocatedNonPreemptibleCpu / CPU_VALUE_FACTOR
        }.`;
      }
      return "";
    },
  },
  methods: {
    resetValue(): void {
      this.inputValue = this.modelValue;
    },
    updateModelValue(): void {
      if (this.isInputInvalid) return;
      this.$emit("update:model-value", this.inputValue);
    },
  },
});
</script>
<style lang="scss" scoped>
.card-wrapper {
  font-weight: 500 !important;
  font-size: 14px;
}
.quota-input {
  max-width: 231px;
  margin-bottom: 30px;
}
.with-chart {
  min-height: 400px;
  width: 400px;
}
.input-row {
  height: 110px;
}
.editable-button {
  cursor: pointer;
  width: 100px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid $black-54;
  text-align: center;
  box-shadow: 0 1px 6px $black-15;
  color: $black-70;
  background-color: white;
  font-weight: 500;
  transition: box-shadow 0.3s;
  &:hover {
    box-shadow: 0 1px 6px 2px $black-25;
  }
}
.primary-border {
  border: 2px solid $primary;
}
.amber-14-border {
  border: 2px solid $amber-14;
}
.negative-border {
  border: 2px solid $negative;
}
.quota-exceeds-alert {
  padding-right: 10px;
  margin: 5px 0;
  font-size: 12px;
  max-width: 231px;
  top: 65px;
  position: absolute;
  line-height: 12px;
}
</style>
