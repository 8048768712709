import ProjectIndex from "@/views/projects/project-index.vue";
import ProjectEdit from "@/views/projects/project-edit.vue";
import { ResourceType, Action } from "@/swagger-models/authorization-client";
import { PROJECT_ROUTE_NAMES } from "./project.routes.names";
import type { RunaiRouteRecord } from "vue-router";

export const projectRoutes: RunaiRouteRecord = {
  path: "/projects",
  component: () => import("@/views/projects/project-app.vue"),
  children: [
    {
      path: "",
      name: PROJECT_ROUTE_NAMES.PROJECT_INDEX,
      component: ProjectIndex,
      meta: {
        pageTitle: "Projects",
        resourceType: ResourceType.Project,
        minPermittedActions: [Action.Read],
        requiresAuth: true,
        hideClusterSelection: true,
      },
    },
    {
      path: "new",
      name: PROJECT_ROUTE_NAMES.PROJECT_NEW,
      component: ProjectEdit,
      meta: {
        pageTitle: "New project",
        fullWidth: true,
        requestToLeave: true,
        backPageName: PROJECT_ROUTE_NAMES.PROJECT_INDEX,
        resourceType: ResourceType.Project,
        minPermittedActions: [Action.Create],
        requiresAuth: true,
        hideClusterSelection: true,
      },
    },
    {
      path: "edit/:id",
      name: PROJECT_ROUTE_NAMES.PROJECT_EDIT,
      component: ProjectEdit,
      meta: {
        pageTitle: "Edit project",
        fullWidth: true,
        requestToLeave: true,
        backPageName: PROJECT_ROUTE_NAMES.PROJECT_INDEX,
        resourceType: ResourceType.Project,
        minPermittedActions: [Action.Update],
        requiresAuth: true,
        hideClusterSelection: true,
      },
    },
  ],
};
