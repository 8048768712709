<template>
  <button :disabled="readOnly" class="editable-button" v-bind="$attrs" :class="buttonClass" type="button">
    {{ inputPlaceholder }}
    <q-menu anchor="bottom middle" self="top middle" @hide="initInput" v-model="isOpen">
      <q-card :class="displayChart ? 'with-chart' : ''">
        <q-card-section class="row justify-between items-center q-py-sm card-wrapper">
          {{ $options.RESOURCE_LABEL.MEMORY }}
          <q-btn flat size="12px" icon="fa-regular fa-xmark" class="q-pa-xs" round v-close-popup></q-btn>
        </q-card-section>
        <q-card-section class="q-py-sm">
          <div class="row" :class="{ 'input-row': isDepartmentEnabled }">
            <div class="col-9">
              <runai-numeric-input
                class="q-mr-md"
                :class="{ 'quota-input': isDepartmentEnabled }"
                aid="memory-quota-input"
                ref="numeric-input"
                :autofocus="true"
                v-model.number="inputValue"
                :placeholder="$options.EResourceState.Unlimited"
                input-class="wide-input"
                no-error-icon
                stack-label
                :read-only="readOnly"
              >
                <template v-if="isMemoryExceedsQuota || isMemoryUnderAllocatedNonPreemptible" #error>
                  <div v-text="alertText" class="quota-exceeds-alert" :class="textAlertClass"></div>
                </template>
              </runai-numeric-input>
            </div>
            <q-select
              aid="memory-unit-select"
              class="col-3"
              :options="$options.memoryUnitOptions"
              :model-value="selectedUnit"
              @update:model-value="updateSelectedUnit"
            />
          </div>
          <template v-if="!hideSlider">
            <div class="row q-my-xl">
              <div class="col q-pr-xl">
                <quota-slider
                  v-model="inputValue"
                  :max="departmentDeservedMemoryConverted"
                  :inner-max="sliderInnerMax"
                  :inner-min="sliderInnerMin"
                  :is-warning-on="isMemoryExceedsQuota || isMemoryUnderAllocatedNonPreemptible"
                  :is-limited="isLimitQuotaOverSubscriptionEnabled"
                  inner-max-marker-text="Department available quota"
                  :inner-min-marker-text="innerMinMarkerText"
                  :hide-max="isDepartmentEntity"
                />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="italic text-caption">
                  For more information, see the
                  <a :href="documentationLink" target="_blank">{{ documentationLinkText }}</a>
                </div>
              </div>
            </div>
          </template>
        </q-card-section>
        <q-card-section class="row justify-end q-py-sm">
          <q-btn
            aid="save-quota-btn"
            flat
            color="primary"
            label="Apply"
            v-close-popup="!isInputInvalid"
            @click="updateModelValue"
          />
        </q-card-section>
      </q-card>
    </q-menu>
  </button>
  <q-tooltip v-if="isAlertOn" max-width="350px" :offset="[180, 0]" :class="tooltipAlertClass" anchor="top right"
    >{{ alertText }}
  </q-tooltip>
</template>

<script lang="ts">
import { inject, type PropType, ref, toRef } from "vue";
import { defineComponent } from "vue";

// cmps
import { RunaiNumericInput } from "@/components/common/runai-numeric-input";

// models
import { EMemoryUnit, EQuotaEntity, EResourceLabel, EResourceState, memoryUnitOptions } from "@/models/resource.model";
//utils
import { resourceUtil } from "@/utils/resource.util";
//stores
import { QuotaSlider } from "@/components/quota-management/quota-slider";
import {
  useQuotaInput,
  type useQuotaInputProperties,
} from "@/components/quota-management/quota-inputs/quota-input-composable/use-quota-input.composeable";

export default defineComponent({
  components: {
    QuotaSlider,
    RunaiNumericInput,
  },
  emits: ["update:model-value", "close"],
  inject: ["entity"],
  memoryUnitOptions: memoryUnitOptions,
  EResourceState: EResourceState,
  RESOURCE_LABEL: EResourceLabel,
  props: {
    modelValue: {
      type: [Number, null] as PropType<number | null>,
      required: true,
    },
    readOnly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    projectsDeservedMemory: {
      type: Number as PropType<number>,
      default: 0,
    },
    departmentDeservedMemory: {
      type: Number as PropType<number>,
      default: 0,
    },
    allocatedNonPreemptibleMemory: {
      type: Number as PropType<number>,
      default: 0,
    },
    isAlertOn: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    departmentDeservedMemoryIsUnlimited: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    projectsDeservedMemoryIsUnlimited: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    hideSlider: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputValue: 0 as number | null,
      inputPlaceholder: "" as string,
      selectedUnit: EMemoryUnit.MB as EMemoryUnit,
    };
  },
  setup(props) {
    const entity = ref(inject<EQuotaEntity>("entity"));
    if (!entity.value) {
      throw new Error("'entity' is not provided");
    }
    const {
      isProjectEntity,
      isOpen,
      isLimitQuotaOverSubscriptionEnabled,
      isDepartmentEnabled,
      documentationLink,
      documentationLinkText,
      innerMinMarkerText,
      buttonClass,
      textAlertClass,
      tooltipAlertClass,
    }: useQuotaInputProperties = useQuotaInput(entity.value, toRef(props, "isAlertOn"));
    return {
      isProjectEntity,
      isOpen,
      isLimitQuotaOverSubscriptionEnabled,
      isDepartmentEnabled,
      documentationLink,
      documentationLinkText,
      innerMinMarkerText,
      buttonClass,
      textAlertClass,
      tooltipAlertClass,
    };
  },
  created() {
    this.initInput();
  },
  computed: {
    sliderInnerMin(): number {
      return this.isDepartmentEntity
        ? Math.round(this.fromMibToMemoryUnit(this.projectsDeservedMemory))
        : this.allocatedNonPreemptibleMemoryConverted;
    },
    sliderInnerMax(): number | undefined {
      return this.isDepartmentEntity ? undefined : this.departmentAvailableQuota;
    },
    isDepartmentEntity(): boolean {
      return this.entity === EQuotaEntity.department;
    },
    EQuotaEntity(): typeof EQuotaEntity {
      return EQuotaEntity;
    },
    departmentDeservedMemoryConverted(): number {
      return Math.round(this.fromMibToMemoryUnit(this.departmentDeservedMemory));
    },
    allocatedNonPreemptibleMemoryConverted(): number {
      return Math.round(this.fromMibToMemoryUnit(this.allocatedNonPreemptibleMemory));
    },
    innerProjectsDeservedMemory(): number {
      if (this.modelValue === null) {
        return this.projectsDeservedMemory;
      }
      return this.projectsDeservedMemory - this.modelValue;
    },
    departmentAvailableQuota(): number {
      const quotaAdjustment = this.modelValue ?? 0;
      const availableQuota = this.departmentDeservedMemory - this.projectsDeservedMemory + quotaAdjustment;

      return Math.round(this.fromMibToMemoryUnit(availableQuota));
    },
    projectsDeservedMemoryWithCurrentInput(): number {
      if (this.inputValue !== null) {
        if (this.selectedUnit === EMemoryUnit.MB) {
          return this.innerProjectsDeservedMemory + resourceUtil.fromMbToMib(this.inputValue);
        } else {
          return this.innerProjectsDeservedMemory + resourceUtil.fromGbToMib(this.inputValue);
        }
      }
      return this.innerProjectsDeservedMemory;
    },
    isInputInvalid(): boolean {
      return (
        this.isLimitQuotaOverSubscriptionEnabled &&
        (this.isMemoryExceedsQuota || this.isMemoryUnderAllocatedNonPreemptible)
      );
    },
    isMemoryUnderAllocatedNonPreemptible(): boolean {
      if (this.isDepartmentEntity || !this.isDepartmentEnabled) return false;
      if (this.inputValue === null) return false;
      const inputValue =
        this.selectedUnit === EMemoryUnit.MB
          ? resourceUtil.fromMbToMib(this.inputValue)
          : resourceUtil.fromGbToMib(this.inputValue);
      return resourceUtil.isResourceUnderAllocatedNonPreemptible(inputValue, this.allocatedNonPreemptibleMemory);
    },
    isMemoryExceedsQuota(): boolean {
      if (!this.isDepartmentEnabled) return false;
      if (this.isDepartmentEntity) {
        if (this.inputValue === null) return false;
        return this.inputValue < this.projectsDeservedMemory;
      }

      if (this.inputValue === null && !this.departmentDeservedMemoryIsUnlimited) return true;
      if (Math.trunc(this.departmentDeservedMemory) === Math.trunc(this.projectsDeservedMemoryWithCurrentInput)) {
        return false;
      }
      return resourceUtil.isResourceExceedsQuota(
        this.projectsDeservedMemoryWithCurrentInput,
        this.departmentDeservedMemory,
        this.departmentDeservedMemoryIsUnlimited,
      );
    },
    displayChart(): boolean {
      return this.isDepartmentEnabled;
    },
    alertText(): string {
      if (this.isDepartmentEntity) {
        return `The department's quota is less than the quota assigned to its subordinate projects. To remain within the limit, enter a number above ${Math.round(
          this.fromMibToMemoryUnit(this.projectsDeservedMemory),
        )} ${this.selectedUnit}.`;
      }
      if (this.isMemoryExceedsQuota) {
        const remainQuota = this.departmentDeservedMemory - (this.projectsDeservedMemory - (this.modelValue || 0));
        return remainQuota < 0
          ? "The project's quota exceeds the available department quota"
          : `The project's quota exceeds the available department quota. To remain within the quota, enter a number up to ${Math.round(
              this.fromMibToMemoryUnit(remainQuota),
            )} ${this.selectedUnit}.`;
      }
      if (this.isMemoryUnderAllocatedNonPreemptible) {
        return `The project's quota is less than the usage by non-preemptive workloads. To remain above the usage, enter a number above ${Math.round(
          this.fromMibToMemoryUnit(this.allocatedNonPreemptibleMemory),
        )} ${this.selectedUnit}`;
      }
      return "";
    },
  },
  methods: {
    initInput(): void {
      if (this.modelValue !== null) {
        const convertedValue = resourceUtil.convertMemoryValue(this.modelValue);
        this.inputValue = parseFloat(convertedValue.value.toFixed(2));
        this.selectedUnit = convertedValue.selectedUnit;
        this.inputPlaceholder = `${this.inputValue} ${convertedValue.selectedUnit}`;
      } else {
        this.inputPlaceholder = EResourceState.Unlimited;
        this.inputValue = null;
      }
    },
    updateModelValue(): void {
      if (this.isInputInvalid) return;
      if (this.inputValue === null) {
        this.inputPlaceholder = EResourceState.Unlimited;
        this.$emit("update:model-value", null);
      } else if (this.selectedUnit === EMemoryUnit.MB) {
        this.$emit("update:model-value", resourceUtil.fromMbToMib(this.inputValue));
        this.inputPlaceholder = `${this.inputValue} ${this.selectedUnit}`;
      } else if (this.selectedUnit === EMemoryUnit.GB) {
        this.$emit("update:model-value", resourceUtil.fromGbToMib(this.inputValue));
        this.inputPlaceholder = `${this.inputValue} ${this.selectedUnit}`;
      }
    },
    updateSelectedUnit(unit: EMemoryUnit): void {
      this.selectedUnit = unit;
    },
    fromMibToMemoryUnit(mib: number): number {
      return resourceUtil.fromMibToMemoryUnit(this.selectedUnit, mib);
    },
  },
});
</script>

<style lang="scss" scoped>
.card-wrapper {
  font-weight: 500 !important;
  font-size: 14px;
}
.quota-input {
  max-width: 231px;
  margin-bottom: 30px;
}
.with-chart {
  min-height: 400px;
  width: 400px;
}
.input-row {
  height: 110px;
}
.editable-button {
  cursor: pointer;
  width: 100px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid $black-54;
  text-align: center;
  box-shadow: 0 1px 6px $black-15;
  color: $black-70;
  background-color: white;
  font-weight: 500;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 1px 6px 2px $black-25;
  }
}
.primary-border {
  border: 2px solid $primary;
}
.amber-14-border {
  border: 2px solid $amber-14;
}
.negative-border {
  border: 2px solid $negative;
}
.quota-exceeds-alert {
  padding-right: 10px;
  margin: 5px 0;
  font-size: 12px;
  max-width: 231px;
  top: 65px;
  position: absolute;
  line-height: 12px;
}
</style>
