<template>
  <chart-widget-wrapper
    :options="wrapperOptions"
    :loading="displayLoading"
    :error="error"
    @link-clicked="redirectToEntityIndex"
    hide-actions
    :empty="isChartEmpty"
  >
    <highcharts v-if="chartOptions" :options="chartOptions" ref="chart" key="gpu-allocation-ratio-chart" />
  </chart-widget-wrapper>
</template>
<script lang="ts">
import ChartWidgetWrapper from "@/components/dashboard-v2/widgets/common/widget-wrapper/chart-widget-wrapper/chart-widget-wrapper.vue";
import type { BreadcrumbOptions } from "highcharts";

const UNKNOWN_RESOURCE = "Unknown";
import { defineComponent, type PropType } from "vue";
import {
  type ChartDataMapItem,
  type DepartmentsMetricData,
  DRILL_DOWN_TITLES,
  DRILL_DOWN_TITLES_PROJECTS,
  EDrillDownType,
  type IChartArrayItemConfig,
  type IChartMapItemConfig,
  type IResourceData,
  type MetricsData,
} from "@/models/metrics.model";
import { useNodePoolStore } from "@/stores/node-pool.store";
import { ACTIVE_AXIS_LABEL_COLOR, EChartType, EWidgetEntity, type IWidgetWrapperOptions } from "@/models/chart.model";
import { useSettingStore } from "@/stores/setting.store";
import { departmentService } from "@/services/control-plane/department.service/department.service";
import { useClusterStore } from "@/stores/cluster.store";
import { chartUtil } from "@/utils/chart.util";
//Highcharts
import Highcharts, { type Options as HighchartsOptions } from "highcharts";
import { Chart } from "highcharts-vue";
import drilldown from "highcharts/modules/drilldown";
import { HttpErrorResponse } from "@/models/http-response.model";
import type { IFilterBy, IFilterModel } from "@/models/filter.model";
import commonUtil from "@/utils/common.util/common.util";
import { widgetUtil } from "@/utils/widget.util";
import { filterService } from "@/services/filter.service/filter.service";
import { ETableFilters } from "@/models/table.model";
import { allProjectColumns } from "@/table-models/project.table-model";
import { departmentIndexColumns } from "@/table-models/department.table-model";
import { EDepartmentColumnName } from "@/models/department.model";
import { EProjectColumnName } from "@/models/project.model";
import { PROJECT_ROUTE_NAMES } from "@/router/project.routes/project.routes.names";
import { DEPARTMENT_ROUTE_NAMES } from "@/router/department.routes/department.routes.names";

drilldown(Highcharts);
export default defineComponent({
  name: "gpu-allocation-ratio-widget",
  components: {
    ChartWidgetWrapper,
    Highcharts: Chart,
  },
  props: {
    filterBy: {
      type: Array as PropType<IFilterModel[]>,
      required: false,
    },
  },
  data() {
    return {
      nodePoolStore: useNodePoolStore(),
      clusterStore: useClusterStore(),
      settingStore: useSettingStore(),
      nodePoolsNames: [] as string[],
      allocationClusterByNodePoolArray: [] as IChartArrayItemConfig[],
      allocationDepartmentsByNodePoolArray: [] as IChartArrayItemConfig[],
      displayLoading: true as boolean,
      error: false as boolean,
      isChartEmpty: false as boolean,
      chartOptions: null as null | HighchartsOptions,
      wrapperOptions: {
        title: "Allocation ratio by node pool",
        timeFrame: "Now",
        linkText: "",
        entityType: EWidgetEntity.Department,
        tooltipText: "This ratio (allocation/quota) shows how well <br/>the department or project is using its quota",
      } as IWidgetWrapperOptions,
    };
  },
  async created() {
    this.initWrapperOptions();
    await this.getInitialData();
    this.displayLoading = false;
  },
  computed: {
    EChartType(): typeof EChartType {
      return EChartType;
    },
    isOnlyDefaultNodePool(): boolean {
      return this.nodePoolStore.isOnlyDefaultNodePool;
    },
    byNodePoolTitle(): string {
      return this.isOnlyDefaultNodePool ? "" : "by node pool";
    },
    isDepartmentEnabled(): boolean {
      return this.settingStore.isDepartmentEnabled;
    },
    drilldownTitles(): string[] {
      return this.isDepartmentEnabled ? DRILL_DOWN_TITLES : DRILL_DOWN_TITLES_PROJECTS;
    },
    nodePoolFilter(): string | undefined {
      const nodePoolFilter = this.filterBy?.find((filter: IFilterModel) => filter.field === "nodepool");
      if (nodePoolFilter) {
        return nodePoolFilter.term;
      }
      return undefined;
    },
  },
  methods: {
    initWrapperOptions(): void {
      if (this.isDepartmentEnabled) {
        this.wrapperOptions.linkText = "All departments";
      } else {
        this.wrapperOptions.linkText = "All projects";
      }
    },
    async getInitialData(): Promise<void> {
      await this.loadInitialChartData();
      this.loadChartOptions();
      this.setChartData();
    },
    loadChartOptions(): void {
      this.chartOptions = {
        ...chartUtil.getBasicWidgetChartOptions({
          yAxisTitle: "Allocation ratio",
          type: "column",
          height: 240,
          yAxisFormatFunction: (value: number | string): string => `${value}%`,
        }),
        drilldown: {
          breadcrumbs: {
            showFullPath: true,
            buttonTheme: {
              style: {
                color: "#0654FE",
              },
            },
            // @ts-ignore
            formatter: this.formatBreadcrumbs,
          },
          allowPointDrilldown: false,
          activeAxisLabelStyle: {
            color: ACTIVE_AXIS_LABEL_COLOR,
          },
          activeDataLabelStyle: {
            color: ACTIVE_AXIS_LABEL_COLOR,
          },
        },
      };
      //@ts-ignore
      this.chartOptions.tooltip.formatter = function () {
        const nodePoolName = this.point.series.name;
        const allocationRatio = commonUtil.formatNumberWithFixedDecimals(this.y as number, 2) + "%";
        const gpuAllocation = commonUtil.formatNumberWithFixedDecimals(this.point.meta.allocated, 2);
        const gpuQuota = commonUtil.formatNumberWithFixedDecimals(this.point.meta.quota, 2);

        return `<span style="color:${this.color}">●</span> Node pool:  <span class="text-bold">${nodePoolName}</span><br> <span>&nbsp;&nbsp;&nbsp;GPU allocation ratio: <span class="text-bold"> ${allocationRatio} </span></span>
         <br> <span>&nbsp;&nbsp;&nbsp;GPU allocation: <span class="text-bold">${gpuAllocation}</span></span>
         <br> <span>&nbsp;&nbsp;&nbsp;GPU quota: <span class="text-bold">${gpuQuota}</span></span>
      `;
      };
    },
    async loadInitialChartData(): Promise<void> {
      try {
        const res: DepartmentsMetricData = await departmentService.getDepartmentsMetric(
          this.clusterStore.currentClusterId,
          this.nodePoolFilter,
        );
        const topAllocationRatioProjects = widgetUtil.filterTopAllocationRatioProjects(res.data);
        this.nodePoolsNames = this.extractNodepoolNames(topAllocationRatioProjects);
        this.transformDataIntoHighChartFormat(topAllocationRatioProjects);
      } catch (e: unknown) {
        this.handleError(e);
      } finally {
        this.displayLoading = false;
      }
    },
    buildResourcesByNodePoolMap(
      resourceMap: Record<string, IChartMapItemConfig>,
      resourceKey: string,
      resourceName: string,
      drilldownTo: string,
      projectResource: IResourceData,
      drilldownId: string,
    ): void {
      if (!resourceMap[resourceKey]) {
        resourceMap[resourceKey] = {
          id: drilldownId,
          name: resourceName,
          data: {},
        };
      }
      if (!resourceMap[resourceKey].data[resourceName]) {
        resourceMap[resourceKey].data[resourceName] = {
          name: resourceName,
          quotaY: projectResource.gpu.quota,
          allocatedY: projectResource.gpu.allocated,
          drilldown: drilldownTo ? `${drilldownTo}-${resourceName}` : "",
        };
      } else {
        resourceMap[resourceKey].data[resourceName].quotaY += projectResource.gpu.quota;
        resourceMap[resourceKey].data[resourceName].allocatedY += projectResource.gpu.allocated;
      }
    },
    filterOutZeroY(data: IChartArrayItemConfig[]): IChartArrayItemConfig[] {
      return data
        .map((item) => {
          const filteredData = item.data.filter((d) => d.y !== 0);
          return {
            ...item,
            data: filteredData,
          };
        })
        .filter((item) => item.data.length > 0);
    },
    setChartData(): void {
      const filteredChartData = this.filterOutZeroY(this.allocationClusterByNodePoolArray);
      if (this.chartOptions !== null) {
        if (filteredChartData.length === 0) {
          this.isChartEmpty = true;
        } else {
          this.isChartEmpty = false;
          //@ts-ignore
          this.chartOptions.series = this.sortByNodepoolName(filteredChartData);
        }
      }

      //@ts-ignore
      this.chartOptions.drilldown.series = this.allocationDepartmentsByNodePoolArray;
      this.fixNodePoolsNames();
    },
    sortByNodepoolName(array: IChartArrayItemConfig[]): IChartArrayItemConfig[] {
      return array.sort((a: IChartArrayItemConfig, b: IChartArrayItemConfig) => {
        const nodepoolNameA = a.name.toLowerCase();
        const nodepoolNameB = b.name.toLowerCase();
        return nodepoolNameA.localeCompare(nodepoolNameB);
      });
    },
    transformDataIntoHighChartFormat(data: Array<MetricsData>) {
      const clusterByNodePoolMap: Record<string, IChartMapItemConfig> = {};
      const departmentsByNodePoolMap: Record<string, IChartMapItemConfig> = {};
      const projectsByNodePoolAndDepartmentsMap: Record<string, IChartMapItemConfig> = {};
      const projectsByNodePoolsMap: Record<string, IChartMapItemConfig> = {};

      for (const departmentData of data) {
        const clusterName = departmentData.metadata.clusterName;

        const resources: IResourceData[] = departmentData.current.projectResources
          ? departmentData.current.projectResources
          : departmentData.current.resources;

        if (this.isDepartmentEnabled) {
          for (const departmentResource of departmentData.current.resources) {
            const nodepoolName = departmentResource.nodepoolName;

            this.buildResourcesByNodePoolMap(
              clusterByNodePoolMap,
              nodepoolName,
              clusterName,
              this.getDrilldownId(
                EDrillDownType.Clusters,
                clusterName,
                nodepoolName,
                departmentData.metadata.departmentName || UNKNOWN_RESOURCE,
              ),
              departmentResource,
              "",
            );
          }
        }

        for (const projectResource of resources) {
          const departmentName: string = projectResource.departmentName
            ? projectResource.departmentName
            : departmentData.metadata.departmentName || UNKNOWN_RESOURCE;
          const projectName: string = projectResource.projectName
            ? projectResource.projectName
            : departmentData.metadata.projectName || UNKNOWN_RESOURCE;

          const nodepoolName = projectResource.nodepoolName;

          if (!this.isDepartmentEnabled) {
            this.buildResourcesByNodePoolMap(
              clusterByNodePoolMap,
              nodepoolName,
              clusterName,
              this.getDrilldownId(EDrillDownType.Clusters, clusterName, nodepoolName, departmentName),
              projectResource,
              "",
            );
          }

          this.buildResourcesByNodePoolMap(
            departmentsByNodePoolMap,
            nodepoolName,
            this.isDepartmentEnabled ? departmentName : (projectName as string),
            this.isDepartmentEnabled ? `projects-${nodepoolName}` : "",
            projectResource,
            this.getDrilldownId(EDrillDownType.Departments, clusterName, nodepoolName, departmentName),
          );
          this.buildResourcesByNodePoolMap(
            projectsByNodePoolAndDepartmentsMap,
            nodepoolName + "-" + this.isDepartmentEnabled ? nodepoolName + "-" + departmentName : projectName,
            projectName,
            "",
            projectResource,
            this.getDrilldownId(EDrillDownType.Projects, clusterName, nodepoolName, departmentName),
          );
          this.buildResourcesByNodePoolMap(
            projectsByNodePoolsMap,
            nodepoolName,
            projectName,
            "",
            projectResource,
            this.getDrilldownId(EDrillDownType.Projects, clusterName, nodepoolName, departmentName),
          );
        }
      }

      this.convertResourceMapToChartArrayData(clusterByNodePoolMap, this.allocationClusterByNodePoolArray);
      this.convertResourceMapToChartArrayData(departmentsByNodePoolMap, this.allocationDepartmentsByNodePoolArray);
      if (this.isDepartmentEnabled) {
        this.convertResourceMapToChartArrayData(
          projectsByNodePoolAndDepartmentsMap,
          this.allocationDepartmentsByNodePoolArray,
        );
      }
    },
    getDrilldownId(drillDownType: EDrillDownType, cluster: string, nodepool: string, department: string): string {
      switch (drillDownType) {
        case EDrillDownType.Clusters:
          return this.isDepartmentEnabled ? `departments-${nodepool}` : `projects-${nodepool}`;
        case EDrillDownType.Departments:
          return this.isDepartmentEnabled ? `departments-${nodepool}-${cluster}` : `projects-${nodepool}-${cluster}`;
        case EDrillDownType.Projects:
          return this.isDepartmentEnabled ? `projects-${nodepool}-${department}` : `projects-${nodepool}`;
      }
    },
    convertResourceMapToChartArrayData(
      resourceMap: Record<string, IChartMapItemConfig>,
      chartArray: IChartArrayItemConfig[],
    ): void {
      const calculateAllocationRatio = (quota: number, allocated: number): number => {
        if (quota === 0) return 0;
        return parseFloat(((allocated / quota) * 100).toFixed(2));
      };
      const getCorrectNodePoolName = (mixedName: string) => {
        const correctName = this.nodePoolsNames.find(
          (name) => mixedName.startsWith(`${name}`) && mixedName.length !== name.length,
        );
        return correctName || null;
      };

      for (const nodepoolName in resourceMap) {
        const nodePoolData = resourceMap[nodepoolName];
        const data: Array<ChartDataMapItem> = Object.values(nodePoolData.data);

        const item: IChartArrayItemConfig = {
          id: nodePoolData.id,
          name: nodepoolName,
          meta: {
            correctName: getCorrectNodePoolName(nodepoolName),
          },
          data: data.map((entry: ChartDataMapItem) => {
            return {
              name: entry.name,
              y: calculateAllocationRatio(entry.quotaY, entry.allocatedY),
              drilldown: entry.drilldown,
              meta: {
                quota: entry.quotaY,
                allocated: entry.allocatedY,
              },
            };
          }),
        };

        chartArray.push(item);
      }
    },
    extractNodepoolNames(data: MetricsData[]): string[] {
      const nodepoolNames = new Set<string>();

      data.forEach((item: MetricsData) => {
        item.current.resources.forEach((resource) => {
          nodepoolNames.add(resource.nodepoolName);
        });

        item.current.projectResources.forEach((projectResource) => {
          nodepoolNames.add(projectResource.nodepoolName);
        });
      });

      return Array.from(nodepoolNames);
    },
    fixNodePoolsNames(): void {
      //@ts-ignore
      this.chartOptions.drilldown.series.forEach((series) => {
        //@ts-ignore
        if (series.meta.correctName !== null) {
          //@ts-ignore
          series.name = series.meta.correctName;
        }
      });
    },
    formatBreadcrumbs(breadcrumbOptions: BreadcrumbOptions): string {
      if (this.drilldownTitles) {
        switch (breadcrumbOptions.level) {
          case 0:
            // @ts-ignore
            return breadcrumbOptions.levelOptions?.data[0].name;
          case 1:
            return this.drilldownTitles[1];
          case 2:
            return breadcrumbOptions?.levelOptions?.name as string;
        }
      }
      return breadcrumbOptions?.levelOptions?.name as string;
    },
    handleError(error: unknown): void {
      this.error = true;
      if (error instanceof HttpErrorResponse) {
        console.error(error.message);
      } else {
        console.error("Error fetching data", error);
      }
    },
    resetData(): void {
      this.chartOptions = null;
      this.nodePoolsNames = [];
      this.allocationClusterByNodePoolArray = [];
      this.allocationDepartmentsByNodePoolArray = [];
    },
    redirectToEntityIndex(): void {
      this.setColumnFilter();
      const routeName = this.isDepartmentEnabled
        ? DEPARTMENT_ROUTE_NAMES.DEPARTMENT_INDEX
        : PROJECT_ROUTE_NAMES.PROJECT_INDEX;
      this.$router.push({
        name: routeName,
      });
    },
    setColumnFilter(): void {
      if (this.nodePoolFilter) {
        this.setNodePoolColumnFilter(this.getNodesFilterBy());
      }
    },
    getNodesFilterBy(): IFilterBy {
      const columns = this.isDepartmentEnabled ? departmentIndexColumns : allProjectColumns;
      const sortBy = this.isDepartmentEnabled ? EDepartmentColumnName.DepartmentName : EProjectColumnName.ProjectName;
      const tableFilterName = this.isDepartmentEnabled ? ETableFilters.DEPARTMENT : ETableFilters.PROJECT;
      const defaultFilters: IFilterBy = filterService.getDefaultFilters(sortBy, columns);
      return filterService.loadFilters(window.location, tableFilterName, defaultFilters);
    },
    setNodePoolColumnFilter(nodesFilterBy: IFilterBy): void {
      const filterName = this.isDepartmentEnabled ? EDepartmentColumnName.NodePools : EProjectColumnName.NodePools;
      const tableFilterName = this.isDepartmentEnabled ? ETableFilters.DEPARTMENT : ETableFilters.PROJECT;
      filterService.setColumnFilter(nodesFilterBy, this.nodePoolFilter as string, filterName, tableFilterName);
    },
    removeNodePoolFilter(nodesFilterBy: IFilterBy): void {
      const tableFilterName = this.isDepartmentEnabled ? ETableFilters.DEPARTMENT : ETableFilters.PROJECT;
      const filterName = this.isDepartmentEnabled ? EDepartmentColumnName.NodePools : EProjectColumnName.NodePools;
      filterService.removeColumnFilter(nodesFilterBy, filterName, tableFilterName);
    },
  },
  watch: {
    filterBy: {
      async handler(): Promise<void> {
        this.displayLoading = true;
        this.resetData();
        await this.getInitialData();
      },
      deep: true,
    },
  },
});
</script>
<style lang="scss"></style>
