import { type IWorkloadCreateFormConfig, EWorkloadSectionNames } from "./workload-create-form.model";

export const workloadCreateFormUtil = {
  getWorkloadSectionDefaultsConfig,
};

function getWorkloadSectionDefaultsConfig(sectionNames: Array<EWorkloadSectionNames>): IWorkloadCreateFormConfig {
  const defaultsOptions: IWorkloadCreateFormConfig = _getDefaultsOptions();

  if (sectionNames.length === 0) return {};

  return sectionNames.reduce((config: IWorkloadCreateFormConfig, sectionName: EWorkloadSectionNames) => {
    if (defaultsOptions[sectionName]) {
      // @ts-ignore
      config[sectionName] = defaultsOptions[sectionName];
    }

    return config;
  }, {});
}

function _getDefaultsOptions(): IWorkloadCreateFormConfig {
  const defaultsOptions: IWorkloadCreateFormConfig = {
    [EWorkloadSectionNames.ToggleEditingForm]: {
      sectionOptions: {
        mainTitle: "",
      },
    },
    [EWorkloadSectionNames.Project]: {
      projects: [],
    },
    [EWorkloadSectionNames.MultiNode]: {
      sectionDefaultOpen: false,
    },
    [EWorkloadSectionNames.WorkloadName]: {
      sectionDefaultOpen: false,
    },
    [EWorkloadSectionNames.Credentials]: {
      sectionDefaultOpen: true,
    },
    [EWorkloadSectionNames.ModelName]: {
      sectionDefaultOpen: true,
    },
    [EWorkloadSectionNames.Environment]: {
      environments: [],
      sectionDefaultOpen: true,
      sectionOptions: {
        canAddEnvVariable: true,
        isToolBoxRequired: true,
      },
    },
    [EWorkloadSectionNames.Compute]: {
      sectionDefaultOpen: true,
      isRequired: true,
      computeResources: [],
      sectionOptions: {
        applyPolicyDefaults: false,
      },
    },
    [EWorkloadSectionNames.DataSource]: {
      dataSources: [],
    },
    [EWorkloadSectionNames.Volumes]: {
      storageClasses: [],
      sectionDefaultOpen: false,
    },
    [EWorkloadSectionNames.General]: {
      sectionDefaultOpen: false,
    },
  };

  return defaultsOptions;
}
